import React from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const notifyTopRight = (notiText) => {
  console.log(notiText);
  toast.success(`✅ ${notiText} !`, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

const notifyInfo = (notiText) => {
  toast.info(`❓ ${notiText} !`, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
const notifyWarning = (notiText) => {
  toast.warn(`❗ ${notiText} !`, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

// const notifyError = (notiText) => {
//   toast.error(`❌ ${notiText} !`, {
//     position: "top-right",
//     autoClose: 15000,
//     hideProgressBar: false,
//     closeOnClick: true,
//     pauseOnHover: true,
//     draggable: true,
//     progress: undefined,
//   });
// };
const notifyError = (notiText) => {
  toast.error(<div style={{ whiteSpace: "pre-line" }}>{notiText}</div>, {
    position: "top-right",
    autoClose: 15000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const Toast = () => {
  return (
    // <ToastContainer
    //   position="top-right"
    //   autoClose={3000}
    //   hideProgressBar={false}
    //   newestOnTop
    //   closeOnClick
    //   rtl={false}
    //   pauseOnFocusLoss
    //   draggable
    //   pauseOnHover
    // />
    <ToastContainer />
  );
};

export default Toast;
export { notifyTopRight, notifyInfo, notifyWarning, notifyError };

import React, { Fragment, useState, useRef } from "react";
import _ from "lodash";
import { useAuth } from "../../../context/AuthContext";
import RegisterFields from "./RegisterFields";
import orderFormFields from "./orderFormFields";
export const OrderEditForm = ({
  onChange,
  initailValues,
  fileNames,
  defaultfiles,
  onViewFile,
  warrantyEnd,
  warrantyStart,
  receiptDate,
}) => {
  const inputRef = useRef({});
  const { currentUser } = useAuth();
  // console.log(warrantyEnd);
  // console.log(warrantyStart);
  // console.log(receiptDate);
  const renderFields = () => {
    return _.map(
      orderFormFields,
      ({ label, name, type, options, required, description }) => {
        return (
          <RegisterFields
            name={name}
            label={label}
            type={type}
            value={
              name === "warrantyEndDate"
                ? warrantyEnd
                  ? new Date(warrantyEnd).toLocaleDateString("fr-CA", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })
                  : initailValues[name].toDate().toLocaleDateString("en-CA")
                : name === "warrantyStartDate"
                ? warrantyStart
                  ? new Date(warrantyStart).toLocaleDateString("fr-CA", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })
                  : initailValues[name].toDate().toLocaleDateString("en-CA")
                : name === "receiptDate"
                ? receiptDate
                  ? new Date(receiptDate).toLocaleDateString("fr-CA", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })
                  : initailValues[name].toDate().toLocaleDateString("en-CA")
                : inputRef.current.name
            }
            options={options}
            required={required}
            initailValue={
              type !== "date"
                ? initailValues[name]
                : initailValues[name]
                ? initailValues[name].toDate().toLocaleDateString("en-CA")
                : ""
            }
            disabled={
              // name === "receiptNo" ||
              name === "hn" || name === "refHospital" || name === "refCustomer"
                ? true
                : false
            }
            fileNames={fileNames}
            defaultfiles={defaultfiles}
            onChange={onChange(name)}
            onViewFile={onViewFile}
            description={description}
          />
        );
      }
    );
  };

  return <Fragment>{renderFields()}</Fragment>;
};

import React, { Fragment, useState, useRef, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../../context/AuthContext";
import { db } from "../../../firebase";
import {
  setDoc,
  getDoc,
  updateDoc,
  doc,
  collection,
  getDocs,
  addDoc,
  collectionGroup,
  where,
  query,
} from "firebase/firestore";
// import {
//   TextInputForm,
//   TextInputNotRequiredForm,
//   DropDownForm,
// } from "./CpapUtils";
import { notifyTopRight, notifyWarning, notifyError } from "../toast/SetToast";
import Toast from "../toast/SetToast";
import { Button, Modal, Form, Dropdown } from "react-bootstrap";
import PageTitle from "../../layouts/PageTitle";
import InventoryTable from "./InventoryTable";

const Inventory = () => {
  const { currentUser } = useAuth();

  const [textSearch, setTextSearch] = useState("");
  // const [filterSearch, setFilterSearch] = useState("");
  const [sortBy, setSortBy] = useState("Item Name");
  const [type, setType] = useState("ทั้งหมด");
  const [showModal, setShowModal] = useState(false);
  const [logs, setLogs] = useState([]);
  const [batchLogs, setBatchLogs] = useState([]);
  const [customItemName, setCustomItemName] = useState("");

  const [inventory, setInventory] = useState([]);
  const [newItem, setNewItem] = useState({
    itemId: "",
    itemName: "",
    type: "Mask",
    available: "",
    createdBy: "",
    size: "",
  });

  const onSearchChange = (e) => {
    setTimeout(() => {
      setTextSearch(e.target.value);
      // this.setState({ textSearch: e.target.value });
    }, 800);
  };
  const onFilterChange = (e) => {
    setTimeout(() => {
      setSortBy(e.target.value);
      // this.setState({ textSearch: e.target.value });
    }, 800);
  };
  const onTypeChange = (selectedKey) => {
    // Directly set the state with the selected key
    setType(selectedKey);
  };

  const fetchInventory = async () => {
    try {
      const inventoryRef = collection(db, "inventory");
      // const snapshot = await getDocs(inventoryRef);

      // Initialize an array to hold any query constraints
      let queryConstraints = [];

      // Check if both sortBy and text are provided before adding filters
      if (textSearch) {
        // For example, if you want to filter where the field starts with the given text:
        queryConstraints.push(where(`itemName`, ">=", textSearch));
        queryConstraints.push(where(`itemName`, "<=", textSearch + "\uf8ff"));
      }
      if (type != "ทั้งหมด") {
        queryConstraints.push(where("type", "==", type));
      }

      // Create a query using the inventory reference and any query constraints
      const inventoryQuery = query(inventoryRef, ...queryConstraints);

      // Fetch the documents matching the query
      const snapshot = await getDocs(inventoryQuery);

      // Map over the documents and extract the data
      const inventoryData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log("Inventory Data:", inventoryData); // Debugging
      return inventoryData; // Return the fetched inventory data
    } catch (error) {
      console.error("Error fetching inventory:", error);
      return [];
    }
  };

  const fetchLogs = async () => {
    try {
      const collectionRef = collectionGroup(db, "history");
      // const snapshot = await getDocs(collectionRef);

      // Initialize an array to hold any query constraints
      let queryConstraints = [];

      // Check if both sortBy and text are provided before adding filters
      if (textSearch) {
        // For example, if you want to filter where the field starts with the given text:
        queryConstraints.push(where(`itemName`, ">=", textSearch));
        queryConstraints.push(where(`itemName`, "<=", textSearch + "\uf8ff"));
      }
      if (type != "ทั้งหมด") {
        queryConstraints.push(where("type", "==", type));
      }

      // Create a query using the inventory reference and any query constraints
      const logQuery = query(collectionRef, ...queryConstraints);

      // Fetch the documents matching the query
      const snapshot = await getDocs(logQuery);

      const historyData = snapshot.docs.map((doc) => {
        const data = doc.data();

        // Convert Firestore Timestamp to a human-readable string with Bangkok timezone
        const formattedDatetime = data.datetime.seconds
          ? new Date(
              (data.datetime.seconds + 7 * 60 * 60) * 1000
            ).toLocaleString()
          : "Unknown Date";

        return {
          id: doc.id,
          ...data,
          datetime: formattedDatetime, // Replace Timestamp with a readable string
        };
      });

      console.log("History Data:", historyData); // Debugging
      return historyData;
    } catch (error) {
      console.error("Error fetching history:", error);
      return [];
    }
  };

  // const fetchBatchLogs = async () => {
  //   try {
  //     const collectionRef = collection(db, "batchLogs");
  //     const snapshot = await getDocs(collectionRef);

  //     const historyData = snapshot.docs.map((doc) => {
  //       const data = doc.data();

  //       // Convert Firestore Timestamp to a human-readable string with Bangkok timezone
  //       const formattedDatetime = data.createdAt.seconds
  //         ? new Date(
  //             (data.createdAt.seconds + 7 * 60 * 60) * 1000
  //           ).toLocaleString()
  //         : "Unknown Date";

  //       return {
  //         id: doc.id,
  //         ...data,
  //         createdAt: formattedDatetime, // Replace Timestamp with a readable string
  //       };
  //     });

  //     console.log("History Data:", historyData); // Debugging
  //     return historyData;
  //   } catch (error) {
  //     console.error("Error fetching history:", error);
  //     return [];
  //   }
  // };

  const getData = async () => {
    const data = await fetchInventory(); // Wait for data to be fetched
    const log = await fetchLogs();
    setInventory(data); // Set inventory after fetching
    setLogs(log);
  };

  useEffect(() => {
    getData();
  }, [textSearch, type]);

  // Handle form input change
  const handleInputChange = (e) => {
    setNewItem({ ...newItem, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Debugging state before submission
    console.log("Before submission:", newItem);

    // Validation: Ensure all required fields are filled
    if (
      !newItem.itemId ||
      !newItem.itemName ||
      !newItem.type ||
      !newItem.available
    ) {
      alert("Please fill all fields");
      return;
    }

    // Assign createdBy
    const newItemData = { ...newItem, createdBy: currentUser.displayName };

    try {
      const inventoryRef = collection(db, "inventory");
      await addDoc(inventoryRef, newItemData);

      // Close modal
      setShowModal(false);

      // Reset form state using functional update
      setNewItem((prevState) => ({
        itemId: "",
        itemName: "",
        type: "Mask",
        available: "",
        createdBy: "",
        size: "",
      }));

      // Ensure state update before fetching new data
      setTimeout(() => {
        getData();
      }, 500);
    } catch (error) {
      console.error("Error adding inventory:", error);
    }
  };

  useEffect(() => {
    console.log("Updated newItem state:", newItem);
  }, [newItem]);

  return (
    <React.Fragment>
      <PageTitle
        activeMenu="Inventory"
        motherMenu="Sales"
        motherLink="/inventory"
      />
      <div className="form-head d-flex mb-3 mb-lg-5 align-items-start">
        <Button variant="danger" onClick={() => setShowModal(true)}>
          + New Item
        </Button>
        <div className="input-group search-area ml-auto d-inline-flex">
          <input
            type="text"
            className="form-control"
            placeholder="Search here"
            onChange={onSearchChange}
          />
          <div className="input-group-append">
            <span className="input-group-text c-pointer">
              <i className="flaticon-381-search-2"></i>
            </span>
          </div>
        </div>
        <Dropdown className="ml-3 width200" onSelect={onFilterChange}>
          <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
            <i className="flaticon-381-controls-3 "></i> {sortBy}
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu-right">
            <Dropdown.Item href="#" eventKey={"Item Name"}>
              Item Name
            </Dropdown.Item>
            {/* <Dropdown.Item href="#" eventKey={"Brand"}>
              Brand
            </Dropdown.Item>
            <Dropdown.Item href="#" eventKey={"Model"}>
              Model
            </Dropdown.Item>
            <Dropdown.Item href="#" eventKey={"LastDueDate"}>
              Due Date
            </Dropdown.Item> */}
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown className="ml-1 width200" onSelect={onTypeChange}>
          <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
            <i className="flaticon-381-menu-1"></i> Type {type}
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu-right">
            <Dropdown.Item href="#" eventKey={"ทั้งหมด"}>
              ทั้งหมด
            </Dropdown.Item>
            <Dropdown.Item href="#" eventKey={"Mask"}>
              Mask
            </Dropdown.Item>
            <Dropdown.Item href="#" eventKey={"Filter"}>
              Filter
            </Dropdown.Item>
            <Dropdown.Item href="#" eventKey={"Chin Strap"}>
              Chin Strap
            </Dropdown.Item>
            <Dropdown.Item href="#" eventKey={"Other"}>
              Other
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <InventoryTable
        inventoryList={inventory}
        fetchInventory={setTextSearch}
        logs={logs}
      />

      {/* Add Item Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Item Id</Form.Label>
              <Form.Control
                type="text"
                name="itemId"
                value={newItem.itemId}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Type</Form.Label>
              <select
                name="type" // Add the name attribute here
                className="form-control"
                value={newItem.type}
                onChange={handleInputChange}
              >
                <option value="Mask">Mask</option>
                <option value="Filter">Filter</option>
                <option value="Chin Strap">Chin Strap</option>
                <option value="Other">Other</option>
              </select>
            </Form.Group>

            {/* <Form.Group>
              <Form.Label>Item Name</Form.Label>
              <Form.Control
                type="text"
                name="itemName"
                value={newItem.itemName}
                onChange={handleInputChange}
                required
              />
            </Form.Group> */}
            <Form.Group>
              <Form.Label>Item Name</Form.Label>
              <select
                name="itemName"
                className="form-control"
                value={newItem.itemName}
                onChange={(e) => {
                  if (e.target.value === "custom") {
                    setCustomItemName(true);
                    setNewItem({ ...newItem, itemName: "" });
                  } else {
                    setCustomItemName(false);
                    setNewItem({ ...newItem, itemName: e.target.value });
                  }
                }}
              >
                <option value="">Select an item</option>
                {[
                  "Nasal Mask ResComf",
                  "Full Face Mask ResComf",
                  "Nasal Mask AirFit N20",
                  "Nasal Mask AirFit N30",
                  "Full Face Mask AirFit F20",
                  "Full Face Mask AirTouch F20",
                  "Nasal Pillow AirFit P10",
                  "Nasal Pillow AirFit P30i",
                  "Nasal Mask Wizard 310",
                  "Full Face Mask Wizard 320",
                  "Topson Full Face FM50 Mask",
                  "Topson Nasal NM40 Mask",
                  "Topson Nasal NM50 Mask",
                  "VentMed Nasal Pillow Mark",
                ].map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
                <option value="custom">Other (Specify)</option>
              </select>

              {customItemName && (
                <Form.Control
                  type="text"
                  name="itemName"
                  placeholder="Enter custom item name"
                  // value={newItem.itemName}
                  onChange={handleInputChange}
                  required
                />
              )}
            </Form.Group>

            <Form.Group>
              <Form.Label>Size</Form.Label>
              <select
                name="size" // Add the name attribute here
                className="form-control"
                value={newItem.size}
                onChange={handleInputChange}
              >
                <option value="">-</option>
                <option value="L">L</option>
                <option value="M">M</option>
                <option value="S">S</option>
                <option value="Free Size">Free Size</option>
              </select>
            </Form.Group>

            <Form.Group>
              <Form.Label>Available</Form.Label>
              <Form.Control
                type="number"
                name="available"
                value={newItem.available}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-3">
              Add Item
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default Inventory;

export default [
  { label: "Serial No.", name: "serialNumber", type: "text", required: true },
  {
    label: "Device Name",
    name: "deviceName",
    type: "text",
  },
  {
    label: "Model",
    name: "model",
    type: "text",
  },
  { label: "Description", name: "description", type: "text" },
  { label: "Lot No.", name: "lotNumber", type: "text" },
  {
    label: "Qty",
    name: "qty",
    type: "number",
  },
  {
    label: "Status",
    name: "status",
    type: "select",
    options: ["พร้อมใช้งาน", "ส่งซ่อม", "ยกเลิกใช้งาน"],
  },

  { label: "Incoming Date", name: "incomingDate", type: "date" },
];

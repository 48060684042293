import React, { Fragment, useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";
import filepicture from "../../../images/file.png";
import swal from "sweetalert";
import { v4 as uuidv4 } from "uuid";
import { Formik } from "formik";
import * as Yup from "yup";
import { db } from "../../../firebase";
import { Button, Modal } from "react-bootstrap";
import Toast from "../toast/SetToast";

import {
  getDocs,
  collection,
  where,
  orderBy,
  query,
  deleteDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import OrderModal from "./OrderModal";
import { useAuth } from "../../../context/AuthContext";
import { Timestamp } from "firebase/firestore";

const PatientActivity = (profile) => {
  let text = {
    order: null,
    createDate: null,
    title: null,
    activity: null,
    infoDate1: null,
    infoDate2: null,
    info1: null,
    info2: null,
  };
  const { currentUser } = useAuth();

  const [createModal, setCreateModal] = useState(false);
  const [orderInfo, setOrderInfo] = useState(null);
  const [activity, setActivity] = useState([]);
  const [isLoding, setIsLoading] = useState(true);
  const itemsPerPage = 5;

  const PatientTextInfo = ({ title = "", value = "", bold = false }) => {
    return (
      <div className="form-group row">
        <label className="col-lg-3 col-form-label" htmlFor="val-username">
          {title}
        </label>
        <div className="col-lg-8">
          {bold ? (
            <>
              <b>{value}</b>
            </>
          ) : (
            <>
              <text>{value}</text>
            </>
          )}
        </div>
      </div>
    );
  };

  const onViewOrder = async (index) => {
    // console.log(activity[index]);

    let docRef = null;
    if (activity[index].title == "bookings") {
      const nodename = activity[index].info1;
      const collectionRef = collection(db, "nodes");
      const q = query(collectionRef, where("nodename", "==", nodename));
      const querySnapshot = await getDocs(q);
      var nodeId = "";
      querySnapshot.forEach((doc) => {
        // console.log(`Document ID: ${doc.id}`); // This will log the document ID
        nodeId = doc.id;
      });

      docRef = doc(
        db,
        "nodes",
        // activity[index].info1,
        nodeId,
        "bookings",
        activity[index].id
      );
    } else if (activity[index].title == "followup") {
      docRef = doc(db, "followup", activity[index].id);
    }

    setIsLoading(true);

    await getDoc(docRef)
      .then((doc) => {
        setOrderInfo(doc.data());
      })
      .catch((err) => {
        console.log(err);
      });
    setIsLoading(false);
    setCreateModal(true);
  };

  const onDelete = (index) => {
    // console.log(activity[index]);
    // console.log(activity[index].actId);

    const ref = doc(db, "activities", activity[index].actId);
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        await deleteDoc(ref).then(() => {
          swal(`Poof! Your file has been deleted!`, {
            icon: "success",
          });
        });
        setActivity(activity.filter(({ id }) => id !== activity[index].id));
      } else {
        swal("Canceled");
      }
    });
  };
  const handleOnHideModal = () => {
    setCreateModal(false);
    setIsLoading(true);
  };

  useEffect(() => {
    const fetchActivity = async () => {
      const queryRef = query(
        collection(db, "activities"),
        where("hn", "==", profile.profile.hn),
        orderBy("createdAt", "desc")
      );

      const tempActivity = [];
      const docSnap = await getDocs(queryRef);

      docSnap.forEach((doc) => {
        if (doc.data().title === "orders") {
          console.log("doc: ", doc.data());
        }
        let text = {
          ...doc.data(),
          actId: doc.id,
          id: doc.data().id,
          order: doc.data().order,
          createDate: doc.data().createdAt,
          title: doc.data().title,
          activity: doc.data().activity,
          infoDate1: doc.data().startDate
            ? doc.data().startDate
            : doc.data().warrantyEndDate,
          infoDate2: doc.data().dueDate,
          info1: doc.data().detail1,
          info2: doc.data().detail2,
          note: doc.data().note ? doc.data().note : "",
          createBy: doc.data().createBy ? doc.data().createBy : "",
          // warrantyEndDate: doc.data().warrantyEndDate,
          // warrantyStartDate: doc.data().warrantyStartDate,
          // receiptDate: doc.data().receiptDate,
        };

        if (doc.data().activity === "คืนเครื่อง") {
          text.infoDate1 = doc.data().endDate;
        }
        if (doc.data().title === "orders") {
          text.infoDate1 = doc.data().updatedAt;

          const warrantyMap = {
            "6M": 6,
            "1Y": 12,
            "2Y": 24,
          };

          // if (doc.data().receiptDate && text.warrantyPeriod in warrantyMap) {
          //   const receiptDate = doc.data().receiptDate.toDate(); // 👈 convert here

          //   text.warrantyStartDate = receiptDate; // will be a JS Date

          //   const monthsToAdd = warrantyMap[text.warrantyPeriod];
          //   const endDate = moment(receiptDate).add(monthsToAdd, "months").toDate();

          //   text.warrantyEndDate = Timestamp.fromDate(endDate); // 👈 convert back to Firestore Timestamp if needed
          // }
        }

        tempActivity.push(text);
      });
      const sortData = tempActivity.sort(
        (a, b) =>
          new Date(b.createDate.seconds * 1000) -
          new Date(a.createDate.seconds * 1000)
      );
      if (Array.isArray(sortData)) {
        setActivity(sortData);
      } else {
        console.error("sortData is not an array:", sortData);
      }
    };

    fetchActivity(); // ✅ call the async function inside useEffect
  }, []);

  return (
    <Fragment>
      <Toast />
      {!isLoding && (
        <OrderModal
          createModal={createModal}
          orderInfo={orderInfo}
          onHideModal={handleOnHideModal}
        />
      )}
      <div className="col-xl-6 col-xxl-6 col-lg-6">
        <div className="card">
          <div className="card-header border-0 pb-0">
            <h4 className="card-title">Timeline</h4>
          </div>
          <div className="card-body">
            <PerfectScrollbar
              style={{ height: "100%" }}
              id="DZ_W_TimeLine"
              className="widget-timeline dz-scroll height370 ps ps--active-y"
            >
              <ul className="timeline">
                {activity.map((doc, index) => {
                  const createDate = new Date(
                    doc.createDate.seconds * 1000 +
                      doc.createDate.nanoseconds / 1000000
                  );
                  const infoDate1 = doc.infoDate1
                    ? new Date(
                        doc.infoDate1.seconds * 1000 +
                          doc.infoDate1.nanoseconds / 1000000
                      )
                    : null;
                  const infoDate2 = doc.infoDate2
                    ? new Date(
                        doc.infoDate2.seconds * 1000 +
                          doc.infoDate2.nanoseconds / 1000000
                      )
                    : null;
                  const warrantyStartDate = doc.warrantyStartDate
                    ? new Date(
                        doc.warrantyStartDate.seconds * 1000 +
                          doc.warrantyStartDate.nanoseconds / 1000000
                      )
                    : null;
                  const warrantyEndDate = doc.warrantyEndDate
                    ? new Date(
                        doc.warrantyEndDate.seconds * 1000 +
                          doc.warrantyEndDate.nanoseconds / 1000000
                      )
                    : null;

                  // console.log("warrantyStartDate: ", warrantyStartDate);
                  // console.log("warrantyEndDate: ", warrantyEndDate);

                  return (
                    <li key={index}>
                      <div className="timeline-badge info"></div>

                      {doc.title === "bookings" && (
                        <span className="timeline-panel text-muted">
                          <span>
                            {createDate.toLocaleString("en-GB", {
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                            {["super_admin"].includes(currentUser.roles[0]) && (
                              <Button
                                variant=""
                                className="close"
                                ata-dismiss="alert"
                                aria-label="Close"
                                onClick={() => onDelete(index)}
                              >
                                <span>
                                  <i className="mdi mdi-close"></i>
                                </span>
                              </Button>
                            )}
                          </span>
                          <h6 className="mb-0">
                            {doc.activity ? doc.activity : ""}{" "}
                            <strong className="text-info">
                              {infoDate1
                                ? infoDate1.toLocaleDateString("th-TH", {
                                    weekday: "long",
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  })
                                : ""}
                            </strong>
                          </h6>
                          <p className="mb-0">
                            {doc.info1 ? "Node : " + doc.info1 : ""}
                          </p>
                          <p className="mb-0">
                            {doc.info2 ? "Type : " + doc.info2 : ""}
                          </p>

                          <Link
                            className="text-muted"
                            to="#"
                            onClick={() => {
                              onViewOrder(index);
                            }}
                          >
                            <p className="mb-0">
                              {doc.order ? "Order : " + doc.order : ""}
                            </p>
                          </Link>
                          <p className="mb-0">
                            {doc.note ? "Note : " + doc.note : ""}
                          </p>
                          <p className="mb-0 justify-content-end align-item-end">
                            {doc.createBy ? "CreatedBy : " + doc.createBy : ""}
                          </p>
                        </span>
                      )}

                      {doc.title === "followup" && (
                        <span className="timeline-panel text-muted">
                          <span>
                            {createDate.toLocaleString("en-GB", {
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                            {["super_admin"].includes(currentUser.roles[0]) && (
                              <Button
                                variant=""
                                className="close"
                                ata-dismiss="alert"
                                aria-label="Close"
                                onClick={() => onDelete(index)}
                              >
                                <span>
                                  <i className="mdi mdi-close"></i>
                                </span>
                              </Button>
                            )}
                          </span>
                          <h6 className="mb-0">
                            {doc.activity ? doc.activity : ""}{" "}
                            <strong className="text-info">
                              {infoDate1
                                ? infoDate1.toLocaleDateString("th-TH", {
                                    weekday: "long",
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  })
                                : ""}
                            </strong>
                          </h6>
                          <p className="mb-0">
                            {doc.info1 ? "Node : " + doc.info1 : ""}
                          </p>
                          <p className="mb-0">
                            {doc.info2 ? "Type : " + doc.info2 : ""}
                          </p>
                          <Link
                            className="text-muted"
                            to="#"
                            onClick={() => {
                              onViewOrder(index);
                            }}
                          >
                            <p className="mb-0">
                              {doc.order ? "Order : " + doc.order : ""}
                            </p>
                          </Link>
                        </span>
                      )}

                      {doc.title === "borrowings" && (
                        <>
                          <div
                            // className={
                            //   [
                            //     "คืนเครื่อง",
                            //     "ลงทะเบียน",
                            //     "ส่งเครื่องกลับศูนย์",
                            //     "",
                            //   ].includes(doc.Activity)
                            //     ? "timeline-badge primary"
                            //     : ["ยืมเครื่อง"].includes(doc.Activity)
                            //     ? "timeline-badge danger"
                            //     : [
                            //         "ส่งทำความสะอาด",
                            //         "ส่งซ่อม",
                            //         "จองเครื่อง",
                            //       ].includes(doc.Activity)
                            //     ? "timeline-badge info"
                            //     : "timeline-badge dark"
                            // }
                            className={
                              [
                                "คืนเครื่อง",
                                "ลงทะเบียน",
                                "ส่งเครื่องกลับศูนย์",
                                "",
                              ].includes(doc.activity)
                                ? "timeline-badge primary"
                                : ["ยืมเครื่อง"].includes(doc.activity)
                                ? "timeline-badge danger"
                                : [
                                    "ส่งทำความสะอาด",
                                    "ส่งซ่อม",
                                    "จองเครื่อง",
                                  ].includes(doc.activity)
                                ? "timeline-badge info"
                                : "timeline-badge dark"
                            }
                          ></div>
                          <span className="timeline-panel text-muted">
                            <span>
                              {createDate.toLocaleString("en-GB", {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                              {["super_admin"].includes(
                                currentUser.roles[0]
                              ) && (
                                <Button
                                  variant=""
                                  className="close"
                                  ata-dismiss="alert"
                                  aria-label="Close"
                                  onClick={() => onDelete(index)}
                                >
                                  <span>
                                    <i className="mdi mdi-close"></i>
                                  </span>
                                </Button>
                              )}
                            </span>
                            <h6 className="mt-3 mb-3">
                              <strong className="text-primary">
                                {doc.activity ? doc.activity + " CPAP" : ""}{" "}
                              </strong>
                              <strong className="text-info">
                                {infoDate1
                                  ? infoDate1.toLocaleDateString("th-TH", {
                                      weekday: "long",
                                      year: "numeric",
                                      month: "short",
                                      day: "numeric",
                                    })
                                  : ""}
                              </strong>
                            </h6>
                            <h6>
                              {infoDate2 ? (
                                <>
                                  <strong className="text-dark">
                                    กำหนดคืนเครื่อง :{" "}
                                  </strong>
                                  {infoDate2.toLocaleDateString("th-TH", {
                                    weekday: "long",
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  })}
                                </>
                              ) : (
                                ""
                              )}
                            </h6>
                            <h6>{doc.order ? "Order : " + doc.order : ""}</h6>
                            <h6>
                              <strong className="text-dark">
                                Serial Number :{" "}
                              </strong>
                              <Link to={"/cpap-page/" + doc.info1}>
                                {doc.info1}
                              </Link>
                            </h6>
                            {doc.activity == "ยืมเครื่อง" && (
                              <>
                                <h6>
                                  {" "}
                                  <strong className="text-info">
                                    {doc.brand}&nbsp;&nbsp;{doc.model}
                                  </strong>
                                </h6>

                                <h6>
                                  {" "}
                                  <strong className="text-info">
                                    Mask&nbsp;&nbsp; {doc.mask}
                                  </strong>
                                  &nbsp;&nbsp;&nbsp;
                                  <strong className="text-warning">
                                    Size&nbsp;&nbsp; {doc.maskSize}
                                  </strong>
                                </h6>
                                <h6>
                                  {" "}
                                  <strong className="text-info">
                                    Humidifier{" "}
                                  </strong>
                                  &nbsp;&nbsp;
                                  <strong className="text-warning">
                                    {doc.humidifier}
                                  </strong>
                                </h6>
                                <h6>
                                  {" "}
                                  <strong className="text-info">
                                    Ramp Time{" "}
                                  </strong>
                                  &nbsp;&nbsp;
                                  <strong className="text-warning">
                                    {doc.rampTime} min
                                  </strong>
                                </h6>
                                <h6>
                                  {" "}
                                  <strong className="text-info">
                                    Pressure :{" "}
                                  </strong>
                                  &nbsp;&nbsp;
                                  <strong className="text-warning">
                                    {doc.minPressure} - {doc.maxPressure} cmH2O
                                  </strong>
                                </h6>
                              </>
                            )}
                            <h6 className="mt-2">{doc.note}</h6>
                            <h6 className="mt-2 text-right">
                              <strong className="text-info">
                                Created By :{" "}
                              </strong>
                              {doc.createBy ? doc.createBy : "-"}
                            </h6>
                            {/* <p className="mb-0">
                              {doc.createBy
                                ? "CreatedBy : " + doc.createBy
                                : ""}
                            </p> */}
                          </span>
                        </>
                      )}

                      {doc.title === "orders" && (
                        <>
                          <div
                            className={
                              ["", "รอเอกสาร"].includes(doc.status)
                                ? "timeline-badge info"
                                : ["กำลังดำเนินการ"].includes(doc.status)
                                ? "timeline-badge danger"
                                : ["เสร็จสิ้น"].includes(doc.status)
                                ? "timeline-badge scuccess"
                                : "timeline-badge dark"
                            }
                          ></div>
                          <span className="timeline-panel text-muted">
                            <span>
                              {createDate.toLocaleString("en-GB", {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                              {["super_admin"].includes(
                                currentUser.roles[0]
                              ) && (
                                <Button
                                  variant=""
                                  className="close"
                                  ata-dismiss="alert"
                                  aria-label="Close"
                                  onClick={() => onDelete(index)}
                                >
                                  <span>
                                    <i className="mdi mdi-close"></i>
                                  </span>
                                </Button>
                              )}
                            </span>
                            <h6 className="mt-3 mb-3">
                              <strong className="text-primary">
                                {doc.activity ? "Purchase" : ""}
                              </strong>
                              <strong className="text-warning">
                                {" "}
                                {doc.status}{" "}
                              </strong>
                              <strong className="text-info">
                                {infoDate1
                                  ? infoDate1.toLocaleDateString("th-TH", {
                                      weekday: "long",
                                      year: "numeric",
                                      month: "short",
                                      day: "numeric",
                                    })
                                  : ""}
                              </strong>
                            </h6>
                            <h6>
                              <strong className="text-primary">
                                {doc.salePackage}
                              </strong>
                            </h6>
                            <h6>
                              <strong className="text-dark">
                                Serial Number :{" "}
                              </strong>
                              <Link to={"/product-page/" + doc.info1}>
                                {doc.info1}
                              </Link>{" "}
                            </h6>
                            <h6>
                              <strong className="text-dark">
                                DeviceNo. :{" "}
                              </strong>
                              {doc.info2}{" "}
                            </h6>
                            <h6>
                              <strong className="text-info">{doc.mask} </strong>
                              Size {""}
                              <strong className="text-warning">
                                {doc.maskSize}
                              </strong>
                            </h6>
                            <h6>
                              <strong className="text-dark">
                                warrantyPeriod:{" "}
                              </strong>
                              {doc.warrantyPeriod}
                            </h6>
                            <h6>
                              <strong className="text-dark">Warranty: </strong>
                              {warrantyStartDate && warrantyEndDate
                                ? `${warrantyStartDate.toLocaleDateString(
                                    "th-TH"
                                  )} - ${warrantyEndDate.toLocaleDateString(
                                    "th-TH"
                                  )}`
                                : "-"}
                            </h6>

                            {/* <h6>
                              <strong className="text-dark">
                                warrantyStartDate:{" "}
                              </strong>
                              {warrantyStartDate}
                            </h6>
                            <h6>
                              <strong className="text-dark">
                                warrantyEndDate:{" "}
                              </strong>
                              {warrantyEndDate}
                            </h6> */}
                            <h6>
                              <strong className="text-dark">Sale: </strong>
                              {doc.admin}
                            </h6>
                            <h6 className="mt-2">{doc.note}</h6>
                            <h6 className="mt-2 text-right">
                              <strong className="text-info">
                                Created By :{" "}
                              </strong>
                              {doc.createBy ? doc.createBy : "-"}
                            </h6>
                          </span>
                        </>
                      )}
                    </li>
                  );
                })}
              </ul>
            </PerfectScrollbar>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PatientActivity;

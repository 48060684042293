import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
import { useAuth } from "../../../../context/AuthContext";
import { db } from "../../../../firebase";
import {
  setDoc,
  getDoc,
  updateDoc,
  doc,
  collection,
  getDocs,
  collectionGroup,
  where,
  query,
  orderBy,
} from "firebase/firestore";
import {
  setEvent,
  eventTmp,
  paymentPrivilages,
  orderList,
  colorList,
  statusList,
  typeList,
  PatientLinkInfo,
  PatientDropDownForm,
  PatientCheckboxForm,
  PatientCheckboxFormState,
  PatientTextInfo,
} from "../../Booking/BookingUtils";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import * as XLSX from "xlsx";

import {
  notifyTopRight,
  notifyWarning,
  notifyError,
} from "../../../components/toast/SetToast";
import Toast from "../../../components/toast/SetToast";
import { forEach } from "jszip";

const SleepStudySummary = () => {
  const { currentUser } = useAuth();

  const [technicianList, setTechnicianList] = useState([]);
  const [techrecordList, setTechRecordList] = useState([]);
  const [doctorList, setDoctorList] = useState([]);
  const [doctorTHList, setDoctorTHList] = useState([]);
  const [bookingList, setBookingList] = useState([]);
  const [nodeList, setNodeList] = useState([]);
  const [nodeIdDocs, setNodeIdDocs] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [customerAllList, setCustomerAllList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState("checked");
  const [isFinished, setIsFinished] = useState("");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  var paymentPrivilagesData = paymentPrivilages;
  paymentPrivilagesData[0] = "All";

  const nodeRef = useRef();
  const customerRef = useRef();
  const healthInsuranceRef = useRef();
  const docRef = useRef();
  const techRef = useRef();
  const techRecordRef = useRef();
  // const filterAllRef = useRef(
  //   "All cases except Sleep Test Scheduled , Cancel and Failure"
  // );
  const [selectedFilter, setSelectedFilter] = useState(
    "Only finished cases"
  );

  const buildQuery = (
    bookingsCollectionGroupRef,
    start,
    end,
    node,
    customer,
    insurance,
    techRecord,
    techScore,
    doctor,
    isFinishedCase
  ) => {
    var exceptStatus = ["Cancel", "Failure"];
    var exceptStatusTech = [ "Cancel", "Failure"];
    const exceptStatusDoctor = [
      "Technician Scored",
      "Transferred To Center",
      "Assigned",
      "Sleep Test Scheduled",
      "Cancel",
      "Failure",
    ];

    if (isFinishedCase == "All cases") {
      exceptStatus = ["Cancel", "Failure"];
    } else if (
      isFinishedCase ==
      "All cases except Sleep Test Scheduled , Cancel and Failure"
    ) {
      exceptStatus = ["Sleep Test Scheduled", "Cancel", "Failure"];
    } else if (isFinishedCase == "Only Ship [CPAP Purchase]") {
      exceptStatus = [
        "Sleep Test Scheduled",
        "Transferred To Center",
        "Assigned",
        "Technician Scored",
        "Doctor Scored",
        "Post-Consult",
        "Sale Follow Up",
        "Ship",
        "Ship [CPAP Purchase]",
        "Ship [CPAP Trial]",
        "Ship [CPAP Rental]",
        "Cancel",
        "Failure",
      ];
    }

    console.log("isFinishedCase: ", isFinishedCase);

    let queryConstraints = [];

    queryConstraints.push(orderBy("extendedProps.latestSleepStudyDate"));

    // const startDate = new Date(start).toISOString().split("T")[0]; // "2024-06-25"
    // const endDate = new Date(end).toISOString().split("T")[0]; // "2024-08-05"

    const formatDate = (dateStr) => {
      const date = new Date(dateStr);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    const startDate = formatDate(start); // "2024-06-08"
    const endDate = formatDate(end); // "2024-08-05"

    queryConstraints.push(
      where("extendedProps.latestSleepStudyDate", ">=", startDate)
    );
    queryConstraints.push(
      where("extendedProps.latestSleepStudyDate", "<=", endDate)
    );

    if (node !== "All") {
      queryConstraints.push(where("extendedProps.node", "==", node));
    } else if (node == "All" && currentUser.roles[0] == "admin") {
      queryConstraints.push(where("extendedProps.node", "in", nodeList));
    }

    if (customer !== "All") {
      queryConstraints.push(where("extendedProps.customer", "==", customer));
    }

    if (insurance !== "All") {
      queryConstraints.push(
        where("extendedProps.healthInsurance", "==", insurance)
      );
    }

    if (techRecord !== "All") {
      queryConstraints.push(
        where("extendedProps.techrecord", "==", techRecord)
      );
    }

    if (techScore !== "All") {
      queryConstraints.push(where("extendedProps.technician", "==", techScore));
    }

    if (doctor !== "All") {
      queryConstraints.push(where("extendedProps.doctor", "==", doctor));
    }
    //////////////////////////////////////////////////////////////////////

    if (techRecord !== "All" || techScore !== "All") {
      queryConstraints.push(
        where("extendedProps.status", "not-in", exceptStatusTech)
      );
    } else if (doctor !== "All") {
      queryConstraints.push(
        where("extendedProps.status", "not-in", exceptStatusDoctor)
      );
    } else {
      queryConstraints.push(
        where("extendedProps.status", "not-in", exceptStatus)
      );
    }

    return query(bookingsCollectionGroupRef, ...queryConstraints);
  };

  const reduceDataByOrder = (dataArray) =>
    dataArray.reduce((acc, item) => {
      const order = item.extendedProps.order;

      if (!acc[order]) {
        acc[order] = item;
      }

      return acc;
    }, {});

  const getUniqueOrdersArray = (dataArray) =>
    Object.values(reduceDataByOrder(dataArray));

  const onExport = async () => {
    const node = nodeRef.current.value;
    const customer = customerRef.current.value;
    const insurance = healthInsuranceRef.current.value;
    const techRecord = techRecordRef.current.value;
    const techScore = techRef.current.value;
    const doctor = docRef.current.value;

    const start = startDate;
    const end = endDate;

    // if (node == "All") {
    // } else {
    // Usage of the buildQuery function
    let bookingsCollectionGroupRef = collectionGroup(db, "bookings");
    let queryHandler;
    let tempBooking = [];
    let combinedResults = [];

    // Pass your parameters here, including dates if searchTime is true
    if (techRecord == techScore && techRecord != "All") {
      if (techRecord !== "All") {
        const queryHandler1 = buildQuery(
          bookingsCollectionGroupRef,
          start,
          end,
          node,
          customer,
          insurance,
          techRecord, // techRecord is used in this query
          "All", // techScore is ignored in this query
          doctor,
          selectedFilter
        );

        const docSnap1 = await getDocs(queryHandler1);
        docSnap1.forEach((booking) => {
          const bookingData = booking.data();
          const dateStart = new Date(bookingData.start.toDate());
          dateStart.setHours(dateStart.getHours() + 7);
          bookingData.start = dateStart.toISOString().slice(0, 10);
          combinedResults.push({ ...bookingData });
        });
      }

      // Step 2: Query based on techScore if it's not "All"
      if (techScore !== "All") {
        const queryHandler2 = buildQuery(
          bookingsCollectionGroupRef,
          start,
          end,
          node,
          customer,
          insurance,
          "All", // techRecord is ignored in this query
          techScore, // techScore is used in this query
          doctor,
          selectedFilter
        );

        const docSnap2 = await getDocs(queryHandler2);
        docSnap2.forEach((booking) => {
          const bookingData = booking.data();
          const dateStart = new Date(bookingData.start.toDate());
          dateStart.setHours(dateStart.getHours() + 7);
          bookingData.start = dateStart.toISOString().slice(0, 10);
          combinedResults.push({ ...bookingData });
        });
      }
      console.log(combinedResults);

      // Remove duplicates based on a unique identifier (e.g., bookingId)
      // const uniqueResults = combinedResults.reduce((acc, current) => {
      //   const x = acc.find(
      //     (item) => item.bookingId === current.bookingId // Assuming bookingId is a unique field
      //   );
      //   if (x) {
      //     return acc.concat([current]);
      //   } else {
      //     return acc;
      //   }
      // }, []);
      const uniqueResults = getUniqueOrdersArray(combinedResults);

      setBookingList(uniqueResults);

      createXLSXOtherNode(
        uniqueResults,
        node,
        customer,
        insurance,
        techRecord,
        techScore,
        doctor,
        start,
        end
      );
    } else {
      queryHandler = buildQuery(
        bookingsCollectionGroupRef,
        start,
        end,
        node,
        customer,
        insurance,
        techRecord,
        techScore,
        doctor,
        selectedFilter
      );

      const docSnap = await getDocs(queryHandler);
      if (docSnap.empty) {
        console.log("No documents found");
      } else {
        docSnap.forEach((booking) => {
          const bookingData = booking.data();
          const dateStart = new Date(bookingData.start.toDate());
          dateStart.setHours(dateStart.getHours() + 7);
          bookingData.start = dateStart.toISOString().slice(0, 10);
          tempBooking.push({ ...bookingData });
        });
      }
      setBookingList(tempBooking);
      createXLSXOtherNode(
        tempBooking,
        node,
        customer,
        insurance,
        techRecord,
        techScore,
        doctor,
        start,
        end
      );
    }
    // }
  };

  const checkItems = (array) => {
    let hasHomeFull = false;
    let hasSplitNight = false;
    let hasPremium = false;
    let hasCPAPt = false;
    let hasOvernightPulse = false;

    for (const item of array) {
      if (item.item.includes("Split Night")) {
        hasSplitNight = true;
      }
      if (item.item.includes("Premium")) {
        hasPremium = true;
      }
      if (item.item.includes("Home")) {
        hasHomeFull = true;
      }
      if (item.item.includes("CPAP Titration")) {
        hasCPAPt = true;
      }
      if (item.item.includes("Overnight")) {
        hasOvernightPulse = true;
      }
    }

    return [
      hasSplitNight,
      hasPremium,
      hasHomeFull,
      hasCPAPt,
      hasOvernightPulse,
    ];
  };

  const formatDateRangeInThai = (startDateStr, endDateStr) => {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);

    const options = { year: "numeric", month: "long", day: "numeric" };
    const thaiFormatter = new Intl.DateTimeFormat("th-TH", options);

    const startDay = startDate.getDate();
    const startMonth = startDate.toLocaleString("th-TH", { month: "long" });
    const startYear = startDate.getFullYear();

    const endDay = endDate.getDate();
    const endMonth = endDate.toLocaleString("th-TH", { month: "long" });
    const endYear = endDate.getFullYear();

    if (startYear === endYear) {
      if (startMonth === endMonth) {
        return `${startDay} - ${endDay} ${startMonth} ${startYear}`;
      }
      return `${startDay} ${startMonth} - ${endDay} ${endMonth} ${startYear}`;
    }

    const formattedStartDate = thaiFormatter.format(startDate);
    const formattedEndDate = thaiFormatter.format(endDate);

    return `${formattedStartDate} - ${formattedEndDate}`;
  };

  const createXLSXOtherNode = (
    bookingData,
    node,
    customer,
    insurance,
    techRecord,
    techScore,
    doctor,
    start,
    end
  ) => {
    const rangeDate = formatDateRangeInThai(start, end);

    var fullNightStandard = [];
    var splitNightStandard = [];

    var fullNightPremium = [];
    var splitNightPremium = [];

    var overnightPulse = [];

    var xlsxName = "";
    var sheetName = "";

    var textHeader = "";
    if (node != "All") {
      textHeader = textHeader + node;
      xlsxName = rangeDate + " " + node;
      sheetName = node;

      if (customer != "All") {
        textHeader = textHeader + " - " + customer;
        xlsxName = xlsxName + " - " + customer;
        sheetName = customer;
      }

      if (insurance != "All") {
        textHeader = textHeader + " เคส " + insurance;
        xlsxName = xlsxName + " เคส " + insurance;
        sheetName = sheetName + " เคส " + insurance;
      } else {
        textHeader = textHeader + " เคสทั้งหมด";
        xlsxName = xlsxName + " เคสทั้งหมด";
        sheetName = sheetName + " เคสทั้งหมด";
      }
    } else {
      if (doctor != "") {
        textHeader = textHeader + " แพทย์ผู้อ่านผล " + doctor;
        xlsxName = rangeDate + " แพทย์ผู้อ่านผล " + doctor;
        sheetName = doctor;
      }
      if (techRecord != "") {
        textHeader = textHeader + " Recording Tech " + techRecord;
        xlsxName = rangeDate + " Recording Tech " + techRecord;
        sheetName = doctor;
      }
      if (techScore != "") {
        textHeader = textHeader + " Scoring Tech " + techScore;
        xlsxName = rangeDate + " Scoring Tech " + techScore;
        sheetName = doctor;
      }
    }
    const fileName = `${rangeDate} ${textHeader}`;
    if (node == "Ladprao Hospital") {
      const head = [
        fileName,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ];
      const headers = [
        "ลำดับ",
        "วันที่ตรวจการนอนหลับ",
        "Status ล่าสุด",
        "Sleep Study No.",
        "โรงพยาบาลผู้ส่งตรวจ",
        "ชื่อ - นามสกุล",
        "รหัสโรงพยาบาล",
        "ชนิดการตรวจการนอนหลับ",
        "",
        "แพ็คการตรวจการนอนหลับ",
        "",
        "",
        "",
        "เงินสด",
        "รายละเอียดแพ็คเกจ",
        "ค่าตอบแทนตามสัญญา",
        "AHI (ครั้ง/ชั่วโมง)",
        "",
        "",
        "แพทย์อ่านผล",
        "Recording Tech",
        "Scoring Tech",
        "หมายเหตุ",
      ];
      const subHeaders = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "FN",
        "SN",
        "FN - St",
        "SN - St",
        "FN - Pr",
        "SN - Pr",
        "",
        "",
        "",
        "น้อยกว่า 5",
        "5 ถึง 15",
        "มากกว่า 15",
        "",
        "",
        "",
        "",
      ];

      const data = [head, headers, subHeaders];
      bookingData.forEach((booking, index) => {
        const row = [];

        row.push(index + 1);
        row.push(booking.extendedProps.latestSleepStudyDate);
        row.push(booking.extendedProps.status);
        row.push(booking.title);
        row.push(booking.extendedProps.customer);
        row.push(
          `${booking.extendedProps.firstname} ${booking.extendedProps.lastname}`
        );
        row.push(booking.extendedProps.hospitalNumber);

        const [hasSplitNight, hasPremium, hasHomeFull, hasCPAPt] = checkItems(
          booking.extendedProps.items
        );

        if (hasSplitNight) {
          row.push("");
        } else {
          row.push("✅");
        }

        if (hasSplitNight) {
          row.push("✅");
        } else {
          row.push("");
        }

        if (!hasSplitNight && !hasPremium) {
          row.push("✅");
          fullNightStandard.push(booking);
        } else {
          row.push("");
        }

        if (hasSplitNight && !hasPremium) {
          row.push("✅");
          splitNightStandard.push(booking);
        } else {
          row.push("");
        }

        if (!hasSplitNight && hasPremium) {
          row.push("✅");
          fullNightPremium.push(booking);
        } else {
          row.push("");
        }

        if (hasSplitNight && hasPremium) {
          row.push("✅");
          splitNightPremium.push(booking);
        } else {
          row.push("");
        }
        //เช็คเงินสด
        if (booking.extendedProps.healthInsurance == "ชำระเงินเอง") {
          row.push("✅");
        } else {
          row.push("");
        }
        const itemsText = [];
        booking.extendedProps.items.forEach((item) => {
          itemsText.push(`${item.item} (${item.unitcost})`);
        });

        row.push(itemsText.join("\n"));

        if (hasSplitNight) {
          row.push(7000);
        } else {
          row.push(4000);
        }

        if (booking.extendedProps.ahi && booking.extendedProps.ahi != "") {
          if (parseFloat(booking.extendedProps.ahi) < 5) {
            row.push(parseFloat(booking.extendedProps.ahi));
            row.push("");
            row.push("");
          } else if (parseFloat(booking.extendedProps.ahi) <= 15) {
            row.push("");
            row.push(parseFloat(booking.extendedProps.ahi));
            row.push("");
          } else if (parseFloat(booking.extendedProps.ahi) > 15) {
            row.push("");
            row.push("");
            row.push(parseFloat(booking.extendedProps.ahi));
          }
        } else {
          row.push("");
          row.push("");
          row.push("");
        }
        row.push(GetFullNameDoctor(booking.extendedProps.doctor));
        row.push(booking.extendedProps.techrecord);
        row.push(booking.extendedProps.technician);
        row.push(booking.extendedProps.note);
        console.log(row.length);

        data.push(row);
      });

      const allCases =
        fullNightStandard.length +
        splitNightStandard.length +
        fullNightPremium.length +
        splitNightPremium.length;
      data.push([
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "รวม",
        fullNightStandard.length,
        splitNightStandard.length,
        fullNightPremium.length,
        splitNightPremium.length,
        "รวมทั้งสิ้น",
        `${allCases} เคส`,
        "",
        "",
        "",
        "",
      ]);

      const ws = XLSX.utils.aoa_to_sheet(data);

      // Define the merge ranges
      const merge = [];

      merge.push(
        { s: { r: 0, c: 0 }, e: { r: 0, c: 14 } },

        { s: { r: 1, c: 0 }, e: { r: 2, c: 0 } },
        { s: { r: 1, c: 1 }, e: { r: 2, c: 1 } },
        { s: { r: 1, c: 2 }, e: { r: 2, c: 2 } },
        { s: { r: 1, c: 3 }, e: { r: 2, c: 3 } },
        { s: { r: 1, c: 4 }, e: { r: 2, c: 4 } },
        { s: { r: 1, c: 5 }, e: { r: 2, c: 5 } },
        { s: { r: 1, c: 6 }, e: { r: 2, c: 6 } },

        { s: { r: 1, c: 7 }, e: { r: 1, c: 8 } },
        { s: { r: 2, c: 7 }, e: { r: 2, c: 7 } },
        { s: { r: 2, c: 8 }, e: { r: 2, c: 8 } },

        { s: { r: 1, c: 9 }, e: { r: 1, c: 12 } },
        { s: { r: 2, c: 9 }, e: { r: 2, c: 9 } },
        { s: { r: 2, c: 10 }, e: { r: 2, c: 10 } },
        { s: { r: 2, c: 11 }, e: { r: 2, c: 11 } },
        { s: { r: 2, c: 12 }, e: { r: 2, c: 12 } },

        { s: { r: 1, c: 13 }, e: { r: 2, c: 13 } },
        { s: { r: 1, c: 14 }, e: { r: 2, c: 14 } },
        { s: { r: 1, c: 15 }, e: { r: 2, c: 15 } },

        { s: { r: 1, c: 16 }, e: { r: 1, c: 18 } },
        { s: { r: 2, c: 16 }, e: { r: 2, c: 16 } },
        { s: { r: 2, c: 17 }, e: { r: 2, c: 17 } },
        { s: { r: 2, c: 18 }, e: { r: 2, c: 18 } },

        { s: { r: 1, c: 19 }, e: { r: 2, c: 19 } },
        { s: { r: 1, c: 20 }, e: { r: 2, c: 20 } },
        { s: { r: 1, c: 21 }, e: { r: 2, c: 21 } },

        { s: { r: 1, c: 22 }, e: { r: 2, c: 22 } }
      );
      ws["!merges"] = merge;
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Summary Report");
      XLSX.writeFile(wb, `Summary ${xlsxName}.xlsx`);
    } else {
      const head = [
        fileName,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ];
      const headers = [
        "ลำดับ",
        "วันที่ตรวจการนอนหลับ",
        "Status ล่าสุด",
        "Sleep Study No.",
        "โรงพยาบาลผู้ส่งตรวจ",
        "ชื่อ - นามสกุล",
        "รหัสโรงพยาบาล",
        "ชนิดการตรวจการนอนหลับ",
        "",
        "",
        "",
        "",
        "แพ็คการตรวจการนอนหลับ",
        "",
        "",
        "",
        "",
        "เงินสด",
        "รายละเอียดแพ็คเกจ",
        "AHI (ครั้ง/ชั่วโมง)",
        "",
        "",
        "แพทย์อ่านผล",
        "Recording Tech",
        "Scoring Tech",
        "หมายเหตุ",
      ];
      const subHeaders = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "FN",
        "SN",
        "HN",
        "CT",
        "ON",
        "FN - St",
        "SN - St",
        "FN - Pr",
        "SN - Pr",
        "ON",
        "",
        "",
        "น้อยกว่า 5",
        "5 ถึง 15",
        "มากกว่า 15",
        "",
        "",
        "",
        "",
      ];

      const data = [head, headers, subHeaders];
      bookingData.forEach((booking, index) => {
        const row = [];

        row.push(index + 1);
        row.push(booking.extendedProps.latestSleepStudyDate);
        row.push(booking.extendedProps.status);
        row.push(booking.title);
        row.push(booking.extendedProps.customer);
        row.push(
          `${booking.extendedProps.firstname} ${booking.extendedProps.lastname}`
        );
        row.push(booking.extendedProps.hospitalNumber);

        const [
          hasSplitNight,
          hasPremium,
          hasHomeFull,
          hasCPAPt,
          hasOvernightPulse,
        ] = checkItems(booking.extendedProps.items);
        console.log("checkItems: ", checkItems(booking.extendedProps.items));
        //ชนิดตรวจ full night
        if (hasSplitNight || hasHomeFull || hasOvernightPulse) {
          row.push("");
        } else {
          row.push("✅");
        }
        //ชนิดตรวจ split night
        if (hasSplitNight && !hasOvernightPulse) {
          row.push("✅");
        } else {
          row.push("");
        }
        //ชนิดตรวจ = home
        if (hasHomeFull && !hasOvernightPulse) {
          row.push("✅");
        } else {
          row.push("");
        }
        //ชนิดตรวจ cpap titration
        if (hasCPAPt && !hasOvernightPulse) {
          row.push("✅");
        } else {
          row.push("");
        }
        if (hasOvernightPulse) {
          row.push("✅");
        } else {
          row.push("");
        }
        //แพ็คตรวจการนอนหลับ
        if (!hasSplitNight && !hasPremium && !hasOvernightPulse) {
          row.push("✅");
          fullNightStandard.push(booking);
        } else {
          row.push("");
        }

        if (hasSplitNight && !hasPremium && !hasOvernightPulse) {
          row.push("✅");
          splitNightStandard.push(booking);
        } else {
          row.push("");
        }

        if (!hasSplitNight && hasPremium && !hasOvernightPulse) {
          row.push("✅");
          fullNightPremium.push(booking);
        } else {
          row.push("");
        }

        if (hasSplitNight && hasPremium && !hasOvernightPulse) {
          row.push("✅");
          splitNightPremium.push(booking);
        } else {
          row.push("");
        }
        if (hasOvernightPulse) {
          row.push("✅");
          overnightPulse.push(booking);
        } else {
          row.push("");
        }
        //เช็คเงินสด
        if (booking.extendedProps.healthInsurance == "ชำระเงินเอง") {
          row.push("✅");
        } else {
          row.push("");
        }
        //รายละเอียดแพ็คเกจ
        const itemsText = [];
        booking.extendedProps.items.forEach((item) => {
          itemsText.push(`${item.item} (${item.unitcost})`);
        });
        row.push(itemsText.join("\n"));

        if (booking.extendedProps.ahi && booking.extendedProps.ahi != "") {
          if (parseFloat(booking.extendedProps.ahi) < 5) {
            row.push(parseFloat(booking.extendedProps.ahi));
            row.push("");
            row.push("");
          } else if (parseFloat(booking.extendedProps.ahi) <= 15) {
            row.push("");
            row.push(parseFloat(booking.extendedProps.ahi));
            row.push("");
          } else if (parseFloat(booking.extendedProps.ahi) > 15) {
            row.push("");
            row.push("");
            row.push(parseFloat(booking.extendedProps.ahi));
          }
        } else {
          row.push("");
          row.push("");
          row.push("");
        }

        row.push(GetFullNameDoctor(booking.extendedProps.doctor));
        row.push(booking.extendedProps.techrecord);
        row.push(booking.extendedProps.technician);
        row.push(booking.extendedProps.note);
        console.log(row.length);

        data.push(row);
      });
      const allCases =
        fullNightStandard.length +
        splitNightStandard.length +
        fullNightPremium.length +
        splitNightPremium.length +
        overnightPulse.length;
      data.push([
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "รวม",
        fullNightStandard.length,
        splitNightStandard.length,
        fullNightPremium.length,
        splitNightPremium.length,
        overnightPulse.length,
        "รวมทั้งสิ้น",
        `${allCases} เคส`,
        "",
        "",
        "",
        "",
      ]);

      const ws = XLSX.utils.aoa_to_sheet(data);

      // Define the merge ranges
      const merge = [];

      merge.push(
        { s: { r: 0, c: 0 }, e: { r: 0, c: 14 } },
        { s: { r: 1, c: 0 }, e: { r: 2, c: 0 } },
        { s: { r: 1, c: 1 }, e: { r: 2, c: 1 } },
        { s: { r: 1, c: 2 }, e: { r: 2, c: 2 } },
        { s: { r: 1, c: 3 }, e: { r: 2, c: 3 } },
        { s: { r: 1, c: 4 }, e: { r: 2, c: 4 } },
        { s: { r: 1, c: 5 }, e: { r: 2, c: 5 } },
        { s: { r: 1, c: 6 }, e: { r: 2, c: 6 } },

        { s: { r: 1, c: 7 }, e: { r: 1, c: 11 } },
        { s: { r: 2, c: 7 }, e: { r: 2, c: 7 } },
        { s: { r: 2, c: 8 }, e: { r: 2, c: 8 } },
        { s: { r: 2, c: 9 }, e: { r: 2, c: 9 } },
        { s: { r: 2, c: 10 }, e: { r: 2, c: 10 } },
        { s: { r: 2, c: 11 }, e: { r: 2, c: 11 } },

        { s: { r: 1, c: 12 }, e: { r: 1, c: 16 } },
        { s: { r: 2, c: 12 }, e: { r: 2, c: 12 } },
        { s: { r: 2, c: 13 }, e: { r: 2, c: 13 } },
        { s: { r: 2, c: 14 }, e: { r: 2, c: 14 } },
        { s: { r: 2, c: 15 }, e: { r: 2, c: 15 } },
        { s: { r: 2, c: 16 }, e: { r: 2, c: 16 } },

        { s: { r: 1, c: 17 }, e: { r: 2, c: 17 } },
        { s: { r: 1, c: 18 }, e: { r: 2, c: 18 } },

        { s: { r: 1, c: 19 }, e: { r: 1, c: 21 } },
        { s: { r: 2, c: 19 }, e: { r: 2, c: 19 } },
        { s: { r: 2, c: 20 }, e: { r: 2, c: 20 } },
        { s: { r: 2, c: 21 }, e: { r: 2, c: 21 } },

        { s: { r: 1, c: 22 }, e: { r: 2, c: 22 } },
        { s: { r: 1, c: 23 }, e: { r: 2, c: 23 } },
        { s: { r: 1, c: 24 }, e: { r: 2, c: 24 } },

        { s: { r: 1, c: 25 }, e: { r: 2, c: 25 } }
      );
      ws["!merges"] = merge;
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Summary Report");
      XLSX.writeFile(wb, `Summary ${xlsxName}.xlsx`);
    }
  };
  const CustomerList = (nodename) => {
    var index = nodeList.indexOf(nodename);
    if (nodeList.includes("All")) {
      index = index
      
    } else {
      index = index + 1;
    }
    return ["All", ...customerAllList[index]];
  };

  useEffect(() => {
    fetchAnotherData();
  }, []);

  const fetchAnotherData = async () => {
    try {
      // Use Promise.all for parallel fetching
      const [nodesDocs, itemsDocs, techDocs, docDocs] = await Promise.all([
        getDocs(collection(db, "nodes")),
        getDocs(collection(db, "items")),
        getDocs(collection(db, "sleeptechnicians")),
        getDocs(collection(db, "doctors")),
        // getDocs(collectionGroup(db,"customers"))
      ]);

      let allCustomers = [];
      let allnodeIdDocs = [];
      let allItems = [];
      let allNodes;
      if (["super_admin","sale"].includes(currentUser.roles[0])) {
        allNodes = nodesDocs.docs.map((doc) => doc.data().nodename);
        for (const nodeDoc of nodesDocs.docs) {
          allnodeIdDocs = [...allnodeIdDocs, nodeDoc.id];
          const customerDocs = await getDocs(
            collection(db, "nodes", nodeDoc.id, "customers")
          );
          const customersData = customerDocs.docs.map((customer) => {
            const customerData = customer.id;
            return customerData;
          });
          allCustomers.push(customersData);
        }
        allItems = itemsDocs.docs.map((doc) => ({
          ...doc.data(),
          itemcode: doc.id,
        }));
      } else if (currentUser.roles[0] == "sleep_physician") {
        allNodes = nodesDocs.docs.map((doc) => doc.data().nodename);
        for (const nodeDoc of nodesDocs.docs) {
          allnodeIdDocs = [...allnodeIdDocs, nodeDoc.id];
          const customerDocs = await getDocs(
            collection(db, "nodes", nodeDoc.id, "customers")
          );
          const customersData = customerDocs.docs.map((customer) => {
            const customerData = customer.id;
            return customerData;
          });
          allCustomers.push(customersData);
        }
        allItems = itemsDocs.docs.map((doc) => ({
          ...doc.data(),
          itemcode: doc.id,
        }));
      } else {
        // allNodes = nodesDocs.docs.map((doc) => doc.data().nodename);
        const currentAdmin = await getDoc(
          doc(db, "admins", currentUser.displayName)
        );
        let nodeCode = currentAdmin.data().node;

        // Ensure `nodeCode` is always an array
        if (!Array.isArray(nodeCode)) {
          nodeCode = nodeCode ? [nodeCode] : []; // Convert to array or set to empty
        }

        // Find matching nodes
        const node = nodesDocs.docs.filter((doc) =>
          nodeCode.includes(doc.data().nodecode)
        );

        allnodeIdDocs = node.map((doc) => doc.id);
        allNodes = node.map((doc) => doc.data().nodename);

        for (const id of allnodeIdDocs) {
          const customerDocs = await getDocs(
            collection(db, "nodes", id, "customers")
          );
          const customersData = customerDocs.docs.map((customer) => {
            const customerData = customer.id;
            return customerData;
          });
          allCustomers.push(customersData);
        }
        allItems = itemsDocs.docs
          .map((doc) => ({
            ...doc.data(),
            itemcode: doc.id,
          }))
          .filter((doc) => {
            return doc.node == nodeCode;
          });
      }

      const allTechs = ["All", ...techDocs.docs.map((doc) => doc.data().name)];
      const allTechsRecord = [
        "All",
        ...techDocs.docs.map((doc) => doc.data().name),
      ];
      const allDocs = ["All", ...docDocs.docs.map((doc) => doc.data().name)];
      const allDocsTH = [
        "All",
        ...docDocs.docs.map((doc) => doc.data().fullnameEn),
      ];
      setCustomerAllList(["All", ...allCustomers]);
      setCustomerList(["All", ...allCustomers[0]]);
      if (["staff"].includes(currentUser.roles[0])) {
        setNodeList([...allNodes]);
      } else {
        setNodeList(["All", ...allNodes]);
      }
      setNodeIdDocs(["All", ...allnodeIdDocs]);
      // setDoctorList(allDocs);
      // setDoctorTHList(allDocsTH);
      if (currentUser.roles[0] == "sleep_technician") {
        setTechRecordList([currentUser.displayName]);
        setTechnicianList([currentUser.displayName]);
      } else {
        setTechnicianList(allTechs);
        setTechRecordList(allTechsRecord);
      }

      if (currentUser.roles[0] == "sleep_physician") {
        setDoctorList([currentUser.displayName]);
        const docFullName = [
          ...docDocs.docs
            .filter((doc) => doc.data().name === currentUser.displayName) // Filter the documents
            .map((doc) => doc.data().fullnameEn), // Map to get only fullnameEn
        ];
        setDoctorTHList(docFullName);
      } else {
        setDoctorList(allDocs);
        setDoctorTHList(allDocsTH);
      }

      setItemList(allItems);
    } catch (error) {
      console.log("Error fetching data: ", error);
    }
  };

  const onChangeDateHandler = (dates) => {
    const [start, end] = dates;
    if (start) {
      start.setHours(0, 0, 0, 0); // Set time to 00:00:00
    }

    if (end) {
      end.setHours(23, 59, 59, 999); // Set time to 23:59:59.999
    }
    console.log(dates);
    setStartDate(new Date(start));
    setEndDate(new Date(end));
  };
  const GetFullNameDoctor = (doctor) => {
    const index = doctorList.findIndex((doc) => doc == doctor);
    if (index == -1) {
      return doctor;
    } else {
      return doctorTHList[index];
    }
  };
  return (
    <>
      <div className="col-lg-12 mb-5">
        <b>Summary Report</b>

        <div className="col-xl-9 col-lg-12 mt-4">
          <div className="form-group row">
            <label className="col-lg-3 col-form-label">
              {"Range Date"}
              <span className="text-danger"></span>
            </label>
            <div className="row ml-1">
              <div className="col-lg-3">
                <DatePicker
                  className="form-control"
                  selected={startDate}
                  onChange={(date) => setStartDate(date.setHours(0, 0, 0, 0))}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  minDate={null}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="dd/MM/yyyy"
                  // todayButton={true}
                />
              </div>
              <div className="mt-3">-</div>
              <div className="col-lg-3">
                <DatePicker
                  className="form-control"
                  selected={endDate}
                  onChange={(date) =>
                    setEndDate(date.setHours(23, 59, 59, 999))
                  }
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="dd/MM/yyyy"
                  todayBtn={true}
                />
              </div>
            </div>
          </div>
          <PatientDropDownForm
            title="Node"
            id="node"
            refvalue={nodeRef}
            // selected={currentBooking.extendedProps.node}
            options={nodeList}
            onChange={(e) => {
              setCustomerList(CustomerList(e.target.value));
            }}
            disabled={["sleep_technician", "sleep_physician"].includes(
              currentUser.roles[0]
            )}
          ></PatientDropDownForm>
          <PatientDropDownForm
            title="Customer"
            id="customer"
            refvalue={customerRef}
            // selected={currentBooking.extendedProps.customer}
            options={customerList}
            disabled={["sleep_technician", "sleep_physician"].includes(
              currentUser.roles[0]
            )}
          ></PatientDropDownForm>
          <PatientDropDownForm
            title="Health Insurance"
            id="healthInsurance"
            refvalue={healthInsuranceRef}
            // selected={
            //     currentBooking.extendedProps.healthInsurance
            // }
            options={paymentPrivilagesData}
            disabled={["sleep_technician", "sleep_physician"].includes(
              currentUser.roles[0]
            )}
          ></PatientDropDownForm>
          <PatientDropDownForm
            title="Recording Tech"
            id="technician"
            refvalue={techRecordRef}
            disabled={currentUser.roles[0] == "staff"}
            // selected={currentBooking.extendedProps.techrecord}
            options={techrecordList}
          ></PatientDropDownForm>
          <PatientDropDownForm
            title="Scoring Tech"
            id="technician"
            refvalue={techRef}
            disabled={currentUser.roles[0] == "staff"}
            // selected={currentBooking.extendedProps.technician}
            options={technicianList}
          ></PatientDropDownForm>
          <PatientDropDownForm
            title="Doctor"
            id="doctor"
            refvalue={docRef}
            // disabled={currentUser.roles[0] == "staff"}
            // selected={currentBooking.extendedProps.doctor}
            options={doctorList}
            disabled={["sleep_technician", "staff", "sleep_physician"].includes(
              currentUser.roles[0]
            )}
            // onChange={handleStatusChange}
          ></PatientDropDownForm>
          {["super_admin", "admin", "staff", "sale"].includes(
            currentUser.roles[0]
          ) && (
            <PatientCheckboxFormState
              title="Filter case"
              options={[
                "Only finished cases",
                "All cases",
                "Only Ship [CPAP Purchase]",
              ]}
              name="filter"
              selectedFilter={selectedFilter} // Pass selectedFilter state
              setSelectedFilter={setSelectedFilter} // Pass setter function
            />
          )}

          <Button onClick={onExport}>Export</Button>
        </div>
      </div>
    </>
  );
};

export default SleepStudySummary;

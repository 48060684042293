import React, { Fragment, useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import { Formik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../../context/AuthContext";
import { db } from "../../../firebase";
import {
  getDoc,
  getDocs,
  collection,
  updateDoc,
  doc,
  where,
  orderBy,
  query,
  limit,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";
import {
  PatientTextInputForm,
  PatientTextInputNotRequiredForm,
  PatientDropDownForm,
  PatientDropDownFormRequired,
} from "./PatientUtils";
import {
  notifyTopRight,
  notifyWarning,
  notifyError,
} from "../../components/toast/SetToast";
import Toast from "../../components/toast/SetToast";

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .min(3, "Your username must consist of at least 3 characters ")
    .max(50, "Your username must consist of at least 3 characters ")
    .required("Please enter a username"),
  password: Yup.string()
    .min(5, "Your password must be at least 5 characters long")
    .max(50, "Your password must be at least 5 characters long")
    .required("Please provide a password"),
});

const PatientRegister = () => {
  const profileData = {
    hn: "",
    email: "",
    titleTh: "",
    firstnameTh: "",
    lastnameTh: "",
    nationalId: "",
    titleEn: "",
    firstnameEn: "",
    lastnameEn: "",
    dateOfBirth: "",
    status: "",
    ethnicity: "",
    nationality: "",
    religion: "",
    bloodType: "",
    note: "",
    gender: "",
    occupation: "",
    mobile: "",
    telephone: "",
    address1: "",
    address2: "",
    province: "",
    postcode: "",
    medicineIntolerance: "",
    healthInsurance: "",
    other: "",
    emergencyContact: "",
    relationship: "",
    emergencyContactPhone: "",
    refHn: "",
    refCustomer: "",
    nodeRef: "",
  };

  const [showPassword, setShowPassword] = useState(false);
  const profileRef = useRef(profileData);
  const [nodeList, setNodeList] = useState([]);
  const [nodeIdDocs, setNodeIdDocs] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [customerAllList, setCustomerAllList] = useState([]);

  const { currentUser } = useAuth();

  const marriedStatus = ["โสด", "สมรส", "หย่า", "แยกกันอยู่", "หม้าย", "สมณะ"];
  const genders = ["ชาย", "หญิง"];
  const paymentPrivilages = [
    "ชำระเงินเอง",
    "ประกันสุขภาพถ้วนหน้า",
    "ประกันสังคม",
    "กรมบัญชีกลาง",
    "กรุงเทพมหานคร",
    "ต้นสังกัด",
    "นักศึกษา มธ.",
    "โครงการวิจัยรับค่าบริการล่วงหน้า",
    "องค์การปกครองส่วนท้องถิ่น",
    "โครงการตรวจสุขภาพรับค่าบริการล่วงหน้า",
    "ผ่อนชำระ/นิติกร",
    "อุบัติเหตุและฉุกเฉินนอกโรงพยาบาล(EMS)",
    "ปลัดกระทรวงสาธารณะสุข",
    "โครงการ 85 ปี",
    "อื่นๆ",
  ];

  const submitHandler = async (e) => {
    try {
      e.preventDefault();
      const ref = doc(db, "patients", profileRef.current["hn"].value);
      const docSnap = await getDoc(ref);
      if (docSnap.exists()) {
        notifyError("Invalid HN !");
      } else {
        const patientData = {
          hn: profileRef.current["hn"].value,
          email: profileRef.current["email"].value,
          titleTh: profileRef.current["titleTh"].value,
          firstnameTh: profileRef.current["firstnameTh"].value,
          lastnameTh: profileRef.current["lastnameTh"].value,
          nationalId: profileRef.current["nationalId"].value,
          titleEn: profileRef.current["titleEn"].value,
          firstnameEn: profileRef.current["firstnameEn"].value,
          lastnameEn: profileRef.current["lastnameEn"].value,
          dateOfBirth: profileRef.current["dateOfBirth"].value,
          status: profileRef.current["status"].value,
          ethnicity: profileRef.current["ethnicity"].value,
          nationality: profileRef.current["nationality"].value,
          religion: profileRef.current["religion"].value,
          bloodType: profileRef.current["bloodType"].value,
          note: profileRef.current["note"].value,
          gender: profileRef.current["gender"].value,
          occupation: profileRef.current["occupation"].value,
          mobile: profileRef.current["mobile"].value,
          telephone: profileRef.current["telephone"].value,
          address1: profileRef.current["address1"].value,
          address2: profileRef.current["address2"].value,
          province: profileRef.current["province"].value,
          postcode: profileRef.current["postcode"].value,
          medicineIntolerance: profileRef.current["medicineIntolerance"].value,
          healthInsurance: profileRef.current["healthInsurance"].value,
          other: profileRef.current["other"].value,
          emergencyContact: profileRef.current["emergencyContact"].value,
          relationship: profileRef.current["relationship"].value,
          emergencyContactPhone:
            profileRef.current["emergencyContactPhone"].value,
          refHn: profileRef.current["refHn"].value,
          refCustomer: profileRef.current["refCustomer"].value,
          nodeRef: profileRef.current["nodeRef"].value,
          createdAt: serverTimestamp(),
          loginAccount: null,
        };
        console.log("patientData: ", patientData);
        await setDoc(ref, patientData);
        notifyTopRight("Create success ");

        // Reset all form fields
        for (const key in profileRef.current) {
          if (profileRef.current.hasOwnProperty(key)) {
            profileRef.current[key].value = "";
          }
        }
      }
    } catch (err) {
      console.log(err);
      notifyError(`Error ${err}`);
    }

    // profileRef.current = "";
  };

  const CustomerList = (nodename) => {
    const index = nodeList.indexOf(nodename);
    return customerAllList[index];
  };
  useEffect(async () => {
    const [nodesDocs] = await Promise.all([getDocs(collection(db, "nodes"))]);

    let allCustomers = [];
    let allnodeIdDocs = [];
    let allItems = [];
    let allNodes;

    if (currentUser.roles[0] == "super_admin") {
      allNodes = nodesDocs.docs.map((doc) => doc.data().nodename);
      for (const nodeDoc of nodesDocs.docs) {
        allnodeIdDocs = [...allnodeIdDocs, nodeDoc.id];
        const customerDocs = await getDocs(
          collection(db, "nodes", nodeDoc.id, "customers")
        );
        const customersData = customerDocs.docs.map((customer) => {
          const customerData = customer.id;
          return customerData;
        });
        allCustomers.push(customersData);
      }
    } else {
      // const currentAdmin = await getDoc(
      //   doc(db, "admins", currentUser.displayName)
      // );
      // let nodeCode = currentAdmin.data().node;
      // let node = nodesDocs.docs.filter((doc) => {
      //   return doc.data().nodecode == nodeCode;
      // });
      // console.log("node: ", node);
      // allnodeIdDocs = node.map((doc) => doc.id);
      // allNodes = node.map((doc) => doc.data().nodename);

      // for (const id of allnodeIdDocs) {
      //   const customerDocs = await getDocs(
      //     collection(db, "nodes", id, "customers")
      //   );
      //   const customersData = customerDocs.docs.map((customer) => {
      //     const customerData = customer.id;
      //     return customerData;
      //   });
      //   allCustomers.push(customersData);
      // }

      const currentAdmin = await getDoc(
        doc(db, "admins", currentUser.displayName)
      );
      let nodeCode = currentAdmin.data().node;

      // Ensure `nodeCode` is an array
      if (!Array.isArray(nodeCode)) {
        nodeCode = nodeCode ? [nodeCode] : []; // Convert string to array or empty if null
      }

      // Find matching nodes
      let node = nodesDocs.docs.filter((doc) =>
        nodeCode.includes(doc.data().nodecode)
      );

      // Get all node IDs and names
      allnodeIdDocs = node.map((doc) => doc.id);
      allNodes = node.map((doc) => doc.data().nodename);

      for (const id of allnodeIdDocs) {
        const customerDocs = await getDocs(
          collection(db, "nodes", id, "customers")
        );
        const customersData = customerDocs.docs.map((customer) => {
          const customerData = customer.id;
          return customerData;
        });
        allCustomers.push(customersData);
      }
    }
    setCustomerAllList(allCustomers);
    setCustomerList(allCustomers[0]);
    setNodeList(allNodes);
    setNodeIdDocs(allnodeIdDocs);
  }, []);
  return (
    <Fragment>
      <PageTitle
        activeMenu="New Patient"
        motherMenu="Patient"
        motherLink="/patient-list"
      />
      <Toast />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Patient Registration Form</h4>
            </div>
            <div className="card-body">
              <div className="form-validation">
                <form
                  className="form-valide"
                  action="#"
                  method="post"
                  onSubmit={submitHandler}
                >
                  <div className="row">
                    <div className="col-xl-6">
                      <PatientTextInputForm
                        title="HN"
                        id="hn"
                        name="hn"
                        refvalue={(el) => (profileRef.current["hn"] = el)}
                      />
                      <PatientTextInputNotRequiredForm
                        title="คำนำหน้า"
                        id="prename-th"
                        name="prename-th"
                        refvalue={(el) => (profileRef.current["titleTh"] = el)}
                      />

                      <PatientTextInputNotRequiredForm
                        title="ชื่อ"
                        id="first-name-th"
                        name="first-name-th"
                        refvalue={(el) =>
                          (profileRef.current["firstnameTh"] = el)
                        }
                      />
                      <PatientTextInputNotRequiredForm
                        title="นามสกุล"
                        id="last-name-th"
                        name="last-name-th"
                        refvalue={(el) =>
                          (profileRef.current["lastnameTh"] = el)
                        }
                      />
                      <PatientTextInputNotRequiredForm
                        title="รหัสโรงพยาบาลอ้างอิง"
                        id="reference-hn"
                        name="reference-hn"
                        refvalue={(el) => (profileRef.current["refHn"] = el)}
                        description="*ไม่ต้องใส่ HN ใส่เฉพาะตัวเลข"
                      />
                      <PatientDropDownFormRequired
                        title="Node"
                        htmlFor="reimburse-right"
                        id="reimburse-right"
                        name="reimburse-right"
                        placeholder="กดเพื่อเลือก"
                        options={nodeList}
                        refvalue={(el) => (profileRef.current["nodeRef"] = el)}
                        onChange={(e) => {
                          setCustomerList(CustomerList(e.target.value));
                        }}
                        // selected={patientInfo.nodeRef}
                        // defaultValue={patientInfo.nodeRef}
                      />
                      <PatientDropDownFormRequired
                        title="Customer"
                        id="customer"
                        placeholder="กดเพื่อเลือก"
                        refvalue={(el) =>
                          (profileRef.current["refCustomer"] = el)
                        }
                        // defaultValue={patientInfo.refCustomer}
                        // selected={patientInfo.refCustomer}
                        options={customerList}
                      ></PatientDropDownFormRequired>
                      <PatientTextInputNotRequiredForm
                        title="เลขประจำตัวประชาชน 13 หลัก"
                        id="national-id"
                        name="national-id"
                        refvalue={(el) =>
                          (profileRef.current["nationalId"] = el)
                        }
                      />

                      <PatientTextInputNotRequiredForm
                        title="วันเดือนปีเกิด"
                        id="date-of-birth"
                        name="date-of-birth"
                        type="date"
                        refvalue={(el) =>
                          (profileRef.current["dateOfBirth"] = el)
                        }
                      />
                      <PatientDropDownForm
                        title="เพศ"
                        htmlFor="gender"
                        id="gender"
                        name="gender"
                        placeholder="กดเพื่อเลือก"
                        options={genders}
                        refvalue={(el) => (profileRef.current["gender"] = el)}
                      />

                      <PatientTextInputNotRequiredForm
                        title="เบอร์โทรศัพท์"
                        id="mobile"
                        name="mobile"
                        refvalue={(el) => (profileRef.current["mobile"] = el)}
                      />
                      <PatientTextInputNotRequiredForm
                        title="เบอร์โทรศัพท์บ้าน"
                        id="telephone"
                        name="telephone"
                        refvalue={(el) =>
                          (profileRef.current["telephone"] = el)
                        }
                      />
                      <PatientTextInputNotRequiredForm
                        title="ประวัติการแพ้ยา"
                        id="medicine-allergic"
                        name="medicine-allergic"
                        refvalue={(el) =>
                          (profileRef.current["medicineIntolerance"] = el)
                        }
                      />

                      <PatientDropDownForm
                        title="สิทธิการเบิก"
                        htmlFor="reimburse-right"
                        id="reimburse-right"
                        name="reimburse-right"
                        placeholder="กดเพื่อเลือก"
                        options={paymentPrivilages}
                        refvalue={(el) =>
                          (profileRef.current["healthInsurance"] = el)
                        }
                      />
                      <PatientTextInputNotRequiredForm
                        title="สิทธิ์อื่นๆ โปรดระบุ"
                        id="other-reimburse-right"
                        name="other-reimburse-right"
                        refvalue={(el) => (profileRef.current["other"] = el)}
                      />
                      <div className="form-group row">
                        <label
                          className="col-lg-4 col-form-label"
                          style={{ color: "#424242" }}
                          htmlFor="val-suggestions"
                        >
                          ข้อความ (Remarks)<span className="text-danger"></span>
                        </label>
                        <div className="col-lg-6">
                          <textarea
                            className="form-control"
                            style={{ color: "#424242" }}
                            id="val-suggestions"
                            name="val-suggestions"
                            rows="5"
                            placeholder="เรื่องอื่นๆ เกี่ยวกับคนไข้"
                            ref={(el) => (profileRef.current["note"] = el)}
                          ></textarea>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-6">
                      <PatientTextInputNotRequiredForm
                        title="คำนำหน้า (อังกฤษ)"
                        id="prename-en"
                        name="prename-en"
                        refvalue={(el) => (profileRef.current["titleEn"] = el)}
                      />
                      <PatientTextInputNotRequiredForm
                        title="ชื่อ (อังกฤษ)"
                        id="first-name-en"
                        name="first-name-en"
                        refvalue={(el) =>
                          (profileRef.current["firstnameEn"] = el)
                        }
                      />
                      <PatientTextInputNotRequiredForm
                        title="นามสกุล (อังกฤษ)"
                        id="last-name-en"
                        name="last-name-en"
                        refvalue={(el) =>
                          (profileRef.current["lastnameEn"] = el)
                        }
                      />
                      <PatientTextInputNotRequiredForm
                        title="อีเมล์"
                        id="email"
                        name="email"
                        refvalue={(el) => (profileRef.current["email"] = el)}
                      />
                      <PatientDropDownForm
                        title="สถานภาพ"
                        htmlFor="married-status"
                        id="married-status"
                        name="married-status"
                        placeholder="กดเพื่อเลือก"
                        options={marriedStatus}
                        refvalue={(el) => (profileRef.current["status"] = el)}
                      />

                      <PatientTextInputNotRequiredForm
                        title="เชื้อชาติ"
                        id="ethnicity"
                        name="ethnicity"
                        refvalue={(el) =>
                          (profileRef.current["ethnicity"] = el)
                        }
                      />
                      <PatientTextInputNotRequiredForm
                        title="สัญชาติ"
                        id="nationality"
                        name="nationality"
                        refvalue={(el) =>
                          (profileRef.current["nationality"] = el)
                        }
                      />
                      <PatientTextInputNotRequiredForm
                        title="ศาสนา"
                        id="religion"
                        name="religion"
                        refvalue={(el) => (profileRef.current["religion"] = el)}
                      />
                      <PatientTextInputNotRequiredForm
                        title="หมู่เลือด"
                        id="blood-type"
                        name="blood-type"
                        refvalue={(el) =>
                          (profileRef.current["bloodType"] = el)
                        }
                      />
                      <PatientTextInputNotRequiredForm
                        title="อาชีพ"
                        id="occupation"
                        name="occupation"
                        refvalue={(el) =>
                          (profileRef.current["occupation"] = el)
                        }
                      />
                      <PatientTextInputNotRequiredForm
                        title="ที่อยู่"
                        id="address1"
                        name="address1"
                        refvalue={(el) => (profileRef.current["address1"] = el)}
                      />
                      <PatientTextInputNotRequiredForm
                        title="ที่อยู่ (ต่อ)"
                        id="address2"
                        name="address2"
                        refvalue={(el) => (profileRef.current["address2"] = el)}
                      />

                      <PatientTextInputNotRequiredForm
                        title="จังหวัด"
                        id="province"
                        name="province"
                        refvalue={(el) => (profileRef.current["province"] = el)}
                      />
                      <PatientTextInputNotRequiredForm
                        title="รหัสไปรษณีย์"
                        id="postal-code"
                        name="postal-code"
                        refvalue={(el) => (profileRef.current["postcode"] = el)}
                      />
                      <PatientTextInputNotRequiredForm
                        title="ชื่อผู้ติดต่ออื่น"
                        id="other-contact"
                        name="other-contact"
                        refvalue={(el) =>
                          (profileRef.current["emergencyContact"] = el)
                        }
                      />

                      <PatientTextInputNotRequiredForm
                        title="ความสัมพันธ์"
                        id="other-relation"
                        name="other-relation"
                        refvalue={(el) =>
                          (profileRef.current["relationship"] = el)
                        }
                      />
                      <PatientTextInputNotRequiredForm
                        title="เบอร์ติดต่อผู้ติดต่ออื่น"
                        id="other-phone"
                        name="other-phone"
                        refvalue={(el) =>
                          (profileRef.current["emergencyContactPhone"] = el)
                        }
                      />
                      <br />

                      <div className="form-group row">
                        <div className="col-lg-8 ml-auto">
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PatientRegister;

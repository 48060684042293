import React, { useState, useRef, useEffect } from "react";
import { Dropdown, Modal, Button } from "react-bootstrap";

import { db, functions, storage } from "../../../firebase";
import { httpsCallable } from "firebase/functions";
import {
  setDoc,
  doc,
  serverTimestamp,
  collection,
  updateDoc,
  getDoc,
  getDocs,
  where,
  query,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import List from "./list";
import { notifyError, notifyTopRight } from "../toast/SetToast";
import Toast from "../../components/toast/SetToast";
import { getMessageFromErrorCode } from "../../ErrorMessage";
import { useAuth } from "../../../context/AuthContext";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import defaultImage from "../../../images/avatar/1.png";
import PageTitle from "../../layouts/PageTitle";

const checkAuthorization = (user, allowedRole) => {
  if (!user) {
    return false;
  } else {
    return user.roles.some((r) => allowedRole.includes(r));
  }
};

const AdminList = () => {
  const adminDefault = {
    firstname: "",
    lastname: "",
    email: "",
    node: "",
    role: "",
    photoURL: "",
  };
  const { currentUser } = useAuth();
  const createAccount = httpsCallable(functions, "createAccount");
  const [createModal, setCreateModal] = useState(false);
  const [createPatientModal, setCreatePatientModal] = useState(false);
  const profileRef = useRef(adminDefault);
  const usernameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const [fileUrl, setFileUrl] = useState(null);
  const [fileName, setFilename] = useState(null);
  const [uuidfilename, setuuidFilename] = useState(null);
  const [createSuperAdmin, setCreateSuperAdmin] = useState(false);
  const [createAdmin, setCreateAdmin] = useState(false);
  const [createPatient, setCreatePatient] = useState(false);
  const [availableHn, setAvailableHn] = useState([]);
  const [nodesList, setNodesList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const canCreateSuperAdmin = () => {
    const allowed = ["super_admin"];
    return checkAuthorization(currentUser, allowed);
  };

  const canCreateAdmin = () => {
    const allowed = ["super_admin", "admin"];
    return checkAuthorization(currentUser, allowed);
  };

  const canCreatePatient = () => {
    const allowed = ["super_admin", "admin", "staff"];
    return checkAuthorization(currentUser, allowed);
  };

  const handleOnCancel = (e) => {
    if (profileRef.current["role"].value == "patient") {
      setCreatePatientModal(false);
      return;
    }
    const storageRef = ref(storage, `avatars/${uuidfilename}`);

    deleteObject(storageRef)
      .then(() => {
        console.log("File Deleted !");
        setuuidFilename("");
      })
      .catch((error) => {
        console.log(error);
      });

    setCreateModal(false);
    setCreatePatientModal(false);
  };

  const selectedFileHandler = async (e) => {
    if (e.target.files[0] == null) {
      notifyError("image is required");
      return;
    }
    const tempfilename = `${uuidv4() + "-" + e.target.files[0].name}`;
    setuuidFilename(tempfilename);
    setFilename(e.target.files[0].name);
    const storageRef = ref(storage, `avatars/${tempfilename}`);
    await uploadBytes(storageRef, e.target.files[0])
      .then((sanpshot) => {
        console.log("File Uploaded!");
        getDownloadURL(sanpshot.ref).then((downloadURL) => {
          setFileUrl(downloadURL);
          console.log("File available at", downloadURL);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const submitHandler = async (e) => {
  //   let acc = {
  //     email: emailRef.current.value,
  //     password: passwordRef.current.value,
  //     displayName: usernameRef.current.value,
  //     role: profileRef.current["role"].value,
  //     node: profileRef.current["node"].value,
  //   };
  //   console.log(acc);

  //   if (profileRef.current["role"].value != "patient") {
  //     acc = {
  //       ...acc,
  //       photoURL: fileUrl,
  //     };
  //   }
  //   try {
  //     createAccount(acc)
  //       .then(async (res) => {
  //         if (res.data.error) {
  //           console.log(res.data.error);
  //           notifyError(
  //             `Error ${getMessageFromErrorCode(res.data.error.errorInfo.code)}`
  //           );
  //         } else {
  //           if (profileRef.current["role"].value == "patient") {
  //             const ref = doc(db, "patients", usernameRef.current.value);

  //             await updateDoc(ref, {
  //               email: emailRef.current.value,
  //               loginAccount: true,
  //             });
  //           } else {
  //             const ref = doc(db, "admins", usernameRef.current.value);
  //             await setDoc(ref, {
  //               firstname: profileRef.current["firstname"].value,
  //               lastname: profileRef.current["lastname"].value,
  //               email: emailRef.current.value,
  //               node: profileRef.current["node"].value,
  //               role: profileRef.current["role"].value,
  //               photo: uuidfilename,
  //               createdAt: serverTimestamp(),
  //             });
  //           }

  //           notifyTopRight("Create success ");
  //           setCreateModal(false);
  //           setCreatePatientModal(false);
  //         }
  //       })
  //       .catch((error) => {
  //         notifyError(getMessageFromErrorCode(error.code));
  //       });
  //   } catch (error) {
  //     console.log(error);
  //     notifyError(getMessageFromErrorCode(error.code));
  //   }
  // };

  const submitHandler = async (e) => {
    e.preventDefault();

    // Retrieve multiple selected nodes
    const selectedNodes = Array.from(
      profileRef.current["node"].selectedOptions
    ).map((option) => option.value);

    let acc = {
      email: emailRef.current.value,
      password: passwordRef.current.value,
      displayName: usernameRef.current.value,
      role: profileRef.current["role"].value,
      node: selectedNodes, // Store as an array instead of single value
    };

    console.log(acc); // Debugging: Ensure nodes are stored correctly

    if (profileRef.current["role"].value !== "patient") {
      acc = {
        ...acc,
        photoURL: fileUrl,
      };
    }

    try {
      createAccount(acc)
        .then(async (res) => {
          if (res.data.error) {
            console.log(res.data.error);
            notifyError(
              `Error ${getMessageFromErrorCode(res.data.error.errorInfo.code)}`
            );
          } else {
            if (profileRef.current["role"].value === "patient") {
              const ref = doc(db, "patients", usernameRef.current.value);
              await updateDoc(ref, {
                email: emailRef.current.value,
                loginAccount: true,
              });
            } else {
              const ref = doc(db, "admins", usernameRef.current.value);
              await setDoc(ref, {
                firstname: profileRef.current["firstname"].value,
                lastname: profileRef.current["lastname"].value,
                email: emailRef.current.value,
                node: selectedNodes, // Save nodes as an array in Firestore
                role: profileRef.current["role"].value,
                photo: uuidfilename,
                createdAt: serverTimestamp(),
              });
            }

            notifyTopRight("Create success ");
            setCreateModal(false);
            setCreatePatientModal(false);
          }
        })
        .catch((error) => {
          notifyError(getMessageFromErrorCode(error.code));
        });
    } catch (error) {
      console.log(error);
      notifyError(getMessageFromErrorCode(error.code));
    }
  };

  const getNodes = async () => {
    const nodes = await getDocs(collection(db, "nodes"));
    if (currentUser.roles[0] == "super_admin") {
      const tempNodes = nodes.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setNodesList(tempNodes);
    } else {
      const currentAdmin = await getDoc(
        doc(db, "admins", currentUser.displayName)
      );
      const currentNode = currentAdmin.data().node;
      const tempNodes = nodes.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      const node = tempNodes.filter((doc) => {
        return doc.nodecode == currentNode;
      });
      setNodesList(node);
    }
  };

  useEffect(async () => {
    setIsLoading(true);
    const queryRef = query(
      collection(db, "patients"),
      where("loginAccount", "==", null)
    );
    const tempArray = [];
    const docSnap = await getDocs(queryRef);
    docSnap.forEach((doc) => {
      tempArray.push(doc.data().hn);
    });
    await getNodes();
    setAvailableHn(tempArray);
    setCreateAdmin(canCreateAdmin());
    setCreatePatient(canCreatePatient());
    setCreateSuperAdmin(canCreateSuperAdmin());
    setIsLoading(false);
  }, []);

  return (
    <React.Fragment>
      {!isLoading && (
        <>
          <Toast />
          <PageTitle activeMenu="Accounts" motherMenu="Admin" motherLink="/" />

          <div className="form-head d-flex mb-3 mb-lg-5 align-items-start">
            <Button
              onClick={() => setCreateModal(true)}
              className="btn btn-danger"
              disabled={!createAdmin}
            >
              + Create Admin
            </Button>
            <Button
              onClick={() => setCreatePatientModal(true)}
              className="btn btn-danger ml-5"
              disabled={!createPatient}
            >
              + Create Patient
            </Button>
          </div>

          {/* Create Admin Modal */}
          <Modal
            className="fade bd-example-modal-lg"
            show={createModal}
            onHide={() => setCreateModal(false)}
            size="lg"
          >
            <Modal.Header>
              <Modal.Title>Create Admin</Modal.Title>
              <Button
                variant=""
                className="close"
                onClick={() => setCreateModal(false)}
              >
                <span>&times;</span>
              </Button>
            </Modal.Header>
            <Modal.Body>
              <div className="card-body">
                <div className="row">
                  <div className="col-xl-11">
                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label">Name</label>
                      <div className="col-lg-4">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="First Name"
                          ref={(el) => (profileRef.current["firstname"] = el)}
                        />
                      </div>
                      <div className="col-lg-4">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Last Name"
                          ref={(el) => (profileRef.current["lastname"] = el)}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label">Email</label>
                      <div className="col-lg-8">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email"
                          ref={emailRef}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label">Node</label>
                      <div className="col-lg-4">
                        <select
                          className="form-control"
                          multiple
                          ref={(el) => (profileRef.current["node"] = el)}
                        >
                          {nodesList.map((doc) => (
                            <option key={doc.nodecode} value={doc.nodecode}>
                              {doc.nodecode}
                            </option>
                          ))}
                        </select>
                        <small className="text-muted">
                          Hold Ctrl (Cmd on Mac) to select multiple nodes
                        </small>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        className="col-lg-3 col-form-label"
                        htmlFor="val-username"
                      >
                        Role
                      </label>
                      <div className="col-lg-4">
                        <select
                          className="form-control form-control-lg-2"
                          id="inlineFormCustomSelect"
                          ref={(el) => (profileRef.current["role"] = el)}
                        >
                          {createSuperAdmin && (
                            <>
                              {" "}
                              <option value="super_admin">Super Admin</option>
                              <option value="sleep_physician">
                                Sleep Physician
                              </option>
                              <option value="sleep_technician">
                                Sleep Technician
                              </option>
                            </>
                          )}
                          {createAdmin && <option value="sale">Sale</option>}
                          {createAdmin && <option value="admin">Admin</option>}
                          {createAdmin && <option value="staff">Staff</option>}
                        </select>
                      </div>
                    </div>

                    <div className="form-group row mt-5">
                      <label className="col-lg-3 col-form-label"> </label>
                      <div className="col-lg-4"> </div>
                    </div>

                    <div className="form-group row">
                      <label
                        className="col-lg-3 col-form-label"
                        htmlFor="val-username"
                      >
                        Displayname
                      </label>
                      <div className="col-lg-5">
                        <input
                          type="text"
                          className="form-control"
                          id="val-username"
                          name="val-username"
                          placeholder="Displayname"
                          ref={usernameRef}
                          // value=""
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        className="col-lg-3 col-form-label"
                        htmlFor="val-username"
                      >
                        Password
                      </label>
                      <div className="col-lg-5">
                        <input
                          type="text"
                          className="form-control"
                          id="val-username"
                          name="val-username"
                          placeholder="Password"
                          ref={passwordRef}
                          // value=""
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        className="col-lg-3 col-form-label"
                        htmlFor="val-username"
                      >
                        Profile Image
                      </label>
                      <div className="basic-form custom_file_input">
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span className="input-group-text">Upload</span>
                          </div>
                          <div className="custom-file">
                            <input
                              type="file"
                              className="custom-file-input"
                              onChange={selectedFileHandler}
                            />
                            <label className="custom-file-label">
                              {fileName}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger light" onClick={handleOnCancel}>
                Cancel
              </Button>
              <Button
                variant=""
                type="button"
                className="btn btn-primary"
                onClick={submitHandler}
              >
                Create
              </Button>
            </Modal.Footer>
          </Modal>

          {/* List Component */}
          <List nodesList={nodesList} />
        </>
      )}
    </React.Fragment>
  );
};

export default AdminList;

import React, { Fragment, useRef } from "react";
import _ from "lodash";

import RegisterFields from "./RegisterFields";
import equipmentFields from "./equipmentFields";

export const EquipmentForm = (props) => {
  const inputRef = useRef({});

  const renderFields = () => {
    return _.map(
      equipmentFields,
      ({ label, name, type, options, required, disabled, description }) => {
        return (
          <RegisterFields
            name={name}
            label={label}
            type={type}
            value={inputRef.current.name}
            fileNames={props.fileNames}
            options={options}
            required={required}
            initailValue={
              props.initailValues ? props.initailValues[name] : null
            }
            onChange={props.onChange(name)}
            disabled={disabled}
            description={description}
            viewonly={props.viewonly}
          />
        );
      }
    );
  };

  return <Fragment>{renderFields()}</Fragment>;
};

import moment from "moment";

// Filters Reducer

const filtersReducerDefaultState = {
  text: "",
  searchBy: "firstname",
  sortBy: "date",
  sortByStatus: "ทั้งหมด",
  sortByCustomer: "ทั้งหมด"
};

export default (state = filtersReducerDefaultState, action) => {
  switch (action.type) {
   
    case "SET_TEXT_FILTER":
      return {
        ...state,
        text: action.text,
      };
    case "SET_SEARCHBY_FILTER":
      return {
        ...state,
        searchBy: action.searchBy,
      };
    case "SORT_BY_DATE":
      return {
        ...state,
        sortBy: "date",
      };
    case "SET_SORTBY_STATUS":
      return {
        ...state,
        sortByStatus: action.sortByStatus
      }
    case "SET_SORTBY_CUSTOMER":

      return {
        ...state,
        sortByCustomer: action.sortByCustomer
      }

    default:
      return state;
  }
};

import React, { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { EquipmentForm } from "./EquipmentForm";
import EquipmentItem from "./EquipmentItem";

import { db } from "../../../firebase";
import {
  orderBy,
  where,
  query,
  collection,
  and,
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";

import { Button, Modal } from "react-bootstrap";
import { useAuth } from "../../../context/AuthContext";
import PageTitle from "../../layouts/PageTitle";
// import formFields from "./formFields";
// import orderFormFields from "./orderFormFields";
import { usePagination } from "../../../hook/usePagination";

const EquipmentList = (props) => {
  const { currentUser } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [edit, setEdit] = useState({});
  const [searchItem, setSearchItem] = useState("");
  const [equipments, setEquipments] = useState([]);
  const searchRef = useRef(null);

  const inputRef = useRef({
    serialNumber: null,
    deviceName: null,
    model: null,
    description: null,
    lotNumber: null,
    qty: null,
    status: null,
    incomingDate: null,
  });

  const itemPerPage = 50;

  let QueryFilterConstraint = [];
  let queryRef = query(
    collection(db, "equipments"),
    orderBy("status", "desc"),
    orderBy("serialNumber", "asc")
  );

  if (searchItem !== "") {
    QueryFilterConstraint.push(
      where("serialNumber", ">=", searchItem),
      where("serialNumber", "<=", searchItem + "\uf8ff")
    );

    queryRef = query(
      collection(db, "equipments"),
      and(...QueryFilterConstraint),
      orderBy("status", "desc"),
      orderBy("serialNumber", "asc")
    );
  }

  const {
    items: equipmentsList,
    isLoading,
    isStart,
    isEnd,
    getPrev,
    getNext,
    totalItems,
    totalPages,
    currentPage,
    goToPage,
  } = usePagination(queryRef, { limit: itemPerPage });

  const toggle = () => {
    setShowModal(!showModal);
  };

  const handleChange = (name) => (e) => {
    inputRef.current[name] = e.target.value;
  };

  const handleOnEdit = (id) => {
    const data = equipmentsList.find((item) => item.id === id);

    setEdit(data);
    setShowModal(true);
  };

  const handleOnHide = (sn) => {
    setShowModal(false);
  };

  const handleOnDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        await deleteDoc(doc(db, "equipments", id)).then(() =>
          swal(`Poof! Your file has been deleted!`, {
            icon: "success",
          })
        );
      } else {
        swal("Canceled");
      }
    });
  };

  const onSearchChange = () => {
    setSearchItem(searchRef.current.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const ref = doc(db, "equipments", edit.id);
    const docSnap = await getDoc(ref);
    let formData = {};
    if (docSnap.exists()) {
      formData = {
        serialNumber: inputRef.current.serialNumber
          ? inputRef.current.serialNumber
          : edit.serialNumber,
        deviceName: inputRef.current.deviceName
          ? inputRef.current.deviceName
          : edit.deviceName,
        model: inputRef.current.model ? inputRef.current.model : edit.model,
        description: inputRef.current.description
          ? inputRef.current.description
          : edit.description,
        lotNumber: inputRef.current.lotNumber
          ? inputRef.current.lotNumber
          : edit.lotNumber,
        qty: inputRef.current.qty ? inputRef.current.qty : edit.qty,
        status: inputRef.current.status ? inputRef.current.status : edit.status,
        incomingDate: inputRef.current.incomingDate
          ? inputRef.current.incomingDate
          : edit.incomingDate,
        updatedAt: new Date(),
      };
      // console.log(formData);
    }
    await updateDoc(ref, formData);

    handleOnHide();
  };

  useEffect(() => {}, []);

  return (
    <Fragment>
      <PageTitle activeMenu="Equipment List" motherMenu="Equipments" />
      <Modal
        className="fade bd-example-modal-lg"
        show={showModal}
        onHide={toggle}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Edit Order Datails</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EquipmentForm onChange={handleChange} initailValues={edit} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
        <div className="d-flex justify-content-center mb-3">
          <Button className="sm" onClick={handleSubmit}>
            Save
          </Button>

          <Button className="sm ml-2" variant="light" onClick={handleOnHide}>
            Cancel
          </Button>
        </div>
      </Modal>
      <div className="form-head d-flex mb-3 mb-lg-5 align-items-start">
        <Link
          to="/equipment-register"
          className="btn btn-danger"
          data-toggle="tooltip"
          data-placement="top"
          title="Equipment Registration"
        >
          + New Equipment
        </Link>
        <div className="input-group search-area ml-auto d-inline-flex">
          <input
            type="text"
            className="form-control"
            placeholder="Search serial number here"
            ref={searchRef}
            onChange={onSearchChange}
          />
          <div className="input-group-append">
            <span className="input-group-text c-pointer">
              <i className="flaticon-381-search-2"></i>
            </span>
          </div>
        </div>
      </div>
      {/* <p className="text-danger">Under Testing</p> */}
      <EquipmentItem
        equipments={equipmentsList}
        onEdit={handleOnEdit}
        onDelete={handleOnDelete}
        // onNext={!isEnd && getNext}
        // onPrev={!isStart && getPrev}
        totalItems={totalItems}
        totalPages={totalPages}
        currentPage={currentPage}
        itemsPerPage={itemPerPage}
        goToPage={goToPage}
        isStart={isStart}
        isEnd={isEnd}
        getNext={getNext}
        getPrev={getPrev}
      />
    </Fragment>
  );
};

export default EquipmentList;

import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Card, Dropdown, Table } from "react-bootstrap";
import { db } from "../../../firebase";
import { collectionGroup, getDocs, collection } from "firebase/firestore";
import emergency from "../../../images/emergency-icon.svg";
// import { statusList } from "./BookingUtils";

const BookingUrgent = () => {
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState("ทั้งหมด"); // Default to "ทั้งหมด"
  // const [selectedNode, setSelectedNode] = useState("ทั้งหมด"); // Default to "ทั้งหมด"
  const statusList = [
    "ทั้งหมด",
    "Sleep Test Scheduled",
    "Transferred To Center",
    "Assigned",
    "Technician Scored",
  ];
  // var nodeList = ["ทั้งหมด"]
  const [nodeBy, setNodeBy] = useState("ทั้งหมด");
  const [nodeList, setNodeList] = useState([]);
  // const [nodeIdDocs,setNodeIdDocs] = useState([])
  useEffect(() => {
    setLoading(true);
    // allnodeIdDocs = nodesDocs.docs.map((doc) => doc.id);

    const fetchAndFilterBookings = async () => {
      try {
        const bookingsCollectionGroupRef = collectionGroup(db, "bookings");
        const snapshot = await getDocs(bookingsCollectionGroupRef);

        const todayDate = new Date(); // Today's date

        // Map and filter the data
        const filteredData = snapshot.docs
          .map((doc) => {
            const data = doc.data();
            const { healthInsurance, latestSleepStudyDate, status, node } =
              data.extendedProps || {};
            const latestSleepStudyDateObj = new Date(latestSleepStudyDate);

            // Skip entries where latestSleepStudyDate is in the future
            if (latestSleepStudyDateObj > todayDate) return null;

            // Add alert field based on healthInsurance and the difference in days
            let alert = false;
            let hurry = false;

            // Case 1: Self-payment and more than 8 days past latestSleepStudyDate
            if (
              healthInsurance === "ชำระเงินเอง" &&
              (todayDate - latestSleepStudyDateObj) / (1000 * 60 * 60 * 24) >
                6 &&
              (todayDate - latestSleepStudyDateObj) / (1000 * 60 * 60 * 24) < 8
            ) {
              alert = true;
              hurry = false;
            } else if (
              healthInsurance === "ชำระเงินเอง" &&
              (todayDate - latestSleepStudyDateObj) / (1000 * 60 * 60 * 24) > 8
            ) {
              alert = true;
              hurry = true;
            }

            // Case 2: Non-self-payment and more than 31 days past latestSleepStudyDate
            else if (
              healthInsurance !== "ชำระเงินเอง" &&
              (todayDate - latestSleepStudyDateObj) / (1000 * 60 * 60 * 24) >
                21 &&
              (todayDate - latestSleepStudyDateObj) / (1000 * 60 * 60 * 24) < 30
            ) {
              alert = true;
              hurry = false;
            } else if (
              healthInsurance !== "ชำระเงินเอง" &&
              (todayDate - latestSleepStudyDateObj) / (1000 * 60 * 60 * 24) > 30
            ) {
              alert = true;
              hurry = true;
            }

            // Return data with the alert field
            return {
              id: doc.id,
              ...data,
              alert,
              hurry,
            };
          })
          .filter((data) => data !== null) // Remove null values from the result
          .filter((data) => {
            const { healthInsurance, latestSleepStudyDate, status } =
              data.extendedProps || {};
            const statusConditions = [
              "Sleep Test Scheduled",
              "Transferred To Center",
              "Assigned",
              "Technician Scored",
            ];

            // Parse latestSleepStudyDate string to Date object
            const latestSleepStudyDateObj = new Date(latestSleepStudyDate);

            // Case 1: Self-payment and latestSleepStudyDate - todayDate > 3 days
            if (
              healthInsurance === "ชำระเงินเอง" &&
              (todayDate - latestSleepStudyDateObj) / (1000 * 60 * 60 * 24) >
                4 &&
              statusConditions.includes(status)
            ) {
              return true;
            }

            // Case 2: Non-self-payment and latestSleepStudyDate - todayDate > 20 days
            if (
              healthInsurance !== "ชำระเงินเอง" &&
              (todayDate - latestSleepStudyDateObj) / (1000 * 60 * 60 * 24) >
                13 &&
              statusConditions.includes(status)
            ) {
              return true;
            }

            return false;
          })
          .filter((data) => {
            // Filter by node if nodeBy is not "ทั้งหมด"
            if (nodeBy !== "ทั้งหมด" && data.extendedProps.node !== nodeBy) {
              return false;
            }
            return true;
          })
          .sort((a, b) => {
            // Sort by healthInsurance: 'ชำระเงินเอง' first
            if (
              a.extendedProps.healthInsurance === "ชำระเงินเอง" &&
              b.extendedProps.healthInsurance !== "ชำระเงินเอง"
            ) {
              return -1; // 'ชำระเงินเอง' comes first
            } else if (
              a.extendedProps.healthInsurance !== "ชำระเงินเอง" &&
              b.extendedProps.healthInsurance === "ชำระเงินเอง"
            ) {
              return 1; // Non-'ชำระเงินเอง' comes after
            }

            // Then sort by the time difference, most difference first
            const aTimeDiff =
              (todayDate - new Date(a.extendedProps.latestSleepStudyDate)) /
              (1000 * 60 * 60 * 24); // In days
            const bTimeDiff =
              (todayDate - new Date(b.extendedProps.latestSleepStudyDate)) /
              (1000 * 60 * 60 * 24); // In days

            return bTimeDiff - aTimeDiff; // Sort in descending order (most difference first)
          });

        // Apply status filter based on selected status
        const statusConditions =
          selectedStatus === "ทั้งหมด"
            ? [
                "Sleep Test Scheduled",
                "Transferred To Center",
                "Assigned",
                "Technician Scored",
              ]
            : [selectedStatus];

        const finalFilteredData = filteredData.filter((data) =>
          statusConditions.includes(data.extendedProps.status)
        );

        setFilteredBookings(finalFilteredData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching and filtering bookings: ", error);
        setLoading(false);
      }
    };

    fetchAndFilterBookings();
  }, [selectedStatus, nodeBy]); // Re-run when selectedStatus changes

  useEffect(async () => {
    const [nodesDocs] = await Promise.all([getDocs(collection(db, "nodes"))]);
    // let allnodeIdDocs = [];
    let allNodes = [];

    allNodes = nodesDocs.docs.map((doc) => doc.data().nodename);
    setNodeList(["ทั้งหมด", ...allNodes]);
  }, []);

  //   if (loading) {
  //     return <div>Loading...</div>;
  //   }

  return (
    <div>
      <h4>{"Filter only status Sleep Test Scheduled ,Transferred To Center, Assigned and Technician Scored"}</h4>
      <h4 style={{ paddingLeft: "65px" }}>
        {"ชำระเงินเอง > 3 days, ประกันสังคม > 12 days"}
      </h4>

      <h4>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="flaticon-381-alarm-clock"></i>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {"ชำระเงินเอง > 5 days, ประกันสังคม > 20 days"}
      </h4>
      <h4>
        &nbsp;&nbsp;{" "}
        <img
          src={emergency}
          alt="Emergency Icon"
          style={{ width: "2em", height: "2em" }}
        />
        &nbsp;&nbsp;
        {" ชำระเงินเอง > 7 days, ประกันสังคม > 30 days"}
      </h4>

      {/* Dropdown for Status Filter */}
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Dropdown className="m-3">
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            Select Status {selectedStatus}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {statusList.map((status, index) => (
              <Dropdown.Item
                key={index}
                onClick={() => setSelectedStatus(status)}
              >
                {status}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown className="m-3">
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            Select Node {nodeBy}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {nodeList.map((node, index) => (
              <Dropdown.Item key={index} onClick={() => setNodeBy(node)}>
                {node}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {!loading && (
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Body className="card-body">
                <Table className="table-responsive-12 mb-0 table-striped">
                  <thead>
                    <tr>
                      <th> </th>
                      <th>Health Insurance</th>
                      <th>Sleep Study</th>
                      <th>Name</th>
                      <th>HN</th>
                      <th>TYPE</th>
                      <th>Tech Record</th>
                      <th>Doctor</th>
                      <th>STATUS</th>
                      <th>Sleep Study Date</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody id="booking-table">
                    {filteredBookings.map((element, index) => {
                      return (
                        <tr key={element.id}>
                          <td className="py-0">{index + 1}</td>
                          <td>{element.extendedProps.healthInsurance}</td>
                          <td className="py-0">
                            <Link to="#">{element.extendedProps.order}</Link>
                          </td>
                          <td className="py-0">
                            <Link
                              to={`/patient-page/${element.extendedProps.hn}`}
                            >
                              {element.extendedProps.firstname}{" "}
                              {element.extendedProps.lastname}
                            </Link>
                          </td>
                          <td className="py-0">
                            <Link
                              to={`/patient-page/${element.extendedProps.hn}`}
                            >
                              {element.extendedProps.hn}
                            </Link>
                          </td>
                          <td className="py-0">{element.extendedProps.type}</td>
                          <td className="py-0">
                            {element.extendedProps.techrecord}
                          </td>
                          <td className="py-0">
                            {element.extendedProps.doctor}
                          </td>
                          <td className="py-0">
                            {element.extendedProps.status}
                          </td>
                          <td>{element.extendedProps.latestSleepStudyDate}</td>
                          <td>
                            {element.alert && !element.hurry ? (
                              <i className="flaticon-381-alarm-clock"></i>
                            ) : element.alert && element.hurry ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={emergency}
                                  alt="Emergency Icon"
                                  style={{ width: "3em", height: "3em" }}
                                />
                              </div>
                            ) : null}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default BookingUrgent;

import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getFirestore,
  collectionGroup,
  query,
  where,
  getDocs,
  collection,
} from "firebase/firestore";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const ExportModal = ({ show, handleClose }) => {
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());

  const handleExport = async () => {
    const db = getFirestore();

    // Convert selected date to Firestore Timestamps
    const startTimestamp = new Date(startTime);
    startTimestamp.setHours(0, 0, 0, 0); // Start of the day

    const endTimestamp = new Date(endTime);
    endTimestamp.setHours(23, 59, 59, 999); // End of the day

    console.log(
      "Querying from:",
      startTimestamp.toISOString(),
      "to:",
      endTimestamp.toISOString()
    );

    try {
      // Step 1: Get all inventory items
      const inventorySnapshot = await getDocs(collection(db, "inventory"));
      const inventoryData = inventorySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      let reportData = [];

      // Step 2: Loop through each inventory item to fetch its history transactions
      for (const item of inventoryData) {
        const historyRef = collection(db, "inventory", item.id, "history");
        const historyQuery = query(
          historyRef,
          where("datetime", ">=", startTimestamp),
          where("datetime", "<=", endTimestamp)
        );

        const historySnapshot = await getDocs(historyQuery);

        let addedCount = 0;
        let releasedCount = 0;
        let totalAdded = 0;
        let totalReleased = 0;

        historySnapshot.docs.forEach((doc) => {
          const history = doc.data();
          if (history.status === "+") {
            addedCount++;
            totalAdded += history.amount || 0; // Ensure quantity exists
          } else if (history.status === "-") {
            releasedCount++;
            totalReleased += history.amount || 0;
          }
        });

        reportData.push({
          "Item ID": item.itemId,
          "Item Name": item.itemName, // Assuming inventory has a name field
          "Item Size": item.size,
          "Current Available": item.available || 0,
          "Add Transactions": addedCount,
          "Total Added": totalAdded,
          "Release Transactions": releasedCount,
          "Total Released": totalReleased,
        });
      }
      reportData.sort((a, b) => a["Item ID"].localeCompare(b["Item ID"]));
      console.log("Final Report Data:", reportData);

      // Convert dates to Thai format
      const thaiStartDate = formatThaiDate(startTimestamp);
      const thaiEndDate = formatThaiDate(endTimestamp);
      const filename = "Inventory" + " " + formatFileName(endTimestamp) + " - " +formatFileName(endTimestamp); // File name in dd-mm-YYYY format

      // Step 3: Convert reportData to Excel and Download
      exportToExcel(reportData, filename, thaiStartDate, thaiEndDate);

      alert("Export Successful! Check your downloads.");
      handleClose();
    } catch (error) {
      console.error("Error exporting data:", error);
      alert("Error exporting data!");
    }
  };

  // Function to Format Date to Thai
  const formatThaiDate = (date) => {
    const monthsThai = [
      "มกราคม",
      "กุมภาพันธ์",
      "มีนาคม",
      "เมษายน",
      "พฤษภาคม",
      "มิถุนายน",
      "กรกฎาคม",
      "สิงหาคม",
      "กันยายน",
      "ตุลาคม",
      "พฤศจิกายน",
      "ธันวาคม",
    ];
    const day = date.getDate();
    const month = monthsThai[date.getMonth()];
    const year = date.getFullYear() + 543; // Convert to Buddhist Era (BE)
    return `${day} ${month} ${year}`;
  };

  // Function to Format File Name as dd-mm-YYYY.xlsx
  const formatFileName = (date) => {
    const dd = String(date.getDate()).padStart(2, "0");
    const mm = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
    const yyyy = date.getFullYear();
    return `${dd}-${mm}-${yyyy}`;
  };

  // Function to Export Data to Excel
  const exportToExcel = (data, filename, thaiStartDate, thaiEndDate) => {
    const worksheet = XLSX.utils.json_to_sheet([]);
    const workbook = XLSX.utils.book_new();

    // Add Date Range in the First Row
    const titleRow = [
      `รายงานข้อมูลระหว่างวันที่ ${thaiStartDate} ถึง ${thaiEndDate}`,
    ];

    XLSX.utils.sheet_add_aoa(worksheet, [titleRow], { origin: "A1" });

    // Add Empty Row for Spacing
    XLSX.utils.sheet_add_aoa(worksheet, [[]], { origin: "A2" });

    // Add Table Headers and Data (Starting from Row 3)
    XLSX.utils.sheet_add_json(worksheet, data, {
      origin: "A3",
      skipHeader: false,
    });

    XLSX.utils.book_append_sheet(workbook, worksheet, "Report");

    // Convert workbook to binary and trigger download
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const dataBlob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });

    saveAs(dataBlob, `${filename}.xlsx`);
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Select Date Range</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Start Time</Form.Label>
            <DatePicker
              selected={startTime}
              onChange={(date) => setStartTime(date)}
              //   showTimeSelect
              dateFormat="yyyy-MM-dd" // Show only date
              className="form-control"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>End Time</Form.Label>
            <DatePicker
              selected={endTime}
              onChange={(date) => setEndTime(date)}
              //   showTimeSelect
              dateFormat="yyyy-MM-dd" // Show only date
              className="form-control"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleExport}>
          Export
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExportModal;

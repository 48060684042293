import React, { Component } from "react";
import {
  Col,
  Row,
  Card,
  Modal,
  Button,
  Dropdown,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  setEvent,
  eventTmp,
  paymentPrivilages,
  orderList,
  colorList,
  statusList,
  typeList,
} from "./BookingUtils";
import { PatientDropDownForm } from "./BookingUtils";
import { db } from "../../../firebase";

import {
  query,
  endAt,
  getDocs,
  getDoc,
  collection,
  orderBy,
  limit,
  startAfter,
  endBefore,
  limitToLast,
  startAt,
  getCountFromServer,
  where,
  doc,
  deleteDoc,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import Toast from "../toast/SetToast";
import { notifyError, notifyTopRight } from "../toast/SetToast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import BookingTable from "./BookingTable";
import PageTitle from "../../layouts/PageTitle";
import { AuthContext } from "../../../context/AuthContext";

class BookingList extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      textSearch: "",
      sortBy: "firstname",
      typeBy: "ทั้งหมด",
      statusBy: "ทั้งหมด",
      techBy: "กรุณาเลือก",
      nodeBy: "",
      // isAddNewEvent: false,
      isAddEventModal: false,
      isDatetime: false,
      isNewPatient: false,
      dateTmp: new Date().toISOString().slice(0, 10),
      timeTmp: "",
      items: [],
      totalValue: 0,
      itemList: [],
      doctorList: [],
      technicianList: [],
      techrecordList: [],
      nodeList: [],
      nodeIdDocs: [],
      customerList: [],
      customerAllList: [],
      bookingList: [],
      currentAdmin: null,
      fileUrl: null,
    };
  }
  dateRef = React.createRef();
  titleRef = React.createRef();
  idRef = React.createRef();
  orderRef = React.createRef();
  nodeRef = React.createRef();
  customerRef = React.createRef();
  hnRef = React.createRef();
  typeRef = React.createRef();
  statusRef = React.createRef();
  colorRef = React.createRef();
  firstnameRef = React.createRef();
  lastnameRef = React.createRef();
  nationalIdRef = React.createRef();
  healthInsuranceRef = React.createRef();
  phoneRef = React.createRef();
  noteRef = React.createRef();
  itemsRef = React.createRef([]);
  techRef = React.createRef([]);
  docRef = React.createRef([]);
  referDocRef = React.createRef([]);
  techRecordRef = React.createRef([]);
  fileInputRef = React.createRef(null);
  ahiRef = React.createRef();
  hospitalNumberRef = React.createRef();
  genderRef = React.createRef();

  sortTitle = ["HN", "Name", "Sleep Study", "Activity Date"];
  sortKey = ["hn", "firstname", "order", "start"];
  onClick = (e) => {
    e.preventDefault();
    console.log("onclick..");
  };
  async componentDidMount() {
    const fetchData = async () => {
      try {
        // Use Promise.all for parallel fetching
        const [nodesDocs, itemsDocs, techDocs, docDocs] = await Promise.all([
          getDocs(collection(db, "nodes")),
          getDocs(collection(db, "items")),
          getDocs(collection(db, "sleeptechnicians")),
          getDocs(collection(db, "doctors")),
        ]);

        let allCustomers = [];
        let bookingEvent = [];
        let allnodeIdDocs = [];
        let allNodes = [];
        const currentUserRole = this.context.currentUser.roles[0];

        if (
          currentUserRole === "super_admin" ||
          currentUserRole === "sleep_physician" ||
          currentUserRole === "sleep_technician" ||
          currentUserRole == "sale"
        ) {
          this.setState({ nodeBy: "ทั้งหมด" });
          allNodes = nodesDocs.docs.map((doc) => doc.data().nodename);
          allnodeIdDocs = nodesDocs.docs.map((doc) => doc.id);
        } else {
          const currentAdminTemp = await getDoc(
            doc(db, "admins", this.context.currentUser.displayName)
          );
          this.setState({ currentAdmin: currentAdminTemp });
          let nodeCode = currentAdminTemp.data().node;
          // let nodes = nodesDocs.docs.filter(
          //   (doc) => doc.data().nodecode === nodeCode
          // );
          // if (nodes.length > 0) {
          //   this.setState({ nodeBy: nodes[0].data().nodename });
          //   allnodeIdDocs = nodes.map((doc) => doc.id);
          //   allNodes = nodes.map((doc) => doc.data().nodename);
          // }
          // Ensure `nodeCode` is always an array
          if (!Array.isArray(nodeCode)) {
            nodeCode = nodeCode ? [nodeCode] : []; // Convert to array or set to empty
          }

          // Find matching nodes
          const node = nodesDocs.docs.filter((doc) =>
            nodeCode.includes(doc.data().nodecode)
          );

          allnodeIdDocs = node.map((doc) => doc.id);
          allNodes = node.map((doc) => doc.data().nodename);

          this.setState({ nodeBy: allNodes[0] });
        }

        // Fetch customers and bookings in parallel for all nodes
        const fetchNodeData = allnodeIdDocs.map(async (nodeId) => {
          const [customerDocs, bookingDocs] = await Promise.all([
            getDocs(collection(db, "nodes", nodeId, "customers")),
            getDocs(collection(db, "nodes", nodeId, "bookings")),
          ]);

          const customersData = customerDocs.docs.map(
            (customer) => customer.id
          );
          const nodeBookings = bookingDocs.docs.map((booking) => {
            const bookingData = booking.data();
            const dateStart = new Date(bookingData.start.toDate());
            dateStart.setHours(dateStart.getHours() + 7);
            bookingData.start = dateStart.toISOString().slice(0, 10);
            return bookingData;
          });

          return { customersData, nodeBookings };
        });

        const nodeData = await Promise.all(fetchNodeData);

        nodeData.forEach(({ customersData, nodeBookings }) => {
          allCustomers.push(customersData);
          bookingEvent = [...bookingEvent, ...nodeBookings];
        });

        const allItems = itemsDocs.docs.map((doc) => ({
          ...doc.data(),
          itemcode: doc.id,
        }));
        const allTechs = [
          "กรุณาเลือก",
          ...techDocs.docs.map((doc) => doc.data().name),
        ];
        const allTechsRecord = [
          "กรุณาเลือก",
          ...techDocs.docs.map((doc) => doc.data().name),
        ];
        const allDocs = [
          "กรุณาเลือก",
          ...docDocs.docs.map((doc) => doc.data().name),
        ];

        // Batch update state
        this.setState({
          bookingList: bookingEvent,
          nodeList: allNodes,
          customerAllList: allCustomers,
          customerList: allCustomers[0],
          doctorList: allDocs,
          technicianList: allTechs,
          techrecordList: allTechsRecord,
          itemList: allItems,
          nodeIdDocs: allnodeIdDocs,
        });
      } catch (error) {
        console.log("Error fetching data: ", error);
      }
    };

    setTimeout(() => {
      fetchData();
    }, 10); // Adjust the timeout value as needed (e.g., 5000ms = 5 seconds)
  }

  handleAddItem = () => {
    // Create a new copy of items array and add the new item
    const newItem = {
      id: this.state.items.length + 1,
      itemcode: this.state.itemList[0].itemcode,
      item: this.state.itemList[0].item,
      unitcost: this.state.itemList[0].unitcost,
      qty: 1,
      discount: 0,
      total: this.state.itemList[0].unitcost,
    };
    const updatedItems = [...this.state.items, newItem];
    // Update the items state and calculate the total value
    // setItems(updatedItems);
    this.setState({ items: updatedItems });
    const newTotalValue = this.calculateTotalValue(updatedItems);
    this.setState({ totalValue: newTotalValue });
    // setTotalValue(newTotalValue);
  };
  onUpdateItemValue = (key, index, value) => {
    var tmpItems = this.state.items;
    tmpItems[index][key] = value.target.value;
    tmpItems[index].total =
      tmpItems[index].unitcost * tmpItems[index].qty - tmpItems[index].discount;
    this.setState({ items: tmpItems });
    const newTotalValue = this.calculateTotalValue(tmpItems);
    this.setState({ totalValue: newTotalValue });
  };
  calculateTotalValue = (itemsList) => {
    let totalValueTmp = 0;
    itemsList.forEach((item) => {
      totalValueTmp += parseInt(item.total);
    });
    return totalValueTmp;
  };
  handleDate = (moment, props) => {
    let storeMoment = moment.toISOString().slice(0, 10);
    this.setState({ dateTmp: storeMoment });
  };
  handleTime = (moment, props) => {
    let storeMoment = moment.target.value;
    this.setState({ timeTmp: storeMoment });
  };
  handleStatusChange = (e) => {
    const selectedStatus = this.statusRef.current.value;
    if (selectedStatus == "Post-Consult") {
      this.setState({ isDatetime: true });
    } else {
      this.setState({ isDatetime: false });
    }
  };
  onSearchPatient = async () => {
    const q = query(
      collection(db, "patients"),
      where("hn", "==", this.hnRef.current.value)
    );
    const querySnapshot = await getDocs(q);
    let listPatient = [];
    // console.log(querySnapshot);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      listPatient.push(doc.data());
    });
    if (listPatient[0] == undefined) {
      this.setState({ isNewPatient: true });
    } else {
      try {
        this.firstnameRef.current.value = listPatient[0].firstnameTh;
        this.lastnameRef.current.value = listPatient[0].lastnameTh;
        this.nationalIdRef.current.value = listPatient[0].username;
        this.phoneRef.current.value = listPatient[0].mobile;
        this.hospitalNumberRef.current.value = listPatient[0].hnRef;
        this.genderRef.current.value = listPatient[0].gender;
        if (
          listPatient[0].healthInsurance == "" ||
          listPatient[0].healthInsurance == "please-select"
        ) {
          this.healthInsuranceRef.current.value = "กรุณาเลือก";
        } else {
          this.healthInsuranceRef.current.value =
            listPatient[0].healthInsurance;
        }
      } catch (err) {
        console.log(err);
        // var getTmp = this.state.eventTmp;
        // console.log(getTmp);
        // getTmp.setExtendedProp("firstname", listPatient[0].firstnameTh);
        // getTmp.setExtendedProp("lastname", listPatient[0].lastnameTh);
        // getTmp.setExtendedProp("nationalId", listPatient[0].username);
        // getTmp.setExtendedProp("phone", listPatient[0].mobile);
        // if (
        //   listPatient[0].healthInsurance == "" ||
        //   listPatient[0].healthInsurance == "please-select"
        // ) {
        //   getTmp.setExtendedProp("healthInsurance", "กรุณาเลือก");
        // } else {
        //   getTmp.setExtendedProp(
        //     "healthInsurance",
        //     listPatient[0].healthInsurance
        //   );
        // }
        // this.setState({ eventTmp: getTmp });
      }
      this.setState({ isNewPatient: false });
    }
  };
  CustomerList = (nodename) => {
    const index = this.state.nodeList.indexOf(nodename);
    return this.state.customerAllList[index];
  };
  onAddEvent = async () => {
    const currentUserName = this.context.currentUser.displayName;
    var indexOfStatusList = statusList.findIndex(
      (obj) => obj === this.statusRef.current.value
    );
    var currentLatestSleepStudy;
    if (this.statusRef.current.value === "Sleep Test Scheduled") {
      currentLatestSleepStudy = this.state.dateTmp;
    } else {
      currentLatestSleepStudy = "";
    }
    var colorTmp = colorList[indexOfStatusList];
    var event = {
      title: this.orderRef.current.value,
      start: this.state.dateTmp,
      id: 6,
      end: null,
      color: colorTmp,
      extendedProps: {
        customer: this.customerRef.current.value,
        doctor: this.docRef.current.value,
        firstname: this.firstnameRef.current.value,
        healthInsurance: this.healthInsuranceRef.current.value,
        hn: this.hnRef.current.value,
        items: this.state.items,
        lastname: this.lastnameRef.current.value,
        nationalId: this.nationalIdRef.current.value,
        node: this.nodeRef.current.value,
        note: this.noteRef.current.value,
        order: this.orderRef.current.value,
        phone: this.phoneRef.current.value,
        status: this.statusRef.current.value,
        technician: this.techRef.current.value,
        time: this.state.timeTmp,
        totalValue: this.state.totalValue,
        type: this.typeRef.current.value,
        referDoctor: this.referDocRef.current.value,
        techrecord: this.techRecordRef.current.value,
        latestSleepStudyDate: currentLatestSleepStudy,
        ahi: this.ahiRef.current.value,
        hospitalNumber: this.hospitalNumberRef.current.value,
        createBy: currentUserName,
        gender: this.genderRef.current.value,
      },
    };
    var state = await setEvent(
      event,
      undefined,
      this.state.nodeIdDocs,
      this.state.nodeList
    );
    this.setState({ triggerRerender: true });
    // var statusTmp = this.state.statusBy
    // var sortTmp = this.state.sortBy
    // var typeByTmp = this.state.typeBy

    // this.setState({
    //   statusBy: statusTmp,
    //   sortBy: sortTmp,
    //   typeBy: typeByTmp,
    // })
    // console.log(state);
    this.setState({ isAddEventModal: false });

    // if (state == "OK") {
    //   notifyTopRight("Booking success !");
    //   this.setState({ isAddEventModal: false });
    // } else {
    //   notifyError("Please fill out booking information !");

    // }
  };

  onSearchChange = (e) => {
    setTimeout(() => {
      this.setState({ textSearch: e.target.value });
    }, 800);
  };

  onFilterChange = (option) => {
    this.setState({ sortBy: `${option}` });
  };

  onTypeChange = (option) => {
    this.setState({ typeBy: `${option}` });
  };
  onStatusChange = (option) => {
    this.setState({ statusBy: `${option}` });
  };
  onNodeChange = (option) => {
    this.setState({ nodeBy: `${option}` });
  };
  onGetTitleSort = (key) => {
    const index = this.sortKey.indexOf(key);
    return this.sortTitle[index];
  };
  resetTriggerRerender = () => {
    this.setState({ triggerRerender: false });
  };
  onTechChange = (option) => {
    this.setState({ techBy: `${option}` });
  };
  render() {
    // var FilterOption = "Type" ;

    // this.state.sortBy = FilterOption ;

    return (
      <React.Fragment>
        <PageTitle
          activeMenu="Table"
          motherMenu="Booking"
          motherLink="/booking-calendar"
        />
        <Toast />
        <div className="form-head d-flex mb-3 mb-lg-5 align-items-start">
          {!["staff", "sale", "sleep_technician"].includes(
            this.context.currentUser.roles[0]
          ) && (
            <Button
              onClick={() => {
                this.setState({ isAddEventModal: true });
                this.setState({
                  dateTmp: new Date().toISOString().slice(0, 10),
                });
                this.setState({ timeTmp: "" });
                this.setState({ isDatetime: false });
                this.setState({ isNewPatient: false });
                this.setState({
                  customerList: this.state.customerAllList[0],
                });
              }}
              className="btn-md btn-danger"
            >
              + New Booking
            </Button>
          )}
          <div className="input-group search-area ml-auto d-inline-flex">
            <input
              type="text"
              className="form-control"
              placeholder="Search here"
              onChange={this.onSearchChange}
            />
            <div className="input-group-append">
              <span className="input-group-text c-pointer">
                <i className="flaticon-381-search-2"></i>
              </span>
            </div>
          </div>
          <Dropdown
            className="align-items-end d-inline-flex ml-2"
            onSelect={this.onFilterChange}
          >
            <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
              <i className="flaticon-381-controls-3 "></i> by{" "}
              {this.onGetTitleSort(this.state.sortBy)}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-right">
              <Dropdown.Item href="#" eventKey={"firstname"}>
                Name
              </Dropdown.Item>
              <Dropdown.Item href="#" eventKey={"order"}>
                Sleep Study
              </Dropdown.Item>
              <Dropdown.Item href="#" eventKey={"hn"}>
                HN
              </Dropdown.Item>
              <Dropdown.Item href="#" eventKey={"start"}>
                Activity Date
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="form-head d-flex mb-3 mb-lg-5 justify-content-end align-items-end">
          <Dropdown
            className="align-items-end d-inline-flex ml-2"
            onSelect={this.onTypeChange}
          >
            <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
              <i className="flaticon-381-menu-1"></i>
              {this.state.typeBy == "ทั้งหมด" && "Type"} {this.state.typeBy}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-right">
              <Dropdown.Item href="#" eventKey={"ทั้งหมด"}>
                ทั้งหมด
              </Dropdown.Item>
              {typeList.map((element, index) => {
                return (
                  <Dropdown.Item href="#" eventKey={element}>
                    {element}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown
            className="align-items-end d-inline-flex ml-2"
            onSelect={this.onStatusChange}
          >
            <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
              <i className="flaticon-381-menu-1"></i>
              {this.state.statusBy == "ทั้งหมด" && "Status"}{" "}
              {this.state.statusBy}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-right">
              <Dropdown.Item href="#" eventKey={"ทั้งหมด"}>
                ทั้งหมด
              </Dropdown.Item>
              {statusList.map((element, index) => {
                return (
                  <Dropdown.Item href="#" eventKey={element}>
                    {element}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown
            className="align-items-end d-inline-flex ml-2"
            onSelect={this.onNodeChange}
          >
            <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
              <i className="flaticon-381-menu-1"></i>
              {(this.context.currentUser.roles[0] == "super_admin" ||
                this.context.currentUser.roles[0] == "sleep_physician" ||
                this.context.currentUser.roles[0] === "sleep_technician" ||
                this.context.currentUser.roles[0] == "sale" ||
                this.context.currentUser.roles[0] == "admin") &&
                this.state.nodeBy == "ทั้งหมด" &&
                "Node "}
              {this.state.nodeBy}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-right">
              {(this.context.currentUser.roles[0] == "super_admin" ||
                this.context.currentUser.roles[0] == "sleep_physician" ||
                this.context.currentUser.roles[0] === "sleep_technician" ||
                this.context.currentUser.roles[0] == "sale") && (
                <Dropdown.Item href="#" eventKey={"ทั้งหมด"}>
                  ทั้งหมด
                </Dropdown.Item>
              )}
              {["super_admin", "sale", "staff","admin"].includes(
                this.context.currentUser.roles[0]
              ) &&
                this.state.nodeList.map((element, index) => {
                  return (
                    <Dropdown.Item key={index} href="#" eventKey={element}>
                      {element}
                    </Dropdown.Item>
                  );
                })}
            </Dropdown.Menu>
          </Dropdown>
          {this.context.currentUser.roles[0] == "super_admin" && (
            <Dropdown
              className="align-items-end d-inline-flex ml-2"
              onSelect={this.onTechChange}
            >
              <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
                <i className="flaticon-381-menu-1"></i>
                {"Tech Record "}
                {this.state.techBy}
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-right">
                {this.state.technicianList.map((element, index) => {
                  return (
                    <Dropdown.Item href="#" eventKey={element}>
                      {element}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          )}
          <Button
            onClick={() => {
              this.setState({ sortBy: "hn" });
              this.setState({ typeBy: "ทั้งหมด" });
              this.setState({ statusBy: "ทั้งหมด" });
              if (
                this.context.currentUser.roles[0] == "super_admin" ||
                this.context.currentUser.roles[0] == "sleep_physician" ||
                this.context.currentUser.roles[0] === "sleep_technician" ||
                this.context.currentUser.roles[0] == "sale"
              ) {
                this.setState({ nodeBy: "ทั้งหมด" });
                this.setState({ techBy: "กรุณาเลือก" });
              } else {
                // console.log(this.state.currentAdmin.data().node)
                // this.setState({ nodeBy: this.state.currentAdmin.node });
              }
            }}
            className="btn-md btn-danger align-items-end d-inline-flex ml-2"
          >
            Reset
          </Button>
        </div>

        <BookingTable
          text={this.state.textSearch}
          sortBy={this.state.sortBy}
          typeBy={this.state.typeBy}
          statusBy={this.state.statusBy}
          nodeBy={this.state.nodeBy}
          techBy={this.state.techBy}
          // isAddNewEvent={this.state.isAddNewEvent}
          triggerRerender={this.state.triggerRerender}
          onResetTriggerRerender={this.resetTriggerRerender} // Pass the function to reset triggerRerender as a prop to the child component
        />

        <Modal
          className="fade bd-example-modal-lg"
          show={this.state.isAddEventModal}
          onHide={() => this.setState({ isAddEventModal: false })}
          size="xl"
        >
          <Modal.Header>
            <Modal.Title>Add Event</Modal.Title>
            <Button
              variant=""
              className="close"
              onClick={() => this.setState({ isAddEventModal: false })}
            >
              <span>&times;</span>
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className="card-body">
              <div className="row">
                <div className="col-xl-12">
                  <div className="form-group row">
                    <label
                      className="col-lg-3 col-form-label"
                      htmlFor="val-username"
                    >
                      Order
                    </label>

                    <div className="col-lg-5">
                      <input
                        type="text"
                        className="form-control"
                        id="val-hn"
                        name="val-hn"
                        placeholder="Order"
                        ref={this.orderRef}
                        defaultValue={eventTmp.extendedProps.order}
                        // ref={emailRef}
                        // value=""
                      />
                    </div>
                  </div>
                  <PatientDropDownForm
                    title="Node"
                    id="node"
                    refvalue={this.nodeRef}
                    selected={eventTmp.extendedProps.node}
                    options={this.state.nodeList}
                    onChange={(e) => {
                      this.setState({
                        customerList: this.CustomerList(e.target.value),
                      });
                    }}
                  ></PatientDropDownForm>
                  <PatientDropDownForm
                    title="Customer"
                    id="customer"
                    refvalue={this.customerRef}
                    selected={eventTmp.extendedProps.customer}
                    options={this.state.customerList}
                  ></PatientDropDownForm>

                  <div className="form-group row">
                    <label
                      className="col-lg-3 col-form-label"
                      htmlFor="val-username"
                    >
                      HN (Company Number)
                    </label>

                    <div className="col-lg-7">
                      <input
                        type="text"
                        className="form-control"
                        id="val-hn"
                        name="val-hn"
                        placeholder="HN"
                        ref={this.hnRef}
                        defaultValue={eventTmp.extendedProps.hn}
                        // ref={emailRef}
                        // value=""
                      />
                    </div>
                    <div className="col-lg-2">
                      <Button
                        variant=""
                        type="button"
                        className="btn btn-secondary"
                        onClick={this.onSearchPatient}
                        size="sm"
                      >
                        Search
                      </Button>
                    </div>
                  </div>
                  {this.state.isNewPatient ? (
                    <div className="form-group row">
                      <label
                        className="col-lg-3 col-form-label"
                        htmlFor="val-username"
                      ></label>

                      <div className="col-lg-4">
                        <label className="text-danger">* New Patient</label>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {eventTmp.extendedProps.hn == "" ||
                  eventTmp.extendedProps.hn == null ||
                  eventTmp.extendedProps.hn == undefined ? (
                    <>
                      {" "}
                      <div className="form-group row">
                        <label
                          className="col-lg-3 col-form-label"
                          htmlFor="val-username"
                        >
                          Full Name
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="text"
                            className="form-control"
                            id="val-hn"
                            name="val-hn"
                            placeholder="Firstname"
                            ref={this.firstnameRef}
                            defaultValue={eventTmp.extendedProps.firstname}
                          />
                        </div>
                        <div className="col-lg-4">
                          <input
                            type="text"
                            className="form-control"
                            id="val-hn"
                            name="val-hn"
                            placeholder="Lastname"
                            ref={this.lastnameRef}
                            defaultValue={eventTmp.extendedProps.lastname}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          className="col-lg-3 col-form-label"
                          htmlFor="val-username"
                        >
                          Gender
                        </label>

                        <div className="col-lg-8">
                          <input
                            type="text"
                            className="form-control"
                            id="val-hn"
                            name="val-hn"
                            placeholder="Gender"
                            ref={this.genderRef}
                            defaultValue={eventTmp.extendedProps.gender}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          className="col-lg-3 col-form-label"
                          htmlFor="val-username"
                        >
                          Phone
                        </label>
                        <div className="col-lg-5">
                          <input
                            type="text"
                            className="form-control"
                            id="val-phone"
                            name="val-phone"
                            placeholder="Phone"
                            ref={this.phoneRef}
                            defaultValue={eventTmp.extendedProps.phone}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          className="col-lg-3 col-form-label"
                          htmlFor="val-username"
                        >
                          Referring Hospital Number
                        </label>
                        <div className="col-lg-5">
                          <input
                            type="text"
                            className="form-control"
                            id="val-phone"
                            name="val-phone"
                            placeholder=""
                            ref={this.hospitalNumberRef}
                            defaultValue={eventTmp.extendedProps.hospitalNumber}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {" "}
                      <this.patientTextInfo
                        title="Full Name"
                        value={
                          eventTmp.extendedProps.firstname +
                          " " +
                          eventTmp.extendedProps.lastname
                        }
                        bold={true}
                      />
                      <this.patientTextInfo
                        title="Gender"
                        value={eventTmp.extendedProps.gender}
                        bold={true}
                      />
                      <this.patientTextInfo
                        title="Phone"
                        value={eventTmp.extendedProps.phone}
                        bold={true}
                      />
                      <this.PatientTextInfo
                        title="Referring Hospital Number"
                        value={eventTmp.extendedProps.hospitalNumber}
                        bold={true}
                      ></this.PatientTextInfo>
                    </>
                  )}

                  <PatientDropDownForm
                    title="Health Insurance"
                    id="healthInsurance"
                    refvalue={this.healthInsuranceRef}
                    selected={eventTmp.extendedProps.healthInsurance}
                    options={paymentPrivilages}
                  ></PatientDropDownForm>
                  <PatientDropDownForm
                    title="Type"
                    id="type"
                    refvalue={this.typeRef}
                    selected={eventTmp.extendedProps.type}
                    options={typeList}
                  ></PatientDropDownForm>
                  <PatientDropDownForm
                    title="Status"
                    id="status"
                    refvalue={this.statusRef}
                    selected={eventTmp.extendedProps.status}
                    options={statusList}
                    onChange={this.handleStatusChange}
                  ></PatientDropDownForm>
                  <PatientDropDownForm
                    title="Recording Tech"
                    id="technician"
                    refvalue={this.techRecordRef}
                    selected={eventTmp.extendedProps.techrecord}
                    options={this.state.techrecordList}
                  ></PatientDropDownForm>
                  <PatientDropDownForm
                    title="Scoring Tech"
                    id="technician"
                    refvalue={this.techRef}
                    selected={eventTmp.extendedProps.technician}
                    options={this.state.technicianList}
                  ></PatientDropDownForm>
                  <PatientDropDownForm
                    title="Doctor"
                    id="doctor"
                    refvalue={this.docRef}
                    selected={eventTmp.extendedProps.doctor}
                    options={this.state.doctorList}
                  ></PatientDropDownForm>
                  <div className="form-group row">
                    <label
                      className="col-lg-3 col-form-label"
                      htmlFor="val-username"
                    >
                      Referring Doctor
                    </label>
                    <div className="col-lg-5">
                      <input
                        type="text"
                        className="form-control"
                        id="val-phone"
                        name="val-phone"
                        placeholder="Referring Doctor"
                        ref={this.referDocRef}
                        defaultValue={eventTmp.extendedProps.referDoctor}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="col-lg-3 col-form-label"
                      htmlFor="val-username"
                    >
                      AHI
                    </label>
                    <div className="col-lg-5">
                      <input
                        type="text"
                        className="form-control"
                        id="val-phone"
                        name="val-phone"
                        placeholder=""
                        ref={this.ahiRef}
                        defaultValue={eventTmp.extendedProps.ahi}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      className="col-lg-3 col-form-label"
                      htmlFor="val-username"
                    >
                      Date
                    </label>
                    <div className="col-lg-8 mt-2">
                      {/* <b>{eventTmp.start}</b> */}
                      <DatePicker
                        className="form-control"
                        dateFormat="yyyy-MM-dd"
                        selected={new Date(this.state.dateTmp)}
                        // defaultValue={new Date(currentBooking.start)}
                        onChange={(event, props) =>
                          this.handleDate(event, props)
                        }
                        // value={new Date(currentBooking.start)}
                      />
                    </div>
                  </div>
                  {this.state.isDatetime && (
                    <>
                      {" "}
                      <div className="form-group row">
                        <label
                          className="col-lg-3 col-form-label"
                          htmlFor="val-username"
                        >
                          Time
                        </label>
                        <div className="col-lg-2 mt-2">
                          <input
                            type={"time"}
                            className="form-control"
                            defaultValue={this.state.timeTmp}
                            onChange={(event, props) =>
                              this.handleTime(event, props)
                            }
                          ></input>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="form-group row">
                    <label
                      className="col-lg-3 col-form-label"
                      htmlFor="val-username"
                    >
                      Note
                    </label>
                    <div className="col-lg-5">
                      <textarea
                        type="area"
                        className="form-control"
                        id="val-phone"
                        name="val-phone"
                        rows="4"
                        placeholder="Note"
                        ref={this.noteRef}
                        defaultValue={eventTmp.extendedProps.note}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-lg-5 col-form-label mt-2">
                      <b htmlFor="val-username">Add Item</b>
                      <Button
                        variant="secondary"
                        size="xs"
                        className="ml-2"
                        onClick={this.handleAddItem}
                      >
                        +
                      </Button>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th className="center">No.</th>
                          <th className="center width300">Item Name</th>
                          <th className="center width100">Item Code</th>
                          <th className="center width150">Unit Cost (THB)</th>
                          <th className="center width100">Qty</th>
                          <th className="center">Discount (THB)</th>
                          <th className="center">Total (THB)</th>
                          <th className="center"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.items.map((element, index) => {
                          return (
                            <tr>
                              <td className="">{element.id}</td>
                              <td>
                                <select
                                  className="form-control"
                                  id={"item" + String(index)}
                                  name={"item" + String(index)}
                                  onChange={(evt) => {
                                    var item = evt.target.value;
                                    var tmpItems = this.state.items;

                                    var indexOfItemList =
                                      this.state.itemList.findIndex(
                                        (obj) => obj.item === item
                                      );
                                    console.log(
                                      "selected item: ",
                                      this.state.itemList[indexOfItemList]
                                    );
                                    console.log(
                                      "unitcost: ",
                                      this.state.itemList[indexOfItemList]
                                        .unitcost
                                    );
                                    tmpItems[index].itemcode =
                                      this.state.itemList[
                                        indexOfItemList
                                      ].itemcode;
                                    tmpItems[index].item =
                                      this.state.itemList[indexOfItemList].item;
                                    tmpItems[index].unitcost =
                                      this.state.itemList[
                                        indexOfItemList
                                      ].unitcost;
                                    tmpItems[index].qty = 1;
                                    tmpItems[index].discount = 0;
                                    tmpItems[index].total =
                                      this.state.itemList[
                                        indexOfItemList
                                      ].unitcost;
                                    this.setState({ items: tmpItems }, () => {
                                      const newTotalValue =
                                        this.calculateTotalValue(tmpItems);
                                      this.setState({
                                        totalValue: newTotalValue,
                                      });

                                      // this.getTotalValue();
                                    });
                                  }}
                                >
                                  {this.state.itemList.map((value) => (
                                    <option
                                      value={value.item}
                                      selected={
                                        this.state.items[index].item ==
                                        value.item
                                          ? "selected"
                                          : ""
                                      }
                                    >
                                      {value.item}
                                    </option>
                                  ))}
                                </select>
                              </td>
                              <td className="left strong">
                                {" "}
                                {/* <input
                                    type="text"
                                    className="form-control"
                                    id={"val-item" + String(index)}
                                    name={"val-item" + String(index)}
                                    placeholder="Item"
                                    onChange={(value) =>
                                      this.onUpdateItemValue(
                                        "item",
                                        index,
                                        value
                                      )
                                    }
                                    defaultValue={this.state.items[index].item}
                                  /> */}
                                <b>{this.state.items[index].itemcode}</b>
                              </td>
                              <td className="left">
                                {" "}
                                <input
                                  type="number"
                                  className="form-control"
                                  id={"val-unitcost" + String(index)}
                                  name={"val-unitcost" + String(index)}
                                  placeholder="Unit Cost"
                                  onChange={(value) =>
                                    this.onUpdateItemValue(
                                      "unitcost",
                                      index,
                                      value
                                    )
                                  }
                                  // defaultValue={
                                  //   this.state.items[index].unitcost
                                  // }
                                  value={this.state.items[index].unitcost}
                                />
                              </td>
                              <td className="right">
                                {" "}
                                <input
                                  type="number"
                                  className="form-control"
                                  id={"val-qty" + String(index)}
                                  name={"val-qty" + String(index)}
                                  placeholder="QTY"
                                  onChange={(value) =>
                                    this.onUpdateItemValue("qty", index, value)
                                  }
                                  // defaultValue={this.state.items[index].qty}
                                  value={this.state.items[index].qty}
                                />
                              </td>
                              <td className="center">
                                {" "}
                                <input
                                  type="number"
                                  className="form-control"
                                  id={"val-discount" + String(index)}
                                  name={"val-discount" + String(index)}
                                  placeholder="Discount"
                                  onChange={(value) =>
                                    this.onUpdateItemValue(
                                      "discount",
                                      index,
                                      value
                                    )
                                  }
                                  value={this.state.items[index].discount}
                                />
                              </td>
                              <td className="right">
                                {" "}
                                <input
                                  type="number"
                                  className="form-control"
                                  id={"val-total" + String(index)}
                                  name={"val-total" + String(index)}
                                  placeholder="Total"
                                  onChange={(value) =>
                                    this.onUpdateItemValue(
                                      "total",
                                      index,
                                      value
                                    )
                                  }
                                  value={this.state.items[index].total}
                                />
                              </td>
                              <td>
                                <Button
                                  className="btn btn-danger btn-xs sharp"
                                  onClick={() => {
                                    var tmpItems = this.state.items;
                                    console.log("items: ", tmpItems);
                                    tmpItems = tmpItems.filter(
                                      (item) => item.id != tmpItems[index].id
                                    );
                                    for (let i = 0; i < tmpItems.length; i++) {
                                      tmpItems[i].id = i + 1;
                                    }
                                    console.log("items: ", tmpItems);
                                    this.setState({ items: tmpItems }, () => {
                                      const newTotalValue =
                                        this.calculateTotalValue(tmpItems);
                                      this.setState({
                                        totalValue: newTotalValue,
                                      });
                                    });
                                  }}
                                >
                                  <i className="fa fa-trash"></i>
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-sm-5 ml-auto">
                      <table className="table table-clear">
                        <tbody>
                          <tr>
                            <td className="left">
                              <strong>Total</strong>
                            </td>
                            <td className="right">
                              <strong>{this.state.totalValue + " THB"}</strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="light"
              onClick={() => this.setState({ isAddEventModal: false })}
            >
              Cancel
            </Button>
            <Button
              variant=""
              type="button"
              className="btn btn-primary"
              onClick={() => {
                var allEvents = this.state.bookingList.filter((obj) => {
                  return (
                    obj.start === this.state.dateTmp &&
                    obj.extendedProps.hn !== "" &&
                    obj.extendedProps.hn != null
                  );
                });
                let listHNEvent = allEvents.filter((element) => {
                  return element.extendedProps.hn === this.hnRef.current.value;
                });

                if (listHNEvent.length >= 1) {
                  swal({
                    title: `HN${this.hnRef.current.value} is already booking on this day !`,
                    text: `Please recheck booking on ${this.state.dateTmp}`,
                    icon: "warning",
                    buttons: ["Cancel", "Confirm"],
                    dangerMode: true,
                  }).then((willBooking) => {
                    if (willBooking) {
                      this.onAddEvent();
                    }
                  });
                } else {
                  this.onAddEvent();
                }
              }}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default BookingList;

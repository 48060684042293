import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
} from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";

import {
  Button,
  Card,
  Col,
  Row,
  Dropdown,
  Modal,
  Table,
  Badge,
} from "react-bootstrap";
import filepicture from "../../../images/file_bl.png";
import swal from "sweetalert";

import { Formik } from "formik";
import * as Yup from "yup";
// import { useAuth, currentUser } from "../../../context/AuthContext";
import { db, storage } from "../../../firebase";
import {
  setDoc,
  getDoc,
  getDocs,
  collection,
  updateDoc,
  addDoc,
  doc,
  deleteDoc,
  query,
  orderBy,
} from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  list,
} from "firebase/storage";
import {
  PatientTextInputForm,
  PatientTextInputNotRequiredForm,
  PatientDropDownForm,
} from "./PatientUtils";
import { notifyTopRight, notifyWarning, notifyError } from "../toast/SetToast";
import Toast from "../toast/SetToast";
import { sleepHabitData, sleepHabitFormTitle } from "./formData/sleepHabitData";
import profileData from "./formData/profileData";
import demographicData from "./formData/demographicDataRevC"; //personal information rev.C
import preSleepInfoData from "./formData/preSleepInfoData";
import nightTimeHabitData from "./formData/nightTimeHabitData";
import { berlinQuestionnaireData } from "./formData/berlinQuestionnaireData";
import {
  daytimeBehavior,
  daytimeBehaviorData,
} from "./formData/daytimeBehaviorData";
import { STOPBANGQuestionnaireData } from "./formData/STOPBANGQuestionnaireData";
import postQuestionnairesData from "./formData/postQuestionnairesData";
import { shortSAQLIData } from "./formData/shortSAQLIData";
import { DropdownBox } from "./formData/inputComponents";
// import currentUser from

const PatientForm = (profile) => {
  const history = useHistory();
  const [patientInfo, setPatientInfo] = useState(profileData);
  const [assign, setAssign] = useState([]);
  const [forms, setForms] = useState([]);
  const [copySuccess, setCopySuccess] = useState("");
  // const [pathForm, setPathForm] = useState([]);
  const chackbox = document.querySelectorAll(".sorting_1 input");
  const motherChackBox = document.querySelector(".sorting_asc input");

  const chackboxFun = (type) => {
    var checkboxTmp = [];
    console.log(chackbox.length);
    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
          checkboxTmp.push(true);
        } else {
          element.checked = false;
          checkboxTmp.push(false);
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          checkboxTmp.push(false);
          //   break;
        } else {
          // motherChackBox.checked = true;
          checkboxTmp.push(true);
        }
      }
    }

    if (checkboxTmp.every((val, i) => val === true)) {
      motherChackBox.checked = true;
    }
    setAssign(checkboxTmp);
    var formsTmp = forms;
    formsTmp.forEach((element, index) => {
      formsTmp[index].assign = checkboxTmp[index];
      if (checkboxTmp[index] == false) {
        formsTmp[index].submit = false;
      }
    });
    setForms(formsTmp);
    // update form to firebase
    updateForm();
  };

  const writeText = (form, uuid, patient) => {
    console.log("form name", form);
    let writeUrl = "";
    if (form === "ข้อมูลพื้นฐาน") {
      writeUrl = `https://docs.google.com/forms/d/e/1FAIpQLScySG_4518UmzD3iayPZfYtZNYvasprjAovOJqJrec5tq7GVA/viewform?usp=pp_url&entry.838754873=${encodeURIComponent(
        uuid
      )}&entry.609079995=${encodeURIComponent(
        patient.hn
      )}&entry.817972638=${encodeURIComponent(
        patient.firstnameTh
      )}&entry.2138131198=${encodeURIComponent(patient.lastnameTh)}`;
    } else if (form === "แบบซักประวัติก่อนตรวจการนอน") {
      writeUrl = `https://docs.google.com/forms/d/e/1FAIpQLSctvkv5dh22HFMk-F1H61_iQvxziWenAK0M8IaapqKnB2DRzA/viewform?usp=pp_url&entry.838754873=${encodeURIComponent(
        uuid
      )}&entry.609079995=${encodeURIComponent(
        patient.hn
      )}&entry.817972638=${encodeURIComponent(
        patient.firstnameTh
      )}&entry.2138131198=${encodeURIComponent(patient.lastnameTh)}`;
    } else {
      writeUrl = `https://docs.google.com/forms/d/e/1FAIpQLSdfCWwuW9YqvyYili4YRR-MorC4yW-acSlComd72JACO6iwlw/viewform?usp=pp_url&entry.838754873=${encodeURIComponent(
        uuid
      )}&entry.609079995=${encodeURIComponent(
        patient.hn
      )}&entry.817972638=${encodeURIComponent(
        patient.firstnameTh
      )}&entry.2138131198=${encodeURIComponent(patient.lastnameTh)}`;
    }
    return writeUrl;
  };

  const updateForm = () => {
    forms.forEach(async (element, index) => {
      let docColRef = doc(
        db,
        "patients",
        patientInfo.hn,
        "form",
        element.formname
      );
      let docSnap = await getDoc(docColRef);
      if (docSnap.exists()) {
        await updateDoc(docColRef, element);
        console.log("update doc");
      } else {
        await setDoc(docColRef, element);
        console.log("set doc");
      }
    });
  };
  const listForm = [
    {
      id: 1,
      formId: "F-01",
      formname: "ข้อมูลพื้นฐาน",
      assign: true,
      submit: false,
      info: demographicData,
      uuid: "",
      createAt: "",
      updateAt: "",
      createBy: "",
    },
    {
      id: 2,
      formId: "F-02",
      formname: "แบบสอบถามหลังการตรวจการนอนหลับ",
      assign: true,
      submit: false,
      info: postQuestionnairesData,
      uuid: "",
      createAt: "",
      updateAt: "",
      createBy: "",
    },
    {
      id: 3,
      formId: "F-03",
      formname: "แบบซักประวัติก่อนตรวจการนอน",
      assign: true,
      submit: false,
      info: preSleepInfoData,
      uuid: "",
      createAt: "",
      updateAt: "",
      createBy: "",
    },
    // {
    //   id: 4,
    //   formId: "F-04",
    //   formname: "ข้อมูลพฤติกรรมตอนกลางคืน",
    //   assign: true,
    //   submit: false,
    //   info: nightTimeHabitData,
    //   uuid: "",
    //   createAt: "",
    //   updateAt: "",
    //   createBy: "",
    // },
    // {
    //   id: 5,
    //   formId: "F-05",
    //   formname: "ข้อมูลเกี่ยวกับพฤติกรรมนอนกลางวัน",
    //   assign: true,
    //   submit: false,
    //   info: daytimeBehaviorData,
    //   uuid: "",
    //   createAt: "",
    //   updateAt: "",
    //   createBy: "",
    // },
    // {
    //   id: 6,
    //   formId: "F-06",
    //   formname: "แบบทดสอบเบอร์ลินฉบับภาษาไทย",
    //   assign: true,
    //   submit: false,
    //   info: berlinQuestionnaireData,
    //   uuid: "",
    //   createAt: "",
    //   updateAt: "",
    //   createBy: "",
    // },
    // {
    //   id: 7,
    //   formId: "F-07",
    //   formname: "STOP BANG Questionnaire",
    //   assign: true,
    //   submit: false,
    //   info: STOPBANGQuestionnaireData,
    //   uuid: "",
    //   createAt: "",
    //   updateAt: "",
    //   createBy: "",
    // },
    // {
    //   id: 8,
    //   formId: "F-08",
    //   formname: "ดัชนีคุณภาพชีวิตของผู้ป่วยโรคนอนกรน",
    //   assign: true,
    //   submit: false,
    //   info: shortSAQLIData,
    //   uuid: "",
    //   createAt: "",
    //   updateAt: "",
    //   createBy: "",
    // },
    // {
    //   id: 9,
    //   formId: "F-09",
    //   formname: "แบบสอบถามหลังการตรวจการนอนหลับ",
    //   assign: true,
    //   submit: false,
    //   info: postQuestionnairesData,
    //   uuid: "",
    //   createAt: "",
    //   updateAt: "",
    //   createBy: "",
    // },
  ];

  const optionsForm = [
    "ข้อมูลพื้นฐาน",
    "แบบสอบถามหลังการตรวจการนอนหลับ",
    "แบบซักประวัติก่อนตรวจการนอน",
    // "ข้อมูลเกี่ยวกับตารางการนอนหลับ",
    // "ข้อมูลพฤติกรรมตอนกลางคืน",
    // "ข้อมูลเกี่ยวกับพฤติกรรมนอนกลางวัน",
    // "แบบทดสอบเบอร์ลินฉบับภาษาไทย",
    // "STOP BANG Questionnaire",
    // "ดัชนีคุณภาพชีวิตของผู้ป่วยโรคนอนกรน",
  ];
  const pathForm = [
    "/demographic/",
    "/postQuestionnaires/",
    "/preSleepInformation/",
    // "/sleephabit/",
    // "/nightTimeHabit/",
    // "/daytimeBehavior/",
    // "/BerlinQuestionnaire/",
    // "/stopbangQuestionnaire/",
    // "/shortSAQLI/",
  ];

  const formRef = useRef();
  const assignForm = async () => {
    // console.log(formRef.current.value);
    const indexForm = listForm.findIndex(
      (obj) => obj.formname === formRef.current.value
    );
    console.log(indexForm);
    console.log(listForm[indexForm]);
    console.log(patientInfo);
    var docColRef = doc(collection(db, "patients", patientInfo.hn, "form"));
    var formtmp = listForm[indexForm];
    const docSnap = setDoc(docColRef, formtmp);
    const updateRef = doc(db, "patients", patientInfo.hn, "form", docColRef.id);
    formtmp["uuid"] = docColRef.id;
    var dateNow = new Date();
    dateNow.setHours(dateNow.getHours() + 7);
    formtmp["createAt"] = dateNow.toISOString();
    formtmp["updateAt"] = dateNow.toISOString();
    // formtmp['createBy'] = currentUser

    await updateDoc(updateRef, formtmp);
    const ref = collection(db, "patients", patientInfo.hn, "form");
    const docSnapGet = await getDocs(query(ref, orderBy("createAt")));
    var formsTmp = [];
    var assignTmp = [];
    docSnapGet.forEach((element) => {
      formsTmp.push(element.data());
      // assignTmp.push(element.data().assign);
    });
    console.log("formsTmp", formsTmp);
    formsTmp.reverse();
    setForms(formsTmp);
  };
  const deleteHandler = async (formId) => {
    try {
      const refDelete = doc(db, "patients", patientInfo.hn, "form", formId);
      await deleteDoc(refDelete);

      const ref = collection(db, "patients", patientInfo.hn, "form");
      const docSnapGet = await getDocs(query(ref, orderBy("createAt")));
      var formsTmp = [];
      var assignTmp = [];
      docSnapGet.forEach((element) => {
        formsTmp.push(element.data());
        // assignTmp.push(element.data().assign);
      });
      console.log("formsTmp", formsTmp);
      formsTmp.reverse();
      setForms(formsTmp);
    } catch (err) {
      notifyError(err);
    }
  };
  useEffect(async () => {
    if (patientInfo.hn != "") {
      // console.log("patient: ", profile.profile);
      const ref = collection(db, "patients", patientInfo.hn, "form");
      const docSnap = await getDocs(query(ref, orderBy("createAt")));
      var formsTmp = [];
      var assignTmp = [];
      docSnap.forEach((element) => {
        formsTmp.push(element.data());
        // assignTmp.push(element.data().assign);
      });
      formsTmp.reverse();
      // console.log("formsTmp", formsTmp);
      setForms(formsTmp);
      return;
    }
    const profileObj = profile.profile;
    setPatientInfo(profileObj);
  }, [patientInfo]);
  // console.log("forms: ", forms);
  return (
    <Fragment>
      <Toast />
      <Col lg={12}>
        <div className="mb-3">
          <b>Assign Form</b>
        </div>

        <Row>
          <div className="col-lg-4 ">
            <select
              className="form-control"
              // id={id}
              // name=""
              ref={formRef}
              // defaultValue={defaultValue}
              // disabled={disabled}
              // onChange={onChange}
            >
              {/* <option value="please-select">{placeholder}</option> */}
              {optionsForm.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
          </div>{" "}
          <div className="col-lg-1">
            <Button onClick={assignForm}>Assign</Button>
          </div>
        </Row>
        <Table className="table-responsive-xl mt-4">
          <thead>
            <tr>
              {/* <th>
                  <strong>FORM NAME</strong>
                </th> */}
              <th>
                <strong>Status</strong>
              </th>
              <th>
                <strong>FORM</strong>
              </th>
              <th>
                <strong>Create At</strong>
              </th>
              <th>
                <strong>Update At</strong>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {forms.map((element, index) => {
              return (
                <tr>
                  {/* <td>
                      <span className="w-space-no">{element.formname}</span>
                    </td> */}
                  {element.assign ? (
                    <>
                      {element.submit ? (
                        <>
                          <td>
                            <Badge variant="success light">Successful</Badge>
                          </td>
                          <td>
                            <Link
                              to={
                                pathForm[
                                  listForm.findIndex(
                                    (obj) => obj.formname === element.formname
                                  )
                                ] +
                                patientInfo.hn +
                                "/" +
                                element.uuid
                              }
                            >
                              <img
                                src={filepicture}
                                alt="profile"
                                width={"30"}
                                height={"30"}
                                className="mr-2"

                                // className="img-fluid  mb-4 w-5"
                              />
                              {element.formname}
                            </Link>
                          </td>
                          <td>
                            <span className="w-space-no">
                              {element.createAt.replace("T", " ").slice(0, 18)}
                            </span>
                          </td>{" "}
                          <td>
                            <span className="w-space-no">
                              {element.updateAt.replace("T", " ").slice(0, 18)}
                            </span>
                          </td>
                        </>
                      ) : (
                        <>
                          <td>
                            <Badge variant="warning light">Waiting</Badge>
                          </td>
                          <td>
                            <Link
                              to={
                                pathForm[
                                  listForm.findIndex(
                                    (obj) => obj.formname === element.formname
                                  )
                                ] +
                                patientInfo.hn +
                                "/" +
                                element.uuid
                              }
                            >
                              <img
                                src={filepicture}
                                alt="profile"
                                width={"30"}
                                height={"30"}
                                className="mr-2"

                                // className="img-fluid  mb-4 w-5"
                              />
                              {element.formname}
                            </Link>
                          </td>
                          <td>
                            <span className="w-space-no">
                              {element.createAt.replace("T", " ").slice(0, 19)}
                            </span>
                          </td>{" "}
                          <td>
                            <span className="w-space-no">
                              {element.updateAt.replace("T", " ").slice(0, 19)}
                            </span>
                          </td>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <td>
                        {" "}
                        <Badge variant="danger light">Not Assign</Badge>
                      </td>
                      <td>-</td>
                      <td>
                        <span className="w-space-no">
                          {element.createAt.replace("T", " ").slice(0, 19)}
                        </span>
                      </td>{" "}
                      <td>
                        <span className="w-space-no">
                          {element.updateAt.replace("T", " ").slice(0, 19)}
                        </span>
                      </td>
                    </>
                  )}
                  <td>
                    <Dropdown className="dropdown custom-dropdown mb-0">
                      <Dropdown.Toggle
                        className="btn sharp btn-primary tp-btn i-false"
                        data-toggle="dropdown"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="18px"
                          height="18px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect x="0" y="0" width="24" height="24" />
                            <circle fill="#000000" cx="12" cy="5" r="2" />
                            <circle fill="#000000" cx="12" cy="12" r="2" />
                            <circle fill="#000000" cx="12" cy="19" r="2" />
                          </g>
                        </svg>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                        <Dropdown.Item
                          className="dropdown-item"
                          onClick={async () => {
                            await navigator.clipboard.writeText(
                              writeText(
                                element.formname,
                                element.uuid,
                                patientInfo
                              )
                            );
                            setCopySuccess("Copied!");
                          }}
                        >
                          Copy Link
                        </Dropdown.Item>

                        <Dropdown.Item
                          className="dropdown-item"
                          onClick={() =>
                            history.push(
                              pathForm[
                                listForm.findIndex(
                                  (obj) => obj.formname === element.formname
                                )
                              ] +
                                patientInfo.hn +
                                "/" +
                                element.uuid
                            )
                          }
                        >
                          Detail
                        </Dropdown.Item>

                        <Dropdown.Item
                          className="dropdown-item text-danger"
                          onClick={() =>
                            swal({
                              title: "Are you sure?",
                              text: "Once deleted, you will not be able to recover this form!",
                              icon: "warning",
                              buttons: true,
                              dangerMode: true,
                            }).then((willDelete) => {
                              if (willDelete) {
                                deleteHandler(element.uuid);

                                swal(
                                  `Form ${element.formname} has been deleted!`,
                                  {
                                    icon: "success",
                                  }
                                );
                              } else {
                                swal("Canceled");
                              }
                            })
                          }
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Col>
    </Fragment>
  );
};

export default PatientForm;

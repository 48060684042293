import React, { Fragment, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  Modal,
  Button,
  Dropdown,
  Table,
  Pagination,
} from "react-bootstrap";

import { db, functions, storage } from "../../../firebase";
import { ref, uploadBytes, deleteObject, listAll } from "firebase/storage";
import Toast from "../toast/SetToast";
import { httpsCallable } from "firebase/functions";
import {
  query,
  endAt,
  getDocs,
  getDoc,
  collection,
  orderBy,
  limit,
  startAfter,
  endBefore,
  limitToLast,
  startAt,
  getCountFromServer,
  where,
  doc,
  deleteDoc,
  updateDoc,
  setDoc,
  collectionGroup,
} from "firebase/firestore";
import { notifyError, notifyTopRight } from "../toast/SetToast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import {
  setEvent,
  eventTmp,
  paymentPrivilages,
  orderList,
  colorList,
  statusList,
  typeList,
  PatientLinkInfo,
  PatientDropDownForm,
  PatientTextInfo,
} from "./BookingUtils";
import { useAuth } from "../../../context/AuthContext";

const BookingTable = ({
  text,
  sortBy,
  typeBy,
  statusBy,
  nodeBy,
  techBy,
  triggerRerender,
  onResetTriggerRerender,
}) => {
  const [latestDoc, setLatestDoc] = useState(null);
  const [firstDoc, setFirstDoc] = useState(null);
  const [prevFirstDoc, setPrevFirstDoc] = useState(null);
  const [prevLastestDoc, setPrevLastestDoc] = useState(null);
  const [loading, setLoading] = useState(true);
  const [goNext, setGoNext] = useState(false);
  const [goPrev, setGoPrev] = useState(true);
  const [page, setPage] = useState(1);
  const [bookingsCount, setBookingsCount] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [docCount, setdocCount] = useState(0);

  const getPatienBySearch = httpsCallable(functions, "getPatienBySearch");
  const createPatient = httpsCallable(functions, "createPatient");
  const getSignedUrl = httpsCallable(functions, "getSignedUrl");

  const { currentUser } = useAuth();
  const itemsPerPage = 33;

  // console.log("text:", text);
  // console.log("sortBy:", sortBy);
  // console.log("typeBy:", typeBy);

  const refDeleteDoc = async (bookingInfo) => {
    const nodeIndex = nodeList.indexOf(bookingInfo.extendedProps.node);
    const nodeId = nodeIdDocs[nodeIndex];
    const refDelete = doc(
      db,
      "nodes",
      nodeId,
      "bookings",
      bookingInfo.extendedProps.uuid
    );
    await deleteDoc(refDelete)
      .then(async (res) => {
        // notifyTopRight("Delete Success!", res);
        swal("Your booking event has been deleted!", {
          icon: "success",
        });

        // refresh useEffect
        setBookingsCount(bookingsCount - 1);
        console.log("bookingsCount delete:", bookingsCount);
      })
      .catch((err) => {
        notifyError("Detele Error ", err);
      });
  };

  // // SET DEFAULT
  let bookingsCollectionGroupRef = collectionGroup(db, "bookings");

  // let queryHandler = query(
  //   collection(db, "nodes"),
  //   orderBy(sortBy),
  //   limit(itemsPerPage)
  // );
  let queryHandler = query(
    bookingsCollectionGroupRef,
    orderBy(sortBy),
    // where(attributeName, '==', attributeValue),
    limit(itemsPerPage)
  );

  let count;

  // //  NEXT PAGE
  const nextPage = async () => {
    setLoading(true);
    let bookingsCollectionGroupRef = collectionGroup(db, "bookings");
    var statusPage = "Next";
    // Pass your parameters here, including dates if searchTime is true
    let queryHandler = buildQuery(
      bookingsCollectionGroupRef,
      sortBy,
      statusBy,
      typeBy,
      nodeBy,
      techBy,
      currentUser,
      text,
      itemsPerPage,
      statusPage
    );
    // const tempBooking = [];
    // const docSnap = await getDocs(queryHandler);
    // // count = docSnap.size;
    // if (docSnap.empty) {
    //   console.log("No documents found");
    // } else {
    //   docSnap.forEach((booking) => {
    //     const bookingData = booking.data();
    //     const dateStart = new Date(bookingData.start.toDate());
    //     dateStart.setHours(dateStart.getHours() + 7);
    //     bookingData.start = dateStart.toISOString().slice(0, 10);

    //     tempBooking.push({ ...bookingData });
    //   });
    // }
    const tempBooking = [];
    const docSnap = await getDocs(queryHandler);
    if (docSnap.empty) {
      console.log("No documents found");
    } else {
      const promises = docSnap.docs.map(async (booking) => {
        const bookingData = booking.data();
        const dateStart = new Date(bookingData.start.toDate());
        dateStart.setHours(dateStart.getHours() + 7);
        bookingData.start = dateStart.toISOString().slice(0, 10);

        if (
          bookingData.extendedProps.hn == "" ||
          !bookingData.extendedProps.hn
        ) {
          bookingData.reports = [];
        } else {
          // add booking reports
          const patientReports = await getDocs(
            collection(db, "patients", bookingData.extendedProps.hn, "report")
          );
          if (patientReports.empty) {
            bookingData.reports = [];
          } else {
            const reports = [];
            patientReports.forEach((report) => {
              var reportData = report.data();
              var id = report.id;
              reportData = { ...reportData, id };
              reports.push(reportData);
              // console.log("report: ",report.data())
            });
            bookingData.reports = reports;
          }
        }
        tempBooking.push({ ...bookingData });
      });

      // Wait for all promises to resolve
      await Promise.all(promises);
    }

    if (page + 1 >= totalPages) {
      setGoNext(false);
    }
    setLoading(false);
    setPage(page + 1);
    setBookingList(tempBooking);
    setLatestDoc(docSnap.docs[docSnap.docs.length - 1]);
    setFirstDoc(docSnap.docs[0]);
    setGoPrev(true);
  };

  // //  PREV PAGE
  const previousPage = async () => {
    setLoading(true);
    let bookingsCollectionGroupRef = collectionGroup(db, "bookings");
    var statusPage = "Prev";
    // Pass your parameters here, including dates if searchTime is true
    let queryHandler = buildQuery(
      bookingsCollectionGroupRef,
      sortBy,
      statusBy,
      typeBy,
      nodeBy,
      techBy,
      currentUser,
      text,
      itemsPerPage,
      statusPage
    );

    // const tempBooking = [];
    // const docSnap = await getDocs(queryHandler);
    // // count = docSnap.size;
    // if (docSnap.empty) {
    //   console.log("No documents found");
    // } else {
    //   docSnap.forEach((booking) => {
    //     const bookingData = booking.data();
    //     const dateStart = new Date(bookingData.start.toDate());
    //     dateStart.setHours(dateStart.getHours() + 7);
    //     bookingData.start = dateStart.toISOString().slice(0, 10);
    //     tempBooking.push({ ...bookingData });
    //   });
    // }

    const tempBooking = [];
    const docSnap = await getDocs(queryHandler);
    if (docSnap.empty) {
      console.log("No documents found");
    } else {
      const promises = docSnap.docs.map(async (booking) => {
        const bookingData = booking.data();
        const dateStart = new Date(bookingData.start.toDate());
        dateStart.setHours(dateStart.getHours() + 7);
        bookingData.start = dateStart.toISOString().slice(0, 10);

        if (
          bookingData.extendedProps.hn == "" ||
          !bookingData.extendedProps.hn
        ) {
          bookingData.reports = [];
        } else {
          // add booking reports
          const patientReports = await getDocs(
            collection(db, "patients", bookingData.extendedProps.hn, "report")
          );
          if (patientReports.empty) {
            bookingData.reports = [];
          } else {
            const reports = [];
            patientReports.forEach((report) => {
              var reportData = report.data();
              var id = report.id;
              reportData = { ...reportData, id };
              reports.push(reportData);
              // console.log("report: ",report.data())
            });
            bookingData.reports = reports;
          }
        }
        tempBooking.push({ ...bookingData });
      });

      // Wait for all promises to resolve
      await Promise.all(promises);
    }

    setLoading(false);
    setPage(page - 1);
    if (page <= 2) {
      setGoPrev(false);
    }
    setBookingList(tempBooking);
    setLatestDoc(docSnap.docs[docSnap.docs.length - 1]);
    setFirstDoc(docSnap.docs[0]);
    setGoNext(true);
  };

  // const typeList = typeList;
  // const statusList = statusList;
  // const colorList = colorList;
  // const orderList = orderList;
  // const paymentPrivilages = paymentPrivilages;

  const onSearchPatient = async (e) => {
    const q = query(
      collection(db, "patients"),
      where("hn", "==", hnRef.current.value)
    );
    const querySnapshot = await getDocs(q);
    let listPatient = [];
    // console.log(querySnapshot);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      listPatient.push(doc.data());
    });
    if (listPatient[0] == undefined) {
      setIsNewPatient(true);
    } else {
      try {
        firstnameRef.current.value = listPatient[0].firstnameTh;
        lastnameRef.current.value = listPatient[0].lastnameTh;
        nationalIdRef.current.value = listPatient[0].nationalId
          ? listPatient[0].nationalId
          : null;
        phoneRef.current.value = listPatient[0].mobile;
        hospitalNumberRef.current.value = listPatient[0].refHn;
        genderRef.current.value = listPatient[0].gender;
        if (
          listPatient[0].healthInsurance == "" ||
          listPatient[0].healthInsurance == "please-select"
        ) {
          healthInsuranceRef.current.value = "กรุณาเลือก";
        } else {
          healthInsuranceRef.current.value = listPatient[0].healthInsurance;
        }
      } catch (err) {
        setCurrentBooking({
          ...currentBooking,
          extendedProps: {
            ...currentBooking.extendedProps,
            nationalId: listPatient[0].nationalId
              ? listPatient[0].nationalId
              : null,
            gender: listPatient[0].gender ? listPatient[0].gender : null,
            firstname: listPatient[0].firstnameTh,
            lastname: listPatient[0].lastnameTh,
            phone: listPatient[0].mobile,
            hospitalNumber: listPatient[0].refHn,
          },
        });
      }
      setIsNewPatient(false);
    }
  };
  const drop = (bookingInfo) => {
    return (
      <Dropdown>
        <Dropdown.Toggle variant="" className="table-dropdown icon-false">
          <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect x="0" y="0" width="24" height="24"></rect>
              <circle fill="#000000" cx="5" cy="12" r="2"></circle>
              <circle fill="#000000" cx="12" cy="12" r="2"></circle>
              <circle fill="#000000" cx="19" cy="12" r="2"></circle>
            </g>
          </svg>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              setCustomerList(CustomerList(bookingInfo.extendedProps.node));
              handleBookingClick(bookingInfo);
              if (bookingInfo.extendedProps.status == "Post-Consult") {
                setIsDatetime(true);
              } else {
                setIsDatetime(false);
              }
            }}
          >
            Order Detail
          </Dropdown.Item>
          <Dropdown.Item href={`/patient-page/${bookingInfo.extendedProps.hn}`}>
            Patient Page
          </Dropdown.Item>
          {!["staff", "sale", "sleep_technician"].includes(
            currentUser.roles[0]
          ) && (
            <Dropdown.Item
              href="#"
              onClick={() => {
                // refDeleteDoc(bookingInfo);
                swal({
                  title: "Are you sure to delete ?",
                  text: "Once deleted, you will not be able to recover this booking event !",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                }).then((willDelete) => {
                  if (willDelete) {
                    refDeleteDoc(bookingInfo);
                    // swal("Your booking event has been deleted!", {
                    //   icon: "success",
                    // });
                  }
                });
              }}
              className="text-danger"
            >
              Delete
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };
  const [bookingList, setBookingList] = useState([]);
  const [nodeList, setNodeList] = useState([]);
  const [nodeIdDocs, setNodeIdDocs] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [customerAllList, setCustomerAllList] = useState([]);
  const [currentBooking, setCurrentBooking] = useState(null);
  const [addEventModal, setAddEventModal] = useState(false);
  const [editEventModal, setEditEventModal] = useState(false);
  const [editBookingDetail, setEditBookingDetail] = useState(false);
  const [technicianList, setTechnicianList] = useState([]);
  const [techrecordList, setTechRecordList] = useState([]);
  const [doctorList, setDoctorList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [isNewPatient, setIsNewPatient] = useState(false);
  const [isDatetime, setIsDatetime] = useState(false);
  const [items, setItems] = useState([]);
  const [totalValue, setTotalValue] = useState(0);
  const [isModalReady, setIsModalReady] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [createModal, setCreateModal] = useState(false);
  const [loadingTimeout, setLoadingTimeout] = useState(false); // New state for timeout
  const dateRef = useRef();
  const titleRef = useRef();
  const idRef = useRef();
  const orderRef = useRef();
  const nodeRef = useRef();
  const customerRef = useRef();
  const hnRef = useRef();
  const typeRef = useRef();
  const statusRef = useRef();
  const colorRef = useRef();
  const firstnameRef = useRef();
  const lastnameRef = useRef();
  const nationalIdRef = useRef();
  const healthInsuranceRef = useRef();
  const phoneRef = useRef();
  const noteRef = useRef();
  const itemsRef = useRef();
  const techRef = useRef();
  const docRef = useRef();
  const techRecordRef = useRef();
  const referDoctorRef = useRef();
  const fileInputRef = useRef();
  const hospitalNumberRef = useRef();
  const genderRef = useRef();
  const ahiRef = useRef();
  const handleBookingClick = async (bookingInfo) => {
    // console.log(CustomerList(bookingInfo.extendedProps.node));
    console.log("info: ", bookingInfo);
    const docRef = collection(
      db,
      "patients",
      bookingInfo.extendedProps.hn,
      "report"
    );
    const docSnap = await getDocs(docRef).then((snapshot) => {
      const reports = [];
      snapshot.forEach(async (childSnapshot) => {
        reports.push({
          id: childSnapshot.id,
          ...childSnapshot.data(),
        });
      });

      if (reports.length > 0) {
        bookingInfo.extendedProps.reports = reports;
        // eventClick.event.setExtendedProp("reports", reports);
      } else {
        bookingInfo.extendedProps.reports = null;

        // eventClick.event.setExtendedProp("reports", null);
      }
    });
    setCustomerList(CustomerList(bookingInfo.extendedProps.node));
    setCurrentBooking(bookingInfo);
    setIsModalReady(true);
    setEditEventModal(true);
    setItems(bookingInfo.extendedProps.items);
    setTotalValue(bookingInfo.extendedProps.totalValue);
  };

  const handleCloseModal = () => {
    setEditEventModal(false);
  };
  const onUpdateItemValue = (key, index, value) => {
    var tmpItems = items;
    tmpItems[index][key] = value.target.value;
    tmpItems[index].total =
      tmpItems[index].unitcost * tmpItems[index].qty - tmpItems[index].discount;
    setItems(tmpItems);
    const newTotalValue = calculateTotalValue(tmpItems);
    setTotalValue(newTotalValue);
  };

  const handleStatusChange = () => {
    const selectedStatus = statusRef.current.value;
    setCurrentBooking((prevBooking) => ({
      ...prevBooking,
      extendedProps: {
        ...prevBooking.extendedProps,
        status: selectedStatus,
      },
    }));
    if (selectedStatus == "Post-Consult") {
      setIsDatetime(true);
    } else {
      setIsDatetime(false);
    }
  };
  const handleDate = (moment, props) => {
    let storeMoment = moment.toISOString().slice(0, 10);
    setCurrentBooking({ ...currentBooking, start: storeMoment });
  };
  const handleTime = (moment, props) => {
    setCurrentBooking((prevBooking) => ({
      ...prevBooking,
      extendedProps: {
        ...prevBooking.extendedProps,
        time: moment.target.value,
      },
    }));
    // var evtmp = { ...currentBooking };
    // evtmp.extendedProps.time = moment.target.value;
    // setCurrentBooking({ ...currentBooking, time: moment.target.value });
  };
  const handleAddItem = () => {
    // Create a new copy of items array and add the new item
    const newItem = {
      id: items.length + 1,
      itemcode: itemList[0].itemcode,
      item: itemList[0].item,
      unitcost: itemList[0].unitcost,
      qty: 1,
      discount: 0,
      total: itemList[0].unitcost,
    };
    const updatedItems = [...items, newItem];
    // Update the items state and calculate the total value
    setItems(updatedItems);
    const newTotalValue = calculateTotalValue(updatedItems);
    setTotalValue(newTotalValue);
  };

  const calculateTotalValue = (itemsList) => {
    let totalValueTmp = 0;
    itemsList.forEach((item) => {
      totalValueTmp += parseInt(item.total);
    });
    return totalValueTmp;
  };

  const onEditEvent = () => {
    if (editBookingDetail) {
      var currentBookingTmp = currentBooking;
      var currentBookingTmpOld = JSON.parse(JSON.stringify(currentBooking));

      const currentAdmin = currentUser.displayName;
      currentBookingTmp.extendedProps.createBy = currentAdmin;

      currentBookingTmp.extendedProps.order = orderRef.current.value;
      currentBookingTmp.extendedProps.hn = hnRef.current.value;
      try {
        currentBookingTmp.extendedProps.nationalId =
          nationalIdRef.current.value;
        currentBookingTmp.extendedProps.firstname = firstnameRef.current.value;
        currentBookingTmp.extendedProps.lastname = lastnameRef.current.value;
        currentBookingTmp.extendedProps.phone = phoneRef.current.value;
        currentBookingTmp.extendedProps.gender = genderRef.current.value;
        currentBookingTmp.extendedProps.hospitalNumber =
          hospitalNumberRef.current.value;
      } catch (err) {
        console.log("get current patient info");
      }
      if (currentBookingTmp.extendedProps.hospitalNumber == undefined) {
        currentBookingTmp.extendedProps.hospitalNumber = "";
      }
      if (currentBookingTmp.extendedProps.gender == undefined) {
        currentBookingTmp.extendedProps.gender = "";
      }
      currentBookingTmp.extendedProps.type = typeRef.current.value;
      currentBookingTmp.extendedProps.healthInsurance =
        healthInsuranceRef.current.value;
      currentBookingTmp.extendedProps.status = statusRef.current.value;
      currentBookingTmp.extendedProps.note = noteRef.current.value;
      currentBookingTmp.extendedProps.node = nodeRef.current.value;
      currentBookingTmp.extendedProps.customer = customerRef.current.value;

      currentBookingTmp.extendedProps.technician = techRef.current.value;
      currentBookingTmp.extendedProps.techrecord = techRecordRef.current.value;
      currentBookingTmp.extendedProps.doctor = docRef.current.value;
      currentBookingTmp.extendedProps.referDoctor =
        referDoctorRef.current.value;

      currentBookingTmp.extendedProps.items = items;
      currentBookingTmp.extendedProps.totalValue = totalValue;
      currentBookingTmp.id = 6;
      currentBookingTmp.title = orderRef.current.value;
      var indexOfStatusList = statusList.findIndex(
        (obj) => obj === statusRef.current.value
      );
      currentBookingTmp.color = colorList[indexOfStatusList];
      currentBookingTmp.extendedProps.ahi = ahiRef.current.value;
      if (statusRef.current.value != "Post-Consult") {
        currentBookingTmp.extendedProps.time = "";
      }
      if (statusRef.current.value === "Sleep Test Scheduled") {
        currentBookingTmp.extendedProps.latestSleepStudyDate =
          currentBookingTmp.start;
      }
      setCurrentBooking(currentBookingTmp);
      if (
        currentBookingTmp.extendedProps.node !=
        currentBookingTmpOld.extendedProps.node
      ) {
        setEvent(currentBookingTmp, currentBookingTmpOld, nodeIdDocs, nodeList);
      } else {
        setEvent(currentBookingTmp, undefined, nodeIdDocs, nodeList);
      }
      initNewData();
    }
    setEditEventModal(false);
  };
  const CustomerList = (nodename) => {
    const index = nodeList.indexOf(nodename);
    return customerAllList[index];
  };

  const buildQuery = (
    collectionRef,
    sortBy,
    statusBy,
    typeBy,
    nodeBy,
    techBy,
    currentUser,
    text,
    itemsPerPage,
    statusPage
  ) => {
    let queryConstraints = [];

    queryConstraints.push(
      orderBy(sortBy === "start" ? "start" : `extendedProps.${sortBy}`)
    );

    if (statusBy !== "ทั้งหมด") {
      queryConstraints.push(where("extendedProps.status", "==", statusBy));
    }

    if (typeBy !== "ทั้งหมด") {
      queryConstraints.push(where("extendedProps.type", "==", typeBy));
    }

    if (nodeBy !== "ทั้งหมด") {
      queryConstraints.push(where("extendedProps.node", "==", nodeBy));
    }

    if (currentUser.roles[0] == "sleep_physician") {
      queryConstraints.push(
        where("extendedProps.doctor", "==", currentUser.displayName)
      );
    }

    if (currentUser.roles[0] == "sleep_technician") {
      queryConstraints.push(
        where("extendedProps.techrecord", "==", currentUser.displayName)
      );
    }

    if (
      currentUser.roles[0] == "super_admin" &&
      !["กรุณาเลือก", ""].includes(techBy)
    ) {
      queryConstraints.push(where("extendedProps.techrecord", "==", techBy));
    }

    if (sortBy !== "start" && text) {
      queryConstraints.push(where(`extendedProps.${sortBy}`, ">=", text));
      queryConstraints.push(
        where(`extendedProps.${sortBy}`, "<=", text + "\uf8ff")
      );
    }
    if (statusPage == "Init") {
      queryConstraints.push(startAfter(null));
      queryConstraints.push(limit(itemsPerPage));
    } else if (statusPage == "Next") {
      queryConstraints.push(startAfter(latestDoc));
      queryConstraints.push(limit(itemsPerPage));
    } else if (statusPage == "Prev") {
      queryConstraints.push(endBefore(firstDoc));
      queryConstraints.push(limitToLast(itemsPerPage));
    }
    // Always apply pagination last

    return query(collectionRef, ...queryConstraints);
  };

  const initNewData = async () => {
    // Usage of the buildQuery function
    let bookingsCollectionGroupRef = collectionGroup(db, "bookings");
    var statusPage = "Init";
    // Pass your parameters here, including dates if searchTime is true
    let queryHandler = buildQuery(
      bookingsCollectionGroupRef,
      sortBy,
      statusBy,
      typeBy,
      nodeBy,
      techBy,
      currentUser,
      text,
      itemsPerPage,
      statusPage
    );

    // Counting documents may require a separate function without limit constraint
    var count = await getCountFromServer(
      buildQuery(
        bookingsCollectionGroupRef,
        sortBy,
        statusBy,
        typeBy,
        nodeBy,
        techBy,
        currentUser,
        text,
        undefined, // No limit for the count
        statusPage
      )
    );
    // const tempBooking = [];
    // const docSnap = await getDocs(queryHandler);
    // if (docSnap.empty) {
    //   console.log("No documents found");
    // } else {
    //   docSnap.forEach(async (booking) => {
    //     const bookingData = booking.data();
    //     const dateStart = new Date(bookingData.start.toDate());
    //     dateStart.setHours(dateStart.getHours() + 7);
    //     bookingData.start = dateStart.toISOString().slice(0, 10);

    //     // add booking reports
    //     const patientReports = await getDocs(collection(db,"patients",bookingData.extendedProps.hn,"report"))
    //     if (patientReports.empty) {
    //       bookingData.reports = []
    //     } else {
    //       const reports = []
    //       patientReports.forEach((report) => {
    //         reports.push(report.data())
    //       })
    //       bookingData.reports = reports
    //     }
    //     tempBooking.push({ ...bookingData });
    //   });
    // }

    const tempBooking = [];
    const docSnap = await getDocs(queryHandler);
    if (docSnap.empty) {
      console.log("No documents found");
    } else {
      const promises = docSnap.docs.map(async (booking) => {
        const bookingData = booking.data();
        const dateStart = new Date(bookingData.start.toDate());
        dateStart.setHours(dateStart.getHours() + 7);
        bookingData.start = dateStart.toISOString().slice(0, 10);

        // add booking reports
        if (
          bookingData.extendedProps.hn == "" ||
          !bookingData.extendedProps.hn
        ) {
          bookingData.reports = [];
        } else {
          const patientReports = await getDocs(
            collection(db, "patients", bookingData.extendedProps.hn, "report")
          );
          if (patientReports.empty) {
            bookingData.reports = [];
          } else {
            const reports = [];
            patientReports.forEach((report) => {
              var reportData = report.data();
              var id = report.id;
              reportData = { ...reportData, id };
              reports.push(reportData);
              // console.log("report: ",report.data())
            });
            bookingData.reports = reports;
          }
        }
        tempBooking.push({ ...bookingData });
      });

      // Wait for all promises to resolve
      await Promise.all(promises);
    }

    // Now tempBooking is updated, you can set it to bookingList
    setBookingList(tempBooking);

    // setBookingList(tempBooking);
    var totalPagesTmp = Math.ceil(count.data().count / itemsPerPage);
    setBookingsCount(count.data().count);
    setTotalPages(totalPagesTmp);
    setPage(1);
    // setPage(1);

    if (docSnap.docs.length >= 1) {
      setLatestDoc(docSnap.docs[docSnap.docs.length - 1]);
      setFirstDoc(docSnap.docs[0]);
    } else {
      setLatestDoc(null);
      setFirstDoc(null);
    }

    // You can do whatever you want with the tempBooking array here
    if (count.data().count <= itemsPerPage) {
      console.log("no more doc");
      setGoNext(false);
      setGoPrev(false);
    } else {
      setGoNext(true);
      setGoPrev(false);
    }
    // setLoading(false);
  };

  const fetchAnotherData = async () => {
    try {
      // Use Promise.all for parallel fetching
      const [nodesDocs, itemsDocs, techDocs, docDocs] = await Promise.all([
        getDocs(collection(db, "nodes")),
        getDocs(collection(db, "items")),
        getDocs(collection(db, "sleeptechnicians")),
        getDocs(collection(db, "doctors")),
        // getDocs(collectionGroup(db,"customers"))
      ]);

      let allCustomers = [];
      let allnodeIdDocs = [];
      let allItems = [];
      let allNodes;
      if (
        currentUser.roles[0] == "super_admin" ||
        currentUser.roles[0] == "sale"
      ) {
        allNodes = nodesDocs.docs.map((doc) => doc.data().nodename);
        for (const nodeDoc of nodesDocs.docs) {
          allnodeIdDocs = [...allnodeIdDocs, nodeDoc.id];
          const customerDocs = await getDocs(
            collection(db, "nodes", nodeDoc.id, "customers")
          );
          const customersData = customerDocs.docs.map((customer) => {
            const customerData = customer.id;
            return customerData;
          });
          allCustomers.push(customersData);
        }
        allItems = itemsDocs.docs.map((doc) => ({
          ...doc.data(),
          itemcode: doc.id,
        }));
      } else if (currentUser.roles[0] == "sleep_physician") {
        allNodes = nodesDocs.docs.map((doc) => doc.data().nodename);
        for (const nodeDoc of nodesDocs.docs) {
          allnodeIdDocs = [...allnodeIdDocs, nodeDoc.id];
          const customerDocs = await getDocs(
            collection(db, "nodes", nodeDoc.id, "customers")
          );
          const customersData = customerDocs.docs.map((customer) => {
            const customerData = customer.id;
            return customerData;
          });
          allCustomers.push(customersData);
        }
        allItems = itemsDocs.docs.map((doc) => ({
          ...doc.data(),
          itemcode: doc.id,
        }));
      } else {
        // allNodes = nodesDocs.docs.map((doc) => doc.data().nodename);
        const currentAdmin = await getDoc(
          doc(db, "admins", currentUser.displayName)
        );
        let nodeCode = currentAdmin.data().node;
        let node = nodesDocs.docs.filter((doc) => {
          return doc.data().nodecode == nodeCode;
        });
        console.log("node: ", node);
        allnodeIdDocs = node.map((doc) => doc.id);
        allNodes = node.map((doc) => doc.data().nodename);

        for (const id of allnodeIdDocs) {
          const customerDocs = await getDocs(
            collection(db, "nodes", id, "customers")
          );
          const customersData = customerDocs.docs.map((customer) => {
            const customerData = customer.id;
            return customerData;
          });
          allCustomers.push(customersData);
        }
        allItems = itemsDocs.docs
          .map((doc) => ({
            ...doc.data(),
            itemcode: doc.id,
          }))
          .filter((doc) => {
            return doc.node == nodeCode;
          });
      }

      const allTechs = [
        "กรุณาเลือก",
        ...techDocs.docs.map((doc) => doc.data().name),
      ];
      const allDocs = [
        "กรุณาเลือก",
        ...docDocs.docs.map((doc) => doc.data().name),
      ];
      setCustomerAllList(allCustomers);
      setCustomerList(allCustomers[0]);
      setNodeList(allNodes);
      setNodeIdDocs(allnodeIdDocs);
      setDoctorList(allDocs);
      setTechnicianList(allTechs);
      setTechRecordList(allTechs);
      setItemList(allItems);
    } catch (error) {
      console.log("Error fetching data: ", error);
    }
  };

  // useEffect(() => {
  //   setLoading(true);
  //   // Call the fetchData function to get the tempBooking array
  //   fetchAnotherData();
  //   // fetchData();
  //   initNewData();
  //   if (triggerRerender) {
  //     onResetTriggerRerender();
  //   }
  // }, [
  //   text,
  //   sortBy,
  //   typeBy,
  //   statusBy,
  //   nodeBy,
  //   bookingsCount,
  //   triggerRerender,
  //   onResetTriggerRerender,
  // ]);
  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        // Fetch additional data
        await fetchAnotherData();

        // Fetch booking data
        await initNewData();

        // Reset the trigger if used for re-rendering
        if (triggerRerender) {
          onResetTriggerRerender();
        }

        setLoading(false); // Set loading to false once all data is fetched
      } catch (error) {
        console.log("Error fetching data: ", error);
        setLoading(false); // Set loading to false in case of error
      }
    };

    fetchData();
  }, [
    text,
    sortBy,
    typeBy,
    statusBy,
    nodeBy,
    techBy,
    triggerRerender, // Removed bookingsCount to prevent unnecessary re-renders
  ]);

  const handleOnClick = (hn, id, file) => {
    getSignedUrl({
      folder: "reports",
      hn,
      id,
      filename: file,
    })
      .then((result) => {
        // console.log("url", result.data);
        // this.setState({ fileUrl: result.data });
        setFileUrl(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
    setCreateModal(true);
    // this.setState({ createModal: true });
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  // Function to handle file selection
  const handleFileChange = (e) => {
    console.log("file change");
    // const file = event.target.files[0];
    // console.log('Selected file:', file.name);  // Process the file as needed
    let files = [];

    for (let i = 0; i < e.target.files.length; i++) {
      const f = e.target.files[i];
      files.push(f);
      // setSelectedFiles((prevState) => [...prevState, f]);
      // setFilenames((prevState) => [...prevState, f.name]);
    }
    const report = {
      hn: currentBooking.extendedProps.hn,
      createBy: currentUser.displayName,
      createAt: new Date(),
    };
    startAddReport(report, files);
  };
  const startAddReport = async (reportData = {}, files, type = "report") => {
    console.log("start add report");
    const { hn = "", createBy = "", createAt = 0 } = reportData;
    const report = { hn, createBy, createAt };
    console.log("report: ", report);

    try {
      const docRef = doc(collection(db, "patients", report.hn, type));
      const docId = docRef.id;
      console.log("docRef: ", docRef);

      await setDoc(docRef, report);
      console.log("docRef inside: ", docRef);

      const promises = [];
      const fileNames = [];
      files.forEach((file) => {
        const storageRef = ref(
          storage,
          `${type}s/${report.hn}/${docId}/${file.name}`
        );
        fileNames.push(file.name);
        const uploadTask = uploadBytes(storageRef, file);
        promises.push(uploadTask);
      });

      await Promise.all(promises);
      await updateDoc(doc(db, "patients", report.hn, type, docId), {
        reportFile: fileNames,
      });

      const eventTmp = { ...currentBooking };
      const collectionRef = collection(
        db,
        "patients",
        eventTmp.extendedProps.hn,
        "report"
      );
      const snapshot = await getDocs(collectionRef);

      const reports = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (reports.length > 0) {
        eventTmp.extendedProps.reports = reports;
      } else {
        eventTmp.extendedProps.reports = null;
      }

      setCurrentBooking(eventTmp);

      console.log("UPLOAD SUCCESSFULLY !");
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnDownload = async (reports) => {
    // console.log("patientReports: ",patientReports)
    if (reports.length > 0) {
      reports.forEach((report) => {
        console.log(report);
        const hn = report.hn;
        const id = report.id;
        const file = report.reportFile;
        // console.log(file)
        getSignedUrl({
          folder: "reports",
          hn,
          id,
          filename: file,
        })
          .then((result) => {
            fetch(result.data).then((res) => {
              res.blob().then((blob) => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement("a");
                alink.href = fileURL;
                alink.download = `${file}`;
                alink.click();
              });
            });
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  };

  return (
    <Fragment>
      <Toast />
      {!loading ? (
        <>
          {" "}
          <Modal
            className="fade bd-example-modal-lg"
            show={createModal}
            onHide={() => setCreateModal(false)}
            size="xl"
          >
            <Modal.Header>
              <Modal.Title>Report</Modal.Title>
            </Modal.Header>
            <Modal.Body className="m-0 p-0">
              <div
                className="card-body mx-0 p-0 col-lg-12"
                style={{ height: "85vh" }}
              >
                <iframe
                  className="col-lg-12 m-0 p-0"
                  src={fileUrl + "#toolbar=0"}
                  width="100%"
                  height="100%"
                ></iframe>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
          <Row>
            <Col lg={12}>
              <Card>
                <Card.Body className="card-body">
                  <Table className="table-responsive-12 mb-0 table-striped">
                    <thead>
                      <tr>
                        <th> </th>
                        <th>Node</th>
                        <th>Sleep Study</th>
                        <th>Name</th>
                        <th>HN</th>
                        <th>TYPE</th>
                        <th>STATUS</th>
                        <th>REPORT</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody id="booking-table">
                      {bookingList.map((element, index) => {
                        return (
                          <tr>
                            <td className="py-0">
                              {index + 1 + 33 * (page - 1)}
                            </td>
                            <td className="py-0">
                              {element.extendedProps.node}
                            </td>
                            <td className="py-0">
                              <Link
                                onClick={() => {
                                  handleBookingClick(element);
                                  if (
                                    element.extendedProps.status ==
                                    "Post-Consult"
                                  ) {
                                    setIsDatetime(true);
                                  } else {
                                    setIsDatetime(false);
                                  }
                                }}
                                to="#"
                              >
                                {element.extendedProps.order}
                              </Link>
                            </td>
                            <td className="py-0">
                              <Link
                                // target="_blank"
                                to={"/patient-page/" + element.extendedProps.hn}
                              >
                                {" "}
                                {element.extendedProps.firstname +
                                  " " +
                                  element.extendedProps.lastname}
                              </Link>
                            </td>
                            <td className="py-0">
                              <Link
                                // target="_blank"
                                to={"/patient-page/" + element.extendedProps.hn}
                              >
                                {element.extendedProps.hn}
                              </Link>
                            </td>
                            <td className="py-0">
                              {element.extendedProps.type}
                            </td>
                            <td className="py-0">
                              {element.extendedProps.status +
                                " (" +
                                element.start +
                                ")"}
                            </td>
                            <td>
                              {" "}
                              <Link
                                to="#"
                                className={
                                  element.reports && element.reports.length > 0
                                    ? "btn btn-info shadow btn-xs sharp mr-1"
                                    : "btn btn-dark shadow btn-xs sharp mr-1"
                                }
                                onClick={() => {
                                  handleOnDownload(element.reports);
                                }}
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Download item"
                              >
                                {/* {element.extendedProps.reports && element.extendedProps.reports.length > 0 ? (<i className="fa fa-download"></i>) : (<i className="fa fa-download" style={{color: "red"}}></i>)}  */}
                                <i className="fa fa-download"></i>
                                {/* {element.extendedProps.reports.length} */}
                              </Link>
                            </td>

                            <td className="py-0">{drop(element)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>

                  <div className="d-flex justify-content-center mt-3">
                    <Pagination
                      className="pagination-primary pagination-circle"
                      size="lg"
                    >
                      <Button
                        onClick={previousPage}
                        disabled={!goPrev}
                        className="page-link"
                      >
                        <i className="la la-angle-left" />
                      </Button>
                      {/* {Page }{totalPages} */}
                      <div className="d-flex justify-content-center mr-5 ml-5 mt-2">
                        {page}
                        {" / "}
                        {totalPages}
                      </div>

                      <Button
                        onClick={nextPage}
                        disabled={!goNext}
                        className="page-link"
                      >
                        <i className="la la-angle-right" />
                      </Button>
                    </Pagination>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {currentBooking && editEventModal && isModalReady && (
            <Modal
              className="fade bd-example-modal-xl"
              show={editEventModal}
              onHide={() => {
                setEditEventModal(false);
                setEditBookingDetail(false);
                // setCurrentBooking(eventTmp);
              }}
              size="xl"
            >
              <Modal.Header>
                <Modal.Title>Edit Event</Modal.Title>
                <Button
                  variant=""
                  className="close"
                  onClick={() => {
                    setEditBookingDetail(false);
                    // setCurrentBooking(eventTmp);
                    setEditEventModal(false);
                  }}
                >
                  <span>&times;</span>
                </Button>
              </Modal.Header>
              <Modal.Body>
                {!["staff", "sleep_technician"].includes(
                  currentUser.roles[0]
                ) && (
                  <div className="ml-4">
                    <Button
                      variant=""
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        setEditBookingDetail(!editBookingDetail);
                      }}
                      size="sm"
                    >
                      Edit
                    </Button>
                  </div>
                )}
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl-12">
                      {editBookingDetail ? (
                        <>
                          <div className="form-group row">
                            <label
                              className="col-lg-3 col-form-label"
                              htmlFor="val-username"
                            >
                              Order
                            </label>

                            <div className="col-lg-7">
                              <input
                                type="text"
                                className="form-control"
                                id="val-hn"
                                name="val-hn"
                                placeholder="Order"
                                ref={orderRef}
                                defaultValue={
                                  currentBooking.extendedProps.order
                                }
                              />
                            </div>
                          </div>
                          <PatientDropDownForm
                            title="Node"
                            id="node"
                            refvalue={nodeRef}
                            selected={currentBooking.extendedProps.node}
                            options={nodeList}
                            onChange={(e) => {
                              setCustomerList(CustomerList(e.target.value));
                            }}
                          ></PatientDropDownForm>
                          <PatientDropDownForm
                            title="Customer"
                            id="customer"
                            refvalue={customerRef}
                            selected={currentBooking.extendedProps.customer}
                            options={customerList}
                          ></PatientDropDownForm>
                          <div className="form-group row">
                            <label
                              className="col-lg-3 col-form-label"
                              htmlFor="val-username"
                            >
                              HN (Company Number)
                            </label>

                            <div className="col-lg-7">
                              <input
                                type="text"
                                className="form-control"
                                id="val-hn"
                                name="val-hn"
                                placeholder="HN"
                                ref={hnRef}
                                defaultValue={currentBooking.extendedProps.hn}
                                // ref={emailRef}
                                // value=""
                              />
                            </div>
                            <div className="col-lg-2">
                              <Button
                                variant=""
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => onSearchPatient()}
                                size="sm"
                              >
                                Search
                              </Button>
                            </div>
                          </div>
                          {isNewPatient ? (
                            <div className="form-group row">
                              <label
                                className="col-lg-3 col-form-label"
                                htmlFor="val-username"
                              ></label>

                              <div className="col-lg-4">
                                <label className="text-danger">
                                  * New Patient
                                </label>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                          {currentBooking.extendedProps.hn == "" ? (
                            <>
                              {" "}
                              <div className="form-group row">
                                <label
                                  className="col-lg-3 col-form-label"
                                  htmlFor="val-username"
                                >
                                  Full Name
                                </label>
                                <div className="col-lg-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="val-hn"
                                    name="val-hn"
                                    placeholder="Firstname"
                                    ref={firstnameRef}
                                    defaultValue={
                                      currentBooking.extendedProps.firstname
                                    }
                                  />
                                </div>
                                <div className="col-lg-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="val-hn"
                                    name="val-hn"
                                    placeholder="Lastname"
                                    ref={lastnameRef}
                                    defaultValue={
                                      currentBooking.extendedProps.lastname
                                    }
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label
                                  className="col-lg-3 col-form-label"
                                  htmlFor="val-username"
                                >
                                  Gender
                                </label>

                                <div className="col-lg-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="val-hn"
                                    name="val-hn"
                                    placeholder="Gender"
                                    ref={genderRef}
                                    defaultValue={
                                      currentBooking.extendedProps.gender
                                    }
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label
                                  className="col-lg-3 col-form-label"
                                  htmlFor="val-username"
                                >
                                  Phone
                                </label>
                                <div className="col-lg-5">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="val-phone"
                                    name="val-phone"
                                    placeholder="Phone"
                                    ref={phoneRef}
                                    defaultValue={
                                      currentBooking.extendedProps.phone
                                    }
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label
                                  className="col-lg-3 col-form-label"
                                  htmlFor="val-username"
                                >
                                  Referring Hospital Number
                                </label>
                                <div className="col-lg-5">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="val-phone"
                                    name="val-phone"
                                    placeholder=""
                                    ref={hospitalNumberRef}
                                    defaultValue={
                                      currentBooking.extendedProps
                                        .hospitalNumber
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {" "}
                              <PatientTextInfo
                                title="Full Name"
                                value={
                                  currentBooking.extendedProps.firstname +
                                  " " +
                                  currentBooking.extendedProps.lastname
                                }
                                bold={true}
                              />
                              <PatientTextInfo
                                title="Gender"
                                value={currentBooking.extendedProps.gender}
                                bold={true}
                              />
                              <PatientTextInfo
                                title="Phone"
                                value={currentBooking.extendedProps.phone}
                                bold={true}
                              />
                              <PatientTextInfo
                                title="Referring Hospital Number"
                                value={
                                  currentBooking.extendedProps.hospitalNumber
                                }
                                bold={true}
                              />
                            </>
                          )}
                          <PatientDropDownForm
                            title="Health Insurance"
                            id="healthInsurance"
                            refvalue={healthInsuranceRef}
                            selected={
                              currentBooking.extendedProps.healthInsurance
                            }
                            options={paymentPrivilages}
                          ></PatientDropDownForm>
                          <PatientDropDownForm
                            title="Type"
                            id="type"
                            refvalue={typeRef}
                            selected={currentBooking.extendedProps.type}
                            options={typeList}
                          ></PatientDropDownForm>
                          <PatientDropDownForm
                            title="Status"
                            id="status"
                            refvalue={statusRef}
                            selected={currentBooking.extendedProps.status}
                            options={statusList}
                            onChange={handleStatusChange}
                            defaultValue={currentBooking.extendedProps.status}
                          ></PatientDropDownForm>
                          <PatientDropDownForm
                            title="Recording Tech"
                            id="technician"
                            refvalue={techRecordRef}
                            selected={currentBooking.extendedProps.techrecord}
                            options={techrecordList}
                          ></PatientDropDownForm>
                          <PatientDropDownForm
                            title="Scoring Tech"
                            id="technician"
                            refvalue={techRef}
                            selected={currentBooking.extendedProps.technician}
                            options={technicianList}
                          ></PatientDropDownForm>
                          <PatientDropDownForm
                            title="Doctor"
                            id="doctor"
                            refvalue={docRef}
                            selected={currentBooking.extendedProps.doctor}
                            options={doctorList}
                            // onChange={handleStatusChange}
                          ></PatientDropDownForm>
                          <div className="form-group row">
                            <label
                              className="col-lg-3 col-form-label"
                              htmlFor="val-username"
                            >
                              Referring Doctor
                            </label>
                            <div className="col-lg-5">
                              <input
                                type="text"
                                className="form-control"
                                id="val-phone"
                                name="val-phone"
                                placeholder="Referring Doctor"
                                ref={referDoctorRef}
                                defaultValue={
                                  currentBooking.extendedProps.referDoctor
                                }
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              className="col-lg-3 col-form-label"
                              htmlFor="val-username"
                            >
                              AHI
                            </label>
                            <div className="col-lg-5">
                              <input
                                type="text"
                                className="form-control"
                                id="val-refDoctor"
                                name="val-refDoctor"
                                placeholder=""
                                ref={ahiRef}
                                defaultValue={currentBooking.extendedProps.ahi}
                              />
                            </div>
                          </div>
                          {currentBooking.extendedProps.latestSleepStudyDate ? (
                            <PatientTextInfo
                              title="Sleep Study Date"
                              value={
                                currentBooking.extendedProps
                                  .latestSleepStudyDate
                              }
                              bold={true}
                            />
                          ) : (
                            <></>
                          )}
                          <div className="form-group row">
                            <label
                              className="col-lg-3 col-form-label"
                              htmlFor="val-username"
                            >
                              Date
                            </label>
                            <div className="col-lg-2 mt-2">
                              <DatePicker
                                className="form-control"
                                dateFormat="yyyy-MM-dd"
                                selected={
                                  currentBooking.start
                                    ? new Date(currentBooking.start)
                                    : null
                                }
                                // defaultValue={new Date(currentBooking.start)}
                                onChange={(event, props) =>
                                  handleDate(event, props)
                                }
                                // value={new Date(currentBooking.start)}
                              />
                            </div>
                          </div>
                          {isDatetime ? (
                            <>
                              {" "}
                              <div className="form-group row">
                                <label
                                  className="col-lg-3 col-form-label"
                                  htmlFor="val-username"
                                >
                                  Time
                                </label>
                                <div className="col-lg-2 mt-2">
                                  <input
                                    type={"time"}
                                    className="form-control"
                                    defaultValue={
                                      currentBooking.extendedProps.time
                                    }
                                    // defaultValue={new Date(state.dateTmp + "T00:00")}
                                    onChange={(event, props) =>
                                      handleTime(event, props)
                                    }
                                  ></input>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}

                          <div className="form-group row">
                            <label
                              className="col-lg-3 col-form-label"
                              htmlFor="val-username"
                            >
                              Note
                            </label>
                            <div className="col-lg-5">
                              <textarea
                                type="area"
                                className="form-control"
                                id="val-phone"
                                name="val-phone"
                                rows="4"
                                placeholder="Note"
                                ref={noteRef}
                                defaultValue={currentBooking.extendedProps.note}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-lg-5 col-form-label mt-2">
                              <b htmlFor="val-username">Add Item</b>
                              <Button
                                variant="secondary"
                                size="xs"
                                className="ml-2"
                                onClick={handleAddItem}
                              >
                                +
                              </Button>
                            </div>
                          </div>
                          <div className="table-responsive">
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th className="center">No.</th>
                                  <th className="center width300">Item Name</th>
                                  <th className="center width100">Item Code</th>
                                  <th className="center width150">
                                    Unit Cost (THB)
                                  </th>
                                  <th className="center width100">Qty</th>
                                  <th className="center">Discount (THB)</th>
                                  <th className="center">Total (THB)</th>
                                  <th className="center"></th>
                                </tr>
                              </thead>
                              <tbody>
                                {items.map((element, index) => {
                                  return (
                                    <tr>
                                      <td className="">{element.id}</td>
                                      <td>
                                        <select
                                          className="form-control"
                                          id={"item" + String(index)}
                                          name={"item" + String(index)}
                                          onChange={(evt) => {
                                            var item = evt.target.value;
                                            var tmpItems = items;

                                            var indexOfItemList =
                                              itemList.findIndex(
                                                (obj) => obj.item === item
                                              );
                                            console.log(
                                              "selected item: ",
                                              itemList[indexOfItemList]
                                            );
                                            console.log(
                                              "unitcost: ",
                                              itemList[indexOfItemList].unitcost
                                            );
                                            tmpItems[index].itemcode =
                                              itemList[
                                                indexOfItemList
                                              ].itemcode;
                                            tmpItems[index].item =
                                              itemList[indexOfItemList].item;
                                            tmpItems[index].unitcost =
                                              itemList[
                                                indexOfItemList
                                              ].unitcost;
                                            tmpItems[index].qty = 1;
                                            tmpItems[index].discount = 0;
                                            tmpItems[index].total =
                                              itemList[
                                                indexOfItemList
                                              ].unitcost;
                                            setItems(tmpItems);
                                            const newTotalValue =
                                              calculateTotalValue(tmpItems);
                                            setTotalValue(newTotalValue);
                                          }}
                                        >
                                          {itemList.map((value) => (
                                            <option
                                              value={value.item}
                                              selected={
                                                items[index].item == value.item
                                                  ? "selected"
                                                  : ""
                                              }
                                            >
                                              {value.item}
                                            </option>
                                          ))}
                                        </select>
                                      </td>
                                      <td className="left strong">
                                        <b>{items[index].itemcode}</b>
                                      </td>
                                      <td className="left">
                                        {" "}
                                        <input
                                          type="number"
                                          className="form-control"
                                          id={"val-unitcost" + String(index)}
                                          name={"val-unitcost" + String(index)}
                                          placeholder="Unit Cost"
                                          onChange={(data) =>
                                            onUpdateItemValue(
                                              "unitcost",
                                              index,
                                              data
                                            )
                                          }
                                          // defaultValue={
                                          //   state.items[index].unitcost
                                          // }
                                          value={items[index].unitcost}
                                        />
                                      </td>
                                      <td className="right">
                                        {" "}
                                        <input
                                          type="number"
                                          className="form-control"
                                          id={"val-qty" + String(index)}
                                          name={"val-qty" + String(index)}
                                          placeholder="QTY"
                                          onChange={(data) =>
                                            onUpdateItemValue(
                                              "qty",
                                              index,
                                              data
                                            )
                                          }
                                          // defaultValue={state.items[index].qty}
                                          value={items[index].qty}
                                        />
                                      </td>
                                      <td className="center">
                                        {" "}
                                        <input
                                          type="number"
                                          className="form-control"
                                          id={"val-discount" + String(index)}
                                          name={"val-discount" + String(index)}
                                          placeholder="Discount"
                                          onChange={(data) => {
                                            onUpdateItemValue(
                                              "discount",
                                              index,
                                              data
                                            );
                                          }}
                                          // defaultValue={
                                          //   state.items[index].discount
                                          // }
                                          value={items[index].discount}
                                        />
                                      </td>
                                      <td className="right">
                                        {" "}
                                        <input
                                          type="number"
                                          className="form-control"
                                          id={"val-total" + String(index)}
                                          name={"val-total" + String(index)}
                                          placeholder="Total"
                                          onChange={(data) =>
                                            onUpdateItemValue(
                                              "total",
                                              index,
                                              data
                                            )
                                          }
                                          // defaultValue={state.items[index].total}
                                          value={items[index].total}
                                        />
                                      </td>
                                      <td>
                                        <Button
                                          className="btn btn-danger btn-xs sharp"
                                          onClick={() => {
                                            var tmpItems = items;
                                            console.log("items: ", tmpItems);
                                            tmpItems = tmpItems.filter(
                                              (item) =>
                                                item.id != tmpItems[index].id
                                            );
                                            for (
                                              let i = 0;
                                              i < tmpItems.length;
                                              i++
                                            ) {
                                              tmpItems[i].id = i + 1;
                                            }
                                            console.log("items: ", tmpItems);
                                            setItems(tmpItems);
                                            const newTotalValue =
                                              calculateTotalValue(tmpItems);
                                            setTotalValue(newTotalValue);
                                          }}
                                        >
                                          <i className="fa fa-trash"></i>
                                        </Button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          <div className="row">
                            <div className="col-lg-4 col-sm-5 ml-auto">
                              <table className="table table-clear">
                                <tbody>
                                  <tr>
                                    <td className="left">
                                      <strong>Total</strong>
                                    </td>
                                    <td className="right">
                                      <strong>{totalValue + " THB"}</strong>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <PatientTextInfo
                            title="Order"
                            value={currentBooking.extendedProps.order}
                            bold={true}
                          />
                          <PatientTextInfo
                            title="Node"
                            value={currentBooking.extendedProps.node}
                            bold={true}
                          />
                          <PatientTextInfo
                            title="Customer"
                            value={currentBooking.extendedProps.customer}
                            bold={true}
                          />
                          <PatientLinkInfo
                            title="HN (Company Number)"
                            value={currentBooking.extendedProps.hn}
                            bold={true}
                            path={
                              "/patient-page/" + currentBooking.extendedProps.hn
                            }
                          ></PatientLinkInfo>
                          <PatientLinkInfo
                            title="Full Name"
                            value={
                              currentBooking.extendedProps.firstname +
                              " " +
                              currentBooking.extendedProps.lastname
                            }
                            bold={true}
                            path={
                              "/patient-page/" + currentBooking.extendedProps.hn
                            }
                          ></PatientLinkInfo>
                          <PatientTextInfo
                            title="Gender"
                            value={currentBooking.extendedProps.gender}
                            bold={true}
                          />
                          <PatientTextInfo
                            title="Phone"
                            value={currentBooking.extendedProps.phone}
                            bold={true}
                          />
                          <PatientTextInfo
                            title="Referring Hospital Number"
                            value={currentBooking.extendedProps.hospitalNumber}
                            bold={true}
                          />
                          <PatientTextInfo
                            title="Health Insurance"
                            value={currentBooking.extendedProps.healthInsurance}
                            bold={true}
                          />
                          <PatientTextInfo
                            title="Type"
                            value={currentBooking.extendedProps.type}
                            bold={true}
                          />
                          <PatientTextInfo
                            title="Status"
                            value={currentBooking.extendedProps.status}
                            bold={true}
                          />
                          <PatientTextInfo
                            title="Recording Tech"
                            value={currentBooking.extendedProps.techrecord}
                            bold={true}
                          />
                          <PatientTextInfo
                            title="Scoring Tech"
                            value={currentBooking.extendedProps.technician}
                            bold={true}
                          />
                          <PatientTextInfo
                            title="Doctor"
                            value={currentBooking.extendedProps.doctor}
                            bold={true}
                          />
                          <PatientTextInfo
                            title="Referring Doctor"
                            value={currentBooking.extendedProps.referDoctor}
                            bold={true}
                          />
                          <PatientTextInfo
                            title="AHI"
                            value={currentBooking.extendedProps.ahi}
                            bold={true}
                          />
                          <PatientTextInfo
                            title="Report"
                            value={
                              currentBooking.extendedProps.reports ? (
                                currentBooking.extendedProps.reports.map(
                                  (data, index) => {
                                    const filename = data.reportFile[0];
                                    var reportColor = "";
                                    if (
                                      filename
                                        .toLowerCase()
                                        .includes("medical") ||
                                      filename.includes("cert") ||
                                      filename.includes("ใบรับรองแพทย์")
                                    ) {
                                      reportColor = "#2D573F";
                                    } else {
                                      reportColor = "#dd0102";
                                    }
                                    return (
                                      <div className="col-lg-10">
                                        <div
                                          className="row"
                                          style={{
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <Link
                                            to="#"
                                            onClick={() =>
                                              handleOnClick(
                                                data.hn,
                                                data.id,
                                                data.reportFile[0]
                                              )
                                            }
                                          >
                                            <i
                                              className="flaticon-381-file-2 pr-3"
                                              style={{ color: reportColor }}
                                            ></i>
                                            {data.reportFile[0]}
                                            <br />
                                          </Link>
                                          {![
                                            "staff",
                                            "sleep_technician",
                                          ].includes(currentUser.roles[0]) &&
                                            index == 0 && (
                                              <>
                                                <Button
                                                  onClick={handleButtonClick}
                                                  className="btn-xxs btn-primary"
                                                  style={{
                                                    justifyContent: "flex-end",
                                                  }}
                                                  // disabled={!createAdmin}
                                                >
                                                  + Upload
                                                </Button>
                                                <input
                                                  type="file"
                                                  multiple
                                                  ref={fileInputRef}
                                                  style={{ display: "none" }}
                                                  onChange={handleFileChange}
                                                />
                                              </>
                                            )}
                                        </div>
                                      </div>
                                    );
                                  }
                                )
                              ) : (
                                <div className="col-lg-6">
                                  {!["staff", "sleep_technician"].includes(
                                    currentUser.roles[0]
                                  ) && (
                                    <div
                                      className="row"
                                      style={{
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div>No Report</div>
                                      <Button
                                        onClick={handleButtonClick}
                                        className="btn-xxs btn-primary"
                                        style={{
                                          justifyContent: "flex-end",
                                        }}
                                        // disabled={!createAdmin}
                                      >
                                        + Upload
                                      </Button>
                                      <input
                                        type="file"
                                        multiple
                                        ref={fileInputRef}
                                        style={{ display: "none" }}
                                        onChange={handleFileChange}
                                      />
                                    </div>
                                  )}
                                </div>
                              )
                            }
                            bold={true}
                          />
                          {currentBooking.extendedProps.latestSleepStudyDate ? (
                            <PatientTextInfo
                              title="Sleep Study Date"
                              value={
                                currentBooking.extendedProps
                                  .latestSleepStudyDate
                              }
                              bold={true}
                            />
                          ) : (
                            <></>
                          )}

                          <PatientTextInfo
                            title="Date"
                            value={currentBooking.start}
                            bold={true}
                          />
                          {currentBooking.extendedProps.status ==
                            "Post-Consult" && (
                            <PatientTextInfo
                              title="Time"
                              value={currentBooking.extendedProps.time}
                              bold={true}
                            />
                          )}
                          <PatientTextInfo
                            title="Note"
                            value={currentBooking.extendedProps.note}
                            bold={true}
                          />
                          <div className="table-responsive">
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th className="center">No.</th>
                                  <th className="center">Item Name</th>
                                  <th className="center">Item Code</th>
                                  <th className="center">Unit Cost (THB)</th>
                                  <th className="center">Qty</th>
                                  <th className="center">Discount (THB)</th>
                                  <th className="center">Total (THB)</th>
                                </tr>
                              </thead>
                              <tbody>
                                {items.map((element, index) => {
                                  return (
                                    <tr>
                                      <td className="">
                                        <b>{element.id}</b>
                                      </td>
                                      <td>
                                        <b>{element.item}</b>
                                      </td>
                                      <td className="left strong">
                                        <b>{element.itemcode}</b>
                                      </td>
                                      <td className="left">
                                        {" "}
                                        <b>{element.unitcost}</b>
                                      </td>
                                      <td className="right">
                                        {" "}
                                        <b>{element.qty}</b>
                                      </td>
                                      <td className="center">
                                        {" "}
                                        <b>{element.discount}</b>
                                      </td>
                                      <td className="right">
                                        {" "}
                                        <b>{element.total}</b>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          <div className="row">
                            <div className="col-lg-4 col-sm-5 ml-auto">
                              <table className="table table-clear">
                                <tbody>
                                  <tr>
                                    <td className="left">
                                      <strong>Total</strong>
                                    </td>
                                    <td className="right">
                                      <strong>{totalValue + " THB"}</strong>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className="justify-content-between">
                {!["staff", "sleep_technician"].includes(
                  currentUser.roles[0]
                ) && (
                  <>
                    {" "}
                    <div>
                      <Button
                        variant=""
                        className="btn btn-danger"
                        onClick={() => {
                          refDeleteDoc(currentBooking);
                          setEditBookingDetail(false);
                          setEditEventModal(false);
                        }}
                      >
                        Remove
                      </Button>
                    </div>
                    <div>
                      <Button
                        variant="light"
                        onClick={() => {
                          setEditBookingDetail(false);
                          setEditEventModal(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant=""
                        type="button"
                        className="btn btn-primary ml-2"
                        onClick={() => {
                          var allEvents = bookingList.filter((obj) => {
                            return (
                              obj.start === currentBooking.start &&
                              obj.extendedProps.hn !== ""
                            );
                          });
                          let listHNEvent = allEvents.filter((element) => {
                            return (
                              element.extendedProps.hn ===
                              currentBooking.extendedProps.hn
                            );
                          });

                          if (listHNEvent.length > 1) {
                            swal({
                              title: `HN${currentBooking.extendedProps.hn} is already booking on this day !`,
                              text: `Please recheck booking on ${currentBooking.start}`,
                              icon: "warning",
                              buttons: true,
                              dangerMode: true,
                            }).then((willBooking) => {
                              if (willBooking) {
                                onEditEvent();
                              }
                            });
                          } else {
                            onEditEvent();
                          }
                          setEditBookingDetail(false);
                        }}
                      >
                        Done
                      </Button>
                    </div>
                  </>
                )}
              </Modal.Footer>
            </Modal>
          )}
        </>
      ) : (
        <div class="media d-flex align-items-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden"></span>
          </div>
        </div>
      )}
    </Fragment>
  );
};

//   const handleDate = (date) => {
//     if (date != undefined) {
//       try {
//         var datetime = new Date(
//           date.toDate().setHours(new Date(date.toDate()).getHours() + 7)
//         )
//           .toISOString()
//           .replace("T", " ")
//           .slice(0, 19);
//         return datetime;
//       } catch {
//         // console.log(new Date(date).getHours());
//         // console.log(date);
//         var newDate = new Date(date);
//         var hours = newDate.getHours() + 7;
//         newDate = new Date(newDate.setHours(hours));
//         var newString = newDate.toISOString().replace("T", " ").slice(0, 19);

//         return newString;
//       }
//     } else {
//       return "";
//     }
//   };

export default BookingTable;

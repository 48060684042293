import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useFocusEffect,
} from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import { Formik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../../../context/AuthContext";
import { db } from "../../../../firebase";
import {
  setDoc,
  getDoc,
  getDocs,
  updateDoc,
  doc,
  collection,
  query,
  orderBy,
  limit,
  where,
  Timestamp,
} from "firebase/firestore";
import {
  TextInputForm,
  TextInputNotRequiredForm,
  DropDownForm,
  NoteInputNotRequiredForm,
  handleDate,
} from "../CpapUtils";
import {
  notifyTopRight,
  notifyWarning,
  notifyError,
} from "../../../components/toast/SetToast";
import Toast from "../../../components/toast/SetToast";
import { Button } from "react-bootstrap";
import {
  CpapBorrowingData,
  CpapBorrowingOptions,
  MaskSizes,
  Masks,
  PressureOptions,
  RampTimeOptions,
} from "../formdata/CpapBorrowingData";
import {
  CpapRegisterData,
  CpapRegisterChoices,
} from "../formdata/CpapRegisterData";
import swal from "sweetalert";

const CpapBorrowingSet = ({ cpapinfo, setShow }) => {
  const { currentUser } = useAuth();

  // SerialNumber of CPAP

  const submitHandler = async (e) => {
    // try {
    e.preventDefault();
    const docColRef = doc(
      collection(db, "cpaps", CpapInfo.SerialNumber, "borrowing")
    );

    var info = CpapBorrowingData;

    var payload = {
      SerialNumber: CpapInfo.SerialNumber,
      Admin: currentUser.displayName,
      CreateAt: new Date(),
      Note: CpapRef.current["Note"].value,
      Activity: CpapRef.current["Activity"].value,
      HN: "",
      BorrowDate: null,
      DueDate: null,
      BorrowCondition: "",
      Mask: "",
      MaskSize: "",
      Humidifier: "",
      RampTime: "",
      InitialPressure: "",
      MinPressure: "",
      MaxPressure: "",
      ReturnDate: null,
      ReturnCondition: "",
      Status: "",
      Tel: "",
      FirstName: "",
      LastName: "",
    };

    if (["ยืมเครื่อง"].includes(CpapRef.current["Activity"].value)) {
      if (CpapRef.current["BorrowDate"].value !== "") {
        var BorrowDate = new Date(CpapRef.current["BorrowDate"].value);
      } else {
        BorrowDate = null;
      }

      if (CpapRef.current["DueDate"].value !== "") {
        var DueDate = new Date(CpapRef.current["DueDate"].value);
      } else {
        DueDate = null;
      }

      payload = {
        ...payload,
        HN: CpapRef.current["HN"].value,
        Tel: HNInfo.tel, //XXX
        FirstName: HNInfo.firstname, //XXX
        LastName: HNInfo.lastname, //XXX
        BorrowDate: BorrowDate,
        DueDate: DueDate,
        BorrowCondition: CpapRef.current["BorrowCondition"].value,
        Mask: CpapRef.current["Mask"].value,
        MaskSize: CpapRef.current["MaskSize"].value,
        Humidifier: CpapRef.current["Humidifier"].value,
        RampTime: CpapRef.current["RampTime"].value,
        InitialPressure: CpapRef.current["InitialPressure"].value,
        MinPressure: CpapRef.current["MinPressure"].value,
        MaxPressure: CpapRef.current["MaxPressure"].value,
        Status: "ไม่ว่าง",
      };
    }

    if (["คืนเครื่อง"].includes(CpapRef.current["Activity"].value)) {
      payload = {
        ...payload,
        HN: CpapRef.current["HN"].value, // kan, for create activity
        Tel: "",
        FirstName: "",
        LastName: "",
        BorrowDate: null,
        DueDate: "",
        BorrowCondition: "",
        ReturnDate: new Date(CpapRef.current["ReturnDate"].value),
        ReturnCondition: CpapRef.current["ReturnCondition"].value,
        Status: "ว่าง",
      };
    }

    if (
      ["ส่งทำความสะอาด", "ลงทะเบียน", "ส่งเครื่องกลับศูนย์"].includes(
        CpapRef.current["Activity"].value
      )
    ) {
      payload = { ...payload, Status: "ว่าง" };
    }

    if (["ส่งซ่อม","จองเครื่อง"].includes(CpapRef.current["Activity"].value)) {
      payload = { ...payload, Status: "ไม่ว่าง" };
    }

    if (["ยกเลิกใช้งาน"].includes(CpapRef.current["Activity"].value)) {
      payload = { ...payload, Status: "ยกเลิกใช้งาน" };
    }

    // console.log(CpapRef.current["Activity"].value, ":", payload);
    if (
      payload["Activity"] === "ยืมเครื่อง" &&
      payload["Activity"] === CpapInfo.Activity
    ) {
      swal({
        title: `Are you sure to set activity ${payload["Activity"]} ?`,
        text: `Current activity is ${payload["Activity"]} by ${payload["HN"]}`,
        icon: "warning",
        buttons: ["Cancel", "Confirm"],
        dangerMode: true,
      }).then(async (willSetDoc) => {
        if (willSetDoc) {
          // set cpap activity
          await setDoc(docColRef, payload)
            .then(async (data) => {
              const ref = doc(db, "cpaps", CpapInfo.SerialNumber);
              const docSnap = await getDoc(ref);
              if (docSnap.exists()) {
                var tmpDocSnap = docSnap.data();
                tmpDocSnap.Status = payload["Status"];
                tmpDocSnap.Activity = payload["Activity"];
                tmpDocSnap.UpdatedDateTime = payload["CreateAt"];
                tmpDocSnap.LastNote = payload["Note"];
                if (payload["Activity"] == "ยืมเครื่อง") {
                  tmpDocSnap.LastHN = payload["HN"];
                  tmpDocSnap.LastBorrowDate = payload["BorrowDate"];
                  tmpDocSnap.LastDueDate = payload["DueDate"];
                  tmpDocSnap.LastBorrowCondition = payload["BorrowCondition"];
                  tmpDocSnap.LastTel = payload["Tel"];
                  tmpDocSnap.LastFirstName = payload["FirstName"];
                  tmpDocSnap.LastLastName = payload["LastName"];
                }
                if (payload["Activity"] == "คืนเครื่อง") {
                  tmpDocSnap.LastReturnDate = payload["ReturnDate"];
                  tmpDocSnap.LastReturnCondition = payload["ReturnCondition"];
                  tmpDocSnap.LastHN = payload["HN"];
                  tmpDocSnap.LastTel = payload["Tel"];
                  tmpDocSnap.LastFirstName = payload["FirstName"];
                  tmpDocSnap.LastLastName = payload["LastName"];
                  tmpDocSnap.LastBorrowDate = payload["BorrowDate"];
                  tmpDocSnap.LastDueDate = payload["DueDate"];
                  tmpDocSnap.LastBorrowCondition = payload["BorrowCondition"];
                }
                console.log("im here work well , ", tmpDocSnap);
                // update CPAP Status to show
                try {
                  await updateDoc(ref, tmpDocSnap);
                  console.log("CPAP document updated successfully");
                  // You can also trigger a toast here
                  notifyTopRight("อัปเดตข้อมูลสำเร็จ");
                } catch (err) {
                  console.error("Failed to update CPAP document:", err);
                  notifyError("เกิดข้อผิดพลาดในการอัปเดตข้อมูล", err.message);
                }
                setShow(payload["Status"], 1);
                // set patient borrowings
                if (
                  ["ยืมเครื่อง", "คืนเครื่อง"].includes(payload["Activity"])
                ) {
                  if (info["HN"] != null) {
                    const refPatientBorrowing = doc(
                      collection(db, "borrowings")
                    );
                    await setDoc(refPatientBorrowing, payload);
                  }
                }
              }
              notifyTopRight("บันทึกข้อมูลสำเร็จ");
              console.log("data:", data);
            })
            .catch((err) => {
              notifyError("บันทึกข้อมูลผิดพลาด, ", err);
              console.log("err:", err);
            });
        }
      });
    } else {
      // set cpap activity
      await setDoc(docColRef, payload)
        .then(async (data) => {
          console.log("cpap serial number", CpapInfo.SerialNumber);
          const ref = doc(db, "cpaps", CpapInfo.SerialNumber);
          const docSnap = await getDoc(ref);
          console.log(docSnap.data());
          if (docSnap.exists()) {
            var tmpDocSnap = docSnap.data();
            tmpDocSnap.Status = payload["Status"];
            tmpDocSnap.Activity = payload["Activity"];
            tmpDocSnap.UpdatedDateTime = payload["CreateAt"];
            tmpDocSnap.LastNote = payload["Note"];
            if (payload["Activity"] == "ยืมเครื่อง") {
              tmpDocSnap.LastHN = payload["HN"];
              tmpDocSnap.LastBorrowDate = payload["BorrowDate"];
              tmpDocSnap.LastDueDate = payload["DueDate"];
              tmpDocSnap.LastBorrowCondition = payload["BorrowCondition"];
            }
            if (payload["Activity"] == "คืนเครื่อง") {
              tmpDocSnap.LastDueDate = null;
              tmpDocSnap.LastReturnDate = payload["ReturnDate"];
              tmpDocSnap.LastReturnCondition = payload["ReturnCondition"];
            }
            console.log("im here work well else , ", tmpDocSnap);
            console.log("status: ", payload["Status"]);
            try {
              await updateDoc(ref, tmpDocSnap);
              console.log("CPAP document updated successfully");
              // You can also trigger a toast here
              notifyTopRight("อัปเดตข้อมูลสำเร็จ");
            } catch (err) {
              console.error("Failed to update CPAP document:", err);
              notifyError("เกิดข้อผิดพลาดในการอัปเดตข้อมูล", err.message);
            }
            setShow(payload["Status"], 1);
            // set patient borrowings
            if (["ยืมเครื่อง", "คืนเครื่อง"].includes(payload["Activity"])) {
              if (info["HN"] != null) {
                const refPatientBorrowing = doc(collection(db, "borrowings"));
                await setDoc(refPatientBorrowing, payload).then(() => {
                  console.log("borrow payload", payload);
                });
              }
            }
          }
        })
        .catch((err) => {
          notifyError("บันทึกข้อมูลผิดพลาด, ", err);
          console.log("err:", err);
        });
    }
  };

  const [CpapInfo, setCpapInfo] = useState(CpapRegisterData);
  const [CpapBorrowing, setCpapBorrowing] = useState(CpapBorrowingData);
  const [CpapBorrowingList, setCpapBorrowingList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [stateEdit, setStateEdit] = useState(false);
  const [CpapStatus, setCpapStatus] = useState("");
  const [isExistPatient, setIsExistPatient] = useState(false);
  const [HNInfo, setHNInfo] = useState({
    firstname: "",
    lastname: "",
    tel: "",
  });
  const [noteSearch, setNoteSearch] = useState("");

  const CpapRef = useRef(CpapBorrowingData);

  const onSearchPatient = async (hn) => {
    console.log("hn: ", hn);
    if (hn === undefined || hn === "" || hn === null) {
      setNoteSearch("Please fill HN !");
      setIsExistPatient(false);
      setHNInfo({
        firstname: "",
        lastname: "",
        tel: "",
      });
    } else {
      const q = query(collection(db, "patients"), where("hn", "==", hn));
      const querySnapshot = await getDocs(q);
      let listPatient = [];
      querySnapshot.forEach((doc) => {
        listPatient.push(doc.data());
      });
      console.log(listPatient[0]);
      if (listPatient[0] == undefined) {
        setNoteSearch("HN not found !");
        setIsExistPatient(false);
        setHNInfo({
          firstname: "",
          lastname: "",
          tel: "",
        });
      } else {
        setNoteSearch("");
        setIsExistPatient(true);
        setHNInfo({
          firstname: listPatient[0].firstnameTh,
          lastname: listPatient[0].lastnameTh,
          tel: listPatient[0].mobile,
        });
      }
    }
  };

  useEffect(async () => {
    if (CpapInfo.SerialNumber != undefined && CpapInfo.SerialNumber != "") {
      const refBorrowing = collection(
        db,
        "cpaps",
        CpapInfo.SerialNumber,
        "borrowing"
      );

      const listCpapHistoryTmp = [];
      const queryHandler = query(refBorrowing, orderBy("CreateAt"), limit(3));
      const docSnapBorrowingList = await getDocs(queryHandler);

      if (docSnapBorrowingList.empty) {
        const cpapBorrowingTmp = {
          SerialNumber: CpapInfo.SerialNumber,
          Status: CpapInfo.Status,
          Activity: CpapInfo.Activity,
          HN: "",
          Tel: "",
          FirstName: "",
          LastName: "",
          Admin: "",
          Mask: "",
          MaskSize: "",
          Humidifier: "",
          RampTime: "",
          InitialPressure: "",
          MinPressure: "",
          MaxPressure: "",
          BorrowDate: "",
          DueDate: "",
          ReturnDate: "",
          BorrowCondition: "",
          ReturnCondition: "",
          Note: "",
          CreateAt: new Date(),
        };

        const docColRef = doc(
          collection(db, "cpaps", CpapInfo.SerialNumber, "borrowing")
        );
        const docSnap = await setDoc(docColRef, cpapBorrowingTmp);
        console.log("uuid: ", docColRef.id);
        console.log("docSnap: ", cpapBorrowingTmp);

        setCpapBorrowing(cpapBorrowingTmp);
        setCpapBorrowingList([cpapBorrowingTmp]);
        setCpapStatus(cpapBorrowingTmp.Activity);
      } else {
        docSnapBorrowingList.forEach((element) => {
          listCpapHistoryTmp.push(element.data());
        });
        listCpapHistoryTmp.reverse();
        console.log("listCpapHistoryTmp: ", listCpapHistoryTmp);
        setCpapBorrowing(listCpapHistoryTmp[0]);
        setCpapBorrowingList(listCpapHistoryTmp);
        setCpapStatus(listCpapHistoryTmp[0].Activity);
      }

      setIsLoading(false);
      return;
    }

    const ref = doc(db, "cpaps", cpapinfo.SerialNumber);
    const docSnap = await getDoc(ref);
    setCpapInfo(docSnap.data());
  }, [CpapInfo]);

  const current_datetime = new Date().toISOString().substring(0, 10);
  const LastActivity = CpapInfo.Activity;

  const LastBorrowDate = handleDate(CpapInfo["LastBorrowDate"]).substring(
    0,
    10
  );
  const LastReturnDate = handleDate(CpapInfo["LastReturnDate"]);
  const LastDueDate = handleDate(CpapInfo["LastDueDate"]).substring(0, 10);
  return (
    <Fragment>
      <br />
      <Toast />
      {!isLoading && (
        <div className="row">
          <div className="col-lg-12">
            <div className="form-validation">
              <form
                className="form-valide"
                action="#"
                method="post"
                onSubmit={submitHandler}
              >
                <div className="row">
                  <div className="col-xl-6">
                    <div className="form-group row">
                      <label className="col-lg-4 col-form-label">
                        Serial Number
                        <span className="text-danger">*</span>
                      </label>
                      <div className="col-lg-6">
                        <h4 className="col-lg-12 text-primary font-weight-bold">
                          {CpapBorrowing["SerialNumber"]}
                        </h4>
                      </div>
                    </div>
                    <DropDownForm
                      title="Activity"
                      htmlFor="cpap-activity"
                      id="cpap-activity"
                      name="cpap-activity"
                      placeholder="กดเพื่อเลือก"
                      options={CpapBorrowingOptions}
                      refvalue={(el) => (CpapRef.current["Activity"] = el)}
                      defaultValue={CpapInfo["Activity"]}
                      disabled={
                        stateEdit || currentUser.roles[0] != "super_admin"
                      }
                      onChange={(el) => {
                        setCpapStatus(el.target.value);
                        if (
                          [
                            "คืนเครื่อง",
                            "ส่งทำความสะอาด",
                            "ส่งซ่อม",
                            "จองเครื่อง",
                            "ส่งเครื่องกลับศูนย์",
                            "ลงทะเบียน",
                            "ยกเลิกใช้งาน",
                          ].includes(el.target.value)
                        ) {
                          setNoteSearch("");
                          setIsExistPatient(false);
                          setHNInfo({
                            firstname: "",
                            lastname: "",
                            tel: "",
                          });
                        }
                      }}
                    />

                    {CpapStatus == "ยืมเครื่อง" &&
                    currentUser.roles[0] == "super_admin" ? (
                      <>
                        <TextInputNotRequiredForm
                          title="HN"
                          type="cpap-hn"
                          id="cpap-hn"
                          name="cpap-hn"
                          placeholder=""
                          refvalue={(el) => (CpapRef.current["HN"] = el)}
                          defaultValue=""
                          disabled={
                            stateEdit || currentUser.roles[0] != "super_admin"
                          }
                          isBorrow={true}
                          onClick={() =>
                            onSearchPatient(CpapRef.current["HN"].value)
                          }
                        />
                        {noteSearch != "" && (
                          <div className="form-group row">
                            <label className="col-lg-4 col-form-label">
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <div className="col-lg-6">
                              <text className="text-danger">
                                * {noteSearch}
                              </text>
                            </div>
                          </div>
                        )}
                        {isExistPatient == true ? (
                          <>
                            <div className="form-group row">
                              <label className="col-lg-4 col-form-label">
                                Name
                              </label>
                              <div className="col-lg-6 mt-2">
                                <b>
                                  {HNInfo.firstname} {HNInfo.lastname}
                                </b>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-lg-4 col-form-label">
                                Tel
                              </label>
                              <div className="col-lg-6 mt-2">
                                <b>{HNInfo.tel}</b>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        <DropDownForm
                          title="Mask"
                          htmlFor="cpap-mask"
                          id="cpap-mask"
                          name="cpap-mask"
                          placeholder="กดเพื่อเลือก"
                          options={Masks}
                          refvalue={(el) => (CpapRef.current["Mask"] = el)}
                          defaultValue="Nasal Mask"
                          disabled={
                            stateEdit || currentUser.roles[0] != "super_admin"
                          }
                        />
                        <DropDownForm
                          title="MaskSize"
                          htmlFor="cpap-masksize"
                          id="cpap-masksize"
                          name="cpap-masksize"
                          placeholder="กดเพื่อเลือก"
                          options={MaskSizes}
                          refvalue={(el) => (CpapRef.current["MaskSize"] = el)}
                          defaultValue="M"
                          disabled={
                            stateEdit || currentUser.roles[0] != "super_admin"
                          }
                        />
                        <DropDownForm
                          title="Humidifier"
                          htmlFor="cpap-humidifier"
                          id="cpap-humidifier"
                          name="cpap-humidifier"
                          placeholder="กดเพื่อเลือก"
                          options={["Yes", "No"]}
                          refvalue={(el) =>
                            (CpapRef.current["Humidifier"] = el)
                          }
                          defaultValue="No"
                          disabled={
                            stateEdit || currentUser.roles[0] != "super_admin"
                          }
                        />
                        <DropDownForm
                          title="Ramp Time (minute)"
                          htmlFor="cpap-RampTime"
                          id="cpap-RampTime"
                          name="cpap-RampTime"
                          placeholder="กดเพื่อเลือก"
                          options={RampTimeOptions}
                          refvalue={(el) => (CpapRef.current["RampTime"] = el)}
                          defaultValue="15"
                          disabled={
                            stateEdit || currentUser.roles[0] != "super_admin"
                          }
                        />
                        <DropDownForm
                          title="Initial Pressure (cmH2O)"
                          htmlFor="cpap-InitialPressure"
                          id="cpap-InitialPressure"
                          name="cpap-InitialPressure"
                          placeholder="กดเพื่อเลือก"
                          options={PressureOptions}
                          refvalue={(el) =>
                            (CpapRef.current["InitialPressure"] = el)
                          }
                          defaultValue="4"
                          disabled={
                            stateEdit || currentUser.roles[0] != "super_admin"
                          }
                        />
                        <DropDownForm
                          title="Min Pressure (cmH2O)"
                          htmlFor="cpap-MinPressure"
                          id="cpap-MinPressure"
                          name="cpap-MinPressure"
                          placeholder="กดเพื่อเลือก"
                          options={PressureOptions}
                          refvalue={(el) =>
                            (CpapRef.current["MinPressure"] = el)
                          }
                          defaultValue="4"
                          disabled={
                            stateEdit || currentUser.roles[0] != "super_admin"
                          }
                        />
                        <DropDownForm
                          title="Max Pressure (cmH2O)"
                          htmlFor="cpap-MaxPressure"
                          id="cpap-MaxPressure"
                          name="cpap-MaxPressure"
                          placeholder="กดเพื่อเลือก"
                          options={PressureOptions}
                          refvalue={(el) =>
                            (CpapRef.current["MaxPressure"] = el)
                          }
                          defaultValue="15"
                          disabled={
                            stateEdit || currentUser.roles[0] != "super_admin"
                          }
                        />
                        <TextInputNotRequiredForm
                          title="BorrowDate"
                          type="date"
                          id="cpap-borrowdate"
                          name="cpap-borrowdate"
                          placeholder={current_datetime}
                          refvalue={(el) =>
                            (CpapRef.current["BorrowDate"] = el)
                          }
                          defaultValue={current_datetime}
                          disabled={
                            stateEdit || currentUser.roles[0] != "super_admin"
                          }
                        />
                        <TextInputNotRequiredForm
                          title="DueDate"
                          type="date"
                          id="cpap-duedate"
                          name="cpap-duedate"
                          placeholder=""
                          refvalue={(el) => (CpapRef.current["DueDate"] = el)}
                          defaultValue=""
                          disabled={
                            stateEdit || currentUser.roles[0] != "super_admin"
                          }
                        />
                        <TextInputNotRequiredForm
                          title="BorrowCondition"
                          type="cpap-borrowcondition"
                          id="cpap-borrowcondition"
                          name="cpap-borrowcondition"
                          placeholder=""
                          refvalue={(el) =>
                            (CpapRef.current["BorrowCondition"] = el)
                          }
                          defaultValue=""
                          disabled={
                            stateEdit || currentUser.roles[0] != "super_admin"
                          }
                        />
                        <TextInputNotRequiredForm
                          title="Note"
                          type="cpap-note"
                          id="cpap-note"
                          name="cpap-note"
                          placeholder=""
                          refvalue={(el) => (CpapRef.current["Note"] = el)}
                          defaultValue={CpapInfo.LastNote}
                          disabled={
                            stateEdit || currentUser.roles[0] != "super_admin"
                          }
                        />
                      </>
                    ) : (
                      <></>
                    )}

                    {CpapStatus == "คืนเครื่อง" ? (
                      <>
                        <TextInputNotRequiredForm
                          title="HN"
                          type="cpap-hn"
                          id="cpap-hn"
                          name="cpap-hn"
                          placeholder=""
                          refvalue={(el) => (CpapRef.current["HN"] = el)}
                          defaultValue={CpapInfo["LastHN"]}
                          disabled={true}
                        />

                        <TextInputNotRequiredForm
                          title="BorrowDate"
                          type="date"
                          id="cpap-borrowdate"
                          name="cpap-borrowdate"
                          placeholder=""
                          refvalue={(el) =>
                            (CpapRef.current["BorrowDate"] = el)
                          }
                          defaultValue={LastBorrowDate}
                          disabled={true}
                        />
                        <TextInputNotRequiredForm
                          title="DueDate"
                          type="date"
                          id="cpap-duedate"
                          name="cpap-duedate"
                          placeholder=""
                          refvalue={(el) => (CpapRef.current["DueDate"] = el)}
                          defaultValue={LastDueDate}
                          disabled={true}
                        />
                        <TextInputNotRequiredForm
                          title="BorrowCondition"
                          type="cpap-borrowcondition"
                          id="cpap-borrowcondition"
                          name="cpap-borrowcondition"
                          placeholder=""
                          refvalue={(el) =>
                            (CpapRef.current["BorrowCondition"] = el)
                          }
                          defaultValue={CpapInfo["LastBorrowCondition"]}
                          disabled={true}
                        />
                        <TextInputNotRequiredForm
                          title="ReturnDate"
                          type="date"
                          id="cpap-returndate"
                          name="cpap-returndate"
                          placeholder={current_datetime}
                          refvalue={(el) =>
                            (CpapRef.current["ReturnDate"] = el)
                          }
                          defaultValue={current_datetime}
                          disabled={
                            stateEdit || currentUser.roles[0] != "super_admin"
                          }
                        />
                        <TextInputNotRequiredForm
                          title="ReturnCondition"
                          type="cpap-returncondition"
                          id="cpap-returncondition"
                          name="cpap-returncondition"
                          placeholder=""
                          refvalue={(el) =>
                            (CpapRef.current["ReturnCondition"] = el)
                          }
                          defaultValue=""
                          disabled={
                            stateEdit || currentUser.roles[0] != "super_admin"
                          }
                        />
                        <TextInputNotRequiredForm
                          title="Note"
                          type="cpap-note"
                          id="cpap-note"
                          name="cpap-note"
                          placeholder=""
                          refvalue={(el) => (CpapRef.current["Note"] = el)}
                          defaultValue={CpapInfo.LastNote}
                          disabled={
                            stateEdit || currentUser.roles[0] != "super_admin"
                          }
                        />
                      </>
                    ) : (
                      <></>
                    )}

                    {CpapStatus == "ส่งทำความสะอาด" ? (
                      <>
                        <TextInputNotRequiredForm
                          title="Note"
                          type="cpap-note"
                          id="cpap-note"
                          name="cpap-note"
                          placeholder=""
                          refvalue={(el) => (CpapRef.current["Note"] = el)}
                          defaultValue={CpapInfo.LastNote}
                          disabled={
                            stateEdit || currentUser.roles[0] != "super_admin"
                          }
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    {CpapStatus == "ส่งซ่อม" || CpapStatus == "จองเครื่อง" ? (
                      <>
                        <TextInputNotRequiredForm
                          title="Note"
                          type="cpap-note"
                          id="cpap-note"
                          name="cpap-note"
                          placeholder=""
                          refvalue={(el) => (CpapRef.current["Note"] = el)}
                          defaultValue={CpapInfo.LastNote}
                          disabled={
                            stateEdit || currentUser.roles[0] != "super_admin"
                          }
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    {CpapStatus == "ส่งเครื่องกลับศูนย์" ? (
                      <>
                        <TextInputNotRequiredForm
                          title="ReturnDate"
                          type="date"
                          id="cpap-returndate"
                          name="cpap-returndate"
                          placeholder={current_datetime}
                          refvalue={(el) =>
                            (CpapRef.current["ReturnDate"] = el)
                          }
                          defaultValue={current_datetime}
                          disabled={
                            stateEdit || currentUser.roles[0] != "super_admin"
                          }
                        />
                        <TextInputNotRequiredForm
                          title="ReturnCondition"
                          type="cpap-returncondition"
                          id="cpap-returncondition"
                          name="cpap-returncondition"
                          placeholder=""
                          refvalue={(el) =>
                            (CpapRef.current["ReturnCondition"] = el)
                          }
                          defaultValue=""
                          disabled={
                            stateEdit || currentUser.roles[0] != "super_admin"
                          }
                        />
                        <TextInputNotRequiredForm
                          title="Note"
                          type="cpap-note"
                          id="cpap-note"
                          name="cpap-note"
                          placeholder=""
                          refvalue={(el) => (CpapRef.current["Note"] = el)}
                          defaultValue={CpapInfo.LastNote}
                          disabled={
                            stateEdit || currentUser.roles[0] != "super_admin"
                          }
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    {CpapStatus == "ยกเลิกใช้งาน" ? (
                      <>
                        <TextInputNotRequiredForm
                          title="Note"
                          type="cpap-note"
                          id="cpap-note"
                          name="cpap-note"
                          placeholder=""
                          refvalue={(el) => (CpapRef.current["Note"] = el)}
                          defaultValue={CpapInfo.LastNote}
                          disabled={
                            stateEdit || currentUser.roles[0] != "super_admin"
                          }
                        />
                      </>
                    ) : (
                      <></>
                    )}

                    {CpapStatus == "ลงทะเบียน" ? (
                      <>
                        <NoteInputNotRequiredForm
                          title="Note"
                          type="cpap-note"
                          id="cpap-note"
                          name="cpap-note"
                          placeholder=""
                          refvalue={(el) => (CpapRef.current["Note"] = el)}
                          defaultValue={CpapInfo.LastNote}
                          disabled={
                            stateEdit || currentUser.roles[0] != "super_admin"
                          }
                        />
                      </>
                    ) : (
                      <></>
                    )}

                    {!stateEdit && currentUser.roles[0] == "super_admin" && (
                      <div className="form-group row">
                        <div className="col-lg-8 ml-auto">
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};
export default CpapBorrowingSet;

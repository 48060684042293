import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Badge, Pagination } from "react-bootstrap";
import {
  query,
  endAt,
  setDoc,
  getDocs,
  collection,
  orderBy,
  limit,
  startAfter,
  endBefore,
  limitToLast,
  startAt,
  getCountFromServer,
  doc,
  deleteDoc,
  updateDoc,
  getDoc,
  where,
  collectionGroup,
} from "firebase/firestore";
import { db } from "../../../firebase";

const EquipmentItem = ({
  equipments,
  onEdit,
  onDelete,
  // onNext,
  // onPrev,
  totalItems,
  totalPages,
  currentPage,
  itemsPerPage,
  goToPage,
  isStart,
  isEnd,
  getNext,
  getPrev,
}) => {
  const [loading, setLoading] = useState(false);

  const numberPagination = Array(Math.ceil(totalItems / itemsPerPage))
    .fill()
    .map((_, i) => i + 1);

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  // calculate how many page number to show
  const maxPageNumbersToShow = 5; // Adjust as needed
  let startPage = Math.max(
    1,
    currentPage - Math.floor(maxPageNumbersToShow / 2)
  );
  let endPage = Math.min(totalPages, startPage + maxPageNumbersToShow - 1);

  // Adjust startPage if endPage is near the end
  if (endPage - startPage + 1 < maxPageNumbersToShow) {
    startPage = Math.max(1, endPage - maxPageNumbersToShow + 1);
  }

  // Create the pagination items array
  const paginationItems = [];
  if (startPage > 1) {
    paginationItems.push(<Pagination.Ellipsis key="ellipsis-start" disabled />);
  }

  for (let number = startPage; number <= endPage; number++) {
    paginationItems.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => goToPage(number)}
      >
        {number}
      </Pagination.Item>
    );
  }
  if (endPage < totalPages) {
    paginationItems.push(<Pagination.Ellipsis key="ellipsis-end" disabled />);
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table mb-0 table-striped">
                  <thead>
                    <tr>
                      <th> </th>
                      <th>Serial Number</th>
                      <th>Name</th>
                      <th>Model</th>
                      <th>Description</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody id="equipments-table">
                    {!loading ? (
                      equipments.map((item, index) => {
                        var variantData = "";
                        var newDateOptions = {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        };

                        if (item.status == "ส่งซ่อม") {
                          variantData = "warning dark";
                        } else if (item.status == "ยกเลิกใช้งาน") {
                          variantData = "danger dark";
                        } else if (item.status == "พร้อมใช้งาน") {
                          variantData = "success light";
                        }

                        var variantActivity = "";
                        if (
                          [
                            "ลงทะเบียน",
                            "คืนเครื่อง",
                            "ส่งทำความสะอาด",
                          ].includes(item["Activity"])
                        ) {
                          variantActivity = "success light";
                        } else if (
                          ["ยืมเครื่อง", "ส่งซ่อม"].includes(item["Activity"])
                        ) {
                          variantActivity = "info";
                        } else if (
                          ["ยกเลิกใช้งาน"].includes(item["Activity"])
                        ) {
                          variantActivity = "danger";
                        }
                        return (
                          <tr className="btn-reveal-trigger">
                            <td className="py-1">
                              {index + 1 + (currentPage - 1) * itemsPerPage}
                            </td>
                            <td className="py-2">
                              <Link to={"/equipment-page/" + item.id}>
                                {item.serialNumber}
                              </Link>
                            </td>
                            <td className="py-2">{item.deviceName}</td>

                            <td className="py-2">{item.model}</td>
                            <td className="py-2">{item.description}</td>

                            <td className="py-2">
                              <Badge variant={variantData}>{item.status}</Badge>
                            </td>

                            <td className="py-2 text-right">
                              <div className="d-flex">
                                <Link
                                  className="btn btn-info shadow btn-xs sharp mr-2"
                                  to="#"
                                  onClick={() => onEdit(item.id)}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Edit equipment details"
                                >
                                  <i className="fa fa-pencil"></i>
                                </Link>
                                <Link
                                  to="#"
                                  className="btn btn-danger shadow btn-xs sharp"
                                  onClick={() => onDelete(item.id)}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Delete equipment"
                                >
                                  <i className="fa fa-trash"></i>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <div class="media d-flex align-items-center">
                        <div class="spinner-border" role="status">
                          <span class="visually-hidden"></span>
                        </div>
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-between align-items-center mt-3">
                <div className="dataTables_info">
                  Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
                  {totalItems < currentPage * itemsPerPage
                    ? totalItems
                    : (currentPage + 1) * itemsPerPage}{" "}
                  of {totalItems} entries
                </div>
                <div className="dataTables_paginate paging_simple_numbers">
                  <Pagination
                    className="pagination-primary pagination-circle"
                    size="lg"
                  >
                    {/* <li className="page-item page-indicator " onClick={onPrev}>
                      <Link className="page-link" to="#">
                        <i className="la la-angle-left" />
                      </Link>
                    </li>
                    {numberPagination.map((number, i) => (
                      <Pagination.Item
                        className={currentPage === i ? "active" : ""}
                        disabled
                      >
                        {number}
                      </Pagination.Item>
                    ))}
                    <li className="page-item page-indicator" onClick={onNext}>
                      <Link className="page-link" to="#">
                        <i className="la la-angle-right" />
                      </Link>
                    </li> */}
                    <Pagination.Prev onClick={getPrev} disabled={isStart} />
                    {paginationItems}
                    <Pagination.Next onClick={getNext} disabled={isEnd} />
                  </Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EquipmentItem;

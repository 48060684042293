import React, { useState, Component, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Button } from "react-bootstrap";
import Calendar from "react-calendar";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";

//import Images:
import avatar from "../../../images/avatar/1.jpg";
import avatar1 from "../../../images/avatar/2.jpg";
import avatar2 from "../../../images/avatar/3.jpg";
import { useAuth } from "../../../context/AuthContext";
import { db, storage } from "../../../firebase";
import {
  setDoc,
  getDoc,
  updateDoc,
  doc,
  getDocs,
  collection,
  where,
  query,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import {
  notifyTopRight,
  notifyWarning,
  notifyError,
} from "../../components/toast/SetToast";
import Toast from "../../components/toast/SetToast";
import PageTitle from "../../layouts/PageTitle";

const PatientChart = loadable(() =>
  pMinDelay(import("../charts/doctor_details/PatientChart"), 1000)
);
const DoctorsAbility = loadable(() =>
  pMinDelay(import("../charts/doctor_details/DoctorsAbility"), 1000)
);

const Home = () => {
  const profileData = {
    firstname: null,
    lastname: null,
    username: null,
    email: null,
    node: [],
    position: null,
    photo: null,
    role: null,
  };

  const [isEditProfile, setEditProfile] = useState(false);
  const [isEditPassword, setEditPassword] = useState(false);
  const [isShowPassword, setShowPassword] = useState(false);

  const [profile, setProfile] = useState(profileData);
  const [fileUrl, setFileUrl] = useState(null);
  const [fileName, setFilename] = useState("Leave blank to keep the same");
  const [uuidfilename, setuuidFilename] = useState(null);
  const [nodesList, setNodesList] = useState([]);
  const {
    currentUser,
    updateUserPassword,
    updateUserEmail,
    updateUserdisplayName,
    updateUserPhoto,
  } = useAuth();
  const firstnameRef = useRef();
  const lastnameRef = useRef();
  const emailRef = useRef();
  const positionRef = useRef();
  const nodeRef = useRef();
  const roleRef = useRef();

  const passwordRef = useRef();
  const passwordConfirmRef = useRef();

  const selectedFileHandler = async (e) => {
    if (e.target.files[0] == null) return;
    //upload
    const tempfilename = `${uuidv4() + "-" + e.target.files[0].name}`;
    setuuidFilename(tempfilename);
    setFilename(e.target.files[0].name);
    const storageRef = ref(storage, `avatars/${tempfilename}`);
    await uploadBytes(storageRef, e.target.files[0])
      .then((sanpshot) => {
        console.log("File Uploaded!");
        getDownloadURL(sanpshot.ref).then((downloadURL) => {
          setFileUrl(downloadURL);
          console.log("File available at", downloadURL);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleOnCancel = async () => {
    if (uuidfilename == null) {
      setEditProfile(false);
      return;
    }
    const photoRef = ref(storage, `avatars/${uuidfilename}`);
    await deleteObject(photoRef)
      .then(async () => {
        console.log("File Deleted !");
        setFileUrl(null);
      })
      .catch((error) => {
        console.log(error);
      });
    setFilename("");
    setEditProfile(false);
  };

  const handleOnClick = async (e) => {
    e.preventDefault();

    const docRef = doc(db, "admins", currentUser.displayName);
    const docSnap = await getDoc(docRef);
    if (emailRef.current.value !== currentUser.email) {
      await updateUserEmail(emailRef.current.value);
    }

    updateUserPhoto(fileUrl);

    let update = {
      firstname: firstnameRef.current.value,
      lastname: lastnameRef.current.value,
      email: emailRef.current.value,
      node: nodeRef.current.value,
      position: positionRef.current.value,
    };

    // delete
    if (uuidfilename != null) {
      const photoRef = ref(storage, `avatars/${profile.photo}`);
      deleteObject(photoRef)
        .then(() => {
          console.log("File Deleted !");
          setuuidFilename(null);
          setFileUrl(null);
        })
        .catch((error) => {
          console.log(error);
        });
      update = { ...update, photo: uuidfilename };
    }

    if (docSnap.exists()) {
      await updateDoc(docRef, {
        ...update,
      });
    } else {
      await setDoc(docRef, {
        firstname: firstnameRef.current.value,
        lastname: lastnameRef.current.value,
        email: emailRef.current.value,
        node: nodeRef.current.value,
        position: positionRef.current.value,

        photo: uuidfilename,
      });
    }
    notifyTopRight("Update success");
    setEditProfile(false);

    return;
  };

  const handleOnPasswordSubmit = () => {
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      notifyError("Password do not match");
      return;
    }
    updateUserPassword(passwordRef.current.value)
      .then(() => notifyTopRight("Update password success"))
      .catch((err) => notifyError(err));
  };
  const getNodes = async () => {
    const nodes = await getDocs(collection(db, "nodes"));

    if (currentUser.roles === "super_admin") {
      const tempNodes = nodes.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setNodesList(tempNodes);
    } else {
      const adminRef = doc(db, "admins", currentUser.displayName);
      const currentAdmin = await getDoc(adminRef);

      if (currentAdmin.exists()) {
        let currentNodes = currentAdmin.data().node;

        // If currentNodes is a string, convert it into an array and update Firestore
        if (!Array.isArray(currentNodes)) {
          currentNodes = currentNodes ? [currentNodes] : []; // Convert string to array or set empty array if null/undefined

          // Update Firestore to store `node` as an array
          await updateDoc(adminRef, { node: currentNodes });
        }

        // Filter nodes based on the admin's node permissions
        const tempNodes = nodes.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        const userNodes = tempNodes.filter((doc) =>
          currentNodes.includes(doc.nodecode)
        );

        setNodesList(userNodes);
      }
    }
  };

  useEffect(async () => {
    await getNodes();
    const ref = doc(db, "admins", currentUser.displayName);
    const queryRef = query(
      collection(db, "admins"),
      where("email", "==", currentUser.email)
    );
    const docSnap = await getDocs(queryRef);
    docSnap.forEach((doc) => {
      setProfile(doc.data());
      console.log(doc.data());
    });
    // console.log("currentUser: ", currentUser);
    return;
  }, []);

  return (
    <React.Fragment>
      <Toast />
      <PageTitle activeMenu="Your Account" motherMenu="Admin" motherLink="/" />
      <div className="form-head d-flex mb-3 mb-lg-5 align-items-start">
        <Button
          onClick={() => setEditProfile(!isEditProfile)}
          className={isEditProfile ? "btn btn-blue" : "btn btn-dark"}
        >
          <i className="flaticon-381-clock mr-2"></i>Edit Profile
        </Button>
      </div>
      <div className="col-lg-13">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-xl-8">
                <div className="form-group row">
                  <label
                    className="col-lg-3 col-form-label"
                    htmlFor="val-username"
                  >
                    Name
                  </label>
                  {isEditProfile ? (
                    <div className="col-lg-4">
                      <input
                        type="text"
                        className="form-control"
                        id="val-username"
                        name="val-username"
                        placeholder="First Name"
                        ref={firstnameRef}
                        defaultValue={profile.firstname}
                      />
                    </div>
                  ) : (
                    <div className="col-lg-2">
                      <label className="mt-2">{profile.firstname}</label>
                    </div>
                  )}

                  {isEditProfile ? (
                    <div className="col-lg-4">
                      <input
                        type="text"
                        className="form-control"
                        id="val-username"
                        name="val-username"
                        placeholder="Last Name"
                        ref={lastnameRef}
                        defaultValue={profile.lastname}
                      />
                    </div>
                  ) : (
                    <div className="col-lg-2">
                      <label className="mt-2">{profile.lastname}</label>
                    </div>
                  )}
                </div>

                <div className="form-group row">
                  <label
                    className="col-lg-3 col-form-label"
                    htmlFor="val-username"
                  >
                    Email
                  </label>

                  {isEditProfile ? (
                    <div className="col-lg-6">
                      <input
                        type="text"
                        className="form-control"
                        id="val-username"
                        name="val-username"
                        placeholder="Email"
                        disabled
                        ref={emailRef}
                        defaultValue={profile.email}
                      />
                    </div>
                  ) : (
                    <div className="col-lg-4">
                      <label className="mt-2">{profile.email}</label>
                    </div>
                  )}
                </div>

                {isEditProfile ? (
                  <div className="form-group row">
                    <label
                      className="col-lg-3 col-form-label"
                      htmlFor="val-username"
                    >
                      Position
                    </label>
                    <div className="col-lg-3">
                      <select
                        className="form-control form-control-lg-2"
                        id="inlineFormCustomSelect"
                        ref={positionRef}
                        defaultValue={profile.position}
                      >
                        <option value="Administrator">Administrator</option>
                        <option value="SleepTechnician">
                          Sleep Technician
                        </option>
                        <option value="SleepPhysician">Sleep Physician</option>
                      </select>
                    </div>
                  </div>
                ) : (
                  <div className="form-group row">
                    <label
                      className="col-lg-3 col-form-label"
                      htmlFor="val-username"
                    >
                      Position
                    </label>
                    <div className="col-lg-4">
                      <label className="mt-2">Administrator</label>
                    </div>
                  </div>
                )}

                <div className="form-group row">
                  <label
                    className="col-lg-3 col-form-label"
                    htmlFor="val-username"
                  >
                    Node
                  </label>
                  <div className="col-lg-8">
                    {isEditProfile ? (
                      <select
                        className="form-control"
                        id="inlineFormCustomSelect"
                        ref={nodeRef}
                        defaultValue={profile.node} // Default multiple selected values
                        multiple
                        disabled // Prevents user from editing
                      >
                        {nodesList.map((doc) => (
                          <option key={doc.id} value={doc.nodecode}>
                            {doc.nodecode}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <div className="mt-2">
                        {Array.isArray(profile.node) ? (
                          profile.node.map((node, index) => (
                            <span
                              key={index}
                              className="badge badge-primary mr-2"
                            >
                              {node}
                            </span>
                          ))
                        ) : (
                          <label>{profile.node}</label>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    className="col-lg-3 col-form-label"
                    htmlFor="val-username"
                  >
                    Profile Image
                  </label>
                  {isEditProfile ? (
                    <div className="basic-form custom_file_input">
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">Upload</span>
                        </div>
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            onChange={selectedFileHandler}
                          />
                          <label className="custom-file-label">
                            {fileName}
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <label className="mt-2"></label>
                    </>
                  )}
                </div>

                {isEditProfile ? (
                  <div className="form-group row">
                    <label
                      className="col-lg-3 col-form-label"
                      htmlFor="val-username"
                    >
                      {/* Role */}
                    </label>
                    <div className="col-lg-8">
                      <button
                        type="submit"
                        className="btn btn-primary "
                        onClick={handleOnClick}
                      >
                        Submit
                      </button>
                      <button
                        type="submit"
                        className="btn btn-light ml-2 "
                        onClick={handleOnCancel}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="form-head d-flex mb-3 mb-lg-5 align-items-start">
        <Button
          onClick={() => setEditPassword(!isEditPassword)}
          className={isEditPassword ? "btn btn-blue" : "btn btn-dark"}
        >
          <i className="flaticon-381-clock mr-2"></i> Edit Password
        </Button>
      </div>
      <div className="col-lg-13">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-xl-8">
                {isEditPassword ? (
                  <>
                    <div className="form-group row">
                      <label
                        className="col-lg-3 col-form-label"
                        htmlFor="val-oldpassword"
                      >
                        New Password
                      </label>
                      <div className="col-lg-3">
                        <input
                          type="password"
                          className="form-control"
                          id="val-oldpassword"
                          name="val-oldpassword"
                          placeholder="Password"
                          ref={passwordRef}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        className="col-lg-3 col-form-label"
                        htmlFor="val-newpassword"
                      >
                        Confirm New Password
                      </label>
                      <div className="col-lg-3">
                        <input
                          type="password"
                          className="form-control"
                          id="val-newpassword"
                          name="val-newpassword"
                          placeholder="Confirm Password"
                          ref={passwordConfirmRef}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="form-group row">
                    <label
                      className="col-lg-3 col-form-label"
                      htmlFor="val-newpassword"
                    >
                      Password
                    </label>
                    <div className="col-lg-3">
                      <label className="mt-2">**********</label>
                      <i></i>
                    </div>
                  </div>
                )}
                {isEditPassword ? (
                  <div className="form-group row">
                    <label
                      className="col-lg-3 col-form-label"
                      htmlFor="val-username"
                    >
                      {/* Role */}
                    </label>
                    <div className="col-lg-8">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={handleOnPasswordSubmit}
                      >
                        Submit
                      </button>
                      <button
                        type="submit"
                        className="btn btn-light ml-2 "
                        onClick={() => setEditPassword(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Home;

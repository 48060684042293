import React from "react";
import { connect } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { db } from "../../../firebase";
import { getDocs, collection } from "firebase/firestore";
import { AuthContext } from "../../../context/AuthContext";
import {
  setTextFilter,
  sortByDate,
  setSearchByFilter,
  setSortByStatus,
  setSortByCustomer,
} from "../../../actions/filters";

export class ProductOrderListFilters extends React.Component {
  constructor(props) {
    super(props);
    this.cache = {};
  }

  state = {
    calendarFocused: null,
    searchByOption: "Name",
    sortByStatus: "ทั้งหมด",
    sortByCustomer: "ทั้งหมด",
    customerAllList: ["ทั้งหมด"],
  };
  static contextType = AuthContext;

  activitySelect = ["ทั้งหมด", "purchase", "maintenance", "cleaning", "repair"];
  searchBySelect = ["Name", "HN", "Serial Number", "Receipt No."];
  statusSelect = [
    "ทั้งหมด",
    "เสร็จสิ้น",
    "กำลังดำเนินการ",
    "รอเอกสาร",
    "ยกเลิก",
  ];

  componentDidMount() {
    console.log("Component did mount");
    this.fetchData();
  }

  fetchData = async () => {
    try {
      // Parallel fetching
      const [nodesDocs] = await Promise.all([getDocs(collection(db, "nodes"))]);

      let allCustomers = [];
      let allnodeIdDocs = [];
      let allNodes;

      const currentUserRole = this.context.currentUser.roles[0];
      const currentUserName = this.context.currentUser.displayName;

      // Fetch all customers and bookings in parallel using batch queries
      const processNodes = async (nodes) => {
        console.log("Processing nodes:", nodes);
        const nodeIds = nodes.map((nodeDoc) => nodeDoc.id);
        allnodeIdDocs.push(...nodeIds);

        // Check cache before querying Firestore
        const cachedNodes = nodeIds.filter((id) => this.cache[id]);
        const uncachedNodes = nodeIds.filter((id) => !this.cache[id]);

        let cachedCustomers = [];
        let cachedBookings = [];

        // Use cached data if available
        cachedNodes.forEach((nodeId) => {
          cachedCustomers.push(this.cache[nodeId].customers);
        });

        // Fetch only uncached data
        if (uncachedNodes.length > 0) {
          const customerPromises = uncachedNodes.map((id) =>
            getDocs(collection(db, "nodes", id, "customers"))
          );

          const [customerSnapshots] = await Promise.all([
            Promise.all(customerPromises),
          ]);

          customerSnapshots.forEach((snapshot, index) => {
            const customersData = snapshot.docs.map((customer) => customer.id);
            cachedCustomers.push(customersData);

            // Cache the data
            this.cache[uncachedNodes[index]] = {
              customers: customersData,
            };
          });
        }

        const flatCustomerList = cachedCustomers.flat();
        allCustomers.push(...flatCustomerList);
      };

      if (["super_admin", "sale"].includes(currentUserRole)) {
        await processNodes(nodesDocs.docs);
      }
      console.log("allCustomers", allCustomers);
      const uniqueCustomers = ["ทั้งหมด", ...new Set(allCustomers)];

      this.setState(
        {
          customerAllList: uniqueCustomers,
        },
        this.cacheState
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  onTextChange = (e) => {
    this.props.setTextFilter(e.target.value);
  };

  onSearchByChange = (option) => {
    if (option === "HN") {
      this.setState({ searchByOption: option });
      this.props.setSearchByFilter("hn");
    } else if (option === "Serial Number") {
      this.setState({ searchByOption: option });
      this.props.setSearchByFilter("productSn");
    } else if (option === "Receipt No.") {
      this.setState({ searchByOption: option });
      this.props.setSearchByFilter("receiptNo");
    } else if (option === "Name") {
      this.setState({ searchByOption: option });
      this.props.setSearchByFilter("firstname");
    }
  };

  onStatusChange = (option) => {
    if (option === "ทั้งหมด") {
      this.setState({ sortByStatus: option });
      this.props.setSortByStatus("ทั้งหมด");
    } else if (option === "เสร็จสิ้น") {
      this.setState({ sortByStatus: option });
      this.props.setSortByStatus("เสร็จสิ้น");
    } else if (option === "กำลังดำเนินการ") {
      this.setState({ sortByStatus: option });
      this.props.setSortByStatus("กำลังดำเนินการ");
    } else if (option === "รอเอกสาร") {
      this.setState({ sortByStatus: option });
      this.props.setSortByStatus("รอเอกสาร");
    } else if (option === "ยกเลิก") {
      this.setState({ sortByStatus: option });
      this.props.setSortByStatus("ยกเลิก");
    }
  };

  onCustomerChange = (option) => {
    if (option === "ทั้งหมด") {
      this.setState({ sortByCustomer: option });
      this.props.setSortByCustomer("ทั้งหมด");
    } else {
      this.setState({ sortByCustomer: option });
      this.props.setSortByCustomer(option);
    }
  }

  render() {
    return (
      <div className="form-head d-flex mb-3 mb-lg-5 align-items-start">
        <div className="input-group search-area ml-auto d-inline-flex">
          <input
            type="text"
            className="form-control"
            placeholder="Search Here"
            value={this.props.filters.text}
            onChange={this.onTextChange}
          />
          <div className="input-group-append">
            <span className="input-group-text c-pointer">
              <i className="flaticon-381-search-2"></i>
            </span>
          </div>
        </div>
        <Dropdown className="ml-3 width200" onSelect={this.onSearchByChange}>
          <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
            <i className="flaticon-381-controls-3 "></i> by{" "}
            {this.state.searchByOption}
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu-right">
            {this.searchBySelect.map((element, index) => (
              <Dropdown.Item href="#" eventKey={element} key={index}>
                {element}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown className="width200" onSelect={this.onStatusChange}>
          <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
            <i className="flaticon-381-controls-3 "></i> Status{" "}
            {this.state.sortByStatus}
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu-right">
            {this.statusSelect.map((element, index) => (
              <Dropdown.Item href="#" eventKey={element} key={index}>
                {element}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown onSelect={this.onCustomerChange}>
          <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
            <i className="flaticon-381-controls-3 "></i> Customer{" "}
            {this.state.sortByCustomer}
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu-right" style={{ maxHeight: "300px", overflowY: "auto" }}>
            {this.state.customerAllList.map((element, index) => (
              <Dropdown.Item href="#" eventKey={element} key={index}>
                {element}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  filters: state.filters,
});

const mapDispatchToProps = (dispatch) => ({
  setTextFilter: (text) => dispatch(setTextFilter(text)),
  sortByDate: () => dispatch(sortByDate()),
  setSearchByFilter: (searchBy) => dispatch(setSearchByFilter(searchBy)),
  setSortByStatus: (sortByStatus) => dispatch(setSortByStatus(sortByStatus)), // Updated this line
  setSortByCustomer: (sortByCustomer) =>
    dispatch(setSortByCustomer(sortByCustomer)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductOrderListFilters);

import React, { Fragment, useState, useRef, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import { Formik } from "formik";
import * as Yup from "yup";
import { useAuth, currentUser } from "../../../context/AuthContext";
import { db } from "../../../firebase";
import { setDoc, getDoc, updateDoc, doc } from "firebase/firestore";
import {
  TextInputForm,
  TextInputNotRequiredForm,
  DropDownForm,
} from "./CpapUtils";
import {
  notifyTopRight,
  notifyWarning,
  notifyError,
} from "../../components/toast/SetToast";
import Toast from "../../components/toast/SetToast";
import { Badge, Button } from "react-bootstrap";

import {
  CpapRegisterData,
  CpapRegisterChoices,
} from "./formdata/CpapRegisterData";
import CpapDetail from "../CPAP/cpapPage/CpapDetail";
import { Row, Col, Card, Tabs, Tab, Nav } from "react-bootstrap";
import CpapBorrowingSet from "./cpapPage/CpapBorrowing";
import CpapActivity from "./cpapPage/CpapActivity";

const CpapPage = () => {
  //   const { signup, currentUser } = useAuth();
  const history = useHistory();

  const { id } = useParams();
  const [CpapInfo, setCpapInfo] = useState(CpapRegisterData);
  const [isLoading, setIsLoading] = useState(true);
  const [stateEdit, setStateEdit] = useState(true);

  const CpapRef = useRef(CpapRegisterData);
  const Choices = CpapRegisterChoices;
  const [showCpapStatus, setShowCpapStatus] = useState("");
  const [showPage, setShowPage] = useState(0);

  const getStatus = async (status, page) => {
    try {
      setIsLoading(true);
      setShowCpapStatus(status);
      setShowPage(page);
      const ref = doc(db, "cpaps", id);
      const docSnap = await getDoc(ref);
      const data = docSnap.data();
      if (data) {
        setCpapInfo(data);
      } else {
        console.warn("No CPAP data found for ID:", id);
      }
    } catch (err) {
      console.error("Failed to fetch CPAP document:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const tabData = [
    {
      name: "Detail",
      icon: "user",
      content: function (props, setState) {
        return <CpapDetail cpapinfo={props} setShow={setState} />;
      },
    },
    {
      name: "Borrowing",
      icon: "clipboard",
      content: function (props, setState) {
        return <CpapBorrowingSet cpapinfo={props} setShow={setState} />;
      },
    },
    {
      name: "Activity",
      icon: "clipboard",
      content: function (props, setState) {
        return <CpapActivity cpapinfo={props} setShow={setState} />;
      },
    },
  ];
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const ref = doc(db, "cpaps", id);
        const docSnap = await getDoc(ref);
        setCpapInfo(docSnap.data());
      } catch (error) {
        console.log("Cannot find cpap id", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <PageTitle
        activeMenu={CpapInfo.SerialNumber}
        motherMenu="CPAP"
        motherLink="/cpap-list"
      />
      {isLoading ? (
        <></>
      ) : (
        <Card>
          <Card.Header>
            <Card.Title>CPAP Information</Card.Title>
            <Card.Title>
              <Badge variant="success light">{showCpapStatus}</Badge>
            </Card.Title>
          </Card.Header>
          <Card.Body>
            {/* <!-- Nav tabs --> */}
            <div className="default-tab">
              <Tab.Container
                defaultActiveKey={tabData[showPage].name.toLowerCase()}
              >
                <Nav as="ul" className="nav-tabs">
                  {tabData.map((data, i) => (
                    <Nav.Item as="li" key={i}>
                      <Nav.Link eventKey={data.name.toLowerCase()}>
                        <i className={`la la-${data.icon} mr-2`} />
                        {data.name}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
                <Tab.Content className="pt-4">
                  {tabData.map((data, i) => (
                    <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                      {data.content(CpapInfo, getStatus)}
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Tab.Container>
            </div>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default CpapPage;

import { useEffect, useState, useRef } from "react";
import { db } from "../firebase";
import {
  setDoc,
  doc,
  serverTimestamp,
  collection,
  updateDoc,
  getDoc,
  getDocs,
  where,
  query,
  onSnapshot,
  orderBy,
  WhereFilterOp,
  OrderByDirection,
  DocumentData,
  collectionGroup,
  Query,
} from "firebase/firestore";

export const useCollection = (col, _orderBy, _query) => {
  const [documents, setDocuments] = useState([]);
  const [error, setError] = useState(null);
  const queryRef = useRef(undefined);
  const q = useRef(_query).current;
  const o = useRef(_orderBy).current;

  let queryConstraints = [];

  useEffect(() => {
    let ref = collection(db, col);

    if (q) {
      queryConstraints.push(where(...q));
    }
    if (o) {
      queryConstraints.push(orderBy(...o));
    }

    const queryRef = query(ref, ...queryConstraints);
    const unsubscribe = onSnapshot(
      queryRef,
      (snapshot) => {
        const results = [];
        snapshot.forEach((doc) => {
          // console.log(doc);
          results.push({ ...doc.data(), id: doc.id });
        });

        // update state
        setDocuments(results);
        setError(null);
      },
      (error) => {
        console.log(error);
        setError("could not fetch the data");
      }
    );

    // unsubscribe on unmount
    return () => unsubscribe();
  }, [col, q, o]);
  return { documents, error };
};

import React, { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { EquipmentForm } from "./EquipmentForm";
import EquipmentItem from "./EquipmentItem";
import { db, storage } from "../../../firebase";

import {
  orderBy,
  where,
  query,
  collection,
  and,
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import {
  ref,
  getDownloadURL,
  deleteObject,
  listAll,
  uploadBytes,
} from "firebase/storage";
import { Button, Modal } from "react-bootstrap";
import { useAuth } from "../../../context/AuthContext";
import PageTitle from "../../layouts/PageTitle";
import { functions } from "../../../firebase";
import { httpsCallable } from "firebase/functions";
import { usePagination } from "../../../hook/usePagination";
import { useCollection } from "../../../hook/useCollection";
import TicketItem from "./TicketItem";
import { TicketForm } from "./TicketForm";

const TicketList = (props) => {
  const getSignedUrl = httpsCallable(functions, "getSignedUrl");
  const { currentUser } = useAuth();
  const [isLoding, setIsLoading] = useState(false);
  const [isImageLoding, setIsImageLoding] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [edit, setEdit] = useState({});
  const [searchItem, setSearchItem] = useState("");
  const [tickets, setTickets] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileNames, setFilenames] = useState([]);

  const searchRef = useRef(null);
  const textRef = useRef(null);

  const inputRef = useRef({
    ticketType: null,
    problem: null,
    image: null,
    ticketStatus: null,
    dueDate: null,
    requestDate: null,
    dueDate: null,
  });

  const { documents, error } = useCollection("equipments", [
    "serialNumber",
    "asc",
  ]);

  const itemPerPage = 50;

  let QueryFilterConstraint = [];
  let queryRef = query(
    collection(db, "tickets"),
    orderBy("ticketStatus", "desc"),
    orderBy("createdAt", "desc")
  );

  if (searchItem !== "") {
    QueryFilterConstraint.push(
      where("sn", ">=", searchItem),
      where("sn", "<=", searchItem + "\uf8ff")
    );

    queryRef = query(
      collection(db, "tickets"),
      and(...QueryFilterConstraint),
      orderBy("ticketStatus", "desc"),
      orderBy("createdAt", "desc")
    );
  }

  const {
    items: ticketsList,
    // isLoading,
    isStart,
    isEnd,
    getPrev,
    getNext,
    totalItems,
    totalPages,
    currentPage,
    goToPage,
  } = usePagination(queryRef, { limit: itemPerPage });

  const toggle = () => {
    setShowModal(!showModal);
  };

  const handleChange = (name) => (e) => {
    if (name === "image") {
      for (let i = 0; i < e.target.files.length; i++) {
        const f = e.target.files[i];
        setSelectedFiles((prevState) => [...prevState, f]);
        setFilenames((prevState) => [...prevState, f.name]);
      }
    }
    inputRef.current[name] = e.target.value;
  };

  const handleOnEdit = async (id) => {
    const data = ticketsList.find((item) => item.id === id);

    // const ref = collection(db, `equipments/${data.id}`);
    // let queryHandler = query(ref, where("title", "==", "CPAP Image"));

    // console.log("edit equipment data", data);

    setEdit(data);
    setShowModal(true);
  };

  const handleOnHide = (sn) => {
    setShowModal(false);
  };

  const handleOnDelete = (id, image) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        await deleteDoc(doc(db, "tickets", id)).then(() => {
          //delete file
          const storageRef = ref(storage, `ticketImage/${id}`);
          listAll(storageRef).then((res) => {
            const removeRef = res.items.filter((itemRef) =>
              image.includes(itemRef.name)
            );
            removeRef.forEach((res) => {
              const storageRef = ref(storage, `ticketImage/${id}/${res.name}`);
              deleteObject(storageRef, res.name);
            });
          });
          swal(`Poof! Your file has been deleted!`, {
            icon: "success",
          });
        });
      } else {
        swal("Canceled");
      }
    });
  };

  const onSearchChange = () => {
    setSearchItem(searchRef.current.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const docRef = doc(db, "tickets", edit.id);
    const docSnap = await getDoc(docRef);
    let formData = {};
    if (docSnap.exists()) {
      formData = {
        ticketType: inputRef.current.ticketType
          ? inputRef.current.ticketType
          : edit.ticketType,
        problem: inputRef.current.problem
          ? inputRef.current.problem
          : edit.problem,
        // image: inputRef.current.image ? inputRef.current.image : edit.image,
        image: fileNames ? fileNames : edit.image,
        ticketStatus: inputRef.current.ticketStatus
          ? inputRef.current.ticketStatus
          : edit.ticketStatus,
        requestDate: inputRef.current.requestDate
          ? inputRef.current.requestDate
          : edit.requestDate,
        dueDate: inputRef.current.dueDate
          ? inputRef.current.dueDate
          : edit.dueDate,
        repairDetail: textRef.current.value,
        updatedAt: new Date(),
        updatedBy: currentUser.displayName,
      };
      // console.log(formData);
    }

    if (inputRef.current.ticketStatus === "ดำเนินการเสร็จสิ้น") {
      //update equiment status : ส่งซ่อม
      const eqRef = doc(db, "equipments", edit.equipmentId);
      await updateDoc(eqRef, { status: "พร้อมใช้งาน" });
    }

    await updateDoc(docRef, formData);

    if (fileNames.length > 0) {
      //upload image
      const promises = [];
      selectedFiles.map((file) => {
        const storageRef = ref(storage, `ticketImage/${edit.id}/${file.name}`);
        const uploadTask = uploadBytes(storageRef, file);
        promises.push(uploadTask);
      });
      Promise.all(promises);
    }

    handleOnHide();
  };

  const handleOnViewFile = (id, file) => {
    setIsImageLoding(true);
    getDownloadURL(ref(storage, `ticketImage/${id}/${file}`)).then((url) => {
      setFileUrl(url);
      // console.log(url);
    });

    setIsImageLoding(false);
    setCreateModal(true);
  };

  const handleOnHideModal = () => {
    setCreateModal(false);
    setFileUrl(null);
  };

  return (
    <Fragment>
      <PageTitle activeMenu="Ticket List" motherMenu="Tickets" />

      <Modal
        className="fade bd-example-modal-lg"
        show={showModal}
        onHide={toggle}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Edit Ticket Datails</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TicketForm
            onChange={handleChange}
            initailValues={edit}
            equipments={documents
              .filter((item) => item.status !== "พร้อมใช้งาน" || "ส่งซ่อม")
              .map((item) => item.serialNumber)}
            fileNames={fileNames}
            defaultfiles={edit.image}
            ticketId={edit.ticketId}
            onViewFile={handleOnViewFile}
          />

          <div className="form-group row m-1">
            <label className="col-lg-4 col-form-label">รายละเอียดการซ่อม</label>
            <div className="col-lg-6">
              <textarea
                type="textarea"
                className="form-control"
                rows={"3"}
                required
                ref={textRef}
                defaultValue={edit.repairDetail}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
        <div className="d-flex justify-content-center mb-3">
          <Button className="sm" onClick={handleSubmit}>
            Save
          </Button>

          <Button className="sm ml-2" variant="light" onClick={handleOnHide}>
            Cancel
          </Button>
        </div>
      </Modal>

      {!isImageLoding && (
        <Modal
          className="fade bd-example-modal-lg"
          show={createModal}
          onHide={handleOnHideModal}
          size="xl"
          backdrop={true} // Change from "static" to true
          keyboard={true} // Change from false to true
          dialogClassName="modal-90w" // Add this line
        >
          <Modal.Body className="m-0 p-0">
            <div
              className="card-body mx-0 p-0 col-sm-12"
              style={{ height: "80vh" }}
            >
              {fileUrl && (
                <img
                  src={fileUrl}
                  alt="Ticket Image"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain", // Add this line
                  }}
                />
              )}
            </div>
          </Modal.Body>
        </Modal>
      )}
      <div className="form-head d-flex mb-3 mb-lg-5 align-items-start">
        <Link
          to="/ticket-create"
          className="btn btn-danger"
          data-toggle="tooltip"
          data-placement="top"
          title="Create Ticket"
        >
          + New Ticket
        </Link>
        <div className="input-group search-area ml-auto d-inline-flex">
          <input
            type="text"
            className="form-control"
            placeholder="Search serial number here"
            ref={searchRef}
            onChange={onSearchChange}
          />
          <div className="input-group-append">
            <span className="input-group-text c-pointer">
              <i className="flaticon-381-search-2"></i>
            </span>
          </div>
        </div>
      </div>
      {/* <p className="text-danger">Under Testing</p> */}

      <TicketItem
        tickets={ticketsList}
        onEdit={handleOnEdit}
        onViewFile={handleOnViewFile}
        onDelete={handleOnDelete}
        // onNext={!isEnd && getNext}
        // onPrev={!isStart && getPrev}
        totalItems={totalItems}
        totalPages={totalPages}
        currentPage={currentPage}
        itemsPerPage={itemPerPage}
        goToPage={goToPage} //pass goToPage
        isStart={isStart} //pass isStart
        isEnd={isEnd} //pass isEnd
        getNext={getNext} //pass getNext
        getPrev={getPrev} //pass getPrev
      />
    </Fragment>
  );
};

export default TicketList;

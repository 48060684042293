import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { db, storage } from "../../../firebase";
import { Button, Modal } from "react-bootstrap";
import {
  doc,
  onSnapshot,
  getDocs,
  getDoc,
  orderBy,
  limit,
  where,
  query,
  collection,
  and,
  or,
  setDoc,
  updateDoc,
  collectionGroup,
} from "firebase/firestore";
import {
  ref,
  uploadBytes,
  deleteObject,
  listAll,
  getDownloadURL,
} from "firebase/storage";

import swal from "sweetalert";
import OrderDetailModal from "./OrderDetailModal";
import selectOrders from "../../../selectors/orders";
import {
  startSetOrders,
  startEditOrder,
  startRemoveOrder,
  startSetNextOrders,
  startSetPrevOrders,
} from "../../../actions/orders";
import { startEditNote } from "../../../actions/notes";
import { startEditCpap, startSetCpaps } from "../../../actions/cpaps";
import ProductOrderItem from "./ProductOrderItem";
import ProductOrderListFilters from "./ProductOrderListFilters";
import PageTitle from "../../layouts/PageTitle";
import fontkit from "@pdf-lib/fontkit";
import { PDFDocument } from "pdf-lib";
import { usePagination } from "../../../hook/usePagination";
import { useAuth } from "../../../context/AuthContext";

const ProductOrderList = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [mailModal, setMailModal] = useState(false);
  const [orderList, setOrderList] = useState();
  const [edit, setEdit] = useState({});
  const [download, setDownload] = useState({});
  const [loading, setLoading] = useState(true);
  const [emailList, setEmailList] = useState([]);
  const [selectEmail, setSelectEmail] = useState("");
  const [emailLoading, setEmailLoading] = useState(true);
  const [dataToSend, setDataToSend] = useState({});
  const { currentUser } = useAuth();

  const toggle = () => {
    setMailModal(!mailModal);
  };

  const itemPerPage = 50;

  let QueryFilterConstraint = [];
  let queryRef = query(collection(db, "orders"), orderBy("createdAt", "desc"));

  if (props.filters.text !== "") {
    QueryFilterConstraint.push(
      where(props.filters.searchBy, ">=", props.filters.text),
      where(props.filters.searchBy, "<=", props.filters.text + "\uf8ff")
    );

    queryRef = query(
      collection(db, "orders"),
      and(...QueryFilterConstraint),
      orderBy("createdAt", "desc")
    );
  }

  if (props.filters.sortByStatus !== "ทั้งหมด") {
    QueryFilterConstraint.push(
      where("status", "==", props.filters.sortByStatus)
    );
    queryRef = query(
      collection(db, "orders"),
      and(...QueryFilterConstraint),

      orderBy("createdAt", "desc")
    );

    console.log(">>>>",props)
  }

  if (props.filters.sortByCustomer !== "ทั้งหมด") {
    QueryFilterConstraint.push(
      where("refCustomer", "==", props.filters.sortByCustomer)
    );
    queryRef = query(
      collection(db, "orders"),
      and(...QueryFilterConstraint),
      orderBy("createdAt", "desc")
    );
  }


  const {
    items: orders,
    isLoading,
    isStart,
    isEnd,
    getPrev,
    getNext,
    totalItems,
    totalPages,
    currentPage,
    goToPage,
  } = usePagination(queryRef, { limit: itemPerPage });

  const getNationalIdCard = async (hn) => {};

  const handleOnEdit = async (id) => {
    const data = orders.find((c) => c.id === id);

    const ref = collectionGroup(db, "note");
    let queryHandler = query(
      ref,
      where("hn", "==", data.hn),
      where("title", "==", "National ID Card")
    );

    const docSnap = await getDocs(queryHandler);

    const filename = [];
    let noteid = "";
    docSnap.forEach((item) => {
      console.log(item.id);
      noteid = item.id;
      item.data().filename.map((item) => filename.push(item));
    });
    console.log("filename", filename, noteid);
    setEdit({ ...data, filename, noteid });
    setShowModal(true);
  };

  const handleOnHide = (sn) => {
    setShowModal(false);
  };

  const handleOnDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once order was cancelled, the record will be deleted. The product or item will be detached from order and avaliable to sale again",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        props.startRemoveOrder({ id });
        swal(`Poof! Your order has been cancelled!`, {
          icon: "success",
        });
      } else {
        swal("Canceled");
      }
    });
  };

  const handleOnDownload = async (id) => {
    const data = orders.find((c) => c.id === id);
    // console.log(data);

    //get cpap information
    const cpapRef = doc(db, "products", data.productId);
    const cpapInfo = await getDoc(cpapRef);
    if (cpapInfo.exists()) {
      console.log("cpap data");
    } else {
      console.log("No cpap document!");
    }

    //get patient information
    const patientRef = doc(db, "patients", data.hn);
    const patientInfo = await getDoc(patientRef);
    if (patientInfo.exists()) {
      console.log("patient data");
    } else {
      console.log("No patient document!");
    }

    //last sleep test scheduled activity
    const orderRef = query(
      collection(db, "activities"),
      where("hn", "==", data.hn),
      where("activity", "==", "Sleep Test Scheduled"),
      orderBy("createdAt", "desc"),
      limit(1)
    );

    const temp = [];
    const orderSnap = await getDocs(orderRef);

    orderSnap.forEach((doc) => {
      const text = {
        actId: doc.id,
        id: doc.data().id,
        order: doc.data().order,
        createDate: doc.data().createdAt,
        title: doc.data().title,
        activity: doc.data().activity,
        infoDate1: doc.data().startDate,
        infoDate2: doc.data().dueDate,
        info1: doc.data().detail1,
        info2: doc.data().detail2,
      };
      temp.push({ ...text });
    });

    //Apex
    let url1 =
      "https://firebasestorage.googleapis.com/v0/b/braindynamics-197809/o/templates%2Freceipts%2FF-930-001-C%20CPAP%20Receipt%20-%20Apex.pdf?alt=media&token=743609ee-6e52-4c4d-93d7-4c8a6308d37f";
    let namePosition = 90;

    if (data.salePackage === "VentMed Standard") {
      //VentMed Standard
      url1 =
        "https://firebasestorage.googleapis.com/v0/b/braindynamics-197809/o/templates%2Freceipts%2FF-930-001-C%20CPAP%20Receipt%20-%20VentMed%20-%20Standard.pdf?alt=media&token=bdfb0174-70ae-429e-a89e-dd292a5a9642";
      namePosition = 174;
    } else if (data.salePackage === "VentMed Premium") {
      //VentMed Premium
      url1 =
        "https://firebasestorage.googleapis.com/v0/b/braindynamics-197809/o/templates%2Freceipts%2FF-930-001-C%20CPAP%20Receipt%20-%20VentMed%20-%20Premium.pdf?alt=media&token=16789d03-22d9-4c6e-9a1b-ebe057671257";
      namePosition = 120;
    } else if (
      data.salePackage === "ResMed" ||
      data.salePackage === "ResMed - AirStart" ||
      data.salePackage === "ResMed - AirSense10 AutoSet / AutoSet FH"
    ) {
      //ResMed
      url1 =
        "https://firebasestorage.googleapis.com/v0/b/braindynamics-197809/o/templates%2Freceipts%2FF-930-001-C%20CPAP%20Receipt%20-%20ResMed.pdf?alt=media&token=70a8524a-2eac-4f50-8c7d-23b4f01fceda";
      namePosition = 120;
    } else if (data.salePackage === "ResMed - Airmini") {
      //ResMed - Airmini
      url1 =
        "https://firebasestorage.googleapis.com/v0/b/braindynamics-197809/o/templates%2Freceipts%2FF-930-001-C%20CPAP%20Receipt%20-%20ResMed%20AirMini.pdf?alt=media&token=35114b6a-ee86-4a2e-84d8-6fe2ef58757c";
      namePosition = 80;
    } else if (data.salePackage === "Topson Standard") {
      //Topson Standard
      url1 =
        "https://firebasestorage.googleapis.com/v0/b/braindynamics-197809/o/templates%2Freceipts%2FF-930-001-C%20CPAP%20Receipt%20-%20TopSon%20-%20Standard.pdf?alt=media&token=89401305-36c6-4069-8757-97aba6b1376a";
      namePosition = 174;
    } else if (data.salePackage === "Topson Plus") {
      //Topson Plus
      url1 =
        "https://firebasestorage.googleapis.com/v0/b/braindynamics-197809/o/templates%2Freceipts%2FF-930-001-C%20CPAP%20Receipt%20-%20TopSon%20-%20WithHumid.pdf?alt=media&token=a29fffe9-98b5-4532-b464-85f08097a40e";
      namePosition = 174;
    }

    const existingPdfBytes1 = await fetch(url1).then((res) =>
      res.arrayBuffer()
    );

    const url2 =
      "https://firebasestorage.googleapis.com/v0/b/braindynamics-197809/o/templates%2Fwarranty%2FF-501-011-B%20Warranty%20Form.pdf?alt=media&token=5fd8b8f6-ca65-40d9-b222-c034dd29ee27";
    const existingPdfBytes2 = await fetch(url2).then((res) =>
      res.arrayBuffer()
    );

    const fonturl = "./assets/fonts/Sarabun-Medium.ttf";
    const fontBytes = await fetch(fonturl).then((res) => res.arrayBuffer());

    // Load a PDFDocument from the existing PDF bytes
    const pdfDoc1 = await PDFDocument.load(existingPdfBytes1);
    const pdfDoc2 = await PDFDocument.load(existingPdfBytes2);

    const pdfDoc = await PDFDocument.create();

    // Register the `fontkit` instance
    pdfDoc.registerFontkit(fontkit);
    const customFont = await pdfDoc.embedFont(fontBytes, { subset: true });

    const [page1] = await pdfDoc.copyPages(pdfDoc1, [0]);
    const [page2] = await pdfDoc.copyPages(pdfDoc2, [0]);

    pdfDoc.addPage(page1);
    pdfDoc.insertPage(1, page2);

    // Get the first page of the document
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    const secondPage = pages[1];
    // Get the width and height of the first page
    const { width, height } = firstPage.getSize();

    // Draw a string of text diagonally across the first page
    firstPage.drawText(`${data.hn}`, {
      x: 180,
      y: height / 2 + 285,
      size: 10,
      font: customFont,
    });

    if (!orderSnap.empty) {
      firstPage.drawText(`${temp[0].order}`, {
        x: 300,
        y: height / 2 + 285,
        size: 10,
        font: customFont,
      });
    }

    firstPage.drawText(`${data.firstname} ${data.lastname}`, {
      x: 180,
      y: height / 2 + 265,
      size: 10,
      font: customFont,
    });
    firstPage.drawText(`${patientInfo.data().mobile}`, {
      x: 180,
      y: height / 2 + 247,
      size: 10,
      font: customFont,
    });
    firstPage.drawText(`${patientInfo.data().address1}`, {
      x: 180,
      y: height / 2 + 228,
      size: 10,
      font: customFont,
    });
    firstPage.drawText(`${cpapInfo.data().brand}`, {
      x: 180,
      y: height / 2 + 160,
      size: 10,
      font: customFont,
    });
    firstPage.drawText(`${cpapInfo.data().model}`, {
      x: 180,
      y: height / 2 + 142,
      size: 10,
      font: customFont,
    });

    firstPage.drawText(`${data.productSn}`, {
      x: 180,
      y: height / 2 + 125,
      size: 10,
      font: customFont,
    });

    firstPage.drawText(`Device No.  ${cpapInfo.data().deviceNo}`, {
      x: 350,
      y: height / 2 + 125,
      size: 10,
      font: customFont,
    });

    firstPage.drawText(`${data.mask}`, {
      x: 220,
      y: height / 2 + 107,
      size: 10,
      font: customFont,
    });

    firstPage.drawText(`Size ${data.maskSize}`, {
      x: 350,
      y: height / 2 + 107,
      size: 10,
      font: customFont,
    });

    firstPage.drawText(`${data.firstname} ${data.lastname}`, {
      x: 90,
      y: namePosition,
      size: 10,
      font: customFont,
    });

    secondPage.drawText(`${cpapInfo.data().brand}`, {
      x: 217,
      y: height / 2 + 198,
      size: 10,
      font: customFont,
    });
    secondPage.drawText(`${cpapInfo.data().model}`, {
      x: 217,
      y: height / 2 + 177,
      size: 10,
      font: customFont,
    });
    secondPage.drawText(`${data.productSn}`, {
      x: 217,
      y: height / 2 + 158,
      size: 10,
      font: customFont,
    });
    secondPage.drawText(`Device No.  ${cpapInfo.data().deviceNo}`, {
      x: 350,
      y: height / 2 + 158,
      size: 10,
      font: customFont,
    });
    secondPage.drawText(`${data.receiptNo}`, {
      x: 217,
      y: height / 2 + 138,
      size: 10,
      font: customFont,
    });
    secondPage.drawText(
      `${data.receiptDate.toDate().toLocaleString("th-TH", {
        year: "numeric",
        month: "short",
        day: "numeric",
      })}`,
      {
        x: 217,
        y: height / 2 + 118,
        size: 10,
        font: customFont,
      }
    );
    secondPage.drawText(
      `${data.warrantyEndDate.toDate().toLocaleString("th-TH", {
        year: "numeric",
        month: "short",
        day: "numeric",
      })}`,
      {
        x: 420,
        y: height / 2 + 118,
        size: 10,
        font: customFont,
      }
    );

    secondPage.drawText(`${data.firstname} ${data.lastname}`, {
      x: 217,
      y: 280,
      size: 10,
      font: customFont,
    });
    secondPage.drawText(`${patientInfo.data().address1}`, {
      x: 217,
      y: 260,
      size: 10,
      font: customFont,
    });
    secondPage.drawText(`${patientInfo.data().mobile}`, {
      x: 217,
      y: 240,
      size: 10,
      font: customFont,
    });
    secondPage.drawText(`${data.firstname} ${data.lastname}`, {
      x: 146,
      y: 180,
      size: 10,
      font: customFont,
    });

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfDocBytes = await pdfDoc.save();

    const pdfUrl = URL.createObjectURL(
      new Blob([await pdfDoc.save()], { type: "application/pdf" })
    );
    window.open(pdfUrl, "_blank");
  };

  const handleOnNextPage = () => {
    setLoading(true);
    props.startSetNextOrders({
      text: props.filters.text,
      searchBy: props.filters.searchBy,
      sortByStatus: props.filters.sortByStatus,
      limitPage: itemPerPage,
      latestPage: props.pages.latestPage,
    });
    setLoading(false);
    // console.log("next page");
  };
  const handleOnPrevPage = () => {
    setLoading(true);
    props.startSetPrevOrders({
      text: props.filters.text,
      searchBy: props.filters.searchBy,
      sortByStatus: props.filters.sortByStatus,
      limitPage: itemPerPage,
      firstPage: props.pages.firstPage,
    });
    setLoading(false);
  };

  const handleOnSendMail = async (id) => {
    const data = orders.find((c) => c.id === id);

    //get cpap information
    const cpapRef = doc(db, "products", data.productId);
    const cpapInfo = await getDoc(cpapRef);
    if (cpapInfo.exists()) {
      console.log("cpap data");
    } else {
      console.log("No cpap document!");
    }

    //get patient information
    const patientRef = doc(db, "patients", data.hn);
    const patientInfo = await getDoc(patientRef);
    if (patientInfo.exists()) {
      console.log("patient data");
    } else {
      console.log("No patient document!");
    }

    setDataToSend({
      ...data,
      brand: cpapInfo.data().brand,
      model: cpapInfo.data().model,
      nationalId: patientInfo.data().nationalId,
      address1: patientInfo.data().address1,
      address2: patientInfo.data().address2,
      province: patientInfo.data().province,
      postcode: patientInfo.data().postcode,
      healthInsurance: patientInfo.data().healthInsurance,
      mobile: patientInfo.data().mobile,
    });
    toggle();
  };

  const handleSendSubmit = async (isAll) => {
    const text = {
      to: `${isAll ? emailList : selectEmail}`,
      message: {
        subject: `Delivery ${dataToSend.receiptNo} - ${dataToSend.firstname} ${dataToSend.lastname}`,
        html: `
        <h4>
        <strong>Delivery No</strong>: ${dataToSend.receiptNo}<br/>
        <strong>Document Date</strong>: ${dataToSend.receiptDate
          .toDate()
          .toLocaleString("th-TH", {
            day: "numeric",
            month: "short",
            year: "numeric",
          })}</h4>
        <div style="border:1px solid gray; padding-left:1rem; background-color:#eeeeee;">
          <p><strong>Company HN</strong>: ${dataToSend.hn}<br/>
          <strong>Full Name</strong>: ${dataToSend.firstname} ${
          dataToSend.lastname
        } <br/>
          <strong>Tel</strong>: ${dataToSend.mobile}<br/>
          <strong>Address</strong>: ${dataToSend.address1} ${
          dataToSend.address2
        } ${dataToSend.province} ${dataToSend.postcode}<br/>
          <strong>National ID</strong>: ${dataToSend.nationalId}<br/>
          <strong>Ref Hospital</strong>: ${dataToSend.refCustomer}<br/>
          <strong>Ref Hospital HN</strong>: ${dataToSend.refHospital}<br/>
          <strong>Health Insurance</strong>: ${dataToSend.healthInsurance}<br/>
        </div>
        <br>
        <div style="border:1px solid gray; padding-left:1rem; background-color:#eeeeee;">
        <p><strong>Sale Package</strong>: ${dataToSend.salePackage}<br/>
        <strong>Brand</strong>: ${dataToSend.brand}<br/>
        <strong>Model</strong>: ${dataToSend.model}<br/>
        <strong>Serial Number</strong>: ${dataToSend.productSn}<br/>
        <strong>Device No</strong>: ${dataToSend.deviceNo}<br/>
        <strong>Mask</strong>: ${dataToSend.mask} <br/>
        <strong>Size:</strong>${dataToSend.maskSize} <br/>
        <strong>Warranty Period</strong>: ${dataToSend.warrantyPeriod} <br/>
        <strong>Warranty Start</strong>: ${dataToSend.warrantyStartDate
          .toDate()
          .toLocaleString("th-TH", {
            day: "numeric",
            month: "short",
            year: "numeric",
          })} <br/>
          <strong>Warranty End</strong>: ${dataToSend.warrantyEndDate
            .toDate()
            .toLocaleString("th-TH", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })}<br/>
        <strong>Amount</strong>: ${dataToSend.amount} THB<br/>
        <strong>Sale</strong>: ${dataToSend.sales}<br/>
        </div>
        <br>
        <p><strong>Note</strong>: ${dataToSend.note}</p>
        <p><strong>Submitted</strong>: ${currentUser.displayName}</p>
        `,
      },
    };

    const mail = await setDoc(doc(collection(db, "mail")), text).then(
      async () => {
        await updateDoc(doc(db, "orders", dataToSend.id), {
          isMailSent: true,
          mailSentList: isAll ? emailList : selectEmail,
        });
      }
    );

    toggle();
  };

  useEffect(() => {
    setOrderList(orders);
    props.startSetCpaps();
  }, [orders]);

  useEffect(async () => {
    setEmailLoading(true);
    const docRef = collection(db, "accountants");
    await getDocs(docRef).then((snapshot) => {
      const data = [];
      snapshot.forEach(async (childSnapshot) => {
        data.push({
          ...childSnapshot.data(),
        });
      });

      const list = data
        .filter((item) => item.status === "Available")
        .map((item) => item.email);
      setEmailList(list);
      setSelectEmail(list[0]);
    });
    setEmailLoading(false);
  }, []);

  return (
    <Fragment>
      <PageTitle activeMenu="Order List" motherMenu="Sales" />
      <OrderDetailModal
        show={showModal}
        hide={handleOnHide}
        order={edit}
        onEditSubmit={(id, updates, noteid, files, notes) => {
          props.startEditOrder(id, updates);

          props.startEditNote(noteid, notes, files);
        }}
      />
      {!emailLoading && (
        <Modal
          className="fade bd-example-modal-lg"
          show={mailModal}
          onHide={toggle}
          size="lg"
        >
          <Modal.Header>
            <Modal.Title>Send Email</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group row m-1">
              <label label className="col-lg-4 col-form-label">
                Accountant Email
              </label>
              <div className="col-lg-6">
                <select
                  className="form-control"
                  onChange={(e) => setSelectEmail(e.target.value)}
                  value={selectEmail}
                >
                  {emailList.map((opt) => (
                    <option value={opt}>{opt}</option>
                  ))}
                </select>
                {/* <input className="form-control" type="text" /> */}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
          <div className="d-flex justify-content-between mb-3 mx-3">
            <Button className="sm" onClick={() => handleSendSubmit(true)}>
              Send All
            </Button>
            <div>
              <Button className="sm" onClick={() => handleSendSubmit(false)}>
                Send
              </Button>

              <Button className="sm ml-2" variant="light" onClick={toggle}>
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
      <ProductOrderListFilters />
      {!isLoading && (
        <ProductOrderItem
          orders={orderList}
          onEdit={handleOnEdit}
          onDelete={handleOnDelete}
          onDownload={handleOnDownload}
          onSend={handleOnSendMail}
          // onNext={!isEnd && getNext}
          // onPrev={!isStart && getPrev}
          totalItems={totalItems}
          totalPages={totalPages}
          currentPage={currentPage}
          itemsPerPage={itemPerPage}
          goToPage={goToPage} //pass goToPage
          isStart={isStart} //pass isStart
          isEnd={isEnd} //pass isEnd
          getNext={getNext} //pass getNext
          getPrev={getPrev} //pass getPrev
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state, props) => ({
  orders: selectOrders(state.orders),
  filters: state.filters,
  pages: state.pages,
});

const mapDispatchToProps = (dispatch) => ({
  startSetOrders: (text, searchBy, limitPage, sortByStatus) =>
    dispatch(startSetOrders(text, searchBy, limitPage, sortByStatus)),
  startEditOrder: (id, updates) => dispatch(startEditOrder(id, updates)),
  startRemoveOrder: (id) => dispatch(startRemoveOrder(id)),
  startEditCpap: (id, updates) => dispatch(startEditCpap(id, updates)),
  startSetCpaps: () => dispatch(startSetCpaps()),
  startSetNextOrders: (text, searchBy, limitPage, latestPage, sortByStatus) =>
    dispatch(
      startSetNextOrders(text, searchBy, limitPage, latestPage, sortByStatus)
    ),
  startSetPrevOrders: (text, searchBy, limitPage, firstPage, sortByStatus) =>
    dispatch(
      startSetPrevOrders(text, searchBy, limitPage, firstPage, sortByStatus)
    ),

  startEditNote: (id, note, files) => dispatch(startEditNote(id, note, files)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductOrderList);

import React, { Fragment, useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import { Button } from "react-bootstrap";
import { useAuth } from "../../../context/AuthContext";
import { db } from "../../../firebase";
import {
  setDoc,
  getDocs,
  updateDoc,
  doc,
  collection,
  where,
  query,
} from "firebase/firestore";

import { notifyTopRight, notifyWarning, notifyError } from "../toast/SetToast";
import Toast from "../toast/SetToast";
import { startAddCpap } from "../../../actions/cpaps";
import * as XLSX from "xlsx";
import { RegisterForm } from "./RegisterForm";

const ProductRegister = (props) => {
  const { currentUser } = useAuth();
  const [fileNames, setFilenames] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isClear, setIsClear] = useState(false);
  const [incomingDate, setIncomingDate] = useState(new Date());
  const [deviceNo, setDeviceNo] = useState("---");
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click(); // Trigger hidden file input
  };

  const inputRef = useRef({
    serialNumber: "",
    brand: "",
    model: "",
    description: "",
    type: "",
    vendor: "",
    deviceNo: "",
    lotNo: "",
    incomingDate: "",
    note: "",
  });

  const handleChange = (name) => (e) => {
    // inputRef.current[name] = e.target.value;
    if (name === "cpapImage") {
      for (let i = 0; i < e.target.files.length; i++) {
        const f = e.target.files[i];
        setSelectedFiles((prevState) => [...prevState, f]);
        setFilenames((prevState) => [...prevState, f.name]);
        console.log(f.name);
      }
    } else if (name === "deviceNo") {
      setDeviceNo(e.target.value);
      inputRef.current[name] = e.target.value;
    } else if (name === "incomingDate") {
      setIncomingDate(e.target.value);
      inputRef.current[name] = e.target.value;
    } else {
      inputRef.current[name] = e.target.value;
    }
    // console.log(inputRef.current[name]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const productRef = query(
      collection(db, "products"),
      where("serialNumber", "==", inputRef.current.serialNumber)
    );

    const productQuery = await getDocs(productRef);

    if (productQuery.empty) {
      const formData = {
        serialNumber: inputRef.current.serialNumber,
        brand: inputRef.current.brand,
        model: inputRef.current.model,
        description: inputRef.current.description,
        type: inputRef.current.type,
        vendor: inputRef.current.vendor,
        deviceNo: deviceNo,
        lotNo: inputRef.current.lotNo,
        incomingDate: new Date(incomingDate),
        status: "ว่าง",
        sales: "",
        note: inputRef.current.note,
        createdBy: currentUser.displayName,
        updatedAt: new Date(),
        createdAt: new Date(),
      };

      console.log("formData", formData);

      props
        .startAddCpap(formData, selectedFiles)
        .then(() => {
          notifyTopRight("บันทึกข้อมูลสำเร็จ");
        })
        .catch((err) => {
          notifyError("บันทึกข้อมูลผิดพลาด ", err);
        });

      inputRef.current.serialNumber = "";
      inputRef.current.brand = "";
      inputRef.current.model = "";
      inputRef.current.description = "";
      inputRef.current.type = "";
      inputRef.current.vendor = "";
      inputRef.current.deviceNo = "";
      inputRef.current.lotNo = "";
      inputRef.current.incomingDate = "";
      inputRef.current.note = "";

      setSelectedFiles([]);
      setFilenames([]);

      e.target.reset();
    } else {
      notifyError("Product serial number already created.");
    }
  };

  const handleImportExcel = (event) => {
    console.log("File input changed");
    const file = event.target.files[0];

    if (!file) {
      notifyWarning("No file selected");
      return;
    }

    console.log("Selected file:", file.name);

    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      if (rows.length === 0) return;

      const headers = rows[0];
      console.log("Headers:", headers);

      const result = rows
        .slice(1)
        .map((row) => {
          const rowData = {};
          headers.forEach((header, index) => {
            if (header == "deviceNo") {
              rowData[header] =
                row[index] !== undefined && row[index] !== null
                  ? String(row[index])
                  : "---";
            } else if (header === "incomingDate") {
              const rawDate = row[index];
              if (typeof rawDate === "number") {
                // Excel serial to JS Date
                rowData[header] = XLSX.SSF.parse_date_code(rawDate)
                  ? new Date(
                      Date.UTC(
                        XLSX.SSF.parse_date_code(rawDate).y,
                        XLSX.SSF.parse_date_code(rawDate).m - 1,
                        XLSX.SSF.parse_date_code(rawDate).d
                      )
                    )
                  : null;
              } else if (typeof rawDate === "string") {
                const parts = rawDate.split("/");
                if (parts.length === 3) {
                  const [day, month, year] = parts;
                  rowData[header] = new Date(`${year}-${month}-${day}`);
                } else {
                  rowData[header] = null;
                }
              } else {
                rowData[header] = null;
              }
            } else if (header === "status") {
              rowData[header] =
                row[index] !== undefined &&
                row[index] !== null &&
                row[index] !== ""
                  ? String(row[index])
                  : "ว่าง";
            } else if (header == "images") {
              rowData[header] = [];
            } else {
              rowData[header] =
                row[index] !== undefined &&
                row[index] !== null &&
                row[index] !== ""
                  ? String(row[index])
                  : "";
            }
          });
          return rowData;
        })
        .filter((row) => row["serialNumber"].trim() !== "");

      console.log("Filtered data (with Serial Number):", result);

      let successDevice = [];
      let failedDevice = [];
      let existedDevice = [];

      for (const row of result) {
        const formData = {
          serialNumber: row.serialNumber,
          brand: row.brand,
          model: row.model,
          description: row.description,
          type: row.type,
          vendor: row.vendor,
          deviceNo: row.deviceNo,
          lotNo: row.lotNo,
          incomingDate: row.incomingDate,
          status: row.status,
          images: row.images,
          sales: row.sales,
          note: row.note,
          createdBy: currentUser.displayName,
          updatedAt: new Date(),
          createdAt: new Date(),
        };

        const productRef = query(
          collection(db, "products"),
          where("serialNumber", "==", formData.serialNumber)
        );
        const productQuery = await getDocs(productRef);

        if (productQuery.empty) {
          try {
            console.log("Adding product:", formData.serialNumber);
            await props.startAddCpap(formData, []);
            successDevice.push(formData.serialNumber);
          } catch (err) {
            failedDevice.push(formData.serialNumber);
          }
        } else {
          console.log("already exist: ", formData.serialNumber);
          existedDevice.push(formData.serialNumber);
        }
      }

      if (failedDevice.length > 0 || existedDevice.length > 0) {
        const failedList = failedDevice.map((sn, i) => ` ${sn}`).join("\n");
        const existedList = existedDevice.map((sn, i) => ` ${sn}`).join("\n");

        let message = `บันทึกข้อมูลไม่สำเร็จ ${failedDevice.length} รายการ:\n${failedList}`;

        if (existedDevice.length > 0) {
          message += `\nรายการซ้ำ ${
            existedDevice.length
          } รายการ:\n${existedList}`;
        }

        notifyError(message);
      } else {
        const successList = successDevice.map((sn, i) => ` ${sn}`).join("\n");
        let message = `บันทึกข้อมูลสำเร็จ ${successDevice.length} รายการ:\n${successList}`; 
        notifyTopRight(message);
      }

      setSelectedFiles([]);
      setFilenames([]);
      setIsClear(true);
      setIncomingDate(new Date());
      setDeviceNo("---");
      event.target.value = null;
    };

    reader.readAsArrayBuffer(file); // << ✅ FIXED: move outside .onload
  };

  const handleDownloadTemplate = () => {
    const url =
      "https://firebasestorage.googleapis.com/v0/b/braindynamics-197809/o/templates%2Fexcel%2FProductRegister.xlsx?alt=media&token=4fae0eaf-feff-4916-baab-07312a0986c2";
    const link = document.createElement("a");
    link.href = url;
    link.download = "ProductRegister.xlsx";
    link.click();
  };

  return (
    <Fragment>
      <PageTitle
        activeMenu="Product Register"
        motherMenu="Sales"
        motherLink="/product-list"
      />
      <Toast />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Registration</h4>
              <div>
                <Button onClick={handleButtonClick}>Import</Button>

                <input
                  type="file"
                  accept=".xlsx, .xls"
                  ref={fileInputRef}
                  onChange={handleImportExcel}
                  style={{ display: "none" }}
                />
              </div>
            </div>
            <div className="card-body pb-0">
              <p className="">
                Register a new product with unique Serial Number. The product
                will be added to the stock and changing its status when it has
                been sold.
              </p>
              <p>
                {" "}
                <p className="text-danger">
                  {"Format of incomingDate is "}
                  <b className="text-danger">{"dd/mm/yyyy ---> 01/01/2025"}</b>
                </p>
              </p>
              <Button className="btn-danger" onClick={handleDownloadTemplate}>
                Template Excel
              </Button>
            </div>
            <div className="card-body">
              <div className="form-validation">
                <form
                  className="form-valide"
                  action="#"
                  method="post"
                  onSubmit={handleSubmit}
                >
                  <div className="row">
                    <div className="col-xl-6">
                      <RegisterForm
                        onChange={handleChange}
                        initailValues={null}
                        fileNames={fileNames}
                        incomingDate={incomingDate}
                        deviceNo={deviceNo}
                      />
                      <br /> <br />
                      <div className="form-group row">
                        <div className="col-lg-8 ml-auto">
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  startAddCpap: (cpap, files) => dispatch(startAddCpap(cpap, files)),
});

export default connect(undefined, mapDispatchToProps)(ProductRegister);

import React, { Fragment, useState, useRef, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Button, Modal, Card, Badge } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../../context/AuthContext";
import { functions } from "../../../firebase";
import { httpsCallable } from "firebase/functions";
import { EquipmentForm } from "./EquipmentForm";
import { doc } from "firebase/firestore";
import PerfectScrollbar from "react-perfect-scrollbar";
// import { EditForm } from "./EditForm";

const EquipmentDetail = (props) => {
  const getSignedUrl = httpsCallable(functions, "getSignedUrl");
  const [isLoading, setIsLoading] = useState(false);
  const [stateEdit, setStateEdit] = useState(true);

  const [isImageLoding, setIsImageLoding] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);

  const [fileNames, setFilenames] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const inputRef = useRef({
    serialNumber: "",
    deviceName: "",
    model: "",
    description: "",
    status: "",
    lotNumber: "",
    qty: "",
    incomingDate: "",
  });

  const handleChange = (name) => (e) => {
    if (name === "cpapImage") {
      for (let i = 0; i < e.target.files.length; i++) {
        const f = e.target.files[i];
        setSelectedFiles((prevState) => [...prevState, f]);
        setFilenames((prevState) => [...prevState, f.name]);
      }
    } else {
      inputRef.current[name] = e.target.value;
    }
  };

  const handleOnViewFile = (file) => {
    setIsImageLoding(true);
    getSignedUrl({
      folder: "cpapimages",
      hn: props.cpap.serialNumber,
      id: props.cpap.id,
      filename: file,
    })
      .then((result) => {
        console.log("url", result.data);
        setFileUrl(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
    setIsImageLoding(false);
    setCreateModal(true);
  };

  const handleHideView = () => {
    setCreateModal(false);
    setFileUrl(null);
  };

  const submitHandle = (e) => {
    e.preventDefault();
  };
  return (
    <Fragment>
      {!isImageLoding && (
        <Modal
          className="fade bd-example-modal-lg"
          show={createModal}
          onHide={handleHideView}
          size="xl"
        >
          <Modal.Header>
            <Modal.Title>Note</Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            <div
              className="card-body mx-0 p-0 col-lg-12"
              style={{ height: "85vh" }}
            >
              <iframe
                className="col-lg-12 m-0 p-0"
                src={fileUrl + "#toolbar=0"}
                width="100%"
                height="100%"
              ></iframe>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      )}

      {!isLoading && (
        <div className="row">
          <div className="col-lg-12">
            <div className="form-validation">
              <form
                className="form-valide"
                action="#"
                method="post"
                onSubmit={submitHandle}
              >
                <div className="row">
                  <div className="col-xl-6">
                    <Card>
                      <Card.Header>
                        <Card.Title>Information</Card.Title>
                        <Card.Title>
                          {props.equipment.status === "พร้อมใช้งาน" ? (
                            <Badge variant="success light">
                              {props.equipment.status}
                            </Badge>
                          ) : props.equipment.status === "ส่งซ่อม" ? (
                            <Badge variant="warning dark">
                              {props.equipment.status}
                            </Badge>
                          ) : props.equipment.status === "ยกเลิกใช้งาน" ? (
                            <Badge variant="danger dark">
                              {props.equipment.status}
                            </Badge>
                          ) : (
                            <Badge variant="info dark">
                              {props.equipment.status}
                            </Badge>
                          )}
                        </Card.Title>
                      </Card.Header>
                      <Card.Body>
                        <Card.Text>
                          {props.equipment ? (
                            <Card.Text>
                              {/* <strong className="text-info">
                                  แบบฟอร์มข้อมูลพื้นฐาน
                                </strong> */}
                              <EquipmentForm
                                onChange={handleChange}
                                initailValues={props.equipment}
                                viewonly={true}
                              />

                              {/* <p className="mb-0">
                                serialNumber :
                                {props.equipment.serialNumber
                                  ? " " + props.equipment.serialNumber
                                  : " -"}
                              </p>
                              <p className="mb-0">
                                deviceName :
                                {props.equipment.deviceName
                                  ? " " + props.equipment.deviceName
                                  : " -"}
                              </p>
                              <p className="mb-0">
                                model :
                                {props.equipment.model
                                  ? " " + props.equipment.model
                                  : " -"}
                              </p>
                              <p className="mb-0">
                                description :
                                {props.equipment.description
                                  ? " " + props.equipment.description
                                  : " -"}
                              </p>
                              <p className="mb-0">
                                lotNumber :
                                {props.equipment.lotNumber
                                  ? " " + props.equipment.lotNumber
                                  : " -"}
                              </p>
                              <p className="mb-0">
                                status :
                                {props.equipment.status
                                  ? " " + props.equipment.status
                                  : " -"}
                              </p>
                              <p className="mb-0">
                                qty :
                                {props.equipment.qty
                                  ? " " + props.equipment.qty
                                  : " -"}
                              </p>
                              <p className="mb-0">
                                incomingDate :
                                {props.equipment.incomingDate
                                  ? " " + props.equipment.incomingDate
                                  : " -"}
                              </p> */}
                            </Card.Text>
                          ) : (
                            ""
                          )}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    {/* <EditForm
                      onChange={handleChange}
                      initailValues={props.cpap}
                      onEdit={stateEdit}
                      fileNames={fileNames}
                      defaultfiles={props.cpap.filename}
                    /> */}
                    {/* {!stateEdit && (
                      <div className="form-group row">
                        <div className="col-lg-8 ml-auto">
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </div>
                    )} */}
                  </div>
                  <div className="col-xl-6 col-xxl-6 col-lg-6">
                    <div className="card">
                      <div className="card-header border-0 pb-0">
                        <h4 className="card-title">Repair Log</h4>
                      </div>
                      <div className="card-body">
                        <PerfectScrollbar
                          style={{ height: "100%" }}
                          id="DZ_W_TimeLine1"
                          className="widget-timeline dz-scroll style-1 height370 ps ps--active-y"
                        >
                          <ul className="timeline">
                            {props.tickets.map((doc, index) => {
                              return (
                                <li>
                                  <div className="timeline-badge dark"></div>
                                  <div className="timeline-panel text-muted">
                                    <span>
                                      {doc.updatedAt
                                        .toDate()
                                        .toLocaleString("th-TH", {
                                          year: "numeric",
                                          month: "numeric",
                                          day: "numeric",
                                        })}
                                    </span>
                                    <h6 className="mb-0">
                                      {doc.ticketStatus}
                                      {/* <strong className="text-primary">
                                        $500
                                      </strong> */}
                                      .
                                    </h6>
                                    <p className="mb-0">{doc.problem}</p>
                                    <p className="mb-0">{doc.repairDetail}</p>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </PerfectScrollbar>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default EquipmentDetail;

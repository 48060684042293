import React, { Fragment, useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";

import { useAuth } from "../../../context/AuthContext";
import { db, storage } from "../../../firebase";
import { setDoc, updateDoc, doc, collection } from "firebase/firestore";
import { ref, uploadBytes, deleteObject, listAll } from "firebase/storage";
import { notifyTopRight, notifyWarning, notifyError } from "../toast/SetToast";
import Toast from "../toast/SetToast";
import { TicketForm } from "./TicketForm";
import { useCollection } from "../../../hook/useCollection";

const TicketCreate = (props) => {
  const { currentUser } = useAuth();
  const [fileNames, setFilenames] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selected, setSelected] = useState([]);

  const inputRef = useRef({
    sn: "",
    ticketType: "",
    problem: "",
    image: "",
    ticketStatus: "",
    dueDate: "",
    requestDate: "",
  });

  const { documents, error } = useCollection("equipments", [
    "serialNumber",
    "asc",
  ]);

  const handleChange = (name) => (e) => {
    if (name === "image") {
      for (let i = 0; i < e.target.files.length; i++) {
        const f = e.target.files[i];
        setSelectedFiles((prevState) => [...prevState, f]);
        setFilenames((prevState) => [...prevState, f.name]);
        console.log(f.name);
      }
    } else if (name === "sn") {
      const temp = documents.filter(
        (item) => item.serialNumber === e.target.value
      );
      setSelected(temp[0]);
      inputRef.current[name] = e.target.value;
    } else {
      inputRef.current[name] = e.target.value;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      equipmentId: selected.equipmentId,
      sn: inputRef.current.sn,
      ticketType: inputRef.current.ticketType,
      problem: inputRef.current.problem,
      image: fileNames,
      ticketStatus: "รอดำเนินการ",
      requestDate: inputRef.current.requestDate,
      dueDate: inputRef.current.dueDate,
      createdBy: currentUser.displayName,
      updatedAt: new Date(),
      createdAt: new Date(),
    };

    const docRef = doc(collection(db, "tickets"));

    console.log("formData", formData);
    await setDoc(docRef, formData).then(() => {
      updateDoc(doc(db, "tickets", docRef.id), {
        ticketId: docRef.id,
      });
      //upload image
      const promises = [];
      selectedFiles.map((file) => {
        const storageRef = ref(
          storage,
          `ticketImage/${docRef.id}/${file.name}`
        );
        const uploadTask = uploadBytes(storageRef, file);
        promises.push(uploadTask);
      });
      Promise.all(promises);
      notifyTopRight("Create success ");
    });

    //update equiment status : ส่งซ่อม
    const eqRef = doc(db, "equipments", selected.equipmentId);
    await updateDoc(eqRef, { status: "ส่งซ่อม" });

    inputRef.current.sn = "";
    inputRef.current.ticketType = "";
    inputRef.current.model = "";
    inputRef.current.problem = "";
    inputRef.current.image = "";
    inputRef.current.ticketStatus = "";
    inputRef.current.requestDate = "";
    inputRef.current.dueDate = "";

    setSelectedFiles([]);
    setFilenames([]);

    e.target.reset();
  };

  return (
    <Fragment>
      <PageTitle
        activeMenu="Create Ticket"
        motherMenu="Ticket"
        motherLink="/ticket-create"
      />
      <Toast />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Create Ticket</h4>
            </div>

            <div className="card-body">
              <div className="form-validation">
                <form
                  className="form-valide"
                  action="#"
                  method="post"
                  onSubmit={handleSubmit}
                >
                  <div className="row">
                    <div className="col-xl-6">
                      <TicketForm
                        onChange={handleChange}
                        fileNames={fileNames}
                        initailValues={null}
                        equipments={documents
                          .filter((item) => item.status === "พร้อมใช้งาน")
                          .map((item) => item.serialNumber)}
                        defaultfiles={[]}
                      />
                      <br /> <br />
                      <div className="form-group row">
                        <div className="col-lg-8 ml-auto">
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TicketCreate;

import React, { Fragment, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  Modal,
  Button,
  Dropdown,
  Table,
} from "react-bootstrap";

import { db } from "../../../firebase";
import Toast from "../toast/SetToast";

import {
  query,
  endAt,
  getDocs,
  collection,
  orderBy,
  limit,
  startAfter,
  endBefore,
  limitToLast,
  startAt,
  getCountFromServer,
  where,
  doc,
  deleteDoc,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { notifyError, notifyTopRight } from "../toast/SetToast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";

const nodeList = ["BD"];
const typeList = [
  "Home Full Night Sleep Study",
  "Full Night Sleep Study",
  "Split Night Sleep Study",
  "CPAP Titration",
  // "MSLT",
  // "MWT",
];

const statusList = [
  "Schedule",
  "Delivered CPAP",
  "Received CPAP",
  "Report CPAP",
  "CPAP-Consult",
  "Sale Follow Up",
  "Ship",
  "Ship [CPAP Purchase]",
  "Ship [CPAP Rental]",
  "Cancel",
  "Failure",
];
const colorList = [
  "#005DEA",
  // briht blue
  "#00CEAB",
  // light blue
  "#00CEAB",
  //  primary color
  "#711685",
  //  post consult yellow | Google yellow
  "#F4B400",
  // sale follow up
  "#FF99CC",
  // ship green
  "#64A338",
  "#64A338",
  "#64A338",
  // cancel gray
  "#A8A8A8",
  // failure red
  "#DD0102",
];
const orderList = ["R1", "R2", "R3", "R4", "R5", "จองคิว"];
const paymentPrivilages = [
  "กรุณาเลือก",
  "ชำระเงินเอง",
  "ประกันสุขภาพถ้วนหน้า",
  "ประกันสังคม",
  "กรมบัญชีกลาง",
  "กรุงเทพมหานคร",
  "ต้นสังกัด",
  "นักศึกษา มธ.",
  "โครงการวิจัยรับค่าบริการล่วงหน้า",
  "องค์การปกครองส่วนท้องถิ่น",
  "โครงการตรวจสุขภาพรับค่าบริการล่วงหน้า",
  "ผ่อนชำระ/นิติกร",
  "อุบัติเหตุและฉุกเฉินนอกโรงพยาบาล(EMS)",
  "ปลัดกระทรวงสาธารณะสุข",
  "โครงการ 85 ปี",
  "อื่นๆ",
];
const eventTmp = {
  title: "",
  start: "",
  id: "",
  extendedProps: {
    order: "",
    hn: "",
    type: "",
    status: "",
    firstname: "",
    lastname: "",
    nationalId: "",
    healthInsurance: "กรุณาเลือก",
    phone: "",
    note: "",
    node: "",
    customer: "",
    items: [],
    totalValue: 0,
    time: "",
    technician: "",
    doctor: "",
  },
};
export const PatientDropDownForm = ({
  title = "",
  htmlFor = "",
  id = "",
  name = "",
  refvalue,
  placeholder = "Please select",
  selected = "",
  options = [""],
  onChange,
  disabled,
}) => {
  return (
    <div className="form-group row col-lg-6">
      <label className="col-lg-4 col-form-label" htmlFor="val-username">
        {title}
        <span className="text-danger"></span>
      </label>
      <div className="col-lg-8">
        <select
          className="form-control"
          id={id}
          name={name}
          ref={refvalue}
          onChange={onChange}
          disabled={disabled}
        >
          {options.map((value) => (
            <option
              value={value}
              selected={selected == value ? "selected" : ""}
            >
              {value}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
export const PatientTextInfo = ({ title = "", value = "", bold = false }) => {
  return (
    <div className="form-group row col-lg-6">
      <label className="col-lg-4 col-form-label" htmlFor="val-username">
        {title.split('\\n').map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </label>
      <div className="col-lg-8">
        {bold ? (
          <>
            <b>{value}</b>
          </>
        ) : (
          <>
            <text>{value}</text>
          </>
        )}
      </div>
    </div>
  );
};
export const PatientLinkInfo = ({
  title = "",
  value = "",
  bold = false,
  path,
}) => {
  return (
    <div className="form-group row col-lg-6">
      <label className="col-lg-4 col-form-label" htmlFor="val-username">
        {title}
      </label>
      <div className="col-lg-8">
        {bold ? (
          <>
            <Link to={path}>
              <b>{value}</b>
            </Link>
          </>
        ) : (
          <>
            <Link to={path}>
              <text>{value}</text>
            </Link>
          </>
        )}
      </div>
    </div>
  );
};
export const PatientInputText = ({ title, refvalue, defaultValue }) => {
  return (
    <div className="form-group row col-lg-6">
      <label className="col-lg-4 col-form-label" htmlFor="val-username">
        {title.split('\\n').map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </label>
      <div className="col-lg-8">
        <input
          type="text"
          className="form-control"
          // id="val-hn"
          // name="val-hn"
          // placeholder={title}
          ref={refvalue}
          defaultValue={defaultValue}
          // ref={emailRef}
          // value=""
        />
      </div>
    </div>
  );
};

export const PatientInputTextArea = ({ title, refvalue, defaultValue }) => {
  return (
    <div className="form-group row col-lg-6">
      <label className="col-lg-4 col-form-label" htmlFor="val-username">
        {title}
      </label>
      <div className="col-lg-8">
        <textarea
          className="form-control"
          rows="4"
          // placeholder={title}
          ref={refvalue}
          defaultValue={defaultValue}
        />
      </div>
    </div>
  );
};

export const ItemListTable = ({
  items,
  itemList,
  totalValue,
  setItems,
  onUpdateItemValue,
}) => {
  return (
    <>
      {" "}
      <div className="form-group row">
        <div className="col-lg-5 col-form-label mt-2">
          <b htmlFor="val-username">Add Item</b>
          <Button
            variant="secondary"
            size="xs"
            className="ml-2"
            onClick={() => {
              var tmpItems = items;
              var tmpItemList = itemList;
              var tmp = {
                id: tmpItems.length + 1,
                itemcode: tmpItemList[0].itemcode,
                item: tmpItemList[0].item,
                unitcost: tmpItemList[0].unitcost,
                qty: 1,
                discount: 0,
                total: tmpItemList[0].unitcost,
              };
              tmpItems = [...tmpItems, tmp];

              setItems(tmpItems);

              // this.setState(
              //   {
              //     items: tmpItems,
              //   },
              //   () => {
              //     this.getTotalValue();
              //   }
              // );
            }}
          >
            +
          </Button>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th className="center">No.</th>
              <th className="center width250">Item Name</th>
              <th className="center width150">Item Code</th>
              <th className="center width150">Unit Cost (THB)</th>
              <th className="center width100">Qty</th>
              <th className="center">Discount (THB)</th>
              <th className="center">Total (THB)</th>
              <th className="center"></th>
            </tr>
          </thead>
          <tbody>
            {items.map((element, index) => {
              return (
                <tr>
                  <td className="">{element.id}</td>
                  <td>
                    <select
                      className="form-control"
                      id={"item" + String(index)}
                      name={"item" + String(index)}
                      onChange={(evt) => {
                        var item = evt.target.value;
                        var tmpItems = items;

                        var indexOfItemList = itemList.findIndex(
                          (obj) => obj.item === item
                        );
                        console.log(
                          "selected item: ",
                          itemList[indexOfItemList]
                        );
                        console.log(
                          "unitcost: ",
                          itemList[indexOfItemList].unitcost
                        );
                        tmpItems[index].itemcode =
                          itemList[indexOfItemList].itemcode;
                        tmpItems[index].item = itemList[indexOfItemList].item;
                        tmpItems[index].unitcost =
                          itemList[indexOfItemList].unitcost;
                        tmpItems[index].qty = 1;
                        tmpItems[index].discount = 0;
                        tmpItems[index].total =
                          itemList[indexOfItemList].unitcost;
                        setItems(tmpItems);
                        // this.setState({ items: tmpItems }, () => {
                        //   this.getTotalValue();
                        // });
                      }}
                    >
                      {itemList.map((value) => (
                        <option
                          value={value.item}
                          selected={
                            items[index].item == value.item ? "selected" : ""
                          }
                        >
                          {value.item}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td className="left strong">
                    <b>{items[index].itemcode}</b>
                  </td>
                  <td className="left">
                    {" "}
                    <input
                      type="number"
                      className="form-control"
                      id={"val-unitcost" + String(index)}
                      name={"val-unitcost" + String(index)}
                      placeholder="Unit Cost"
                      onChange={(data) =>
                        onUpdateItemValue("unitcost", index, data)
                      }
                      // defaultValue={
                      //   this.state.items[index].unitcost
                      // }
                      value={items[index].unitcost}
                    />
                  </td>
                  <td className="right">
                    {" "}
                    <input
                      type="number"
                      className="form-control"
                      id={"val-qty" + String(index)}
                      name={"val-qty" + String(index)}
                      placeholder="QTY"
                      onChange={(data) => onUpdateItemValue("qty", index, data)}
                      // defaultValue={this.state.items[index].qty}
                      value={items[index].qty}
                    />
                  </td>
                  <td className="center">
                    {" "}
                    <input
                      type="number"
                      className="form-control"
                      id={"val-discount" + String(index)}
                      name={"val-discount" + String(index)}
                      placeholder="Discount"
                      onChange={(data) => {
                        onUpdateItemValue("discount", index, data);
                      }}
                      // defaultValue={
                      //   this.state.items[index].discount
                      // }
                      value={items[index].discount}
                    />
                  </td>
                  <td className="right">
                    {" "}
                    <input
                      type="number"
                      className="form-control"
                      id={"val-total" + String(index)}
                      name={"val-total" + String(index)}
                      placeholder="Total"
                      onChange={(data) =>
                        onUpdateItemValue("total", index, data)
                      }
                      // defaultValue={this.state.items[index].total}
                      value={items[index].total}
                    />
                  </td>
                  <td>
                    <Button
                      className="btn btn-danger btn-xs sharp"
                      onClick={() => {
                        var tmpItems = items;
                        console.log("items: ", tmpItems);
                        tmpItems = tmpItems.filter(
                          (item) => item.id != tmpItems[index].id
                        );
                        for (let i = 0; i < tmpItems.length; i++) {
                          tmpItems[i].id = i + 1;
                        }
                        console.log("items: ", tmpItems);
                        setItems(tmpItems);
                        // this.setState({ items: tmpItems }, () => {
                        //   this.getTotalValue();
                        // });
                      }}
                    >
                      <i className="fa fa-trash"></i>
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="row">
        <div className="col-lg-4 col-sm-5 ml-auto">
          <table className="table table-clear">
            <tbody>
              <tr>
                <td className="left">
                  <strong>Total</strong>
                </td>
                <td className="right">
                  <strong>{totalValue + " THB"}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

const setEvent = async (
  eventAdd,
  oldEvent = undefined,
  nodeIdDocs,
  nodeNameDocs
) => {
  if (oldEvent == undefined) {
    oldEvent = eventAdd;
  } else {
    console.log("oldEvent not undefined");
  }
  try {
    if (
      eventAdd.extendedProps.uuid == undefined ||
      eventAdd.extendedProps.uuid == null ||
      eventAdd.extendedProps.node != oldEvent.extendedProps.node
    ) {
      const nodeIndex = nodeNameDocs.indexOf(eventAdd.extendedProps.node);
      const nodeId = nodeIdDocs[nodeIndex];
      const ref = doc(collection(db, "nodes", nodeId, "bookings"));
      await setDoc(ref, {
        title: eventAdd.title,
        id: eventAdd.id,
        start: new Date(eventAdd.start),
        end: eventAdd.end,
        color: eventAdd.color,
        extendedProps: {
          order: eventAdd.extendedProps.order,
          hn: eventAdd.extendedProps.hn,
          type: eventAdd.extendedProps.type,
          status: eventAdd.extendedProps.status,
          firstname: eventAdd.extendedProps.firstname,
          lastname: eventAdd.extendedProps.lastname,
          nationalId: eventAdd.extendedProps.nationalId,
          healthInsurance: eventAdd.extendedProps.healthInsurance,
          phone: eventAdd.extendedProps.phone,
          node: eventAdd.extendedProps.node,
          customer: eventAdd.extendedProps.customer,
          note: eventAdd.extendedProps.note,
          items: eventAdd.extendedProps.items,
          totalValue: eventAdd.extendedProps.totalValue,
          time: eventAdd.extendedProps.time,
          doctor: eventAdd.extendedProps.doctor,
          technician: eventAdd.extendedProps.technician,
          createBy: eventAdd.extendedProps.createBy,
        },
      });
      eventAdd.extendedProps.uuid = ref.id;
      const refUpdate = doc(
        db,
        "nodes",
        nodeId,
        "bookings",
        eventAdd.extendedProps.uuid
      );

      await updateDoc(refUpdate, {
        title: eventAdd.title,
        id: eventAdd.id,
        start: new Date(eventAdd.start),
        color: eventAdd.color,
        extendedProps: {
          order: eventAdd.extendedProps.order,
          hn: eventAdd.extendedProps.hn,
          type: eventAdd.extendedProps.type,
          status: eventAdd.extendedProps.status,
          uuid: eventAdd.extendedProps.uuid,
          firstname: eventAdd.extendedProps.firstname,
          lastname: eventAdd.extendedProps.lastname,
          nationalId: eventAdd.extendedProps.nationalId,
          healthInsurance: eventAdd.extendedProps.healthInsurance,
          phone: eventAdd.extendedProps.phone,
          node: eventAdd.extendedProps.node,
          customer: eventAdd.extendedProps.customer,
          note: eventAdd.extendedProps.note,
          items: eventAdd.extendedProps.items,
          totalValue: eventAdd.extendedProps.totalValue,
          time: eventAdd.extendedProps.time,
          doctor: eventAdd.extendedProps.doctor,
          technician: eventAdd.extendedProps.technician,
          createBy: eventAdd.extendedProps.createBy,
        },
      });
      if (eventAdd.extendedProps.node != oldEvent.extendedProps.node) {
        const nodeIndexOld = nodeNameDocs.indexOf(oldEvent.extendedProps.node);
        const nodeIdOld = nodeIdDocs[nodeIndexOld];
        const refDelete = doc(
          db,
          "nodes",
          // this.nodeName,
          nodeIdOld,
          "bookings",
          oldEvent.extendedProps.uuid
        );
        await deleteDoc(refDelete);
        console.log("deleted from old node !");
      }
    } else {
      const nodeIndex = nodeNameDocs.indexOf(eventAdd.extendedProps.node);
      const nodeId = nodeIdDocs[nodeIndex];
      const refUpdate = doc(
        db,
        "nodes",
        nodeId,
        "bookings",
        eventAdd.extendedProps.uuid
      );

      await updateDoc(refUpdate, {
        title: eventAdd.title,
        id: eventAdd.id,
        start: new Date(eventAdd.start),
        color: eventAdd.color,
        extendedProps: {
          order: eventAdd.extendedProps.order,
          hn: eventAdd.extendedProps.hn,
          type: eventAdd.extendedProps.type,
          status: eventAdd.extendedProps.status,
          uuid: eventAdd.extendedProps.uuid,
          firstname: eventAdd.extendedProps.firstname,
          lastname: eventAdd.extendedProps.lastname,
          nationalId: eventAdd.extendedProps.nationalId,
          healthInsurance: eventAdd.extendedProps.healthInsurance,
          phone: eventAdd.extendedProps.phone,
          node: eventAdd.extendedProps.node,
          customer: eventAdd.extendedProps.customer,
          note: eventAdd.extendedProps.note,
          items: eventAdd.extendedProps.items,
          totalValue: eventAdd.extendedProps.totalValue,
          time: eventAdd.extendedProps.time,
          doctor: eventAdd.extendedProps.doctor,
          technician: eventAdd.extendedProps.technician,
          createBy: eventAdd.extendedProps.createBy
            ? eventAdd.extendedProps.createBy
            : "",
        },
      });
    }
    notifyTopRight("Update booking success !");
    return "OK";
  } catch (err) {
    console.log(err);
    notifyError("Please fill out patient information !");
    return "Err";
  }
};
export {
  setEvent,
  eventTmp,
  paymentPrivilages,
  orderList,
  colorList,
  statusList,
  typeList,
  nodeList,
};

import React, { useState } from "react";
import {
  Button,
  Modal,
  Form,
  Table,
  InputGroup,
  Row,
  Col,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { db } from "../../../firebase";
import { useAuth } from "../../../context/AuthContext";
import {
  collection,
  doc,
  updateDoc,
  addDoc,
  Timestamp,
  query,
  where,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import "../Inventory/BatchCSS.css";
import swal from "sweetalert";

const TrialInventoryTable = ({ inventoryList, fetchInventory, logs }) => {
  const { currentUser } = useAuth();

  // Single update modal states
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [transaction, setTransaction] = useState({
    status: "+", // default status
    amount: "",
    datetime: new Date().toISOString().slice(0, 16),
    note: "",
  });

  // Batch update modal states
  const [showBatchModal, setShowBatchModal] = useState(false);
  const [batchUpdates, setBatchUpdates] = useState([
    {
      id: 1,
      selectedItem: null, // Will hold the chosen inventory item object
      transaction: {
        status: "+",
        amount: "",
        datetime: new Date().toISOString().slice(0, 16),
        note: "",
      },
    },
  ]);

  // Global fields for batch update
  const [batchGlobal, setBatchGlobal] = useState({
    HN: "",
    firstname: "",
    lastname: "",
  });

  const [singleGlobal, setSingleGlobal] = useState({
    HN: "",
    firstname: "",
    lastname: "",
  });

  // // ----- Single Update Modal Handlers -----
  // const openModal = (item) => {
  //   if (!item) {
  //     console.error("openModal received an undefined item");
  //     return;
  //   }
  //   setSelectedItem(item);
  //   setTransaction({
  //     status: "+",
  //     amount: "",
  //     datetime: new Date().toISOString().slice(0, 16),
  //     note: "",
  //   });
  //   setShowModal(true);
  // };

  // ----- Single Update Modal Handlers -----
  const openModal = (item) => {
    if (!item) {
      console.error("openModal received an undefined item");
      return;
    }
    setSelectedItem(item);
    setTransaction({
      status: "+",
      amount: "",
      datetime: new Date().toISOString().slice(0, 16),
      note: "",
    });
    // Optionally, clear the global fields or leave them as is
    setSingleGlobal({
      HN: "",
      firstname: "",
      lastname: "",
    });
    setShowModal(true);
  };

  // New search function to get patient details based on HN in single update modal
  const onSearchPatientSingle = async () => {
    if (!singleGlobal.HN) return;
    try {
      const q = query(
        collection(db, "patients"),
        where("hn", "==", singleGlobal.HN)
      );
      const querySnapshot = await getDocs(q);
      let listPatient = [];
      querySnapshot.forEach((doc) => {
        listPatient.push(doc.data());
      });
      if (listPatient.length > 0) {
        setSingleGlobal((prev) => ({
          ...prev,
          firstname: listPatient[0].firstnameTh,
          lastname: listPatient[0].lastnameTh,
        }));
      } else {
        alert("Patient not found!");
      }
    } catch (error) {
      console.error("Error searching patient:", error);
    }
  };

  // const handleStockUpdate = async (e) => {
  //   e.preventDefault();
  //   if (
  //     !selectedItem ||
  //     !transaction.amount ||
  //     parseInt(transaction.amount, 10) <= 0
  //   ) {
  //     alert("Please fill all fields correctly.");
  //     return;
  //   }
  //   if (
  //     transaction.status === "-" &&
  //     parseInt(transaction.amount, 10) > parseInt(selectedItem.available, 10)
  //   ) {
  //     alert("Please fill all fields correctly.");
  //     return;
  //   }

  //   try {
  //     const itemRef = doc(db, "inventory", selectedItem.id);
  //     const newAvailable =
  //       transaction.status === "+"
  //         ? Number(selectedItem.available) + Number(transaction.amount)
  //         : Number(selectedItem.available) - Number(transaction.amount);

  //     await updateDoc(itemRef, { available: newAvailable.toString() });

  //     const historyRef = collection(itemRef, "history");
  //     await addDoc(historyRef, {
  //       amount: Number(transaction.amount),
  //       createdBy: currentUser.displayName,
  //       datetime: Timestamp.fromDate(new Date(transaction.datetime)),
  //       itemId: selectedItem.itemId,
  //       type: selectedItem.type,
  //       size: selectedItem.size,
  //       itemName: selectedItem.itemName,
  //       note: transaction.note,
  //       status: transaction.status,
  //     });

  //     setShowModal(false);
  //     fetchInventory();
  //   } catch (error) {
  //     console.error("Error updating stock:", error);
  //   }
  // };

  const handleStockUpdate = async (e) => {
    e.preventDefault();
    if (
      !selectedItem ||
      !transaction.amount ||
      parseInt(transaction.amount, 10) <= 0
    ) {
      alert("Please fill all fields correctly.");
      return;
    }
    if (
      transaction.status === "-" &&
      parseInt(transaction.amount, 10) > parseInt(selectedItem.available, 10)
    ) {
      alert("Please fill all fields correctly.");
      return;
    }

    try {
      const itemRef = doc(db, "trialInventory", selectedItem.id);
      const newAvailable =
        transaction.status === "+"
          ? Number(selectedItem.available) + Number(transaction.amount)
          : Number(selectedItem.available) - Number(transaction.amount);

      await updateDoc(itemRef, { available: newAvailable.toString() });

      const historyRef = collection(itemRef, "trialHistory");
      await addDoc(historyRef, {
        amount: Number(transaction.amount),
        createdBy: currentUser.displayName,
        datetime: Timestamp.fromDate(new Date(transaction.datetime)),
        itemId: selectedItem.itemId,
        type: selectedItem.type,
        size: selectedItem.size,
        itemName: selectedItem.itemName,
        note: transaction.note,
        status: transaction.status,
        // Include the global fields for HN, firstname, and lastname
        ...singleGlobal,
      });

      setShowModal(false);
      fetchInventory();
    } catch (error) {
      console.error("Error updating stock:", error);
    }
  };

  // ----- Batch Update Modal Handlers -----
  const openBatchModal = () => {
    // Reset batch updates with one blank row and clear global fields
    setBatchUpdates([
      {
        id: 1,
        selectedItem: null,
        transaction: {
          status: "+",
          amount: "",
          datetime: new Date().toISOString().slice(0, 16),
          note: "",
        },
      },
    ]);
    setBatchGlobal({
      HN: "",
      firstname: "",
      lastname: "",
    });
    setShowBatchModal(true);
  };

  // Add a new row in the batch update modal
  const addBatchRow = () => {
    const newId =
      batchUpdates.length > 0
        ? Math.max(...batchUpdates.map((row) => row.id)) + 1
        : 1;
    setBatchUpdates((prev) => [
      ...prev,
      {
        id: newId,
        selectedItem: null,
        transaction: {
          status: "+",
          amount: "",
          datetime: new Date().toISOString().slice(0, 16),
          note: "",
        },
      },
    ]);
  };

  // Update a batch row field (for the transaction part)
  const handleBatchChange = (rowId, field, value) => {
    setBatchUpdates((prevRows) =>
      prevRows.map((row) =>
        row.id === rowId
          ? {
              ...row,
              transaction: { ...row.transaction, [field]: value },
            }
          : row
      )
    );
  };

  // Update the selected inventory item for a given row
  const handleBatchItemSelect = (rowId, itemId) => {
    const item = inventoryList.find((inv) => inv.itemId === itemId);
    setBatchUpdates((prevRows) =>
      prevRows.map((row) =>
        row.id === rowId ? { ...row, selectedItem: item } : row
      )
    );
  };

  // Update the global fields for the batch modal
  const handleGlobalChange = (field, value) => {
    setBatchGlobal((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  // Search for patient data using HN and update global firstname and lastname
  const onSearchPatient = async () => {
    if (!batchGlobal.HN) return;
    try {
      const q = query(
        collection(db, "patients"),
        where("hn", "==", batchGlobal.HN)
      );
      const querySnapshot = await getDocs(q);
      let listPatient = [];
      querySnapshot.forEach((doc) => {
        listPatient.push(doc.data());
      });
      if (listPatient.length > 0) {
        console.log(listPatient[0]);
        // Update global firstname and lastname
        setBatchGlobal((prev) => ({
          ...prev,
          firstname: listPatient[0].firstnameTh,
          lastname: listPatient[0].lastnameTh,
        }));
      } else {
        alert("Patient not found!");
      }
    } catch (error) {
      console.error("Error searching patient:", error);
    }
  };

  // Submit all batch update rows to Firebase
  const handleBatchSubmit = async (e) => {
    e.preventDefault();
    console.log("batch: ", batchUpdates);

    try {
      for (const row of batchUpdates) {
        // Validate the row
        if (
          !row.selectedItem ||
          !row.transaction.amount ||
          parseInt(row.transaction.amount, 10) <= 0
        ) {
          alert("Please fill all fields correctly for each batch row.");
          return;
        }
        if (
          row.transaction.status === "-" &&
          parseInt(row.transaction.amount, 10) >
            parseInt(row.selectedItem.available, 10)
        ) {
          alert(
            `Insufficient available stock for item ${row.selectedItem.itemName}.`
          );
          return;
        }

        // Update the inventory document
        const itemRef = doc(db, "trialInventory", row.selectedItem.id);
        const newAvailable =
          row.transaction.status === "+"
            ? Number(row.selectedItem.available) +
              Number(row.transaction.amount)
            : Number(row.selectedItem.available) -
              Number(row.transaction.amount);

        await updateDoc(itemRef, { available: newAvailable.toString() });

        // Add the transaction history for this item including the global fields
        const historyRef = collection(itemRef, "trialHistory");
        await addDoc(historyRef, {
          amount: Number(row.transaction.amount),
          createdBy: currentUser.displayName,
          datetime: Timestamp.fromDate(new Date(row.transaction.datetime)),
          itemId: row.selectedItem.itemId,
          type: row.selectedItem.type,
          size: row.selectedItem.size,
          itemName: row.selectedItem.itemName,
          note: row.transaction.note,
          status: row.transaction.status,
          ...batchGlobal, // Add HN, firstname, and lastname globally to each log
        });
      }
      alert("Batch update successful!");
      setShowBatchModal(false);
      fetchInventory();
    } catch (error) {
      console.error("Error with batch update:", error);
    }
  };

  const openDelete = (item) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete this item and all of its history?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        // Show a second confirmation to be extra sure
        swal({
          title: "Really delete?",
          text: "This action cannot be undone. Are you absolutely sure?",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then(async (reallyDelete) => {
          if (reallyDelete) {
            try {
              // Reference to the inventory document
              const itemRef = doc(db, "trialInventory", item.id);

              // Get all documents in the "history" subcollection
              const historyRef = collection(itemRef, "trialHistory");
              const historySnapshot = await getDocs(historyRef);
              const deletePromises = [];

              historySnapshot.forEach((docSnap) => {
                // Create a reference for each history document
                const historyDocRef = doc(
                  db,
                  "trialInventory",
                  item.id,
                  "trialHistory",
                  docSnap.id
                );
                deletePromises.push(deleteDoc(historyDocRef));
              });

              // Wait for all history deletions to complete
              await Promise.all(deletePromises);

              // Delete the inventory document itself
              await deleteDoc(itemRef);

              swal(
                "Deleted!",
                "The item and its history have been deleted.",
                "success"
              );
              fetchInventory();
            } catch (error) {
              console.error("Error deleting item:", error);
              swal(
                "Error!",
                "An error occurred while deleting the item.",
                "error"
              );
            }
          } else {
            swal("Deletion cancelled.");
          }
        });
      } else {
        swal("Deletion cancelled.");
      }
    });
  };
  return (
    <div className="row">
      {/* Left: Inventory Table */}
      <div className="col-lg-6">
        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h2>Trial Stocks</h2>
            <Button variant="secondary" onClick={openBatchModal}>
              Batch Update
            </Button>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table mb-0 table-striped">
                <thead>
                  <tr>
                    <th style={{ textAlign: "center" }}>#</th>
                    <th style={{ textAlign: "center" }}>Item Id</th>
                    <th style={{ textAlign: "center" }}>Item Type</th>
                    <th style={{ textAlign: "center" }}>Item Name</th>
                    <th style={{ textAlign: "center" }}>Size</th>
                    <th style={{ textAlign: "center" }}>Available</th>
                    <th style={{ textAlign: "center" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {[...inventoryList]
                    .sort((a, b) => a.itemId.localeCompare(b.itemId))
                    .map((item, index) => (
                      <tr key={index}>
                        <td style={{ textAlign: "center" }}>{index + 1}</td>
                        <td style={{ textAlign: "center" }}>{item.itemId}</td>
                        <td style={{ textAlign: "center" }}>{item.type}</td>
                        <td style={{ textAlign: "center" }}>{item.itemName}</td>
                        <td style={{ textAlign: "center" }}>
                          {item.size ? item.size : ""}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {item.available}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <Link
                            className="btn btn-info shadow btn-xs sharp mr-2"
                            to="#"
                            onClick={() => openModal(item)}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Edit order details"
                          >
                            <i className="fa fa-pencil"></i>
                          </Link>
                          <Link
                            className="btn btn-danger shadow btn-xs sharp mr-2"
                            to="#"
                            onClick={() => openDelete(item)}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Delete item"
                          >
                            <i class="flaticon-381-error"></i>{" "}
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Right: Logs */}
      <div className="col-lg-6">
        <div className="card">
          <div className="card-header">
            <h2>Logs</h2>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table mb-0 table-striped">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th style={{ textAlign: "center" }}>Item Name</th>
                    <th style={{ textAlign: "center" }}>Size</th>
                    <th style={{ textAlign: "center" }}>Action</th>
                    <th style={{ textAlign: "center" }}>Amount</th>
                    <th>Note</th>
                    <th>By</th>
                  </tr>
                </thead>
                <tbody>
                  {[...logs]
                    .sort((a, b) => new Date(b.datetime) - new Date(a.datetime))
                    .map((item, index) => (
                      <tr key={index}>
                        <td>{item.datetime}</td>
                        <td style={{ textAlign: "center" }}>{item.itemName}</td>
                        <td style={{ textAlign: "center" }}>
                          {item.size ? item.size : ""}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {item.status === "+" ? "Add" : "Release"}
                        </td>
                        <td style={{ textAlign: "center" }}>{item.amount}</td>
                        <td>{item.note}</td>
                        <td>{item.createdBy}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* ---- Single Update Modal ---- */}
      {selectedItem && (
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Update Inventory</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleStockUpdate}>
              {/* --- Global Fields for Patient Info --- */}
              <Form.Group>
                <Form.Label>HN</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    value={singleGlobal.HN}
                    onChange={(e) =>
                      setSingleGlobal({
                        ...singleGlobal,
                        HN: e.target.value,
                      })
                    }
                    placeholder="Enter HN"
                  />
                  <Button
                    variant="outline-secondary"
                    onClick={onSearchPatientSingle}
                  >
                    Search
                  </Button>
                </InputGroup>
              </Form.Group>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={singleGlobal.firstname}
                      onChange={(e) =>
                        setSingleGlobal({
                          ...singleGlobal,
                          firstname: e.target.value,
                        })
                      }
                      placeholder="Enter First Name"
                      readOnly
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={singleGlobal.lastname}
                      onChange={(e) =>
                        setSingleGlobal({
                          ...singleGlobal,
                          lastname: e.target.value,
                        })
                      }
                      placeholder="Enter Last Name"
                      readOnly
                    />
                  </Form.Group>
                </Col>
              </Row>
              {/* --- End Global Fields --- */}

              <Form.Group>
                <Form.Label>Item Type</Form.Label>
                <Form.Control type="text" value={selectedItem.type} readOnly />
              </Form.Group>
              <Form.Group>
                <Form.Label>Item Name</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedItem.itemName}
                  readOnly
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Size</Form.Label>
                <Form.Control type="text" value={selectedItem.size} readOnly />
              </Form.Group>
              <Form.Group>
                <Form.Label>Status</Form.Label>
                <select
                  className="form-control"
                  value={transaction.status}
                  onChange={(e) =>
                    setTransaction({
                      ...transaction,
                      status: e.target.value,
                    })
                  }
                >
                  <option value="+">Add (+)</option>
                  <option value="-">Release (-)</option>
                </select>
              </Form.Group>
              <Form.Group>
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  type="number"
                  value={transaction.amount}
                  onChange={(e) =>
                    setTransaction({
                      ...transaction,
                      amount: e.target.value,
                    })
                  }
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Available</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedItem.available}
                  readOnly
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Date & Time</Form.Label>
                <Form.Control
                  type="datetime-local"
                  value={transaction.datetime}
                  onChange={(e) =>
                    setTransaction({
                      ...transaction,
                      datetime: e.target.value,
                    })
                  }
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Note</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={transaction.note}
                  onChange={(e) =>
                    setTransaction({
                      ...transaction,
                      note: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Button type="submit" className="mt-3" variant="success">
                Save
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      )}

      {/* ---- Batch Update Modal ---- */}
      <Modal
        show={showBatchModal}
        onHide={() => setShowBatchModal(false)}
        dialogClassName="custom-modal-dialog"
      >
        <Modal.Header closeButton>
          <Modal.Title>Batch Update Inventory</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleBatchSubmit}>
            {/* Global fields for all batch rows */}
            <Form.Group>
              <Form.Label>HN</Form.Label>
              <InputGroup>
                <Form.Control
                  style={{ maxWidth: "350px", marginRight: "20px" }}
                  type="text"
                  value={batchGlobal.HN}
                  onChange={(e) => handleGlobalChange("HN", e.target.value)}
                  placeholder="Enter HN"
                />
                <Button variant="outline-secondary" onClick={onSearchPatient}>
                  Search
                </Button>
              </InputGroup>
            </Form.Group>
            {/* <Form.Group style={{ maxWidth: "300px" }}>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                value={batchGlobal.firstname}
                onChange={(e) =>
                  handleGlobalChange("firstname", e.target.value)
                }
                placeholder="Enter First Name"
              />
            </Form.Group>
            <Form.Group style={{ maxWidth: "300px" }}>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                value={batchGlobal.lastname}
                onChange={(e) => handleGlobalChange("lastname", e.target.value)}
                placeholder="Enter Last Name"
              />
            </Form.Group> */}
            <Row>
              <Col md={3}>
                <Form.Group>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={batchGlobal.firstname}
                    onChange={(e) =>
                      handleGlobalChange("firstname", e.target.value)
                    }
                    placeholder="Enter First Name"
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={batchGlobal.lastname}
                    onChange={(e) =>
                      handleGlobalChange("lastname", e.target.value)
                    }
                    placeholder="Enter Last Name"
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>

            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Item</th>
                  <th>Available</th>
                  <th>Status</th>
                  <th style={{ maxWidth: "100px" }}>Amount</th>
                  <th style={{ maxWidth: "200px" }}>Date & Time</th>
                  <th>Note</th>
                </tr>
              </thead>
              <tbody>
                {batchUpdates.map((row, index) => (
                  <tr key={row.id}>
                    <td>{index + 1}</td>
                    <td>
                      <Form.Control
                        as="select"
                        value={row.selectedItem ? row.selectedItem.itemId : ""}
                        onChange={(e) =>
                          handleBatchItemSelect(row.id, e.target.value)
                        }
                        required
                      >
                        <option value="">Select Item</option>
                        {[...inventoryList]
                          .sort((a, b) => a.itemId.localeCompare(b.itemId))
                          .map((item) => (
                            <option key={item.id} value={item.itemId}>
                              {item.itemName} ({item.size ? item.size : "-"})
                            </option>
                          ))}
                      </Form.Control>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {row.selectedItem ? row.selectedItem.available : "N/A"}
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        value={row.transaction.status}
                        onChange={(e) =>
                          handleBatchChange(row.id, "status", e.target.value)
                        }
                      >
                        <option value="+">Add (+)</option>
                        <option value="-">Release (-)</option>
                      </Form.Control>
                    </td>
                    <td style={{ maxWidth: "100px" }}>
                      <Form.Control
                        type="number"
                        value={row.transaction.amount}
                        onChange={(e) =>
                          handleBatchChange(row.id, "amount", e.target.value)
                        }
                        required
                      />
                    </td>
                    <td style={{ maxWidth: "200px" }}>
                      <Form.Control
                        type="datetime-local"
                        value={row.transaction.datetime}
                        onChange={(e) =>
                          handleBatchChange(row.id, "datetime", e.target.value)
                        }
                        required
                      />
                    </td>
                    <td style={{ maxWidth: "200px" }}>
                      <Form.Control
                        as="textarea"
                        rows={2}
                        value={row.transaction.note}
                        onChange={(e) =>
                          handleBatchChange(row.id, "note", e.target.value)
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="d-flex justify-content-between mt-3">
              <Button variant="secondary" onClick={addBatchRow}>
                Add Row
              </Button>
              <Button type="submit" variant="success">
                Submit Batch Update
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TrialInventoryTable;

export default [
  {
    label: "Serial No.",
    name: "sn",
    type: "select",
    required: true,
    options: [],
  },
  {
    label: "ประเภท",
    name: "ticketType",
    type: "select",
    options: ["แจ้งซ่อม", "อื่นๆ"],
  },
  {
    label: "อธิบายปัญหา",
    name: "problem",
    type: "textarea",
  },
  { label: "รูปภาพประกอบ", name: "image", type: "file" },
  {
    label: "สถานะการดำเนินการ",
    name: "ticketStatus",
    type: "select",
    options: ["รอดำเนินการ", "กำลังดำเนินการ", "ดำเนินการเสร็จสิ้น"],
  },
  {
    label: "วันที่ส่ง",
    name: "requestDate",
    type: "date",
  },
  {
    label: "วันที่ต้องการใช้",
    name: "dueDate",
    type: "date",
  },
];

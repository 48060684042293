import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useFocusEffect,
} from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import { Formik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../../../context/AuthContext";
import { db } from "../../../../firebase";
import PerfectScrollbar from "react-perfect-scrollbar";
import swal from "sweetalert";

import {
  setDoc,
  getDoc,
  getDocs,
  updateDoc,
  doc,
  collection,
  query,
  orderBy,
  limit,
  startAfter,
  deleteDoc,
} from "firebase/firestore";
import {
  TextInputForm,
  TextInputNotRequiredForm,
  DropDownForm,
} from "../CpapUtils";
import {
  notifyTopRight,
  notifyWarning,
  notifyError,
} from "../../../components/toast/SetToast";
import Toast from "../../../components/toast/SetToast";
import { Button } from "react-bootstrap";
import { CpapBorrowingData } from "../formdata/CpapBorrowingData";
import {
  CpapRegisterData,
  CpapRegisterChoices,
} from "../formdata/CpapRegisterData";

const CpapActivity = ({ cpapinfo, setShow }) => {
  const { currentUser } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [CpapInfo, setCpapInfo] = useState(CpapRegisterData);
  const [latestDoc, setLatestDoc] = useState(null);
  const [cpapBorrowingList, setCpapBorrowingList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const itemsPerPage = 10;

  const getNextHistory = async () => {
    const historyQuery = query(
      collection(db, "cpaps", CpapInfo.SerialNumber, "borrowing"),
      orderBy("CreateAt", "desc"),
      startAfter(latestDoc),
      limit(itemsPerPage)
    );

    const docSnapBorrowingList = await getDocs(historyQuery);
    const listCpapHistoryTmp = [];
    docSnapBorrowingList.forEach((element) => {
      // listCpapHistoryTmp.push(element.data());
      var tmp = element.data();
      tmp.CreateAt = tmp.CreateAt.toDate()
        .toISOString()
        .replace("T", " ")
        .slice(0, 19);
      if (tmp.BorrowDate !== null) {
        tmp.BorrowDate = tmp.BorrowDate.toDate()
          .toISOString()
          .replace("T", " ")
          .slice(0, 10);
      }
      if (tmp.ReturnDate !== null) {
        tmp.ReturnDate = tmp.ReturnDate.toDate()
          .toISOString()
          .replace("T", " ")
          .slice(0, 10);
      }
      if (tmp.DueDate !== null) {
        tmp.DueDate = tmp.DueDate.toDate()
          .toISOString()
          .replace("T", " ")
          .slice(0, 10);
      }

      listCpapHistoryTmp.push(tmp);
    });
    // listCpapHistoryTmp.reverse();
    // console.log("listCpapHistoryTmp: ", listCpapHistoryTmp);
    setCpapBorrowingList([...cpapBorrowingList, ...listCpapHistoryTmp]);
    setLatestDoc(
      docSnapBorrowingList.docs[docSnapBorrowingList.docs.length - 1]
    );
    if (docSnapBorrowingList.empty) {
      setHasMore(false);
    }
    setIsLoading(false);
  };
  const onDelete = (index) => {
    const ref = doc(
      db,
      "cpaps",
      CpapInfo.SerialNumber,
      "borrowing",
      cpapBorrowingList[index].id
    );
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        await deleteDoc(ref).then(() => {
          swal(`Poof! Your file has been deleted!`, {
            icon: "success",
          });
          setCpapBorrowingList(
            cpapBorrowingList.filter(
              (activity) => activity.id !== cpapBorrowingList[index].id
            )
          );
        });
      } else {
        swal("Canceled");
      }
    });
  };
  useEffect(async () => {
    if (CpapInfo.SerialNumber != undefined && CpapInfo.SerialNumber != "") {
      const refBorrowing = collection(
        db,
        "cpaps",
        CpapInfo.SerialNumber,
        "borrowing"
      );
      const queryHandler = query(
        refBorrowing,
        orderBy("CreateAt", "desc"),
        limit(itemsPerPage)
      );
      //   const docSnapBorrowingList = await getDocs(refBorrowing);
      const docSnapBorrowingList = await getDocs(queryHandler);
      const listCpapHistoryTmp = [];
      docSnapBorrowingList.forEach((element) => {
        var tmp = element.data();
        var newDateOptions = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        };

        if (tmp.CreateAt) {
          tmp.CreateAt = new Date(tmp.CreateAt.toDate()).toLocaleString(
            "en-GB",
            newDateOptions
          );
        }
        if (tmp.BorrowDate) {
          tmp.BorrowDate = new Date(tmp.BorrowDate.toDate()).toLocaleDateString(
            "en-GB"
          );
        }
        if (tmp.DueDate) {
          tmp.DueDate = new Date(tmp.DueDate.toDate()).toLocaleDateString(
            "en-GB"
          );
        }
        if (tmp.ReturnDate) {
          tmp.ReturnDate = new Date(tmp.ReturnDate.toDate()).toLocaleDateString(
            "en-GB"
          );
        }
        tmp.id = element.id;

        listCpapHistoryTmp.push(tmp);
      });

      setCpapBorrowingList(listCpapHistoryTmp);
      setLatestDoc(
        docSnapBorrowingList.docs[docSnapBorrowingList.docs.length - 1]
      );
      setIsLoading(false);
      return;
    }
    const ref = doc(db, "cpaps", cpapinfo.SerialNumber);
    const docSnap = await getDoc(ref);
    setCpapInfo(docSnap.data());

    // setCpapInfo(docSnap.data());
  }, [CpapInfo]);
  // console.log(cpapBorrowingList);
  return (
    <Fragment>
      <div className="col-xl-5">
        <div className="card-header border-0 pb-0">
          <h4 className="card-title">Timeline</h4>
        </div>
        <div className="card-body">
          <PerfectScrollbar
            style={{ height: "100%" }}
            id="DZ_W_TimeLine"
            className="widget-timeline dz-scroll 370 ps ps--active-y"
          >
            <ul className="timeline">
              {cpapBorrowingList.map((element, index) => {
                return (
                  <li>
                    <div
                      className={
                        [
                          "คืนเครื่อง",
                          "ลงทะเบียน",
                          "ส่งเครื่องกลับศูนย์",
                          "",
                        ].includes(element.Activity)
                          ? "timeline-badge primary"
                          : ["ยืมเครื่อง"].includes(element.Activity)
                          ? "timeline-badge danger"
                          : ["ส่งทำความสะอาด", "ส่งซ่อม","จองเครื่อง"].includes(
                              element.Activity
                            )
                          ? "timeline-badge info"
                          : "timeline-badge dark"
                      }
                    ></div>
                    <span className="timeline-panel text-muted" to="#">
                      <Button
                        variant=""
                        className="close"
                        ata-dismiss="alert"
                        aria-label="Close"
                        onClick={() => onDelete(index)}
                      >
                        <span>
                          <i className="mdi mdi-close"></i>
                        </span>
                      </Button>
                      <h6>{element.CreateAt}</h6>
                      <strong className="text-primary">
                        {element.Activity}
                      </strong>
                      {element.HN != "" && element.HN != null && (
                        <h6 className="mt-3">
                          {" "}
                          <strong className="text-dark">HN : </strong>
                          <Link
                            to={"/patient-page/" + element.HN}
                            data-toggle="tooltip"
                            data-placement="top"
                            title={"To Patient " + element.HN}
                          >
                            {element.HN}
                          </Link>
                        </h6>
                      )}
                      {element.FirstName != "" && element.FirstName != null && (
                        <h6 className="">
                          {" "}
                          <strong className="text-dark">Name : </strong>

                            {`${element.FirstName} ${element.LastName}`}
                        </h6>
                      )}
                      {element.Activity == "ยืมเครื่อง" &&
                        element.Mask != "" && (
                          <h6>
                            {" "}
                            <strong className="text-info">
                              {element.Mask}
                            </strong>
                            &nbsp;&nbsp;&nbsp;
                            <strong className="text-warning">
                              Size&nbsp;&nbsp; {element.MaskSize}
                            </strong>
                          </h6>
                        )}
                      {element.Activity == "ยืมเครื่อง" &&
                        element.Humidifier != "" && (
                          <h6>
                            {" "}
                            <strong className="text-info">Humidifier </strong>
                            &nbsp;&nbsp;
                            <strong className="text-warning">
                              {element.Humidifier}
                            </strong>
                          </h6>
                        )}
                      {element.Activity == "ยืมเครื่อง" &&
                        element.RampTime !== "" && (
                          <h6>
                            {" "}
                            <strong className="text-info">Ramp Time </strong>
                            &nbsp;&nbsp;
                            <strong className="text-warning">
                              {element.RampTime} min
                            </strong>
                          </h6>
                        )}
                      {element.Activity == "ยืมเครื่อง" &&
                        element.MinPressure !== "" &&
                        element.MaxPressure !== "" && (
                          <h6>
                            {" "}
                            <strong className="text-info">Pressure : </strong>
                            &nbsp;&nbsp;
                            <strong className="text-warning">
                              {element.MinPressure} - {element.MaxPressure}{" "}
                              cmH2O
                            </strong>
                          </h6>
                        )}
                      {element.Activity == "ยืมเครื่อง" &&
                        element.DueDate !== null && (
                          <h6>
                            {" "}
                            <strong className="text-dark">DueDate : </strong>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {element.DueDate}
                          </h6>
                        )}
                      {element.Activity == "ยืมเครื่อง" &&
                        element.BorrowDate !== null && (
                          <h6>
                            {" "}
                            <strong className="text-dark">BorrowDate : </strong>
                            &nbsp;{element.BorrowDate}
                          </h6>
                        )}
                      {element.Activity == "ยืมเครื่อง" &&
                        element.BorrowCondition !== null &&
                        element.BorrowCondition !== "" && (
                          <h6>
                            {" "}
                            <strong className="text-dark">
                              BorrowCondition :{" "}
                            </strong>
                            &nbsp;{element.BorrowCondition}
                          </h6>
                        )}
                      {element.Activity == "คืนเครื่อง" &&
                        element.ReturnDate !== null && (
                          <h6>
                            {" "}
                            <strong className="text-dark">ReturnDate : </strong>
                            {element.ReturnDate}
                          </h6>
                        )}
                      {element.Activity == "คืนเครื่อง" &&
                        element.ReturnCondition !== null &&
                        element.ReturnCondition !== "" && (
                          <h6>
                            {" "}
                            <strong className="text-dark">
                              ReturnCondition :{" "}
                            </strong>
                            {element.ReturnCondition}
                          </h6>
                        )}
                      <h6 className="mt-2">{element.Note}</h6>
                      <h6 className="mt-2 text-right">
                        <strong className="text-info">Created By : </strong>
                        {element.Admin ? element.Admin : "-"} 
                      </h6>
                    </span>
                  </li>
                );
              })}
            </ul>
          </PerfectScrollbar>
        </div>
        <Button onClick={getNextHistory} disabled={!hasMore}>
          Load More
        </Button>
      </div>
    </Fragment>
  );
};

export default CpapActivity;

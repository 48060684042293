import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Dropdown, Modal, Badge } from "react-bootstrap";
import { useAuth } from "../../../context/AuthContext";
const PatientNoteForm = (props) => {
  const noteData = {
    title: props.note ? props.note.title : "",
    detail: props.note ? props.note.detail : "",
    filename: props.note ? props.note.filename : [],
    hn: props.note ? props.note.hn : props.hn,
    createBy: props.note ? props.note.createBy : props.createBy,
    updateAt: props.note ? props.note.updateAt : null,
    createAt: props.note ? props.note.createAt : new Date(),
    error: "",
  };
  const { currentUser } = useAuth();

  const [createModal, setCreateModal] = useState(false);
  const [isHide, setIsHide] = useState(true);
  const [newNote, setNewNote] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileNames, setFilenames] = useState([]);
  const titleRef = useRef("");
  const detailRef = useRef("");

  const onTitleChange = (e) => {
    e.preventDefault();
    noteData = {
      ...noteData,
      title: e.target.value,
    };
    setNewNote(noteData);
    // const title = e.target.value;
    // setNewNote(...newNote, { title });
  };

  const onDetailChange = (e) => {
    e.preventDefault();
    noteData = {
      ...noteData,
      detail: e.target.value,
    };
    setNewNote(noteData);
    // const detail = e.target.value;
    // setNewNote(...newNote, { detail });
  };

  const onFilesChange = (e) => {
    let files = [];
    for (let i = 0; i < e.target.files.length; i++) {
      const f = e.target.files[i];
      setSelectedFiles((prevState) => [...prevState, f]);
      setFilenames((prevState) => [...prevState, f.name]);
    }
  };

  // const onHNChange = (e) => {
  //   setNewNote({ hn: e.target.value });
  // };

  // const onCreateByChange = (e) => {
  //   setNewNote( { createBy: e.target.value });
  // };

  // const onUpdateAtChange = (e) => {
  //   setNewNote({ updateAt: e.target.value });
  // };

  // const onCreateAtChange = (e) => {
  //   setNewNote({ createAt: e.target.value });
  // };

  const onSubmit = (e) => {
    e.preventDefault();
    // console.log("seleted files", selectedFiles);
    props.onSubmit(
      {
        title: titleRef.current.value,
        detail: detailRef.current.value,
        filename: newNote.filename,
        hn: noteData.hn,
        createBy: noteData.createBy,
        updateAt: noteData.updateAt,
        createAt: noteData.createAt,
      },
      selectedFiles
    );
    titleRef.current.value = "";
    detailRef.current.value = "";
    setFilenames([]);
    setIsHide(true);
    setSelectedFiles([]);
  };

  const onCancel = () => {
    titleRef.current.value = "";
    detailRef.current.value = "";
    setIsHide(true);
    setFilenames([]);
    setSelectedFiles([]);
  };

  return (
    <>
      <div className="col-lg-12 mb-5">
        <b>Add Note</b>
        <div className="col-xl-9 col-lg-12 ">
          {["super_admin","admin","sale"].includes(currentUser.roles[0]) && (
            <div className="input-group mt-3">
              <input
                type="text"
                className="form-control"
                placeholder="Title..."
                ref={titleRef}
                onFocus={() => {
                  setIsHide(false);
                }}
              ></input>
            </div>
          )}
          {!isHide ? (
            <>
              <textarea
                className="form-control mt-3 mb-3"
                id="val-suggestions"
                name="val-suggestions"
                rows="5"
                placeholder="Add note here..."
                ref={detailRef}
              ></textarea>
              <div className="basic-form custom_file_input">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Upload</span>
                  </div>
                  <div className="custom-file">
                    <input
                      type="file"
                      multiple
                      className="custom-file-input"
                      onChange={onFilesChange}
                    />
                    <label className="custom-file-label">
                      {fileNames + " "}
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-group row ml-1">
                <Button className="sm " onClick={onSubmit}>
                  Save
                </Button>
                <Button className="sm ml-2" variant="light" onClick={onCancel}>
                  Cancel
                </Button>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default PatientNoteForm;

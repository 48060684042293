import React, { Fragment, useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";

import { useAuth } from "../../../context/AuthContext";
import swal from "sweetalert";

import { functions } from "../../../firebase";
import { httpsCallable } from "firebase/functions";

import selectNotes from "../../../selectors/notes";
import { notifyTopRight, notifyWarning, notifyError } from "../toast/SetToast";
import Toast from "../toast/SetToast";
import PatientNoteItem from "./PatientNoteItem";
import PatientNoteForm from "./PatientNoteForm";
import PatientNoteEditForm from "./PatientNoteEditForm";
import {
  startAddNote,
  startSetNotes,
  startRemoveNote,
  startEditNote,
} from "../../../actions/notes";

const PatientNote = ({
  profile,
  notesList,
  startAddNote,
  startSetNotes,
  startRemoveNote,
  startEditNote,
}) => {
  const getSignedUrl = httpsCallable(functions, "getSignedUrl");
  const { currentUser } = useAuth();
  const [isEdit, setIsEdit] = useState(false);
  const [noteEditData, setNoteEditData] = useState(null);
  const [isLoding, setIsLoading] = useState(false);
  const [createModal, setCreateModal] = useState(false);

  const [fileUrl, setFileUrl] = useState(null);
  const [fileType, setFileType] = useState(null);

  const onSubmit = (note, files) => {
    if (!isEdit) {
      startAddNote(note, files);
    } else {
      startEditNote(noteEditData.id, note, files);
      // console.log(note.removeFile);
      setIsEdit(false);
    }
  };

  const onDelete = ({ hn, id }) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        startRemoveNote({ hn, id });
        swal(`Poof! Your file has been deleted!`, {
          icon: "success",
        });
      } else {
        swal("Canceled");
      }
    });
  };

  const onEdit = (data) => {
    setIsEdit(true);
    setNoteEditData(data);
    // console.log("onedit ", data);
    // console.log("oneditId ", data.id);
  };

  const onCancelEdit = () => {
    setIsEdit(false);
  };

  const onViewFile = (hn, id, file) => {
    setIsLoading(true);
    getSignedUrl({
      folder: "files",
      hn,
      id,
      filename: file,
    })
      .then((result) => {
        console.log("url", result.data);
        setFileUrl(result.data);
        
        // Determine file type based on file extension
        const fileExtension = file.split('.').pop().toLowerCase();
        if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'].includes(fileExtension)) {
          setFileType('image');
        } else if (fileExtension === 'pdf') {
          setFileType('pdf');
        } else {
          // Default to pdf viewer for other document types
          setFileType('pdf');
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
    setCreateModal(true);
  };

  const handleOnHideModal = () => {
    setCreateModal(false);
    setFileUrl(null);
    setFileType(null);
  };

  useEffect(() => {
    setIsLoading(true);
    startSetNotes(profile.hn);
    setIsLoading(false);
  }, [profile]);

  return (
    <Fragment>
      <Toast />
      {!isLoding && (
        <Modal
          className="fade bd-example-modal-lg"
          show={createModal}
          onHide={handleOnHideModal}
          size="xl"
        >
          <Modal.Header>
            <Modal.Title>Note</Modal.Title>
            <i 
              className="mdi mdi-close" 
              onClick={handleOnHideModal}
              style={{ 
                cursor: 'pointer', 
                fontSize: '20px'
              }}
            ></i>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            <div
              className="card-body mx-0 p-0 col-lg-12"
              style={{ height: "80vh" }}
            >
              {fileType === 'image' ? (
                // Render image with proper fit
                <img
                  src={fileUrl}
                  alt="File Preview"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain"
                  }}
                />
              ) : (
                // Render PDF in iframe
                <iframe
                  className="col-lg-12 m-0 p-0"
                  src={fileUrl + "#toolbar=0"}
                  width="100%"
                  height="100%"
                ></iframe>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      )}
      {!isEdit ? (
        <PatientNoteForm
          onSubmit={onSubmit}
          hn={profile.hn}
          createBy={currentUser.displayName}
        />
      ) : (
        <PatientNoteEditForm
          onSubmit={onSubmit}
          onCancel={onCancelEdit}
          hn={profile.hn}
          createBy={currentUser.displayName}
          note={noteEditData}
        />
      )}

      <PatientNoteItem
        note={notesList}
        onEdit={onEdit}
        onDelete={onDelete}
        onViewFile={onViewFile}
      />
    </Fragment>
  );
};
const mapStateToProps = (state, props) => ({
  notesList: selectNotes(state.notes),
});

const mapDispatchToProps = (dispatch) => ({
  startSetNotes: (notes) => dispatch(startSetNotes(notes)),
  startEditNote: (id, note, files) => dispatch(startEditNote(id, note, files)),
  startRemoveNote: (data) => dispatch(startRemoveNote(data)),
  startAddNote: (note, files) => dispatch(startAddNote(note, files)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientNote);

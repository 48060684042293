import React, { Fragment, useState, useRef, useEffect } from "react";
// import { useFocusEffect } from '@react-navigation/native';

import { Link, useHistory, useParams } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import { Formik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../../../context/AuthContext";
import { db } from "../../../../firebase";
import { setDoc, getDoc, updateDoc, doc, collection } from "firebase/firestore";
import {
  TextInputForm,
  TextInputNotRequiredForm,
  DropDownForm,
} from "../CpapUtils";
import {
  notifyTopRight,
  notifyWarning,
  notifyError,
} from "../../../components/toast/SetToast";
import Toast from "../../../components/toast/SetToast";
import { Button } from "react-bootstrap";

import {
  CpapRegisterData,
  CpapRegisterChoices,
} from "../formdata/CpapRegisterData";

const CpapDetail = ({ cpapinfo, setShow }) => {
  // SerialNumber of CPAP
  // console.log("cpcpinfo: ", cpapinfo.cpapinfo);
  const { currentUser } = useAuth();
  const [availability, setAvailability] = useState(null);

  const onChangeHandle = (e) => {
    setAvailability(e.target.value);
  };

  const submitHandler = async (e) => {
    try {
      e.preventDefault();

      // const ref = doc(db, "cpaps", CpapInfo.SerialNumber);
      const refGetDefault = doc(db, "cpaps", cpapinfo.SerialNumber);
      const docSnapGetDefault = await getDoc(refGetDefault);

      var info = CpapRegisterData;

      // const CpapKeys = Object.keys(CpapRegisterData);
      // CpapRef.current["SerialNumber"] = CpapInfo.SerialNumber;
      // Object.keys(info).forEach((key) => {
      //   CpapRef.current[key].value != null
      //     ? (info[key] = CpapRef.current[key].value)
      //     : (info[key] = "");
      // });
      info.SerialNumber = CpapInfo.SerialNumber;
      info.Brand = CpapRef.current["Brand"].value;
      info.Model = CpapRef.current["Model"].value;
      info.Type = CpapRef.current["Type"].value;
      info.Vendor = CpapRef.current["Vendor"].value;
      info.DeviceNo = CpapRef.current["DeviceNo"].value;
      info.LotNo = CpapRef.current["LotNo"].value;
      info.IncomingDate = CpapRef.current["IncomingDate"].value;
      info.WarrantyStart = CpapRef.current["WarrantyStart"].value;
      info.WarrantyEnd = CpapRef.current["WarrantyEnd"].value;
      info.MaintenancePeriod = CpapRef.current["MaintenancePeriod"].value;
      info.Condition = CpapRef.current["Condition"].value;
      info.CreatedBy = currentUser.displayName;
      info.CreatedDateTime = new Date();
      info.UpdatedDateTime = new Date();
      info.CpapID = CpapRef.current["CpapID"].value;

      info.Status = availability
        ? availability
        : docSnapGetDefault.data().Status;
      info.Activity = docSnapGetDefault.data().Activity;

      if (info.Type == "Other") {
        info.TypeOther = CpapRef.current["TypeOther"].value;
      } else {
        info.TypeOther = "";
      }
      if (info.Brand == "Other") {
        info.BrandOther = CpapRef.current["BrandOther"].value;
      } else {
        info.BrandOther = "";
      }

      if (docSnapGetDefault.exists()) {
        try {
          var tmpDocSnap = info;
          // tmpDocSnap.submit = true;
          // console.log("updated data: ", tmpDocSnap);
          await updateDoc(refGetDefault, tmpDocSnap);
          setStateEdit(true);

          // const refGet = doc(db, "cpaps", cpapinfo.cpapinfo.SerialNumber);
          // const docSnapGet = await getDoc(refGet);
          // setCpapInfo(docSnapGet.data());
          // setType(docSnapGet.data().Type);
          // setBrand(docSnapGet.data().Brand);
          // setStateEdit(true);
          // cpapinfo.setShowCpapStatus(docSnapGet.data().Status, 0);

          // const cpapBorrowingTmp = {
          //   SerialNumber: info.SerialNumber,
          //   Status: docSnapGet.data().Status,
          //   Activity: docSnapGet.data().Activity,
          //   HN: "",
          //   Admin: "",
          //   Mask: "",
          //   MaskSize: "",
          //   Humidifier: "",
          //   BorrowDate: null,
          //   DueDate: null,
          //   ReturnDate: null,
          //   BorrowCondition: "",
          //   ReturnCondition: "",
          //   Note: "",
          //   CreateAt: new Date(),
          // };
          // console.log("tmp: ", cpapBorrowingTmp);
          // const docColRef = doc(
          //   collection(db, "cpaps", CpapInfo.SerialNumber, "borrowing")
          // );
          // const docSnapSet = await setDoc(docColRef, cpapBorrowingTmp);
          notifyTopRight("อัพเดตข้อมูลสำเร็จ");
        } catch (err) {
          notifyError("บันทึกข้อมูลผิดพลาด, ", err);
          console.log(err);
        }
      } else {
        await setDoc(refGetDefault, info)
          .then((data) => {
            notifyTopRight("บันทึกข้อมูลสำเร็จ");
            console.log("data", data);
            setCpapInfo(info);
          })
          .catch((err) => {
            notifyError("บันทึกข้อมูลผิดพลาด, ", err);
            console.log("err", err);
          });
      }
    } catch (err) {
      notifyError(err);
    }
  };

  const [CpapInfo, setCpapInfo] = useState(cpapinfo);
  const [isLoading, setIsLoading] = useState(false);
  const [stateEdit, setStateEdit] = useState(true);
  const [type, setType] = useState("");
  const [brand, setBrand] = useState("");

  const CpapRef = useRef(CpapRegisterData);
  const Choices = CpapRegisterChoices;

  useEffect(async () => {
    setIsLoading(true);
    setCpapInfo(cpapinfo);
    setIsLoading(false);
  }, []);

  // useEffect(async () => {
  //   if (CpapInfo.SerialNumber != undefined && CpapInfo.SerialNumber != "") {
  //     setIsLoading(false);
  //     // console.log("CPAP SerialNumber : ", CpapInfo.SerialNumber);
  //     return;
  //   }
  //   const ref = doc(db, "cpaps", cpapinfo.cpapinfo.SerialNumber);
  //   const docSnap = await getDoc(ref);
  //   setCpapInfo(docSnap.data());
  //   setType(docSnap.data().Type);
  //   setBrand(docSnap.data().Brand);
  //   console.log(docSnap.data());
  //   // console.log("refresh");
  // }, [CpapInfo]);

  // useFocusEffect(
  //   React.useCallback(async () => {
  //     if (CpapInfo.SerialNumber != undefined && CpapInfo.SerialNumber != "") {
  //       setIsLoading(false);
  //       // console.log("CPAP SerialNumber : ", CpapInfo.SerialNumber);
  //       return;
  //     }
  //     const ref = await doc(db, "cpaps", cpapinfo.cpapinfo.SerialNumber);
  //     const docSnap = await getDoc(ref);
  //     setCpapInfo(docSnap.data());
  //     setType(docSnap.data().Type);
  //     setBrand(docSnap.data().Brand);
  //   }, [CpapInfo])
  // );

  return (
    <Fragment>
      <div className="form-head d-flex mb-3 mb-lg-5 align-items-start">
        {currentUser.roles[0] == "super_admin" && (
          <Button
            // onClick={() => {setStateEdit(!stateEdit)}}
            onClick={() => {
              if (stateEdit == false) {
                Object.keys(CpapInfo).forEach((key) => {
                  try {
                    CpapRef.current[key].value = CpapInfo[key];
                  } catch (err) {
                    console.log("err keey: ", key);
                    console.log(CpapRef.current[key]);
                    CpapRef.current[key] = CpapInfo[key];
                  }
                });
                setType(CpapInfo.Type);
                setBrand(CpapInfo.Brand);
              }
              setStateEdit(!stateEdit);
            }}
            className={stateEdit ? "btn btn-dark" : "btn btn-blue"}
          >
            <i className="flaticon-381-clock mr-2"></i>Edit
          </Button>
        )}
      </div>

      <Toast />
      {!isLoading && (
        <div className="row">
          <div className="col-lg-12">
            {/* <div className="card-header">
                <h4 className="card-title">CPAP Registration Form</h4>
              </div> */}
            <div className="form-validation">
              <form
                className="form-valide"
                action="#"
                method="post"
                onSubmit={submitHandler}
              >
                <div className="row">
                  <div className="col-xl-6">
                    <div className="form-group row">
                      <label className="col-lg-4 col-form-label">
                        Serial Number
                        <span className="text-danger">*</span>
                      </label>
                      <div className="col-lg-6">
                        <h4 className="text-primary font-weight-bold">
                          {CpapInfo["SerialNumber"]}
                        </h4>
                      </div>
                    </div>
                    <TextInputNotRequiredForm
                      title="CPAP ID"
                      type="cpap-id"
                      id="cpap-id"
                      name="cpap-id"
                      placeholder=""
                      refvalue={(el) => (CpapRef.current["CpapID"] = el)}
                      defaultValue={CpapInfo["CpapID"]}
                      disabled={stateEdit}
                    />
                    <DropDownForm
                      title="Brand"
                      htmlFor="cpap-brand"
                      id="cpap-brand"
                      name="cpap-brand"
                      placeholder="กดเพื่อเลือก"
                      options={Choices["Brand"]}
                      refvalue={(el) => (CpapRef.current["Brand"] = el)}
                      defaultValue={CpapInfo["Brand"]}
                      onChange={(el) => setBrand(el.target.value)}
                      disabled={stateEdit}
                    />
                    {brand == "Other" && (
                      <TextInputNotRequiredForm
                        title="Brand Other"
                        type="cpap-brandother"
                        id="cpap-brandother"
                        name="cpap-brandother"
                        placeholder=""
                        refvalue={(el) => (CpapRef.current["BrandOther"] = el)}
                        defaultValue={CpapInfo["BrandOther"]}
                        disabled={stateEdit}
                      />
                    )}
                    <TextInputNotRequiredForm
                      title="Model"
                      type="cpap-model"
                      id="cpap-model"
                      name="cpap-model"
                      placeholder=""
                      refvalue={(el) => (CpapRef.current["Model"] = el)}
                      defaultValue={CpapInfo["Model"]}
                      disabled={stateEdit}
                    />
                    <DropDownForm
                      title="Type"
                      htmlFor="cpap-type"
                      id="cpap-type"
                      name="cpap-type"
                      placeholder="กดเพื่อเลือก"
                      options={Choices["Type"]}
                      refvalue={(el) => (CpapRef.current["Type"] = el)}
                      defaultValue={CpapInfo["Type"]}
                      onChange={(el) => setType(el.target.value)}
                      disabled={stateEdit}
                    />
                    {type == "Other" && (
                      <TextInputNotRequiredForm
                        title="Type Other"
                        type="cpap-typeother"
                        id="cpap-typeother"
                        name="cpap-typeother"
                        placeholder=""
                        refvalue={(el) => (CpapRef.current["TypeOther"] = el)}
                        defaultValue={CpapInfo["TypeOther"]}
                        disabled={stateEdit}
                      />
                    )}
                    <TextInputNotRequiredForm
                      title="Vendor"
                      type="cpap-vendor"
                      id="cpap-vendor"
                      name="cpap-vendor"
                      placeholder=""
                      refvalue={(el) => (CpapRef.current["Vendor"] = el)}
                      defaultValue={CpapInfo["Vendor"]}
                      disabled={stateEdit}
                    />
                    <TextInputNotRequiredForm
                      title="Device No (DN)"
                      type="cpap-device-no"
                      id="cpap-device-no"
                      name="cpap-device-no"
                      placeholder=""
                      refvalue={(el) => (CpapRef.current["DeviceNo"] = el)}
                      defaultValue={CpapInfo["DeviceNo"]}
                      disabled={stateEdit}
                    />
                    <TextInputNotRequiredForm
                      title="Lot No"
                      type="cpap-lot-no"
                      id="cpap-lot-no"
                      name="cpap-lot-no"
                      placeholder=""
                      refvalue={(el) => (CpapRef.current["LotNo"] = el)}
                      defaultValue={CpapInfo["LotNo"]}
                      disabled={stateEdit}
                    />
                    <br /> <br />
                    <TextInputNotRequiredForm
                      title="Incoming Date"
                      id="cpap-incoming"
                      name="cpap-incoming"
                      type="date"
                      refvalue={(el) => (CpapRef.current["IncomingDate"] = el)}
                      defaultValue={CpapInfo["IncomingDate"]}
                      disabled={stateEdit}
                    />
                    <TextInputNotRequiredForm
                      title="Warranty Start"
                      id="cpap-WarrantyStart"
                      name="cpap-WarrantyStart"
                      type="date"
                      refvalue={(el) => (CpapRef.current["WarrantyStart"] = el)}
                      defaultValue={CpapInfo["WarrantyStart"]}
                      disabled={stateEdit}
                    />
                    <TextInputNotRequiredForm
                      title="Warranty End"
                      id="cpap-WarrantyEnd"
                      name="cpap-WarrantyEnd"
                      type="date"
                      refvalue={(el) => (CpapRef.current["WarrantyEnd"] = el)}
                      defaultValue={CpapInfo["WarrantyEnd"]}
                      disabled={stateEdit}
                    />
                    <DropDownForm
                      title="Maintenance Period"
                      htmlFor="cpap-MaintenancePeriod"
                      id="cpap-MaintenancePeriod"
                      name="cpap-MaintenancePeriod"
                      placeholder="กดเพื่อเลือก"
                      options={Choices["MaintenancePeriod"]}
                      refvalue={(el) =>
                        (CpapRef.current["MaintenancePeriod"] = el)
                      }
                      defaultValue={CpapInfo["MaintenancePeriod"]}
                      disabled={stateEdit}
                    />
                    <TextInputNotRequiredForm
                      title="Condition"
                      id="cpap-Condition"
                      name="cpap-Condition"
                      refvalue={(el) => (CpapRef.current["Condition"] = el)}
                      defaultValue={CpapInfo["Condition"]}
                      disabled={stateEdit}
                    />
                    <br /> <br />
                    {!stateEdit && (
                      <div className="form-group row">
                        <div className="col-lg-8 ml-auto">
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default CpapDetail;

import React, { Component } from "react";

/// Link
import { Link } from "react-router-dom";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Menu
import MetisMenu from "metismenujs";
import { AuthContext } from "../../../context/AuthContext";

///
// import icon1 from "../../../images/icon1.png";

class MM extends Component {
  // static contextType = AuthContext;
  componentDidMount() {
    // console.log(this.context.currentUser);
    // if (this.context.currentUser == null) {
    //   window.location.pathname = "/page-login";
    // }
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
    //  this.mm("dispose");
    // console.log(this.mm);
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  /// Open menu
  static contextType = AuthContext;
  componentDidMount() {
    // sidebar open/close
    // console.log(this.context.currentUser);
    if (this.context.currentUser == null) {
      window.location.pathname = "/page-login";
    }
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");

    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }

    btn.addEventListener("click", toggleFunc);

    //sidebar icon Heart blast
    var handleheartBlast = document.querySelector(".heart");

    function heartBlast() {
      return handleheartBlast.classList.toggle("heart-blast");
    }

    handleheartBlast.addEventListener("click", heartBlast);
  }
  render() {
    /// Path
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    /// Active menu
    let admin = ["", "admin-list", "admin-node", "admin-form"],
      dashboard = ["dashboard"],
      booking = ["booking-calendar", "booking-table", "booking-urgent"],
      followup = ["followup-calendar", "followup-table"],
      patient = ["patient-list", "patient-page", "patient-register"],
      node = ["node-list"],
      cpap = ["cpap-list", "cpap-register", "cpap-page", "trial-inventory"],
      product = ["product-list", "product-register", "order-list", "inventory"],
      items = [
        "item-list",
        "tech-list",
        "sale-list",
        "doctor-list",
        "accountant-list",
      ],
      Export = ["export"],
      Equipment = ["equipment-list", "ticket-list"],
      Backup = ["backup"],
      Settings = ["settings"];

    return (
      <div className="deznav border-right">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            {this.context.currentUser &&
              ["super_admin", "sale", "sleep_technician"].includes(
                this.context.currentUser.roles[0]
              ) && (
                <li
                  className={`${dashboard.includes(path) ? "mm-active" : ""}`}
                >
                  <Link
                    className="has-arrow ai-icon"
                    to="#"
                    aria-expanded="false"
                  >
                    <i className="flaticon-381-background"></i>
                    <span className="nav-text">Dashboard</span>
                  </Link>
                  <ul aria-expanded="false">
                    <li>
                      <Link
                        className={`${path === "dashboard" ? "mm-active" : ""}`}
                        to="/dashboard"
                        // onClick={() => (window.location.pathname = "/")}
                      >
                        Dashboard
                      </Link>
                    </li>
                  </ul>
                </li>
              )}
            <li className={`${admin.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
                <i className="flaticon-381-infinity"></i>
                <span className="nav-text">Admin</span>
              </Link>
              <ul aria-expanded="false">
                <li>
                  <Link
                    className={`${path === "" ? "mm-active" : ""}`}
                    to="/"
                    // onClick={() => (window.location.pathname = "/")}
                  >
                    Your Account
                  </Link>
                </li>
                {this.context.currentUser &&
                  this.context.currentUser.roles[0] == "super_admin" && (
                    <li>
                      <Link
                        className={`${
                          path === "admin-list" ? "mm-active" : ""
                        }`}
                        to="/admin-list"
                        // onClick={() => (window.location.pathname = "/admin-list")}
                      >
                        Accounts
                      </Link>
                    </li>
                  )}
              </ul>
            </li>
            {this.context.currentUser &&
              ["super_admin", "staff"].includes(
                this.context.currentUser.roles[0]
              ) && (
                <li className={`${node.includes(path) ? "mm-active" : ""}`}>
                  <Link
                    className="has-arrow ai-icon"
                    to="#"
                    aria-expanded="false"
                  >
                    <i className="flaticon-381-location"></i>
                    <span className="nav-text">Node</span>
                  </Link>
                  <ul aria-expanded="false">
                    <li>
                      <Link
                        className={`${path === "node-list" ? "mm-active" : ""}`}
                        to="/node-list"
                        // onClick={() => (window.location.pathname = "/node-list")}
                      >
                        Our Nodes
                      </Link>
                    </li>
                  </ul>
                </li>
              )}
            {this.context.currentUser &&
              ["super_admin", "staff", "sale", "admin"].includes(
                this.context.currentUser.roles[0]
              ) && (
                <li className={`${patient.includes(path) ? "mm-active" : ""}`}>
                  <Link
                    className="has-arrow ai-icon"
                    to="#"
                    aria-expanded="false"
                  >
                    <i className="flaticon-381-user-9"></i>
                    <span className="nav-text">Patient</span>
                  </Link>
                  <ul aria-expanded="false">
                    <li>
                      <Link
                        className={`${
                          path === "patient-list" ? "mm-active" : ""
                        }`}
                        to="/patient-list"
                      >
                        Search Patient
                      </Link>
                    </li>
                    {this.context.currentUser &&
                      ["super_admin", "admin"].includes(
                        this.context.currentUser.roles[0]
                      ) && (
                        <li>
                          <Link
                            className={`${
                              path === "patient-register" ? "mm-active" : ""
                            }`}
                            to="/patient-register"
                          >
                            New Patient
                          </Link>
                        </li>
                      )}
                  </ul>
                </li>
              )}
            <li className={`${booking.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
                <i class="flaticon-381-calendar-2"></i>{" "}
                <span className="nav-text">Booking</span>
              </Link>
              <ul aria-expanded="false">
                <li>
                  <Link
                    className={`${
                      path === "booking-calendar" ? "mm-active" : ""
                    }`}
                    to="/booking-calendar"
                  >
                    Calendar
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "booking-table" ? "mm-active" : ""}`}
                    to="/booking-table"
                  >
                    Table
                  </Link>
                </li>
                {this.context.currentUser &&
                  ["super_admin", "sale"].includes(
                    this.context.currentUser.roles[0]
                  ) && (
                    <li>
                      <Link
                        className={path === "booking-urgent" ? "mm-active" : ""}
                        to="/booking-urgent"
                      >
                        Urgent Cases
                      </Link>
                    </li>
                  )}
              </ul>
            </li>
            {this.context.currentUser &&
              (["super_admin", "sale"].includes(
                this.context.currentUser.roles[0]
              ) ? (
                <li className={`${cpap.includes(path) ? "mm-active" : ""}`}>
                  <Link
                    className="has-arrow ai-icon"
                    to="#"
                    aria-expanded="false"
                  >
                    <i class="flaticon-381-briefcase"></i>{" "}
                    <span className="nav-text">CPAP Trial</span>
                  </Link>
                  <ul aria-expanded="false">
                    <li>
                      <Link
                        className={`${
                          path === "trial-calendar" ? "mm-active" : ""
                        }`}
                        to="/trial-calendar"
                      >
                        Calendar
                      </Link>
                    </li>

                    <li>
                      <Link
                        className={`${path === "cpap-list" ? "mm-active" : ""}`}
                        to="/cpap-list"
                      >
                        Search CPAP
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`${
                          path === "cpap-register" ? "mm-active" : ""
                        }`}
                        to="/cpap-register"
                      >
                        Register CPAP
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`${
                          path === "trial-inventory" ? "mm-active" : ""
                        }`}
                        to="/trial-inventory"
                      >
                        Trial Inventory
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : ["staff", "admin"].includes(
                  this.context.currentUser.roles[0]
                ) ? (
                <li className={`${cpap.includes(path) ? "mm-active" : ""}`}>
                  <Link
                    className="has-arrow ai-icon"
                    to="#"
                    aria-expanded="false"
                  >
                    <i class="flaticon-381-briefcase"></i>{" "}
                    <span className="nav-text">CPAP Trial</span>
                  </Link>
                  <ul aria-expanded="false">
                    <li>
                      <Link
                        className={`${
                          path === "trial-calendar" ? "mm-active" : ""
                        }`}
                        to="/trial-calendar"
                      >
                        Calendar
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null)}
            {this.context.currentUser ? (
              ["super_admin", "sale"].includes(
                this.context.currentUser.roles[0]
              ) && (
                <>
                  {" "}
                  {this.context.currentUser ? (
                    ["super_admin", "sale"].includes(
                      this.context.currentUser.roles[0]
                    ) && (
                      <>
                        <li
                          className={`${
                            followup.includes(path) ? "mm-active" : ""
                          }`}
                        >
                          <Link
                            className="has-arrow ai-icon"
                            to="#"
                            aria-expanded="false"
                          >
                            <i class="flaticon-381-clock-2"></i>{" "}
                            <span className="nav-text">Followup</span>
                          </Link>
                          <ul aria-expanded="false">
                            <li>
                              <Link
                                className={`${
                                  path === "followup-calendar"
                                    ? "mm-active"
                                    : ""
                                }`}
                                to="/followup-calendar"
                              >
                                Calendar
                              </Link>
                            </li>
                            <li>
                              <Link
                                className={`${
                                  path === "followup-table" ? "mm-active" : ""
                                }`}
                                to="/followup-table"
                              >
                                Table
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </>
                    )
                  ) : (
                    <></>
                  )}
                  {this.context.currentUser &&
                    ["super_admin", "sale"].includes(
                      this.context.currentUser.roles[0]
                    ) && (
                      <li
                        className={`${
                          product.includes(path) ? "mm-active" : ""
                        }`}
                      >
                        <Link
                          className="has-arrow ai-icon"
                          to="#"
                          aria-expanded="false"
                        >
                          <i className="flaticon-381-box-1"></i>
                          <span className="nav-text">Sales</span>
                        </Link>
                        <ul aria-expanded="false">
                          <li>
                            <Link
                              className={`${
                                path === "product-list" ? "mm-active" : ""
                              }`}
                              to="/product-list"
                            >
                              Product List
                            </Link>
                          </li>
                          <li>
                            <Link
                              className={`${
                                path === "order-list" ? "mm-active" : ""
                              }`}
                              to="/order-list"
                            >
                              Order List
                            </Link>
                          </li>
                          <li>
                            <Link
                              className={`${
                                path === "product-register" ? "mm-active" : ""
                              }`}
                              to="/product-register"
                            >
                              Product Register
                            </Link>
                          </li>
                          <li>
                            <Link
                              className={`${
                                path === "inventory" ? "mm-active" : ""
                              }`}
                              to="/inventory"
                            >
                              Inventory
                            </Link>
                          </li>
                        </ul>
                      </li>
                    )}
                  {this.context.currentUser &&
                    this.context.currentUser.roles[0] == "super_admin" && (
                      <li
                        className={`${items.includes(path) ? "mm-active" : ""}`}
                      >
                        <Link
                          className="has-arrow ai-icon"
                          to="#"
                          aria-expanded="false"
                        >
                          <i class="flaticon-381-album-2"></i>{" "}
                          <span className="nav-text">Items</span>
                        </Link>
                        <ul aria-expanded="false">
                          <li>
                            <Link
                              className={`${
                                path === "item-list" ? "mm-active" : ""
                              }`}
                              to="/item-list"
                              // onClick={() => (window.location.pathname = "/node-list")}
                            >
                              Item List
                            </Link>
                          </li>
                          <li>
                            <Link
                              className={`${
                                path === "tech-list" ? "mm-active" : ""
                              }`}
                              to="/tech-list"
                              // onClick={() => (window.location.pathname = "/node-list")}
                            >
                              Technician List
                            </Link>
                          </li>
                          <li>
                            <Link
                              className={`${
                                path === "sale-list" ? "mm-active" : ""
                              }`}
                              to="/sale-list"
                              // onClick={() => (window.location.pathname = "/node-list")}
                            >
                              Sale List
                            </Link>
                          </li>
                          <li>
                            <Link
                              className={`${
                                path === "doctor-list" ? "mm-active" : ""
                              }`}
                              to="/doctor-list"
                              // onClick={() => (window.location.pathname = "/node-list")}
                            >
                              Doctor List
                            </Link>
                          </li>
                          <li>
                            <Link
                              className={`${
                                path === "accountant-list" ? "mm-active" : ""
                              }`}
                              to="/accountant-list"
                              // onClick={() => (window.location.pathname = "/node-list")}
                            >
                              Accountant List
                            </Link>
                          </li>
                        </ul>
                      </li>
                    )}
                </>
              )
            ) : (
              <> </>
            )}
            <li className={`${Export.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
                <i class="flaticon-381-download"></i>{" "}
                <span className="nav-text">Export</span>
              </Link>
              <ul aria-expanded="false">
                <li>
                  <Link
                    className={`${path === "export" ? "mm-active" : ""}`}
                    to="/export"
                    // onClick={() => (window.location.pathname = "/node-list")}
                  >
                    Export
                  </Link>
                </li>
              </ul>
            </li>
            {this.context.currentUser &&
              ["super_admin", "sleep_technician", "sale"].includes(
                this.context.currentUser.roles[0]
              ) && (
                <li
                  className={`${Equipment.includes(path) ? "mm-active" : ""}`}
                >
                  <Link
                    className="has-arrow ai-icon"
                    to="#"
                    aria-expanded="false"
                  >
                    <i class="flaticon-381-file-1"></i>{" "}
                    <span className="nav-text">Equipments</span>
                  </Link>
                  <ul aria-expanded="false">
                    <li>
                      <Link
                        className={`${
                          path === "equipment-list" ? "mm-active" : ""
                        }`}
                        to="/ticket-list"
                      >
                        Tickets
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`${
                          path === "equipment-list" ? "mm-active" : ""
                        }`}
                        to="/equipment-list"
                      >
                        Equipments
                      </Link>
                    </li>
                  </ul>
                </li>
              )}
            {this.context.currentUser &&
              this.context.currentUser.roles[0] == "super_admin" && (
                <li className={`${Backup.includes(path) ? "mm-active" : ""}`}>
                  <Link
                    className="has-arrow ai-icon"
                    to="#"
                    aria-expanded="false"
                  >
                    <i class="flaticon-381-database"></i>{" "}
                    <span className="nav-text">Backup</span>
                  </Link>
                  <ul aria-expanded="false">
                    <li>
                      <Link
                        className={`${path === "backup" ? "mm-active" : ""}`}
                        to="/backup"
                        // onClick={() => (window.location.pathname = "/node-list")}
                      >
                        Backup
                      </Link>
                    </li>
                  </ul>
                </li>
              )}
            {this.context.currentUser &&
              (this.context.currentUser.roles[0] == "super_admin" ||
                this.context.currentUser.roles[0] == "sale") && (
                <li className={`${Settings.includes(path) ? "mm-active" : ""}`}>
                  <Link
                    className="has-arrow ai-icon"
                    to="#"
                    aria-expanded="false"
                  >
                    <i class="flaticon-381-controls-3"></i>{" "}
                    <span className="nav-text">Settings</span>
                  </Link>
                  <ul aria-expanded="false">
                    <li>
                      <Link
                        className={`${path === "settings" ? "mm-active" : ""}`}
                        to="/settings"
                      >
                        Settings
                      </Link>
                    </li>
                  </ul>
                </li>
              )}
          </MM>

          {/* <div className="plus-box">
            <p>Create new appointment</p>
          </div> */}
          <div className="copyright pt-3">
            <p>
              <strong>BDSleep Study Management</strong>{" "}
            </p>
            <p>© 2023 All Rights Reserved</p>
          </div>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;

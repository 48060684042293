import React, { Fragment, useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";

import { useAuth } from "../../../context/AuthContext";
import { db } from "../../../firebase";
import {
  setDoc,
  getDocs,
  updateDoc,
  doc,
  collection,
  where,
  query,
  addDoc,
} from "firebase/firestore";

import { notifyTopRight, notifyWarning, notifyError } from "../toast/SetToast";
import Toast from "../toast/SetToast";
import { EquipmentForm } from "./EquipmentForm";

const EquipmentRegister = (props) => {
  const { currentUser } = useAuth();
  const [fileNames, setFilenames] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [incomingDate, setIncomingDate] = useState(new Date());

  const inputRef = useRef({
    serialNumber: "",
    deviceName: "",
    model: "",
    description: "",
    status: "",
    lotNumber: "",
    qty: "",
    incomingDate: "",
  });

  const handleChange = (name) => (e) => {
    inputRef.current[name] = e.target.value;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const queryRef = query(
      collection(db, "equipments"),
      where("serialNumber", "==", inputRef.current.serialNumber)
    );

    const equipmentQuey = await getDocs(queryRef);

    if (equipmentQuey.empty) {
      const formData = {
        serialNumber: inputRef.current.serialNumber,
        deviceName: inputRef.current.deviceName,
        model: inputRef.current.model,
        description: inputRef.current.description,
        lotNumber: inputRef.current.lotNumber,
        status: inputRef.current.status,
        qty: inputRef.current.qty,
        incomingDate: inputRef.current.incomingDate,
        createdBy: currentUser.displayName,
        updatedAt: new Date(),
        createdAt: new Date(),
      };

      const docRef = doc(collection(db, "equipments"));

      console.log("formData", formData);
      await setDoc(docRef, formData).then(() => {
        updateDoc(doc(db, "equipments", docRef.id), {
          equipmentId: docRef.id,
        });
        notifyTopRight("Create success ");
      });

      inputRef.current.serialNumber = "";
      inputRef.current.deviceName = "";
      inputRef.current.model = "";
      inputRef.current.description = "";
      inputRef.current.status = "";
      inputRef.current.lotNumber = "";
      inputRef.current.incomingDate = "";

      // setSelectedFiles([]);
      // setFilenames([]);

      e.target.reset();
    } else {
      notifyError("Equipment serial number already created.");
    }
  };

  return (
    <Fragment>
      <PageTitle
        activeMenu="Equipment Register"
        motherMenu="Equipments"
        motherLink="/equipment-register"
      />
      <Toast />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Equipment Registration</h4>
            </div>

            <div className="card-body">
              <div className="form-validation">
                <form
                  className="form-valide"
                  action="#"
                  method="post"
                  onSubmit={handleSubmit}
                >
                  <div className="row">
                    <div className="col-xl-6">
                      <EquipmentForm
                        onChange={handleChange}
                        initailValues={null}
                        incomingDate={incomingDate}
                      />
                      <br /> <br />
                      <div className="form-group row">
                        <div className="col-lg-8 ml-auto">
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EquipmentRegister;

import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
import { useAuth } from "../../../../context/AuthContext";
import { db } from "../../../../firebase";
import {
  setDoc,
  getDoc,
  updateDoc,
  doc,
  collection,
  getDocs,
  collectionGroup,
  where,
  query,
  orderBy,
  Timestamp,
} from "firebase/firestore";
import {
  setEvent,
  eventTmp,
  paymentPrivilages,
  orderList,
  colorList,
  statusList,
  typeList,
  PatientLinkInfo,
  PatientDropDownForm,
  PatientTextInfo,
} from "../../Booking/BookingUtils";

import orderFormFields from "../../Sales/orderFormFields";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import * as XLSX from "xlsx";

import {
  notifyTopRight,
  notifyWarning,
  notifyError,
} from "../../../components/toast/SetToast";
import Toast from "../../../components/toast/SetToast";
import { connectStorageEmulator } from "firebase/storage";

const CPAPSale = () => {
  const { currentUser } = useAuth();

  const getSaleList = orderFormFields.find(
    (fieldData) => fieldData.label == "Sales Representative"
  );
  const getActivityList = orderFormFields.find(
    (fieldData) => fieldData.label == "Activity"
  );
  const getStatusList = orderFormFields.find(
    (fieldData) => fieldData.label == "Status"
  );

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [nodeList, setNodeList] = useState([]);
  const [nodeIdDocs, setNodeIdDocs] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [customerAllList, setCustomerAllList] = useState([]);

  const [saleList, setSaleList] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [statusList, setStatusList] = useState([]);

  const nodeRef = useRef();
  const customerRef = useRef();
  const saleRef = useRef();
  const activityRef = useRef();
  const statusRef = useRef();

  useEffect(() => {
    fetchAnotherData();
  }, []);

  const fetchAnotherData = async () => {
    try {
      // Use Promise.all for parallel fetching
      const [nodesDocs] = await Promise.all([getDocs(collection(db, "nodes"))]);

      let allCustomers = [];
      let allnodeIdDocs = [];
      let allNodes;
      if (["super_admin","sale"].includes(currentUser.roles[0])) {
        allNodes = nodesDocs.docs.map((doc) => doc.data().nodename);
        for (const nodeDoc of nodesDocs.docs) {
          allnodeIdDocs = [...allnodeIdDocs, nodeDoc.id];
          const customerDocs = await getDocs(
            collection(db, "nodes", nodeDoc.id, "customers")
          );
          const customersData = customerDocs.docs.map((customer) => {
            const customerData = customer.id;
            return customerData;
          });
          allCustomers.push(customersData);
        }
      } else if (currentUser.roles[0] == "sleep_physician") {
        allNodes = nodesDocs.docs.map((doc) => doc.data().nodename);
        for (const nodeDoc of nodesDocs.docs) {
          allnodeIdDocs = [...allnodeIdDocs, nodeDoc.id];
          const customerDocs = await getDocs(
            collection(db, "nodes", nodeDoc.id, "customers")
          );
          const customersData = customerDocs.docs.map((customer) => {
            const customerData = customer.id;
            return customerData;
          });
          allCustomers.push(customersData);
        }
      } else {
        // allNodes = nodesDocs.docs.map((doc) => doc.data().nodename);
        const currentAdmin = await getDoc(
          doc(db, "admins", currentUser.displayName)
        );
        let nodeCode = currentAdmin.data().node;
        // Ensure `nodeCode` is always an array
        if (!Array.isArray(nodeCode)) {
          nodeCode = nodeCode ? [nodeCode] : []; // Convert to array or set to empty
        }

        // Find matching nodes
        const node = nodesDocs.docs.filter((doc) =>
          nodeCode.includes(doc.data().nodecode)
        );

        allnodeIdDocs = node.map((doc) => doc.id);
        allNodes = node.map((doc) => doc.data().nodename);

        for (const id of allnodeIdDocs) {
          const customerDocs = await getDocs(
            collection(db, "nodes", id, "customers")
          );
          const customersData = customerDocs.docs.map((customer) => {
            const customerData = customer.id;
            return customerData;
          });
          allCustomers.push(customersData);
        }
      }
      setCustomerAllList(["All", ...allCustomers]);
      setCustomerList(["All", ...allCustomers[0]]);
      setNodeList(["All", ...allNodes]);
      setNodeIdDocs(["All", ...allnodeIdDocs]);

      setSaleList(["All", ...getSaleList.options]);
      setActivityList(["All", ...getActivityList.options]);
      setStatusList(["All", ...getStatusList.options]);
    } catch (error) {
      console.log("Error fetching data: ", error);
    }
  };

  const buildQuery = (
    borrowingsCollectionGroupRef,
    start,
    end,
    node,
    customer,
    sale,
    activity,
    status
  ) => {
    console.log({ start, end, node, customer, sale, activity, status });
    const startDate = new Date(start);
    const endDate = new Date(end);

    let queryConstraints = [];

    queryConstraints.push(orderBy("receiptDate"));

    const startDateTimestamp = Timestamp.fromDate(startDate);
    const endDateTimestamp = Timestamp.fromDate(endDate);

    queryConstraints.push(where("receiptDate", ">=", startDateTimestamp));
    queryConstraints.push(where("receiptDate", "<=", endDateTimestamp));

    if (node != "All") {
      queryConstraints.push(where("nodeRef", "==", node));
    }
    if (customer != "All") {
      queryConstraints.push(where("refCustomer", "==", customer));
    }
    if (sale != "All") {
      queryConstraints.push(where("sales", "==", sale));
    }
    if (activity != "All") {
      queryConstraints.push(where("activity", "==", activity));
    }
    if (status != "All") {
      queryConstraints.push(where("status", "==", status));
    }
    return query(borrowingsCollectionGroupRef, ...queryConstraints);
  };

  const onExport = async () => {
    const start = startDate;
    const end = endDate;

    const node = nodeRef.current.value;
    const customer = customerRef.current.value;
    const sale = saleRef.current.value;
    const activity = activityRef.current.value;
    const status = statusRef.current.value;

    // Usage of the buildQuery function
    let ordersCollectionGroupRef = collection(db, "orders");
    // Pass your parameters here, including dates if searchTime is true
    let queryHandler = buildQuery(
      ordersCollectionGroupRef,
      start,
      end,
      node,
      customer,
      sale,
      activity,
      status
    );

    const tempOrders = [];
    const docSnap = await getDocs(queryHandler);
    if (docSnap.empty) {
      console.log("No documents found");
    } else {
      docSnap.forEach((trial) => {
        tempOrders.push(trial.data());
      });
    }

    createXLSX(tempOrders, start, end, node, customer, sale, activity, status);
  };

  const formatDateRangeInThai = (startDateTimestamp, endDateTimestamp) => {
    const startDate = new Date(startDateTimestamp);
    const endDate = new Date(endDateTimestamp);

    const options = { year: "numeric", month: "long", day: "numeric" };
    const thaiFormatter = new Intl.DateTimeFormat("th-TH", options);

    const startDay = startDate.getDate();
    const startMonth = startDate.toLocaleString("th-TH", { month: "long" });
    const startYear = startDate.getFullYear();

    const endDay = endDate.getDate();
    const endMonth = endDate.toLocaleString("th-TH", { month: "long" });
    const endYear = endDate.getFullYear();

    if (startYear === endYear) {
      if (startMonth === endMonth) {
        return `${startDay} - ${endDay} ${startMonth} ${startYear}`;
      }
      return `${startDay} ${startMonth} - ${endDay} ${endMonth} ${startYear}`;
    }

    const formattedStartDate = thaiFormatter.format(startDate);
    const formattedEndDate = thaiFormatter.format(endDate);

    return `${formattedStartDate} - ${formattedEndDate}`;
  };

  const getThaiFormat = (date) => {
    // Check if the input is an object with 'seconds' and 'nanoseconds' properties (Firestore Timestamp)
    if (date) {
      if (
        date &&
        typeof date === "object" &&
        "seconds" in date &&
        "nanoseconds" in date
      ) {
        date = new Date(date.seconds * 1000); // Convert to JavaScript Date
      }

      // Check if the input is a Unix timestamp (number)
      if (typeof date === "number") {
        date = new Date(date); // Convert Unix timestamp to JavaScript Date
      }

      const options = { year: "numeric", month: "long", day: "numeric" };
      const thaiFormatter = new Intl.DateTimeFormat("th-TH", options);
      return thaiFormatter.format(date);
    } else {
      return "";
    }
  };

  const createXLSX = async (
    orders,
    start,
    end,
    node,
    customer,
    sale,
    activity,
    status
  ) => {
    // setIsLoading(true);
    const rangeDate = formatDateRangeInThai(start, end);

    var xlsxName = "";
    var sheetName = "";

    var textHeader = "";
    if (node != "All") {
      textHeader = textHeader + node;
      xlsxName = rangeDate + " " + node;
      sheetName = node;

      if (customer != "All") {
        textHeader = textHeader + " - " + customer;
        xlsxName = xlsxName + " - " + customer;
        sheetName = customer;
      }
      if (activity != "All") {
        textHeader = textHeader + " " + activity;
        xlsxName = xlsxName + " " + activity;
        sheetName = sheetName + " " + activity;
      } else {
        textHeader = textHeader + " activity ทั้งหมด";
        xlsxName = xlsxName + " activity ทั้งหมด";
        sheetName = sheetName + " activity ทั้งหมด";
      }
      if (status != "All") {
        textHeader = textHeader + " " + status;
        xlsxName = xlsxName + " " + status;
        sheetName = sheetName + " " + status;
      } else {
        textHeader = textHeader + " status ทั้งหมด";
        xlsxName = xlsxName + " status ทั้งหมด";
        sheetName = sheetName + " status ทั้งหมด";
      }
    } else {
      if (sale != "") {
        textHeader = rangeDate + " Sale " + sale;
        xlsxName = rangeDate + " Sale " + sale;
        sheetName = sale;
      }
    }

    const headerText = [
      textHeader,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ];
    const headers = [
      "ลำดับ",
      "Receipt No.",
      "Receipt Date.",
      "โรงพยาบาลผู้ส่งตรวจ",
      "ชื่อ - นามสกุล",
      "รหัสโรงพยาบาล",
      "BDT's HN",
      "Serial Number",
      "Package",
      "Mask",
      "Size",
      "Sale Representative",
      "Amount",
      "Status",
      "Note",
    ];

    const data = [headerText, headers];
    orders.forEach(async (orderDoc, index) => {
      const row = [];
      const order = orderDoc;
      // const cpapInfo = await getDocs(collection(db,"products"))

      row.push(index + 1);
      row.push(order.receiptNo);
      row.push(
        order.receiptDate.toDate().toLocaleString("th-TH", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        })
      );
      row.push(order.refCustomer);
      row.push(order.firstname + " " + order.lastname);
      row.push(order.refHospital);
      row.push(order.hn);
      row.push(order.productSn);
      row.push(order.salePackage);
      row.push(order.mask);
      row.push(order.maskSize);
      row.push(order.sales);
      row.push(order.amount);
      row.push(order.status);
      row.push(order.note);

      data.push(row);
    });

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "CPAP Sale");
    XLSX.writeFile(wb, `CPAP Sale ${rangeDate}.xlsx`);
    // setIsLoading(false);
  };
  const CustomerList = (nodename) => {
    const index = nodeList.indexOf(nodename);
    return ["All", ...customerAllList[index]];
  };

  return (
    <>
      {!isLoading ? (
        <div className="col-lg-12 mb-5">
          <b>CPAP Sale</b>

          <div className="col-xl-9 col-lg-12 mt-4">
            <div className="form-group row">
              <label className="col-lg-3 col-form-label">
                {"Range Date"}
                <span className="text-danger"></span>
              </label>
              <div className="row ml-1">
                <div className="col-lg-3">
                  <DatePicker
                    className="form-control"
                    selected={startDate}
                    onChange={(date) => setStartDate(date.setHours(0, 0, 0, 0))}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    minDate={null}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/MM/yyyy"
                    // todayButton={true}
                  />
                </div>
                <div className="mt-3">-</div>
                <div className="col-lg-3">
                  <DatePicker
                    className="form-control"
                    selected={endDate}
                    onChange={(date) =>
                      setEndDate(date.setHours(23, 59, 59, 999))
                    }
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/MM/yyyy"
                    todayBtn={true}
                  />
                </div>
              </div>
            </div>
            <PatientDropDownForm
              title="Node"
              id="node-sale"
              refvalue={nodeRef}
              // selected={currentBooking.extendedProps.node}
              options={nodeList}
              onChange={(e) => {
                setCustomerList(CustomerList(e.target.value));
              }}
            ></PatientDropDownForm>
            <PatientDropDownForm
              title="Customer"
              id="customer-sale"
              refvalue={customerRef}
              // selected={currentBooking.extendedProps.customer}
              options={customerList}
            ></PatientDropDownForm>
            <PatientDropDownForm
              title="Status"
              id="status-sale"
              refvalue={statusRef}
              // selected={currentBooking.extendedProps.customer}
              options={statusList}
            ></PatientDropDownForm>
            <PatientDropDownForm
              title="Activity"
              id="activity-sale"
              refvalue={activityRef}
              // selected={currentBooking.extendedProps.customer}
              options={activityList}
            ></PatientDropDownForm>
            <PatientDropDownForm
              title="Sale"
              id="sale-sale"
              refvalue={saleRef}
              // selected={currentBooking.extendedProps.customer}
              options={saleList}
            ></PatientDropDownForm>
            <Button onClick={onExport}>Export</Button>
          </div>
        </div>
      ) : (
        <Col xl={10}>
          <div class="media d-flex align-items-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden"></span>
            </div>
          </div>
        </Col>
      )}
    </>
  );
};

export default CPAPSale;

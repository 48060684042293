import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Badge, Pagination } from "react-bootstrap";
import Paginator from "./Paginator";
import { query, getDocs, where, collectionGroup } from "firebase/firestore";
import { db } from "../../../firebase";

const ProductOrderItem = ({
  orders,
  onEdit,
  onDelete,
  onDownload,
  onSend,
  onNext,
  onPrev,
  totalItems,
  totalPages,
  currentPage,
  itemsPerPage,
  goToPage, // add goToPage
  isStart, // add isStart
  isEnd, // add isEnd
  getNext, // add getNext
  getPrev, // add getPrev
}) => {
  const [loading, setLoading] = useState(false);

  const numberPagination = Array(Math.ceil(totalItems / itemsPerPage))
    .fill()
    .map((_, i) => i + 1);

  const handleDate = (date) => {
    if (date != undefined) {
      try {
        var datetime = new Date(date.toDate()).toLocaleDateString();
        return datetime;
      } catch {
        var newDate = new Date(date);
        var hours = newDate.getHours() + 7;
        newDate = new Date(newDate.setHours(hours));
        var newString = newDate.toLocaleDateString();
        return newString;
      }
    } else {
      return "";
    }
  };
  const shortenText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength - 3) + "...";
    }
    return text;
  };

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table mb-0 table-striped">
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th>Receipt No.</th>
                      <th>Serial Number</th>
                      <th>HN</th>
                      <th>Name</th>
                      <th>Activity</th>
                      <th>Ref Customer</th>
                      <th>Receipt Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody id="orders-table">
                    {!loading ? (
                      orders.map((order, index) => {
                        var variantData = "";
                        var newDateOptions = {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                        };

                        if (order.status == "เสร็จสิ้น") {
                          variantData = "success light";
                        } else if (order.status == "กำลังดำเนินการ") {
                          variantData = "warning light";
                        } else if (order.status == "รอเอกสาร") {
                          variantData = "info light";
                        } else if (order.status == "ยกเลิก") {
                          variantData = "danger dark";
                        }
                        return (
                          <tr className="btn-reveal-trigger">
                            <td className="py-1">
                              {index + 1 + (currentPage - 1) * itemsPerPage}
                            </td>
                            <td>
                              {order.isMailSent && (
                                <i className="fa fa-send"></i>
                              )}
                            </td>
                            <td className="py-2">
                              <Link
                                to="#"
                                onClick={() => onEdit(order.id)}
                                data-toggle="tooltip"
                                data-placement="top"
                                title={order.accessories + "\n฿" + order.amount}
                              >
                                {order.receiptNo}
                              </Link>
                            </td>
                            <td className="py-2">
                              <Link to={"/product-page/" + order.productSn}>
                                {order.productSn}
                              </Link>
                            </td>
                            <td className="py-2">
                              <Link
                                // target="_blank"
                                to={"/patient-page/" + order.hn}
                              >
                                {order.hn}
                              </Link>
                            </td>
                            <td className="py-2">
                              {order.firstname
                                ? `${order.firstname} ${order.lastname}`
                                : ""}
                            </td>
                            <td className="py-2">{order.activity}</td>
                            <td className="py-2">
                              {order.refCustomer
                                ? shortenText(order.refCustomer, 20)
                                : shortenText(order.refHospital, 20)}
                            </td>
                            <td className="py-2">
                              {handleDate(order.receiptDate)}
                            </td>
                            <td className="py-2">
                              <Badge variant={variantData}>
                                {order.status}
                              </Badge>
                            </td>

                            <td className="py-2 text-right">
                              <div className="d-flex">
                                <Link
                                  className="btn btn-primary shadow btn-xs sharp mr-2"
                                  to="#"
                                  onClick={() => onDownload(order.id)}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Export CPAP Receipt and Warranty"
                                >
                                  <i className="fa fa-file-pdf-o"></i>
                                </Link>
                                <Link
                                  className="btn btn-info shadow btn-xs sharp mr-2"
                                  to="#"
                                  onClick={() => onEdit(order.id)}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Edit order details"
                                >
                                  <i className="fa fa-pencil"></i>
                                </Link>
                                <Link
                                  to="#"
                                  className="btn btn-danger shadow btn-xs sharp mr-2"
                                  onClick={() => onDelete(order.id)}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Cancel order"
                                >
                                  <i className="fa fa-close"></i>
                                </Link>
                                <Link
                                  to="#"
                                  className="btn btn-success shadow btn-xs sharp"
                                  onClick={() => onSend(order.id)}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Email to Accountant"
                                >
                                  <i className="fa fa-send"></i>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <div class="media d-flex align-items-center">
                        <div class="spinner-border" role="status">
                          <span class="visually-hidden"></span>
                        </div>
                      </div>
                    )}
                  </tbody>
                </table>

                {/* <Paginator
                  totalPages={totalPages}
                  next={onNext}
                  prev={onPrev}
                /> */}
              </div>
              <div className="d-flex justify-content-between align-items-center mt-3">
                <div className="dataTables_info">
                  Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
                  {totalItems < currentPage * itemsPerPage
                    ? totalItems
                    : currentPage * itemsPerPage}{" "}
                  of {totalItems} entries
                </div>
                <div className="dataTables_paginate paging_simple_numbers">
                  <Pagination
                    className="pagination-primary pagination-circle"
                    size="lg"
                  >
                    {/* <li className="page-item page-indicator " onClick={onPrev}>
                      <Link className="page-link" to="#">
                        <i className="la la-angle-left" />
                      </Link>
                    </li> */}

                    <Pagination.Prev onClick={getPrev} disabled={isStart} />
                    {/* {numberPagination.map((number, i) => (
                      <Pagination.Item
                        className={currentPage === i ? "active" : ""}
                        disabled
                      >
                        {number}
                      </Pagination.Item>
                    ))} */}

                    {pageNumbers.map((number) => (
                      <Pagination.Item
                        key={number}
                        active={number === currentPage}
                        onClick={() => goToPage(number)}
                      >
                        {number}
                      </Pagination.Item>
                    ))}
                    {/* <li className="page-item page-indicator" onClick={onNext}>
                      <Link className="page-link" to="#">
                        <i className="la la-angle-right" />
                      </Link>
                    </li> */}
                    <Pagination.Next onClick={getNext} disabled={isEnd} />
                  </Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProductOrderItem;

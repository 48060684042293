import React, { Fragment, useRef } from "react";
import _ from "lodash";

import RegisterFields from "./RegisterFields";
import TicketFields from "./ticketFields";

export const TicketForm = (props) => {
  const inputRef = useRef({});
  // console.log(props.fileNames);
  const renderFields = () => {
    return _.map(
      TicketFields,
      ({ label, name, type, options, required, disabled, description }) => {
        return (
          <RegisterFields
            name={name}
            label={label}
            type={type}
            value={inputRef.current.name}
            fileNames={props.fileNames}
            defaultfiles={props.defaultfiles}
            options={options}
            required={required}
            initailValue={
              props.initailValues ? props.initailValues[name] : null
            }
            onChange={props.onChange(name)}
            disabled={disabled}
            description={description}
            equipments={props.equipments}
            onViewFile={props.onViewFile}
            ticketId={props.ticketId}
          />
        );
      }
    );
  };

  return <Fragment>{renderFields()}</Fragment>;
};
